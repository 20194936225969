import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { CurrencySymbol } from '../../../Utility/Constants'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { AmountFormat, formatDate } from '../../../Components/CommonFunctions/CommonFunctions'
import { DateTime } from 'luxon'
import { CommonGridToolBarWithFilterTextCustom } from '../../../Components/Common Components/CommonComponents';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import PharmaFilter from '../PharmacyReports/PharmaFilter'

class PharmaRejectionHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            purRejectionList: [],
            searchkey: "",
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterOpen: false,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                "days": 30,
                "vendorList": []
            },
            pageName: 'purchaseRejection',
            total_amount: 0,
        }
    }

    componentDidMount = () => {
        this.getPurRejectionData()
        if (this.props.history?.location?.state?.successMsg) {
            this.successMessage(this.props.history?.location?.state?.successMsg)
            this.props.history.push({ state: {} })
        }
    }

    getPurRejectionData = () => {
        try {
            let vendorId = []
            this.state.filterDatas.vendorList?.forEach(element => vendorId?.push(element?.vendor_id))
            let url = ''
            if (this.props.isReport) {
                url = Serviceurls.PO_RETURN + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&return_type=Against Rejection`
            } else {
                url = Serviceurls.PO_RETURN + `?return_type=Against Rejection`
            }
            this.setState({ isLoader: true })
            RestAPIService.getAll(url)
                .then((response) => {
                    if (response?.data?.status === "success") {
                        this.setState({
                            purRejectionList: response?.data?.data,
                            total_amount: response.data?.total_amount
                        }, () => {
                            this.setState({ isLoader: false })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ isLoader: false })
                })
        } catch (e) {
            this.errorMessage(e.message)
            this.setState({ isLoader: false })
        }
    }

    getPurRejectionPrint = () => {
        try {
            this.setState({ disableBtn: true })
            let vendorId = []
            this.state.filterDatas.vendorList?.forEach(element => vendorId?.push(element?.vendor_id))
            let url = ''
            if (this.props.isReport) {
                url = Serviceurls.PO_RETURN + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&return_type=Against Rejection&export=pdf`
            } else {
                url = Serviceurls.PO_RETURN + `?return_type=Against Rejection`
            }
            RestAPIService.getAll(url)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    )
                    const fileURL = URL.createObjectURL(file)
                    window.open(fileURL)
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false })
                })

        } catch (e) {
            this.errorMessage(e.message)
            this.setState({ disableBtn: false })
        }
    }

    getPurRejectionExcel = () => {
        try {
            this.setState({ disableBtn: true })
            let vendorId = []
            this.state.filterDatas.vendorList?.forEach(element => vendorId?.push(element?.vendor_id))
            let url = ''
            if (this.props.isReport) {
                url = Serviceurls.PO_RETURN + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&return_type=Against Rejection&export=excel`
            } else {
                url = Serviceurls.PO_RETURN + `?return_type=Against Rejection`
            }
            RestAPIService.excelGet(url)
                .then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data;
                        let filename = response.headers["content-disposition"].split("filename=")[1]
                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({ disableBtn: false })
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                    this.setState({ disableBtn: false })
                })

        } catch (e) {
            this.errorMessage(e.message)
            this.setState({ disableBtn: false })
        }
    }

      getPharmaRejectionIndivPrint = (item) => {
        try {
          RestAPIService.getAllPrint(Serviceurls.PHARMA_PURCHASE_REJECTION_PRINT + '?id=' + item?.id)
            .then((response) => {
              const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
            }).catch((error) => {
              if (error?.response?.data?.message) {
                this.errorMessage(error?.response?.data?.message)
              } else {
                this.errorMessage(error.message)
              }
            })
        } catch (error) {
          this.errorMessage(error.message)
        }
      }

    gridToolBar = () => {
        let ExportData = []
        this.state.purRejectionList?.length > 0 && this.state.purRejectionList.map((item) =>
            ExportData.push({
                "S No": item.serial,
                "Rejection Date": formatDate(item.po_return_date),
                "PO Rejection Number": item.po_return_number,
                "Vendor Name": item.vendor_name,
                "Rejection Quantity": item.total_return_quantity,
                "Rejection Amount": item.net_amount,
                "Taxable Amount": item.taxable_amount,
                "GST": item.gst,
                "CGST": item.cgst_amount,
                "SGST": item.sgst_amount,
            }))
        return (
            <Box>
                <CommonGridToolBarWithFilterTextCustom data={ExportData} filename={"Purchase Rejection"} title={"Purchase Rejection"} />
            </Box>
        )
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => { this.getPurRejectionData() })
    }
    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }
    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    render() {
        const { t } = this.props
        this.state.purRejectionList.forEach((element, index) => element.serial = index + 1)
        const columns = [
            {
                field: "serial", flex: 0.01, headerName: t("SNo"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.serial ? params?.row?.serial : "-"}</Box>)
            },
            {
                field: "po_return_date", flex: 0.083, headerName: t("Rejection Date"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'} emed_tid="PO Date">{params?.row?.po_return_date ? formatDate(params?.row?.po_return_date) : "-"}</Box>)
            },
            {
                field: "po_return_number", flex: 0.083, headerName: t("PO Rejection No"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.po_return_number ? params?.row?.po_return_number : "-"}</Box>)
            },
            {
                field: "invoice_number", flex: 0.083, headerName: t("Invoice Number"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_number ? params?.row?.invoice_number : "-"}</Box>)
            },
            {
                field: "vendor_name", flex: 0.083, headerName: t("Vendor Name"),
                renderCell: ({ row }) => (<div>{row?.vendor_name?.length > 14 ?
                    <Tooltip placement="top" title={row?.vendor_name} arrow><div>{row?.vendor_name.slice(0, 12) + "..."}</div></Tooltip>
                    : row?.vendor_name ? row?.vendor_name : "-"}</div>)
            },
            {
                field: "total_return_quantity", flex: 0.083, headerName: t("Rejection Qty"), type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_return_quantity ? params?.row?.total_return_quantity : "0"}</Box>)
            },
            {
                field: "net_amount", flex: 0.085, headerName: t("Rejection Amount") + ` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.net_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: "taxable_amount", flex: 0.083, headerName: t("Taxable Amount") + ` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => ( <Box component={'div'}>{AmountFormat(params?.row?.taxable_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: "gst", flex: 0.083, headerName: t(`GST (${CurrencySymbol})`), type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.gst || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: "cgst_amount", flex: 0.083, headerName: t(`CGST (${CurrencySymbol})`), type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.cgst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: "sgst_amount", flex: 0.083, headerName: t(`SGST (${CurrencySymbol})`), type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.sgst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
        ]
        if (!this.props.isReport) {
            columns.push(
                {
                    field: "action", flex: 0.083, headerName: t("Action"), headerAlign: "center", align: "center",
                    renderCell: (params) => (
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            <Tooltip title={t("View")} placement="top" arrow>
                                <Button onClick={() => {
                                    this.props.history.push(
                                        {
                                            pathname: "/PharmacyPurchase/PurchaseOrder/ReturnPO"
                                            , state: {
                                                purchase_order_id: params?.row?.purchase_order_id,
                                                ReturnData: params?.row,
                                                from: 'purchase_rejection',
                                                purchase_status: 'Returned'
                                            },
                                        })
                                }} className='eMed_usrconf_btn'>
                                    <img src={ImagePaths.View.default} alt="View" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                            <Tooltip title={t("Print")} placement="top" arrow>
                                <Button className='eMed_usrconf_btn'
                                onClick={() => this.getPharmaRejectionIndivPrint(params?.row)}
                                >
                                    <img src={ImagePaths.PrintIcons.default} alt="Print" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                    )
                }
            )
        }
        return (
            <Box component={'div'}>
                {this.props.isReport ?
                    <Box component={'div'} className='eMed_Pharma_rts_header'>
                        <Box component={'div'} flex={0.8} display={'flex'}>
                            {AmountsCard("Total Amount", this.state.total_amount ? this.state.total_amount : 0, false, "white", false, null, 'pur_return_total')}
                        </Box>
                        <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                            <Button emed_tid="rpt_purRtn_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                                <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                            </Button>
                            <Button emed_tid="rpt_purRtn_flt" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getPurRejectionPrint()}>
                                <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                            </Button>
                            <Button emed_tid="rpt_saleRtn_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getPurRejectionExcel()}>
                                <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                            </Button>
                        </Box>
                    </Box>
                    :
                    <Box component={'div'} className='eMed_Purchase_Table_top'>
                        <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                            <Button emed_tid="add_vnd" className='eMed_Filter_Btns' variant='contained' size='small' onClick={() => { this.props.history.push({ pathname: '/PharmacyPurchase/PurchaseRejection/New' }) }} >{`Purchase Rejection`}</Button>
                        </Box>
                    </Box>
                }
                <Box component={'div'} height={this.props.isReport ? '63vh' : '70vh'}>
                    <DataGrid
                        rows={this.state.purRejectionList}
                        columns={columns}
                        getRowId={(row) => row['serial']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        hideFooterSelectedRowCount
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        headerHeight={40}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    number_of_items: false,
                                    state: false,
                                    igst: false
                                }
                            }
                        }}
                        rowsPerPageOptions={[10, 20, 30]}
                        disableSelectionOnClick
                        pagination
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        loading={this.state.isLoader}
                    />
                </Box>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.props.isReport ?
                    <Drawer
                        anchor={'right'}
                        open={this.state.filterOpen}
                        ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                    >
                        <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                    </Drawer> : null}
            </Box>
        )
    }
}
export default withTranslation()(PharmaRejectionHome)