import {
    Autocomplete,
    Box, Button, Checkbox, Chip, Dialog, FormControlLabel, IconButton, InputAdornment, CircularProgress,
    MenuItem,
    Modal,
    Paper,
    Radio,
    Select,
    Stack,
    Switch,
    Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs,
    TextField, Tooltip, Typography
} from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import { Colors } from '../../../../../Styles/Colors'
import { CommonPatientCard, CommonPatientDetails, DeletePopup, ReasonPopupWithRefund } from '../../../../../Components/Common Components/CommonComponents'
import { ImagePaths } from '../../../../../Utility/ImagePaths'
import CommonValidation from '../../../../../Components/CommonFunctions/CommonValidation'
import { CurrencySymbol } from '../../../../../Utility/Constants'
import { AmountFormat, CheckAccessFunc, formatDateAndTimeMeth2, OpenDirectPrint } from '../../../../../Components/CommonFunctions/CommonFunctions'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import { DateTime } from 'luxon'
import { clearCachevalue, getCachevalue, localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import Success from '../../../../../Assets/Animation/Success.gif';
import PercentIcon from '@mui/icons-material/Percent';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CalculateIcon from '@mui/icons-material/Calculate';

let isUser = null;

const Unit_Types = [
    { label: `${CurrencySymbol}`, value: 'rupee' },
    { label: '%', value: 'percentage' }
]

export default class RadBilling extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // Left States
            radBillingList: [],
            isCard: false,
            isCash: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            CashAmount: "",
            ReceivedCashAmount: "",
            CashBalanceAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            value: JSON.parse(localGetItem('radiology_service_code')) ? "two" : 'one',
            editRate: false,
            editUnit: false,
            subAmount: 0,
            roundOff: 0,
            payableAmount: 0,
            totalPayableAmount: 0,
            overAllDiscountAmt: '',
            overAllDiscountPercent: '',
            removable_ids: [],
            billSummaryId: null,
            deletedIndex: null,
            receiveAmount: null,
            cancelBillClicked: false,
            creditChange: false,
            addInsurance: false,
            addCorporate: false,
            paymentCreditType: '',
            corpName: null,
            CropNo: '',
            employeeNo: '',
            insuranceName: null,
            insuranceNo: '',
            PatientID_cred: '',
            CropName: '',
            InsName: '',
            InsNO: '',

            // Right States
            serviceData: [],
            selectedServiceName: '',
            selectedServiceType: '',
            selectedServiceAmount: 0,
            searchServiceName: '',
            searchServiceType: '',
            billDateAndTime: new Date(),
            DateOpen: false,
            serviceTypeList: [],
            serviceNameList: {},
            dupServiceData: '[]',
            dupServiceNameList: '[]',
            billingList: {},
            cancelBillDate: null,
            invoiceNumber: null,

            DemographicData: {},
            patientData: {},
            patientupdData: {},
            deletePopup: false,
            removeData: false,
            deletePopupTwo: false,
            BillReceivedAmount: '',
            receivePaymentPopup: false,
            allowSms: false,
            billingConcessionType: '',
            concessionRestrictAmt: 0,
            serviceCodeDataList: [],
            dupServiceCodeDataList: '[]',
            frequentServiceCodeList: [],
            serviceCodeText: '',
            serviceNameCodeText: '',
            serviceTypeCodeText: '',
            serviceCodeAmt: 0,
            serviceCodeData: null,
            selectedServiceCodeText: '',
            selectedServiceNameCodeText: '',
            selectedServiceTypeCodeText: '',
            showCode: false,
            isFromIPList : false,
            isFromOPAppoint : false,
            // new States for calculation with round off
            overAllDiscountPercent_w_r:'',
            docPercent_w_r:'',
            hospitalPercent_w_r:'',
            isFromSavedBill : false,
            discountReason: '',
            discountReasonPopup: false
        }
    }

    componentDidMount() {
        let concession = localGetItem("billing_concession_type")
        let showCode = JSON.parse(localGetItem('radiology_service_code')) ? JSON.parse(localGetItem('radiology_service_code')) : false
        this.setState({
            billingConcessionType: concession,
            showCode: showCode
        })
        this.getServiceListData()
        if (showCode) { this.getServiceCodeListData() }
        isUser = JSON.parse(localGetItem("loggedInUserInfo"))

        let patientData = JSON.parse(getCachevalue('RadPatientData'))
        let isFromIPBill = JSON.parse(getCachevalue('IsFromIPBill'))
        let isFromOPBill = JSON.parse(getCachevalue('IsFromOPBill'))
        let smsConfig = JSON.parse(localGetItem("smsConfig"))
        let allowSms = smsConfig?.radiology_bill_sms ? smsConfig?.radiology_bill_sms : false
        this.setState({
            patientData: patientData,
            PatientID_cred: patientData?.patient_id ? patientData?.patient_id : this.props.Patient_id ? this.props.Patient_id : '',
            allowSms: allowSms,
            isFromIPList: isFromIPBill,
            isFromOPAppoint: isFromOPBill
        }, () => { if (this.state.PatientID_cred != "") { this.getpatientdemographic(this.state.PatientID_cred) } }
        )

        let CancelBilldatafromLocal = getCachevalue("RadCancelBillData") ? getCachevalue("RadCancelBillData") : "{}";
        let CancelBillData = JSON.parse(CancelBilldatafromLocal)

        if (CancelBillData?.billSummaryId !== this.state.billSummaryId && CancelBillData?.billSummaryId !== "") {
            if (this.props.history?.location?.state?.fromCancelBill || this.props.history?.location?.state?.fromModifyBill) {
                let modifyBill = this.props.history?.location?.state?.fromModifyBill
                this.setState({
                    billSummaryId: CancelBillData?.billSummaryId,
                }, () => {
                    this.getSavedBillList()
                    this.props.history.push({ state: { billSummaryId: CancelBillData?.billSummaryId, fromCancelBill: modifyBill ? false : true, FromBills: CancelBillData?.FromBills, fromModifyBill: modifyBill ? true : false } })
                })
            }
        }
        setInterval(() => { // DateTime refresh on every minute, when the user not change the datetime
            const newMinute = new Date().getMinutes();
            if (this.state.billDateAndTime?.getMinutes() && this.state.billDateAndTime?.getMinutes() !== newMinute) {
                this.setState({
                    billDateAndTime: new Date(),
                });
            }
        }, 1000);

        if (this.props.transmitData !== this.state.transmitData && this.props.transmitData) {
            this.setState({ transmitData: this.props.transmitData }, () => { this.assignBillItem() })
        }
    }

    componentDidUpdate(prevProps) {
        try {
            if (this.props.DamographData !== this.state.DemographicData && prevProps !== this.props && this.props.fromQuickBill && this.props.DamographData !== undefined) {
                this.setState({
                    DemographicData: this.props.DamographData,
                    billDateAndTime: this.props.DamographData?.appointmentDateTime
                })
            }
            if (prevProps.Patient_id != this.props.Patient_id) {
                this.setState({
                    PatientID_cred: this.props.Patient_id ? this.props.Patient_id : ''
                }, () => { if (this.state.PatientID_cred != "") { this.getpatientdemographic(this.state.PatientID_cred) } }
                )
            }

            let { history } = this.props
            if (((history?.location?.state?.billSummaryId !== this.state.billSummaryId) || (history?.location?.state?.billSummaryId !== this.state.billSummaryId))) {
                if (history?.location?.state?.fromModifyBill || history?.location?.state?.fromSavedBill || history?.location?.state?.fromCancelBill) {
                    this.setState({
                        billSummaryId: history?.location?.state?.billSummaryId,
                        isFromSavedBill: history?.location?.state?.fromSavedBill ? history?.location?.state?.fromSavedBill : false
                    }, () => this.getSavedBillList())
                }
            }
            if (this.props.billSummaryId !== this.state.billSummaryId && prevProps !== this.props) {
                if (this.props.billSummaryId) {
                    let Billid = JSON.parse(getCachevalue('RadPatientData'))
                    this.setState({
                        billSummaryId: Billid?.bill_summary_id ? Billid?.bill_summary_id : this.props.billSummaryId
                    }, () => this.getSavedBillList())
                }
            }
            if (this.props.transmitData !== this.state.transmitData && this.props.transmitData !== prevProps.transmitData && this.props.transmitData) {
                this.setState({ transmitData: this.props.transmitData }, () => { this.assignBillItem() })
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getServiceListData = () => {
        try {
            // let date = this.state.templateDate ? this.state.templateDate : this.state.descripDate
            let startDate = new Date()
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let invoice_date = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")}`
            RestAPIService.getAll(Serviceurls.RAD_SERVICE_CONFIG + '?billing_time=' + Aptime + '&billing_date=' + invoice_date)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            serviceData: response.data.data,
                            dupServiceData: JSON.stringify(response.data.data)
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getServiceCodeListData = () => {
        try {
            let LoggedData = JSON.parse(localGetItem("loggedInUserInfo"))
            let clinicId = LoggedData?.clinic_id ? LoggedData?.clinic_id : null
            RestAPIService.getAll(Serviceurls.RAD_SERVICE_CODE_LIST + `?clinic_id=${clinicId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            serviceCodeDataList: response.data.data ? response.data.data : [],
                            dupServiceCodeDataList: JSON.stringify(response.data.data),
                            frequentServiceCodeList: response.data.frequent_service_codes
                        })
                    }
                }).catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getpatientdemographic = (patient_id) => {
        RestAPIService.getAll(Serviceurls.FO_PATIENT_UPDATE + '?patient_id=' + patient_id)
            .then((response) => {
                if (response.data.status === 'success') {
                    this.setState({
                        paymentCreditType: response.data.data.primary_payment_type ? response.data.data.primary_payment_type : "Patient credit",
                        patientupdData: response.data.data,
                    })
                    if (response.data.data?.primary_payment_type == "Insurance credit") {
                        this.setState({
                            InsName: response.data.data?.insurance_company_name ? response.data.data?.insurance_company_name : '',
                            InsNO: response.data.data?.policy_number ? response.data.data?.policy_number : '',
                        })
                    } else if (response.data.data?.primary_payment_type == "Corprate credit") {
                        this.setState({
                            CropName: response.data.data?.company_name ? response.data.data?.company_name : '',
                            CropNo: response.data.data?.id_no ? response.data.data?.id_no : '',
                            CompanyID: response.data.data?.employer_id ? response.data.data?.employer_id : null,
                        })
                    }

                }
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    this.errorMessage(error.response.data.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
    }
    getSavedBillList = () => {
        try {
            RestAPIService.getAll(Serviceurls.RAD_BILL_POST + '?bill_summary_id=' + this.state.billSummaryId + `${this.props?.RadiologyId ? `&radiology_id=${this.props?.RadiologyId}` : ""}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            billingList: response.data.data
                        }, () => {
                            let serviceList = []
                            this.state.billingList?.bill_line_items?.forEach(element => {
                                serviceList?.push({
                                    service_total_amount: element?.total_amount ? element?.total_amount : 0,
                                    discount_amount: element?.discount_amount ? element?.discount_amount : 0,
                                    discount_percentage: element?.discount_percentage ? element?.discount_percentage : 0,
                                    discount_percentage_w_r: element?.discount_percentage ? CommonValidation.formatAmount(element?.discount_percentage) : 0,
                                    service_rate_per_unit: element?.rate_per_unit ? element?.rate_per_unit : 0,
                                    service_unit: element?.units ? element?.units : 1,
                                    service_name: element?.service_name ? element?.service_name : '',
                                    service_type: element?.service_type ? element?.service_type : '',
                                    service_id: element?.service_id ? element?.service_id : null,
                                    line_item_id: element?.id ? element?.id : null,
                                    // radiology_bill_summary_id: element?.radiology_bill_summary_id ? element.radiology_bill_summary_id : null
                                    service_unit_edit: element?.is_edit_unit ? element?.is_edit_unit : false,
                                    service_rate_edit: element?.is_edit_amount ? element?.is_edit_amount : false,
                                    service_discount_edit: element?.is_edit_discount ? element?.is_edit_discount : false,
                                    is_doctor_wise: element?.config_type === "Doctor Wise" ? true : false,
                                    amount_type: 'rupee',
                                    individual_discount_comments: element?.discount_comment ? element?.discount_comment : "",
                                    service_charges: element.config_type === "Doctor Wise" ? {
                                        total_amount: (element?.original_hospital_fee ? element?.original_hospital_fee : 0) + (element?.original_doctor_fee ? element?.original_doctor_fee : 0),
                                        original_doctor_fee: element?.original_doctor_fee ? element?.original_doctor_fee : 0,
                                        original_hospital_fee: element?.original_hospital_fee ? element?.original_hospital_fee : 0,
                                        doctor_fee: element?.doctor_fee ? element?.doctor_fee : 0,
                                        hospital_fee: element?.hospital_fee ? element?.hospital_fee : 0,
                                        doctor_percentage: CommonValidation.amountPercentageWithoutRoundoff(element?.original_doctor_fee ? element?.original_doctor_fee : 0, ((element?.original_hospital_fee ? element?.original_hospital_fee : 0) + (element?.original_doctor_fee ? element?.original_doctor_fee : 0))),
                                        doctor_percentage_w_r: CommonValidation.amountPercentage(element?.original_doctor_fee ? element?.original_doctor_fee : 0, ((element?.original_hospital_fee ? element?.original_hospital_fee : 0) + (element?.original_doctor_fee ? element?.original_doctor_fee : 0))),
                                        hospital_percentage: CommonValidation.amountPercentageWithoutRoundoff(element?.original_hospital_fee ? element?.original_hospital_fee : 0, ((element?.original_hospital_fee ? element?.original_hospital_fee : 0) + (element?.original_doctor_fee ? element?.original_doctor_fee : 0))),
                                        hospital_percentage_w_r: CommonValidation.amountPercentage(element?.original_hospital_fee ? element?.original_hospital_fee : 0, ((element?.original_hospital_fee ? element?.original_hospital_fee : 0) + (element?.original_doctor_fee ? element?.original_doctor_fee : 0))),
                                        discount_doctor_fee: element?.discount_doctor_fee ? element?.discount_doctor_fee : 0,
                                        discount_hospital_fee: element?.discount_hospital_fee ? element?.discount_hospital_fee : 0,
                                        discount_doctor_percentage: element?.discount_doctor_percentage ? element?.discount_doctor_percentage : 0,
                                        discount_doctor_percentage_w_r: element?.discount_doctor_percentage ? CommonValidation.formatAmount(element?.discount_doctor_percentage) : 0,
                                        discount_hospital_percentage: element?.discount_hospital_percentage ? element?.discount_hospital_percentage : 0,
                                        discount_hospital_percentage_w_r: element?.discount_hospital_percentage ? CommonValidation.formatAmount(element?.discount_hospital_percentage) : 0,
                                    } : {}
                                })
                            })
                            this.setState({
                                radBillingList: serviceList,
                                overAllDiscountAmt: this.state.billingList?.discount_amount ? this.state.billingList?.discount_amount : '',
                                overAllDiscountPercent: this.state.billingList?.discount_percentage ? this.state.billingList?.discount_percentage : '',
                                overAllDiscountPercent_w_r: this.state.billingList?.discount_percentage ? CommonValidation.formatAmount(this.state.billingList?.discount_percentage) : '',
                                discountReason: this.state.billingList?.discount_comment ? this.state.billingList?.discount_comment : '',
                                payableAmount: this.state.billingList?.net_amount ? this.state.billingList?.net_amount : 0,
                                subAmount: this.state.billingList?.total_amount ? this.state.billingList?.total_amount : 0,
                                roundOff: this.state.billingList?.round_off ? this.state.billingList?.round_off : 0,
                                cancelBillDate: this.state.billingList?.invoice_date ? this.state.billingList?.invoice_date : '-',
                                invoiceNumber: this.state.billingList?.invoice_number ? this.state.billingList?.invoice_number : '-',
                                receiveAmount: this.state.billingList?.received_amount ? this.state.billingList?.received_amount : 0,
                                BillReceivedAmount: this.state.billingList?.received_amount ? this.state.billingList?.received_amount : 0,
                            }, () => {
                                if (!this.props?.history?.location?.state?.fromCancelBill && !this.props?.CancelFromFO) {
                                    this.setState({
                                        CashAmount: this.state.billingList?.amount_cash ? this.state.billingList?.amount_cash : '',
                                        CardAmount: this.state.billingList?.amount_card ? this.state.billingList?.amount_card : '',
                                        CardTransactionNumber: this.state.billingList?.card_ref_no ? this.state.billingList?.card_ref_no : '',
                                        UPIAmount: this.state.billingList?.upi_amount ? this.state.billingList?.upi_amount : '',
                                        UPINumber: this.state.billingList?.upi_ref_no ? this.state.billingList?.upi_ref_no : '',
                                        BankTfrAmount: this.state.billingList?.bank_transfer_amount ? this.state.billingList?.bank_transfer_amount : '',
                                        BankTfrNumber: this.state.billingList?.bank_ref_no ? this.state.billingList?.bank_ref_no : '',
                                        ChequeAmount: this.state.billingList?.cheque_amount ? this.state.billingList?.cheque_amount : '',
                                        ChequeNumber: this.state.billingList?.cheque_ref_no ? this.state.billingList?.cheque_ref_no : '',
                                        isCash: this.state.billingList?.cash_amount ? true : false,
                                        isCard: this.state.billingList?.card_amount ? true : false,
                                        isUPI: this.state.billingList?.upi_amount ? true : false,
                                        isBankTranfer: this.state.billingList?.bank_transfer_amount ? true : false,
                                        isCheque: this.state.billingList?.cheque_amount ? true : false,
                                    })
                                }
                                if (this.props?.history?.location?.state?.fromSavedBill) {
                                    this.props?.history?.push({ state: { billSummaryId: '' } })
                                }
                                if (this.props?.SavedFromFO) {
                                    this.props.sentDataTodemographics(this.state.billingList?.doctor_id ? this.state.billingList?.doctor_id : null, this.state.billingList?.radiology_id ? this.state.billingList?.radiology_id : null)
                                }
                            })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    assignBillItem = () => {
        const { transmitData } = this.state;
        const serviceList = transmitData?.line_items?.map(element => {
            let ServiceFee = element?.is_emergency ? element?.service_data?.emergency_charges : element?.is_holiyday ? element?.service_data?.holiday_charges : element?.service_data?.normal_charges
            return ({
                service_id: element?.service_id ? element?.service_id : null,
                service_name: element?.service_name ? element?.service_name : '',
                service_type: element?.service_type ? element?.service_type : '',
                service_amount: ServiceFee?.total ? ServiceFee?.total : 0,
                total_price: ServiceFee?.total ? ServiceFee?.total : 0,
                service_total_amount: ServiceFee?.total ? ServiceFee?.total : 0,
                service_rate_per_unit: ServiceFee?.total ? ServiceFee?.total : 0,
                service_unit: 1,
                service_unit_edit: element?.is_edit_unit ? element?.is_edit_unit : false,
                service_rate_edit: element?.is_edit_amount ? element?.is_edit_amount : false,
                service_discount_edit: element?.is_edit_discount ? element?.is_edit_discount : false,
                is_doctor_wise: element?.config_type === "Doctor Wise" ? true : false,
                amount_type: 'rupee',
                discount_amount: "",
                discount_percentage: "",
                service_charges: element?.config_type === "Doctor Wise" ? {
                    total_amount: ServiceFee?.total ? ServiceFee?.total : 0,
                    original_doctor_fee: ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0,
                    original_hospital_fee: ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0,
                    doctor_fee: ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0,
                    hospital_fee: ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0,
                    doctor_percentage: CommonValidation.amountPercentageWithoutRoundoff(ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0, ServiceFee?.total ? ServiceFee?.total : 0),
                    doctor_percentage_w_r: CommonValidation.amountPercentage(ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0, ServiceFee?.total ? ServiceFee?.total : 0),
                    hospital_percentage: CommonValidation.amountPercentageWithoutRoundoff(ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0, ServiceFee?.total ? ServiceFee?.total : 0),
                    hospital_percentage_w_r: CommonValidation.amountPercentage(ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0, ServiceFee?.total ? ServiceFee?.total : 0),
                    discount_doctor_fee: 0,
                    discount_hospital_fee: 0,
                    discount_doctor_percentage: 0,
                    discount_doctor_percentage_w_r: 0,
                    discount_hospital_percentage_w_r: 0,
                } : {}
            })
        }) || [];

        this.setState({
            radBillingList: serviceList
        });
    };

    checkLeftValidation = (data) => {
        try {
            let errorMessage = null;
            if (this.state.radBillingList?.length === 0) {
                errorMessage = 'Add atleast one service'
            } else if ((this.state.isCard && this.state.CardAmount !== '' && this.state.CardTransactionNumber === '') || (this.state.isUPI && this.state.UPIAmount !== '' && this.state.UPINumber === '') ||
                (this.state.isBankTranfer && this.state.BankTfrAmount !== '' && this.state.BankTfrNumber === '') || (this.state.isCheque && this.state.ChequeAmount === '' && this.state.ChequeNumber === '')) {
                errorMessage = 'Enter Transaction Number'
            } else if ((this.state.isCard && this.state.CardAmount === '') || (this.state.isUPI && this.state.UPIAmount === '') || (this.state.isBankTranfer && this.state.BankTfrAmount === '') ||
                (this.state.isCheque && this.state.ChequeAmount === '')) {
                errorMessage = 'Enter amount'
            } else if (data?.bill_type !== 'saved' && (this.state.discountReason === '' && ((this.state.overAllDiscountPercent !== '' && this.state.overAllDiscountPercent !== 0) || (this.state.overAllDiscountAmt !== '' && this.state.overAllDiscountAmt !== 0)))) {
                errorMessage = "Reason is Mandatory.Click Note Icon for enter Reason"
            }

            return errorMessage
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkAmountValid = () => {
        try {
            let states = this.state
            let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
            let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
            let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
            let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
            let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
            let totalAmt = (CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt);
            return totalAmt;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    OverallBillNavigate = (status) => {
        var { history } = this.props;
        if (status === true) {
            if (this.props.history?.location?.state?.fromModifyBill) {
                history.push({ pathname: '/RadiologyHome/Patient/Billing', state: { fromModifyBill: false, billSummaryId: '', SuccessFromCancel: true } })
            } else if (this.props.ModifyFromFO) {
                history.push({ pathname: '/BillingTransaction/OverAllBills' })
            } else {
                history.push({ pathname: '/RadiologyBillingTransaction/OverallBills' })
            }
        }

    }

    postSaveAndCreditBill = (patientData = {}, patientDetails = {}, fromsaveAndCredit = false) => {
        try {
            let selectedPatientData = JSON.parse(getCachevalue('RadPatientData'))
            let serviceLineItems = []; let payment = []; let states = this.state
            if (this.state.isCash) {
                payment.push(1)
            }
            if (this.state.isCard) {
                payment.push(2)
            }
            if (this.state.isUPI) {
                payment.push(3)
            }
            if (this.state.isCheque) {
                payment.push(4)
            }
            if (this.state.isBankTranfer) {
                payment.push(5)
            }
            let amountValid = this.checkAmountValid()
            let startDate = new Date(this.state.billDateAndTime)
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_SIMPLE);
            let invoice_date = this.state.billDateAndTime ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}:00` : ""
            this.state.radBillingList?.forEach(element => {
                if (element?.is_doctor_wise) {
                    serviceLineItems?.push({
                        id: element?.line_item_id ? element?.line_item_id : null,
                        total_amount: element?.service_total_amount ? +element?.service_total_amount : 0,
                        net_amount: +(element.service_total_amount - element.discount_amount),
                        service_name: element?.service_name ? element?.service_name : 0,
                        service_type: element?.service_type ? element?.service_type : 0,
                        discount_percentage: element?.discount_percentage ? +element?.discount_percentage : 0,
                        discount_amount: element?.discount_amount ? +element?.discount_amount : 0,
                        rate_per_unit: element?.service_rate_per_unit ? +element?.service_rate_per_unit : 0,
                        amount: element?.service_total_amount ? +element?.service_total_amount : 0,
                        units: element?.service_unit ? +element?.service_unit : 1,
                        service_id: element?.service_id ? element?.service_id : null,
                        radiology_bill_summary_id: element?.radiology_bill_summary_id,
                        original_doctor_fee: element?.service_charges?.original_doctor_fee ? +element?.service_charges?.original_doctor_fee : 0,
                        original_hospital_fee: element?.service_charges?.original_hospital_fee ? +element?.service_charges?.original_hospital_fee : 0,
                        discount_doctor_fee: element?.service_charges?.discount_doctor_fee ? +element?.service_charges?.discount_doctor_fee : 0,
                        discount_hospital_fee: element?.service_charges?.discount_hospital_fee ? +element?.service_charges?.discount_hospital_fee : 0,
                        discount_doctor_percentage: element?.service_charges?.discount_doctor_percentage ? +element?.service_charges?.discount_doctor_percentage : 0,
                        discount_hospital_percentage: element?.service_charges?.discount_hospital_percentage ? +element?.service_charges?.discount_hospital_percentage : 0,
                        doctor_fee: element?.service_charges?.doctor_fee ? +element?.service_charges?.doctor_fee : 0,
                        hospital_fee: element?.service_charges?.hospital_fee ? +element?.service_charges?.hospital_fee : 0,
                        discount_comment: element?.individual_discount_comments ? element?.individual_discount_comments : "",
                    })
                } else {
                    serviceLineItems?.push({
                        id: element?.line_item_id ? element?.line_item_id : null,
                        total_amount: element?.service_total_amount ? +element?.service_total_amount : 0,
                        net_amount: +(element.service_total_amount - element.discount_amount),
                        service_name: element?.service_name ? element?.service_name : 0,
                        service_type: element?.service_type ? element?.service_type : 0,
                        discount_percentage: element?.discount_percentage ? +element?.discount_percentage : 0,
                        discount_amount: element?.discount_amount ? +element?.discount_amount : 0,
                        rate_per_unit: element?.service_rate_per_unit ? +element?.service_rate_per_unit : 0,
                        amount: element?.service_total_amount ? +element?.service_total_amount : 0,
                        units: element?.service_unit ? +element?.service_unit : 1,
                        service_id: element?.service_id ? element?.service_id : null,
                        radiology_bill_summary_id: element?.radiology_bill_summary_id,
                        original_doctor_fee: 0,
                        original_hospital_fee: 0,
                        discount_doctor_fee: 0,
                        discount_hospital_fee: 0,
                        discount_doctor_percentage: 0,
                        discount_hospital_percentage: 0,
                        doctor_fee: 0,
                        hospital_fee: 0,
                        discount_comment: "",
                    })
                }
            })

            let PaymentStatus = (amountValid === 0) ? 'not paid' : (amountValid < this.state.payableAmount) ? 'partial' : (amountValid === this.state.payableAmount ? 'paid' : 'not paid')
            let data = {
                bill_type: fromsaveAndCredit ? (this.state.isCredit ? 'credit' : 'saved') : "regular",
                bill_line_items: serviceLineItems,
                acc_number: patientDetails?.patient_account_number ? patientDetails?.patient_account_number : patientData?.patient_account_number ? patientData?.patient_account_number : this.state.patientData?.patient_account_number ? this.state.patientData?.patient_account_number : this.state.DemographicData?.patient_account_number ? this.state.DemographicData?.patient_account_number : null,
                patient_id: patientDetails?.patient_id ? patientDetails?.patient_id : patientData?.PatientID ? patientData?.PatientID : this.state.patientData?.patient_id ? this.state.patientData?.patient_id : this.props.ModifyFromFOData?.patient_id ? this.props.ModifyFromFOData?.patient_id : null,
                invoice_date: invoice_date,
                total_amount: +this.state.subAmount,
                round_off: +this.state.roundOff || 0,
                net_amount: this.state.payableAmount,
                payment_type: "",
                amount_cash: this.state.CashAmount ? +this.state.CashAmount : 0,
                amount_card: this.state.CardAmount ? +this.state.CardAmount : 0,
                upi_amount: this.state.UPIAmount ? +this.state.UPIAmount : 0,
                bank_transfer_amount: this.state.BankTfrAmount ? +this.state.BankTfrAmount : 0,
                cheque_amount: this.state.ChequeAmount ? +this.state.ChequeAmount : 0,
                payment_mode: payment?.length > 0 ? payment : [],
                card_ref_no: this.state.CardTransactionNumber ? this.state.CardTransactionNumber : '',
                upi_ref_no: this.state.UPINumber ? this.state.UPINumber : '',
                bank_ref_no: this.state.BankTfrNumber ? this.state.BankTfrNumber : '',
                cheque_ref_no: this.state.ChequeNumber ? this.state.ChequeNumber : '',
                received_amount: amountValid ? +amountValid : 0,
                discount_percentage: +this.state.overAllDiscountPercent,
                discount_amount: +this.state.overAllDiscountAmt,
                ip_admission_id: patientDetails?.ip_admission_id ? patientDetails?.ip_admission_id : patientData?.ip_admission_id ? patientData?.ip_admission_id : this.state.patientData?.ip_admission_id ? this.state.patientData?.ip_admission_id : this.props.ModifyFromFOData?.ip_admission_id ? this.props.ModifyFromFOData?.ip_admission_id : this.state.billingList?.ip_admission_id ? this.state.billingList?.ip_admission_id : null,
                appointment_id: patientDetails?.appointment_id ? patientDetails?.appointment_id : patientData?.appointment_id ? patientData?.appointment_id : this.state.patientData?.appointment_id ? this.state.patientData?.appointment_id : this.props.ModifyFromFOData?.appointment_id ? this.props.ModifyFromFOData?.appointment_id : this.state.billingList?.appointment_id ? this.state.billingList?.appointment_id : null,
                deleted_ids: this.state.removable_ids ? this.state.removable_ids : [],
                bill_summary_id: this.state.billSummaryId,
                doctor_id: patientData?.selectedDocId?.doctor_id ? patientData?.selectedDocId?.doctor_id : selectedPatientData?.doctor_id,
                specialization_id: patientData?.selectedDocId?.specialization_id,
                // clinic_id: isUser?.clinic_id,
                discount_comment: this.state.discountReason ? this.state.discountReason : "",
                payment_status: PaymentStatus,
                credit_type: this.state?.isCredit ? this.state.paymentCreditType : patientDetails.primary_payment_type ? patientDetails.primary_payment_type : 'Patient credit',
                "employee_id": this.state?.paymentCreditType == "Corprate credit" ? this.state?.CropNo : null,
                "company_name": this.state?.paymentCreditType == "Corprate credit" ? this.state?.CropName : "",
                "insurance_company_name": this.state?.paymentCreditType == "Insurance credit" ? this.state?.InsName : "",
                "policy_number": this.state?.paymentCreditType == "Insurance credit" ? this.state?.InsNO : null,
                "employer_id": this.state?.paymentCreditType == "Corprate credit" ? this.state?.CompanyID : null,
                // collected_in: "RADIOLOGY",
                "test_transmit_summary_id": this.state.transmitData?.transmit_id,
                "is_transmitted": this.state.transmitData?.transmit_id ? true : false,
            }

            if (isUser?.module_name === "front_office") {
                data["radiology_id"] = patientData?.SelectedRadiology?.radiology_id ? patientData?.SelectedRadiology?.radiology_id : this.state.DemographicData?.SelectedRadiology?.radiology_id ? this.state.DemographicData?.SelectedRadiology?.radiology_id : null
                data["collected_in"] = "FO RAD"
            } else {
                data["clinic_id"] = isUser?.clinic_id
                data["collected_in"] = "RADIOLOGY"
            }

            let checkValidation = this.checkLeftValidation(data)
            if (checkValidation === null) {
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.RAD_BILL_POST)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            if ((fromsaveAndCredit && !this.state.isCredit) || this.props?.SavedFromFO) {
                                this.successMessage(response.data.message)
                                this.onClearLeftHandler()
                                this.BillingSucessAction()
                            } else {
                                this.setState({
                                    billSummaryId: response.data?.data?.bill_summary_id ? response.data?.data?.bill_summary_id : null,
                                    receivePaymentPopup: true,
                                    // isCredit: false
                                })
                            }
                        }
                    }).catch((error) => {
                        this.setState({ receivePaymentPopup: false })
                        this.setState({ postLoad: false })
                        if (error?.response?.data?.message) {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.setState({ postLoad: false })
                this.errorMessage(checkValidation)
            }
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    BillingSucessAction() {
        try {
            if (this.props.fromQuickBill) {
                this.props.QuickBillPostSucess()
                if (this.props.getRadTransmitCount && typeof this.props.getRadTransmitCount === 'function') {
                    this.props.getRadTransmitCount(true);
                }
            }
            this.onClearLeftHandler()
            let { history } = this.props
            if (history?.location?.state?.fromModifyBill && !history?.location?.state?.FromBills) {
                clearCachevalue('RadCancelBillData')
                clearCachevalue('isForCancelBill')
                // clearCachevalue('RadPatientData')
                this.OverallBillNavigate(true)
                this.setState({ receivePaymentPopup: false })
            } else if (history?.location?.state?.fromModifyBill && history?.location?.state?.FromBills) {
                clearCachevalue('RadCancelBillData')
                clearCachevalue('isForCancelBill')
                this.OverallBillNavigate(true)
                this.setState({ receivePaymentPopup: false })
            } else if (this.props?.ModifyFromFO) {
                clearCachevalue('RadCancelBillData')
                clearCachevalue('isForCancelBill')
                this.props.QuickBillPostSucess(true)
                this.setState({ receivePaymentPopup: false })
            } else if (this.props?.SavedFromFO) {
                this.props.QuickBillPostSucess(true, true)
            }
        } catch (error) {
            this.errorMessage(error.message)

        }
    }

    postRadBillData = (patientData = {}, patientDetails) => {
        try {
            let selectedPatientData = JSON.parse(getCachevalue('RadPatientData'))
            let serviceLineItems = []; let payment = []; let states = this.state
            if (this.state.isCash) {
                payment.push(1)
            }
            if (this.state.isCard) {
                payment.push(2)
            }
            if (this.state.isUPI) {
                payment.push(3)
            }
            if (this.state.isCheque) {
                payment.push(4)
            }
            if (this.state.isBankTranfer) {
                payment.push(5)
            }
            let checkValidation = this.checkLeftValidation();
            let amountValid = this.checkAmountValid()
            let startDate = new Date(this.state.billDateAndTime)
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_SIMPLE);
            let invoice_date = this.state.billDateAndTime ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}:00` : ""

            this.state.radBillingList?.forEach(element => {
                serviceLineItems?.push({
                    service_name: element?.service_name ? element?.service_name : 0,
                    service_type: element?.service_type ? element?.service_type : 0,
                    rate_per_unit: element?.service_rate_per_unit ? +element?.service_rate_per_unit : 0,
                    amount: element?.service_total_amount ? +element?.service_total_amount : 0,
                    units: element?.service_unit ? +element?.service_unit : 1,
                    service_id: element?.service_id ? element?.service_id : null,
                    line_item_id: element?.line_item_id,
                    radiology_bill_summary_id: element?.radiology_bill_summary_id
                })
            })

            let data = {
                billing_details: {
                    bill_line_items: serviceLineItems,
                    acc_number: patientDetails?.patient_account_number ? patientDetails?.patient_account_number : patientData?.patient_account_number ? patientData?.patient_account_number : this.state.patientData?.patient_account_number,
                    patient_id: patientDetails?.patient_id ? patientDetails?.patient_id : patientData?.PatientID ? patientData?.PatientID : this.state.patientData?.patient_id,
                    invoice_date: invoice_date,
                    total_amount: +this.state.subAmount,
                    round_off: +this.state.roundOff,
                    net_amount: this.state.payableAmount,
                    payment_type: "",
                    cash_amount: this.state.CashAmount ? +this.state.CashAmount : 0,
                    card_amount: this.state.CardAmount ? +this.state.CardAmount : 0,
                    upi_amount: this.state.UPIAmount ? +this.state.UPIAmount : 0,
                    bank_transfer_amount: this.state.BankTfrAmount ? +this.state.BankTfrAmount : 0,
                    cheque_amount: this.state.ChequeAmount ? +this.state.ChequeAmount : 0,
                    payment_mode: payment?.length > 0 ? payment : [],
                    card_number: this.state.CardTransactionNumber ? this.state.CardTransactionNumber : '',
                    upi_ref_no: this.state.UPINumber ? this.state.UPINumber : '',
                    bank_ref_no: this.state.BankTfrNumber ? this.state.BankTfrNumber : '',
                    cheque_ref_no: this.state.ChequeNumber ? this.state.ChequeNumber : '',
                    received_amount: amountValid ? +amountValid : 0,
                    discount_percentage: +this.state.overAllDiscountPercent,
                    discount_amount: +this.state.overAllDiscountAmt,
                    ip_admission_id: patientDetails?.ip_admission_id ? patientDetails?.ip_admission_id : patientData?.ip_admission_id ? patientData?.ip_admission_id : this.state.patientData?.ip_admission_id ? this.state.patientData?.ip_admission_id : null,
                    appointment_id: patientDetails?.appointment_id ? patientDetails?.appointment_id : patientData?.appointment_id ? patientData?.appointment_id : this.state.patientData?.appointment_id ? this.state.patientData?.appointment_id : null,
                    removable_line_item_id: this.state.removable_ids ? this.state.removable_ids : [],
                    bill_summary_id: this.state.billSummaryId,
                    doctor_id: patientData?.selectedDocId?.doctor_id ? patientData?.selectedDocId?.doctor_id : selectedPatientData?.doctor_id,
                    specialization_id: patientData?.selectedDocId?.specialization_id,
                    collected_in: 'RADIOLOGY',
                    clinic_id: isUser?.clinic_id
                },
                is_credit: (amountValid < this.state.payableAmount) ? true : false
            }

            if (states.isCash || states.isCard || states.isUPI || states.isBankTranfer || states.isCheque) {
                if (amountValid > this.state.payableAmount) {
                    this.errorMessage("Amount is greater than payable amount")
                } else if (checkValidation === null) {
                    this.setState({ postLoad: true })
                    RestAPIService.create(data, Serviceurls.RAD_BILL_RECEIVED_POST)
                        .then((response) => {
                            if (response.data.status === 'success') {
                                this.successMessage(response.data.message)
                                this.setState({ receivePaymentPopup: true, isCredit: false })
                                if (this.props.fromQuickBill) { this.props.QuickBillPostSucess() }
                                this.onClearLeftHandler()
                                if (this.props.fromQuickBill) {
                                    this.props.history?.push({ pathname: '/RadiologyHome' })
                                    this.setState({ receivePaymentPopup: false })
                                }
                                let { history } = this.props
                                if (history?.location?.state?.fromSavedBill) {
                                    history?.push({ state: { billSummaryId: '', fromSavedBill: false } })
                                    this.setState({ receivePaymentPopup: false })
                                }
                            }
                        }).catch((error) => {
                            this.setState({ postLoad: false })
                            if (error?.response?.data?.message) {
                                this.errorMessage(error.response.data.message)
                            } else {
                                this.errorMessage(error.message)
                            }
                        })
                } else {
                    this.setState({ postLoad: false })
                    this.errorMessage(checkValidation)
                }
            } else {
                this.errorMessage("Select payment mode")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postNewInsurance = () => {
        var states = this.state.DemographicData

        var data = {
            "insurance_name": states.insurance_company_name
        }

        RestAPIService.create(data, Serviceurls.FO_INSURANCE_LIST)
            .then((response) => {
                if (response.data.status === 'success') {
                }
            }).catch((e) => {
                if (e?.response?.data?.message) {
                    this.errorMessage(e.response.data.message)
                } else {
                    this.errorMessage(e.message)
                }
            })
    }

    MandatoryCheck() {
        let states = this.state.DemographicData;
        let MandatoryCount = +(states.mandotory_count)
        let flag = false;
        for (let i = 0; i < MandatoryCount; i++) {
            if (CommonValidation.removeSpace(states[states.layoutData[i].field_name]) === "" || states[states.layoutData[i].field_name] === null) {
                flag = false
                this.errorMessage(`${states.layoutData[i].label} is Mandatory !`)
                break
            } else {
                flag = true
            }
        }
        return flag
    }

    createPatientData(fromsaveAndCredit) {
        try {
            let { history } = this.props;
            let states = this.state.DemographicData;

            var date = new Date(states.dob)

            var Dob = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
            const dataForm = new FormData();

            dataForm.append("first_name", states.first_name === null ? "" : CommonValidation.removeSpace(states.first_name))
            dataForm.append("last_name", states.surname === null ? "" : CommonValidation.removeSpace(states.surname))
            dataForm.append("middle_name", states.middle_name === null ? "" : CommonValidation.removeSpace(states.middle_name))
            dataForm.append("father_husband_name", states.father_husband_name === null ? "" : CommonValidation.removeSpace(states.father_husband_name))
            dataForm.append("father_husband_age", states.father_husband_age === null ? "" : CommonValidation.removeSpace(states.father_husband_age))
            dataForm.append("gender", states.gender === null ? "" : states.gender)
            dataForm.append("title", states.title === null ? "" : states.title)
            dataForm.append("occupation", states.occupation === null ? "" : CommonValidation.removeSpace(states.occupation))
            dataForm.append("dob", Dob === null ? "" : Dob)
            dataForm.append("approx_dob", Dob === null ? "" : Dob)
            dataForm.append("marital_status", states.marital_status === null ? "" : CommonValidation.removeSpace(states.marital_status))
            dataForm.append("mobile_number", states.mobile_no === null ? "" : CommonValidation.removeSpace(states.mobile_no))
            dataForm.append("mobile_number_2", "")
            dataForm.append("email", states.email_id === null ? "" : CommonValidation.removeSpace(states.email_id))
            dataForm.append("address_line_1", states.address_1 === null ? "" : CommonValidation.removeSpace(states.address_1))
            dataForm.append("address_line_2", states.address_2 === null ? "" : CommonValidation.removeSpace(states.address_2))
            dataForm.append("pincode", states.pin_code === null ? "" : CommonValidation.removeSpace(states.pin_code))
            dataForm.append("city_name", states.city === null ? "" : CommonValidation.removeSpace(states.city))
            dataForm.append("state_name", states.state ? CommonValidation.removeSpace(states.state) : "")
            dataForm.append("country", states.country === null ? "" : CommonValidation.removeSpace(states.country))
            dataForm.append("reffered_by", states.referredBy === null ? "" : CommonValidation.removeSpace(states.referredBy))
            dataForm.append("referral_source", states.referralSource === null ? "" : CommonValidation.removeSpace(states.referralSource))
            dataForm.append("id_no", states.employee_id === null ? "" : CommonValidation.removeSpace(states.employee_id))
            dataForm.append("employer_id", states.employer_id === null ? "" : CommonValidation.removeSpace(states.employer_id))
            dataForm.append("caretaker_name", states.emergency_contact_person === null ? "" : CommonValidation.removeSpace(states.emergency_contact_person))
            dataForm.append("caretaker_mobile", states.emergency_contact_mobile === null ? "" : CommonValidation.removeSpace(states.emergency_contact_mobile))
            dataForm.append("caretaker_relationship", states.relationship === null ? "" : CommonValidation.removeSpace(states.relationship))
            dataForm.append("receive_email", states.receive_email === null ? "" : states.receive_email)
            dataForm.append("receive_whatsapp", states.receive_whatsapp === null ? "" : CommonValidation.removeSpace(states.receive_whatsapp))
            dataForm.append("receive_sms", states.receive_sms === null ? "" : states.receive_sms)
            dataForm.append("insurance_company_name", states.insurance_company_name === null ? "" : CommonValidation.removeSpace(states.insurance_company_name))
            dataForm.append("policy_number", states.policy_number === null ? "" : CommonValidation.removeSpace(states.policy_number))
            dataForm.append("guardian", states.guardian === null ? "" : CommonValidation.removeSpace(states.guardian))
            dataForm.append("mother_name", states.mother_name === null ? "" : CommonValidation.removeSpace(states.mother_name))
            dataForm.append("mother_age", states.mother_age === null ? "" : CommonValidation.removeSpace(states.mother_age))
            dataForm.append("old_referral_no", states.old_referral_no === "" ? "" : CommonValidation.removeSpace(states.old_referral_no))
            dataForm.append('clinic_id', isUser?.clinic_id)
            dataForm.append('relationship_label', states.relationship_label === '' ? '' : states.relationship_label)

            if (states.PatientID !== null) { dataForm.append("patient_id", states.PatientID) }
            let ApiUrl = "";
            if (states.PatientID === null) {
                ApiUrl = Serviceurls.FO_PATIENT_CREATE
            }
            else {
                ApiUrl = Serviceurls.FO_PATIENT_UPDATE
            }
            RestAPIService.create(dataForm, ApiUrl)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        if ((states.insurance_company_name != "" && states.insurance_company_name != null) && states.NewInsurance) {
                            this.postNewInsurance()
                        }
                        let patientId = (states?.PatientID === null) ? response?.data?.data : this.state.DemographicData
                        // if (fromsaveAndCredit) {
                        this.postSaveAndCreditBill(this.state.DemographicData, patientId, fromsaveAndCredit)
                        // } else {
                        //     this.postRadBillData(this.state.DemographicData, patientId)
                        // }
                    }
                }).catch((e) => {
                    if (e?.response?.data?.message) {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    DemoValidation(fromsaveAndCredit) {
        try {
            let states = this.state.DemographicData;
            let Email = (states.MandatoryLabels.includes("Email ID") || states.email_id !== "") ? CommonValidation.emailValidation(states.email_id) : true
            Email = states.email_id == null ? true : true;
            let Corporate = (states.employer_id === "" || states.employer_id === null) ? true : (states.employee_id !== "" && states.employee_id !== null)
            let Insurance = (states.insurance_company_name === "" || states.insurance_company_name === null) ? true : (states.policy_number !== "" && states.policy_number !== null)
            if (this.MandatoryCheck()) {
                if (states.mobile_no.length !== 10) { this.errorMessage("Invalid Mobile Number") }
                else if (!Email) { this.errorMessage("Invalid Email ID") }
                else if (!(new Date(states.dob) < new Date() && new Date(states.dob) > new Date("1872-01-01"))) { this.errorMessage("Invalid Date of Birth") }
                else if (!Corporate) { this.errorMessage("Employee ID No is empty") }
                else if (!Insurance) { this.errorMessage("Insurance Policy No is empty") }
                else if (states.selectedSpecId === null) { this.errorMessage("Select Specialization") }
                else if (states.selectedDocId === null) { this.errorMessage("Select Doctor") }
                else if (states.SelectedRadiology === null && isUser?.module_name === "front_office") { this.errorMessage("Select Radiology") }
                // else if (CommonValidation.removeSpace(states.purposeOfVisit) === "") { this.errorMessage("Enter Purpose of Visit") }
                // else if (DateTime.fromJSDate(states.appointmentDateTime).toFormat('yyyy-MM-dd') === "Invalid DateTime" || !(new Date((new Date).getFullYear(), (new Date).getMonth() - 2, 1) < states.appointmentDateTime && new Date((new Date).getFullYear(), (new Date).getMonth() + 3, 0) > states.appointmentDateTime)) { this.errorMessage("Invalid Appointment Date & Time !") }
                else if (this.props.BillFromIP || this.props.BillFromOP) { this.postSaveAndCreditBill(this.state.DemographicData, this.state.patientupdData, fromsaveAndCredit) }
                else { 
                    let RadPatientData = JSON.parse(getCachevalue('RadPatientData'))
                    if(RadPatientData?.patient_id){
                       fromsaveAndCredit ? this.postSaveAndCreditBill({}, {}, true) : this.postSaveAndCreditBill({}, {}, false)
                    }else{
                        this.createPatientData(fromsaveAndCredit)
                    }
                 }
            }
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    postCancelBillData = (comments, ReceiptType) => {
        try {
            let payment = []
            if (this.state.isCash) {
                payment.push(1)
            }
            if (this.state.isCard) {
                payment.push(2)
            }
            if (this.state.isUPI) {
                payment.push(3)
            }
            if (this.state.isCheque) {
                payment.push(4)
            }
            if (this.state.isBankTranfer) {
                payment.push(5)
            }
            let data = {
                add_to_advance: false,
                bank_ref_no: this.state.BankTfrNumber,
                bank_transfer_amount: this.state.BankTfrAmount ? +this.state.BankTfrAmount : 0,
                bill_summary_id: this.state.billSummaryId,
                bill_type: 'cancelled',
                cancel_reason: comments,
                cash_amount: this.state.CashAmount ? +this.state.CashAmount : 0,
                collected_in: 'RADIOLOGY',
                net_amount: this.state.receiveAmount === 0 ? +this.state.subAmount : this.state.payableAmount,
                payment_mode: payment?.length > 0 ? payment : [],
                receipt_type: ReceiptType,
                received_amount: this.state.receiveAmount === 0 ? 0 : +this.state.payableAmount,
                upi_amount: this.state.UPIAmount ? +this.state.UPIAmount : 0,
                upi_ref_no: this.state.UPINumber,
                // clinic_id: this.state.isUser?.clinic_id,
            }
            if (isUser?.module_name === "front_office") {
                data["radiology_id"] = this.props?.ModifyFromFOData?.radiology_id ? this.props?.ModifyFromFOData?.radiology_id : null
                data["collected_in"] = "FO RAD"
            } else {
                data["clinic_id"] = isUser?.clinic_id
                data["collected_in"] = "RADIOLOGY"
            }
            RestAPIService.create(data, Serviceurls.RAD_CANCEL_BILL)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response.data.message)
                        this.setState({ billSummaryId: null })
                        if (this.props.fromQuickBill) { this.props.QuickBillPostSucess() }
                        this.onClearLeftHandler()
                        let { history } = this.props
                        let cancelBillData = JSON.parse(getCachevalue('RadCancelBillData'))
                        if (history?.location?.state?.fromCancelBill && !cancelBillData?.lastBillCancel) {
                            clearCachevalue('RadCancelBillData')
                            clearCachevalue('isForCancelBill')
                            history?.push({ state: { fromCancelBill: false, billSummaryId: '' } })
                        } else {
                            clearCachevalue('RadCancelBillData')
                            clearCachevalue('isForCancelBill')
                            clearCachevalue('RadPatientData')
                            clearCachevalue('IsFromIPBill')
                            clearCachevalue('IsFromOPBill')
                            if (this.props?.CancelFromFO) {
                                this.props.QuickBillPostSucess(true)
                            } else {
                                setTimeout(() => {
                                    history?.push({ pathname: '/RadiologyHome', state: { fromCancelBill: false, billSummaryId: '' } })
                                }, 1000)
                            }

                        }
                    }
                }).catch((e) => {
                    if (e?.response?.data?.message) {
                        this.setState({ cancelBillClicked: false })
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.setState({ cancelBillClicked: false })
                        this.errorMessage(e.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false,
                deletePopupTwo: false,
                removeData: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    ReasonPopupClose = () => {
        this.setState({
            cancelBillClicked: false
        })
    }

    addCancelComments = (comments, ReceiptType) => {
        this.postCancelBillData(comments, ReceiptType)
    }

    handleCheckBox = (e, key) => {
        try {
            var states = this.state
            states[key] = e.target.checked
            this.setState({ states }, () => {
                if (!states.isCash) { this.setState({ CashAmount: "" , ReceivedCashAmount: "",CashBalanceAmount: "",}) }
                if (!states.isCard) { this.setState({ CardAmount: "", CardTransactionNumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
                if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "" ,ReceivedCashAmount: "",CashBalanceAmount: "",}) }
                if (!states.isCheque) { this.setState({ ChequeAmount: "", ChequeNumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
                if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
                // if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && key !== "isCredit" && !states.isCredit) {
                //     this.setState({ CashAmount: this.state.payableAmount, })  // Fetch Full amount in Cash Amount, when only the cash is selected
                // }
                if (key !== "isCredit") {
                    this.AutoFetchbillAmount()
                }
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    AutoFetchbillAmount = () => {
        let FetchingAccess = localGetItem("auto_fetch_bill_amount_to_payment")
        var states = this.state
        if (FetchingAccess === "true") {
            this.setState({
                isCredit: false
            }, () => {
                if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && !states.isCheque) {
                    this.setState({ CashAmount: this.state.payableAmount })
                } else if (!states.isCash && states.isCard && !states.isUPI && !states.isBankTranfer && !states.isCheque) {
                    this.setState({ CardAmount: this.state.payableAmount })
                } else if (!states.isCash && !states.isCard && states.isUPI && !states.isBankTranfer && !states.isCheque) {
                    this.setState({ UPIAmount: this.state.payableAmount })
                } else if (!states.isCash && !states.isCard && !states.isUPI && states.isBankTranfer && !states.isCheque) {
                    this.setState({ BankTfrAmount: this.state.payableAmount })
                } else if (!states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && states.isCheque) {
                    this.setState({ ChequeAmount: this.state.payableAmount })
                }
            })
        }
    }

    renderAmountTextFields = (label, key, Icon) => {
        var states = this.state
        return (
            <TextField
                sx={{ padding: 0, margin: 0, width: "10vw" }}
                className='eMed_CrdPop_Amount_TextFields'
                size='small'
                // disabled={this.state.forCancel}
                label={label}
                autoComplete='off'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={Icon} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if (((number && +e.target.value > 0) && (+e.target.value <= this.state.payableAmount)) || e.target.value === "") {
                        states[key] = e.target.value
                        this.setState({
                            states
                        }, ()=>{
                            if (key === "CashAmount") {
                                this.setState({
                                  CashBalanceAmount: this.state.ReceivedCashAmount === "" ? "" : +e.target.value - +this.state.ReceivedCashAmount
                                })
                              }
                            if(this.checkAmountValid() === +this.state.payableAmount){
                                this.setState({
                                    isCredit: false
                                })
                            }
                        })
                    }
                }}
            />
        )
    }

    renderNumberTextFields = (label, key, Right = false) => {
        var states = this.state
        return (
            <TextField
                className='eMed_CrdPop_Amount_TextFields'
                sx={{ marginLeft: Right ? "1vw" : 0, width: "10vw" }}
                inputProps={{ maxLength: 20 }}
                // disabled={this.state.forCancel}
                disabled ={key === "CashBalanceAmount" || (key === "ReceivedCashAmount" ? (this.state.CashAmount === "" ? true : false) : false)}
                focused={key === "CashBalanceAmount"}
                size='small'
                label={label}
                value={states[key]}
                autoComplete='off'
                onChange={(e) => {
                    let number = CommonValidation.gstnumberValidation(e.target.value);
                    let numberOnly = CommonValidation.numberOnly(e.target.value)
                    if (key === "ReceivedCashAmount") {
                        if (numberOnly || e.target.value === "") {
                          this.setState({
                            ReceivedCashAmount: e.target.value,
                            CashBalanceAmount: e.target.value === "" ? "" : +this.state.CashAmount - +e.target.value
                          })
                        }
                      }
                    else {
                        if (number || e.target.value === "") {
                            states[key] = e.target.value.toUpperCase()
                            this.setState({
                                states
                            })
                        }
                    }
                }}
            />
        )
    }

    renderLftFooter = () => {
        try {
            let states = this.state
            let visibility = this.props.history?.location?.state?.fromModifyBill || this.props?.ModifyFromFO
            let visibilityCancel = this.props.history?.location?.state?.fromCancelBill || this.props?.CancelFromFO
            return (
                <Box component={'div'} height={'17vh'} overflow={'auto'} width={'37vw'} visibility={((visibility) || (visibility && !visibilityCancel) || (visibilityCancel && this.state.receiveAmount === 0)) ? 'hidden' : 'visible'}>
                    <Box ml={"0.5vw"}>
                        <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' disabled={+this.state.overAllDiscountPercent === 100 ? true : false} />} label={"Cash"} />
                        {((visibilityCancel)) ? null :
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} size='small' disabled={+this.state.overAllDiscountPercent === 100 ? true : false} />} label={'Card'} />}
                        <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={'UPI'} disabled={+this.state.overAllDiscountPercent === 100 ? true : false} />
                        <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10vw" }} control={<Checkbox checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={'Bank Transfer'} disabled={+this.state.overAllDiscountPercent === 100 ? true : false} />
                        {((visibilityCancel)) ? null :
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCheque} onChange={(e) => { this.handleCheckBox(e, "isCheque") }} size='small' />} label={"Cheque"} disabled={+this.state.overAllDiscountPercent === 100 ? true : false} />}
                    </Box>
                    <Box component={'div'} display={'flex'} ml={'0.3vw'}>
                        {
                            this.state.isCash ?
                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                    {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                                    {states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && !states.isCheque ?
                                        <>
                                            {
                                                this.state.openCashBalancebox ?
                                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"} alignItems={'center'}>
                                                        {this.renderNumberTextFields("Cash Collected", "ReceivedCashAmount", true)}
                                                        {this.renderNumberTextFields("Balance Amount", "CashBalanceAmount", true)}
                                                        <Box onClick={() => { this.setState({ openCashBalancebox: false }) }} variant='outlined'  sx={{ marginTop:"1vw", marginLeft:"0.5vw", cursor:'pointer'}} size='small'>
                                                            <img src={ImagePaths.ClearIcon.default} style={{ height: '1.5vw', width: "1.5vw" }} alt='close' />
                                                        </Box>                                                        </Box> :
                                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"} alignItems={'center'}>
                                                        <IconButton onClick={() => { this.setState({ openCashBalancebox: true }) }}><CalculateIcon color='primary' /></IconButton>
                                                    </Box>
                                            }
                                        </> : null
                                    }
                                </Box> : null
                        }
                        {
                            this.state.isCard ?
                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                    {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                                    {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                                </Box>
                                : null
                        }
                        {
                            this.state.isUPI ?
                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                    {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                    {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                </Box>
                                : null
                        }
                        {
                            this.state.isBankTranfer ?
                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                    {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                    {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                </Box>
                                : null
                        }
                        {
                            this.state.isCheque ?
                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                    {this.renderAmountTextFields("ChequeAmount", "ChequeAmount", ImagePaths.ChequeIcon.default)}
                                    {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumber", true)}
                                </Box>
                                : null
                        }
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    billingCalculation = (key) => {
        try {
            let { radBillingList } = this.state
            let subAmount = 0; let payableAmount = 0;

            switch (key) {
                case 'subAmount':
                    let tltAmt = 0;
                    radBillingList.forEach(element => {
                        tltAmt = tltAmt + ((element.service_charges.hospital_fee || element.service_charges.hospital_fee === 0) ? element.service_charges?.hospital_fee : (element.service_total_amount - (+element.discount_amount)))
                        subAmount = subAmount + ((+element.service_total_amount) - (+element.discount_amount))
                        this.state.subAmount = +subAmount
                        this.state.concessionRestrictAmt = +this.state.billingConcessionType === 1 ? +subAmount : +tltAmt
                    })
                    return AmountFormat(this.state.subAmount)
                case 'roundOff':
                    this.state.roundOff = CommonValidation.roundOff(this.state.subAmount, this.state.overAllDiscountAmt)
                    return AmountFormat(this.state.roundOff);
                case 'payableAmount':
                    let discount = this.state.overAllDiscountAmt ? +this.state.overAllDiscountAmt : 0
                    payableAmount = +this.state.subAmount - +discount
                    if (this.props.history?.location?.state?.fromCancelBill || this.props?.CancelFromFO) {
                        this.state.payableAmount = this.state.receiveAmount
                    } else {
                        this.state.payableAmount = Math.round(+payableAmount)
                    }
                    return AmountFormat(+this.state.payableAmount)
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderRgtFooter = () => {
        try {
            return (
                <Box component={'div'}>
                    <Box component={'div'} display={'flex'} justifyContent={'space-between'} mb={'0.3vw'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600}>Sub Amount</Typography>
                        <Typography fontSize={'0.9vw'} fontWeight={600}>{this.billingCalculation('subAmount')}</Typography>
                    </Box>
                    <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={'0.3vw'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600}>Discount</Typography>
                        {+this.state.billingConcessionType === 1 ? null : <Box component={'div'}>
                            <Tooltip placement='top' title={`Maximum Concession Should be ${AmountFormat(this.state.concessionRestrictAmt || 0)}`}>
                                <ErrorOutlineIcon color='primary' sx={{ width: '1.3vw', height: '1.3vw' }} />
                            </Tooltip>
                        </Box>}
                        <Box component={'div'}>
                            {this.textBox('overAllDiscountPercent', this.state.overAllDiscountPercent_w_r, '6vw', '2vw', '%')}
                            {this.textBox('overAllDiscountAmt', this.state.overAllDiscountAmt, '6vw', '2vw', AmountFormat(0))}
                        </Box>
                        {(this.state.overAllDiscountPercent || this.state.overAllDiscountAmt) && !this.props.history?.location?.state?.fromCancelBill && !this.props?.CancelFromFO ?
                            <Tooltip placement='top' title={this.state.discountReason ? this.state.discountReason : this.state.savedDiscountReason}>
                                <Box onClick={() => this.setState({ discountReasonPopup: this.state.ForCancel ? false : true, discountReason: this.state.discountReason ? this.state.discountReason : this.state.savedDiscountReason })} component={'img'} src={ImagePaths.LabReportOff.default} width={'1.5vw'} height={'1.5vw'} sx={{ cursor: 'pointer' }} alignSelf={'center'} />
                            </Tooltip> :
                            <Box component={'img'} src={ImagePaths.BillTransUser.default} width={'1vw'} height={'1vw'} alignSelf={'center'} />
                        }
                    </Box>
                    <Box component={'div'} display={'flex'} justifyContent={'space-between'} borderBottom={'2px solid lightgray'} mb={'0.3vw'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600}>Round Off</Typography>
                        <Typography fontSize={'0.9vw'} fontWeight={600}>{this.billingCalculation('roundOff')}</Typography>
                    </Box>
                    <Box component={'div'} display={'flex'} justifyContent={'space-between'} mb={'0.3vw'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600}>Payable Amount</Typography>
                        <Typography fontSize={'0.9vw'} fontWeight={600}>{this.billingCalculation('payableAmount')}</Typography>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearLeftHandler = () => {
        try {
            this.setState({
                isCredit: false,
                isCash: false,
                isCard: false,
                isUPI: false,
                isBankTranfer: false,
                isCheque: false,
                CardAmount: '',
                CardTransactionNumber: '',
                CashAmount: '',
                ReceivedCashAmount: "",
                CashBalanceAmount: "",
                UPIAmount: '',
                UPINumber: '',
                BankTfrAmount: '',
                BankTfrNumber: '',
                ChequeAmount: '',
                ChequeNumber: '',
                radBillingList: [],
                subAmount: 0,
                payableAmount: 0,
                roundOff: 0,
                overAllDiscountAmt: '',
                overAllDiscountPercent: '',
                postLoad: false,
                removable_ids: [],
                billSummaryId: this.props?.SavedFromFO ? this.state.billSummaryId : null,
                cancelBillClicked: false,
                creditChange: false,
                addInsurance: false,
                addCorporate: false,
                paymentCreditType: '',
                corpName: null,
                employeeNo: '',
                insuranceName: null,
                insuranceNo: '',
                concessionRestrictAmt: 0,
                CropName: '',
                CropNo: '',
                InsName: '',
                InsNO: '',
                receivePaymentPopup: false,
                concessionRestrictAmt:0,
                overAllDiscountPercent_w_r:''
            }, () => {
                if (this.props.fromQuickBill && !this.props?.BillFromIP && !this.props?.BillFromOP && !this.props?.ModifyFromFO && !this.props?.CancelFromFO && !this.props?.SavedFromFO) {
                    this.props.ClearClicked()
                }
                this.onClearRightHandler()
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteLftHandler = () => {
        try {
            this.state.removable_ids.push(this.state.radBillingList[this.state.deletedIndex].line_item_id)
            this.state.radBillingList?.splice(this.state.deletedIndex, 1)
            this.setState({
                radBillingList: this.state.radBillingList,
                removable_ids: this.state.removable_ids
            }, () => {
                this.setState({
                    deletedIndex: null,
                    deletePopupTwo: false,
                    removeData: false,
                    overAllDiscountPercent: this.state.radBillingList?.length === 0 ? '' : this.state.overAllDiscountPercent,
                    overAllDiscountPercent_w_r: this.state.radBillingList?.length === 0 ? '' : CommonValidation.formatAmount(this.state.overAllDiscountPercent),
                    subAmount: this.state.radBillingList?.length === 0 ? 0 : this.state.subAmount,
                }, () => {
                    // this.calculateDiscountAmt()
                    // this.onClearLeftHandler()
                    this.setState({
                        overAllDiscountAmt: '',
                        overAllDiscountPercent: '',
                        overAllDiscountPercent_w_r:''
                    })
                })
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandlerLftAll = () => {
        try {
            this.state.radBillingList?.forEach(element => {
                this.state.removable_ids?.push(element?.line_item_id)
            })
            this.setState({
                removable_ids: this.state.removable_ids
            }, () => {
                this.setState({
                    radBillingList: [],
                    deletePopup: false,
                    removeData: false
                }, () => {
                    this.onClearLeftHandler()
                })
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    amtCalculate = (e, name) => {
        let states = JSON.parse(JSON.stringify(this.state))
        let value = e.target.value
        let serviceFee = JSON.parse(JSON.stringify(states.selectedData))
        switch (name) {
            case 'doctorAmt':
                if (CommonValidation.RangeDecimalNumber(value) || value == '') {
                    states.selectedData.service_amount = (+value) + (+states.hospitalAmt)
                    states.selectedData.service_rate_per_unit = serviceFee.service_amount
                    states.selectedData.totalAmount = states.selectedData?.service_rate_per_unit * (+states.selectedData?.service_unit)
                    this.setState({
                        docAmt: +value,
                        selectedData: states.selectedData
                    }, () => {
                        let totalAmt = (+value) + (+states.hospitalAmt)
                        this.setState({
                            totalBillAmt: (+value) + (+states.hospitalAmt)
                        }, () => {
                            this.setState({
                                docPercent: totalAmt ? +((+value / totalAmt) * 100) : 0,
                                docPercent_w_r: totalAmt ? +((+value / totalAmt) * 100).toFixed(2) : 0,
                                hospitalPercent: totalAmt ? +((+states.hospitalAmt / totalAmt) * 100) : 0,
                                hospitalPercent_w_r: totalAmt ? +((+states.hospitalAmt / totalAmt) * 100).toFixed(2) : 0,
                            })
                        })
                    })
                }
                break;
            case 'hospitalAmt':
                if (CommonValidation.RangeDecimalNumber(value) || value == '') {
                    serviceFee.service_amount = (+states.docAmt) + (+value)
                    states.selectedData.service_rate_per_unit = serviceFee.total_amt
                    states.selectedData.service_total_amount = states.selectedData?.service_rate_per_unit * (+states.selectedData?.service_unit)
                    this.setState({
                        hospitalAmt: +value,
                        selectedData: states.selectedData
                    }, () => {
                        let totalAmt = (+states.docAmt) + (+value)
                        this.setState({
                            totalBillAmt: (+states.docAmt) + (+value)
                        }, () => {
                            this.setState({
                                docPercent: totalAmt ? +((+states.docAmt / totalAmt) * 100) : 0,
                                docPercent_w_r: totalAmt ? +((+states.docAmt / totalAmt) * 100).toFixed(2) : 0,
                                hospitalPercent: totalAmt ? +((+value / totalAmt) * 100) : 0,
                                hospitalPercent_w_r: totalAmt ? +((+value / totalAmt) * 100).toFixed(2) : 0,
                            })
                        })
                    })
                }
                break;
            default:
                break;
        }
    }

    CalculateServiceValues = (index) => {
        try {
            let { radBillingList } = this.state
            radBillingList[index].service_total_amount = Math.round(radBillingList[index].service_unit * radBillingList[index].service_rate_per_unit)
            radBillingList[index].service_amount = Math.round(radBillingList[index].service_unit * radBillingList[index].service_rate_per_unit)
            radBillingList[index].service_charges.original_doctor_fee = CommonValidation.calculatePercentage(radBillingList[index]?.service_charges?.doctor_percentage, radBillingList[index]?.service_amount)
            radBillingList[index].service_charges.original_hospital_fee = CommonValidation.calculatePercentage(radBillingList[index]?.service_charges?.hospital_percentage, radBillingList[index]?.service_amount)
            radBillingList[index].service_charges.discount_hospital_fee = radBillingList[index].service_charges?.discount_hospital_percentage ? CommonValidation.calculatePercentage(radBillingList[index].service_charges?.discount_hospital_percentage, radBillingList[index].service_charges.original_hospital_fee) : 0;
            radBillingList[index].service_charges.discount_doctor_fee = radBillingList[index].service_charges?.discount_doctor_percentage ? CommonValidation.calculatePercentage(radBillingList[index].service_charges?.discount_doctor_percentage, radBillingList[index].service_charges.original_doctor_fee) : 0;
            radBillingList[index].discount_amount = radBillingList[index]?.service_charges?.discount_doctor_fee + radBillingList[index]?.service_charges?.discount_hospital_fee
            radBillingList[index].discount_percentage = CommonValidation.amountPercentageWithoutRoundoff(radBillingList[index]?.discount_amount, radBillingList[index]?.service_amount)
            radBillingList[index].discount_percentage_w_r = CommonValidation.amountPercentage(radBillingList[index]?.discount_amount, radBillingList[index]?.service_amount)
            radBillingList[index].service_charges.doctor_fee = CommonValidation.formatAmount(radBillingList[index].service_charges.original_doctor_fee - (radBillingList[index].service_charges.discount_doctor_fee ? radBillingList[index].service_charges.discount_doctor_fee : 0))
            radBillingList[index].service_charges.hospital_fee = CommonValidation.formatAmount(radBillingList[index].service_charges.original_hospital_fee - (radBillingList[index].service_charges.discount_hospital_fee ? radBillingList[index].service_charges.discount_hospital_fee : 0))
            radBillingList[index].discount_amount = radBillingList[index]?.service_charges?.discount_doctor_fee + radBillingList[index]?.service_charges?.discount_hospital_fee
            radBillingList[index].discount_percentage = CommonValidation.amountPercentageWithoutRoundoff(radBillingList[index]?.discount_amount, radBillingList[index]?.service_amount)
            radBillingList[index].discount_percentage_w_r = CommonValidation.amountPercentage(radBillingList[index]?.discount_amount, radBillingList[index]?.service_amount)
            this.setState({ radBillingList }, () => {
                // this.calculateDiscountAmt()
                this.setState({
                    overAllDiscountAmt: '',
                    overAllDiscountPercent: '',
                    overAllDiscountPercent_w_r:''
                })
            })
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }

    newShareSplitUp = () => {
        try {
            let { radBillingList, selectedData } = this.state
            radBillingList[this.state.rowIndex].service_rate_per_unit = this.state.totalBillAmt ? +(+this.state.totalBillAmt / radBillingList[this.state.rowIndex].service_unit).toFixed(2) : 0
            radBillingList[this.state.rowIndex].service_charges.doctor_percentage = this.state.docPercent ? +this.state.docPercent : 0
            radBillingList[this.state.rowIndex].service_charges.doctor_percentage_w_r = this.state.docPercent_w_r ? +this.state.docPercent_w_r : 0
            radBillingList[this.state.rowIndex].service_charges.hospital_percentage = this.state.hospitalPercent ? +this.state.hospitalPercent : 0
            radBillingList[this.state.rowIndex].service_charges.hospital_percentage_w_r = this.state.hospitalPercent_w_r ? +this.state.hospitalPercent_w_r : 0
            radBillingList[this.state.rowIndex].service_charges.original_doctor_fee = this.state.docAmt ? +this.state.docAmt : 0
            radBillingList[this.state.rowIndex].service_charges.original_hospital_fee = this.state.hospitalAmt ? +this.state.hospitalAmt : 0
            radBillingList[this.state.rowIndex].service_charges.discount_hospital_percentage = this.state.hospitalAmt ? radBillingList[this.state.rowIndex]?.service_charges.discount_hospital_percentage : 0
            radBillingList[this.state.rowIndex].service_charges.discount_hospital_percentage_w_r = this.state.hospitalAmt ? CommonValidation.formatAmount(radBillingList[this.state.rowIndex]?.service_charges.discount_hospital_percentage) : 0
            radBillingList[this.state.rowIndex].service_charges.discount_doctor_percentage = this.state.docAmt ? radBillingList[this.state.rowIndex]?.service_charges.discount_doctor_percentage : 0
            radBillingList[this.state.rowIndex].service_charges.discount_doctor_percentage_w_r = this.state.docAmt ? CommonValidation.formatAmount(radBillingList[this.state.rowIndex]?.service_charges.discount_doctor_percentage) : 0
            this.setState({ radBillingList }, () => {
                this.setState({ docSplitPopup: false }, () => {
                    this.CalculateServiceValues(this.state.rowIndex)
                })
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    splitScreenPopup = () => {
        return (
            <Modal open={this.state.docSplitPopup}>
                <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "31vw", height: "60vh" }}>
                    <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                        <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>
                            Split-Up
                        </Typography>
                        <div className="eMed_DialogBox_Close">
                            <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={this.closeSplitPopup} />
                        </div>
                    </div>
                    <hr />
                    {/* <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                        <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>Doctor Consultation</Typography>
                    </div> */}
                    <Box>
                        <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} pl={'0.5vw'}>
                            <Box display={'flex'} justifyContent={'space-between'} pl={'1vw'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600}> Rate / Unit</Typography>
                                <Typography fontWeight={'600'} pl={'1.5vw'}>{AmountFormat((this.state.totalBillAmt / (this.state.selectedData?.service_unit ? this.state.selectedData?.service_unit : 1)) || 0)}</Typography>
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'} pl={'1vw'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>Unit</Typography>
                                <Typography fontWeight={'600'} pl={'1.5vw'}>{this.state.selectedData?.service_unit ? this.state.selectedData?.service_unit : "-"}</Typography>
                            </Box>
                        </Box>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Doctor Share</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField sx={{ width: '10vw' }} label='Amount' size='small' value={this.state.docAmt} onChange={(e) => this.amtCalculate(e, 'doctorAmt')}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.docPercent_w_r}></TextField>
                            </div>
                        </div>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Hospital Share</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField sx={{ width: '10vw' }} label='Amount' size='small' value={this.state.hospitalAmt} onChange={(e) => { this.amtCalculate(e, 'hospitalAmt') }}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.hospitalPercent_w_r}></TextField>
                            </div>
                        </div>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Total</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Amount' value={AmountFormat(this.state.totalBillAmt || 0)}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={"100"}></TextField>
                            </div>
                        </div>
                    </Box>
                    <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            variant='outlined'
                            sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                            onClick={this.closeSplitPopup}
                        >Close</Button>
                        <Button
                            variant='contained'
                            sx={{ width: '5vw', height: '2vw' }}
                            onClick={() => { this.newShareSplitUp() }}
                        >Save</Button>
                    </Box>
                </Paper>
            </Modal>
        )
    }

    addShare = (item, index) => {
        this.setState({
            docAmt: (item?.service_charges?.original_doctor_fee ? item.service_charges?.original_doctor_fee : item.service_charges?.doctor_fee ? item.service_charges?.doctor_fee : ''),
            docPercent: item?.service_charges?.doctor_percentage ? item?.service_charges?.doctor_percentage : '',
            docPercent_w_r: item?.service_charges?.doctor_percentage ? CommonValidation.formatAmount(item?.service_charges?.doctor_percentage) : '',
            hospitalAmt: item?.service_charges?.original_hospital_fee ? item.service_charges?.original_hospital_fee : item.service_charges?.hospital_fee ? item.service_charges?.hospital_fee : '',
            hospitalPercent: item?.service_charges?.hospital_percentage ? item?.service_charges?.hospital_percentage : '',
            hospitalPercent_w_r: item?.service_charges?.hospital_percentage ? CommonValidation.formatAmount(item?.service_charges?.hospital_percentage) : '',
            // totalBillAmt: item?.amount ? item?.amount : '',
            totalBillPercent: '100',
            rowIndex: index,
            selectedData: item,
            dupliSelectedData: JSON.stringify(item),
        }, () => {
            this.setState({ docSplitPopup: true, totalBillAmt: +this.state.docAmt + +this.state.hospitalAmt })
        })
    }

    renderLftScreen = () => {
        try {
            let states = this.state
            let amountValid = this.checkAmountValid()
            let checkInvalidSelection = ((states.isCash ? (states.CashAmount ? false : true) : false) || (states.isCard ? (states.CardAmount ? false : true) : false) || (states.isUPI ? (states.UPIAmount ? false : true) : false) || (states.isBankTranfer ? (states.BankTfrAmount ? false : true) : false) || (states.isCheque ? (states.ChequeAmount ? false : true) : false))
            let paymentSelected = states.isCash || states.isCard || states.isUPI || states.isBankTranfer || states.isCheque
            let visibility = this.props.history?.location?.state?.fromModifyBill || this.props?.ModifyFromFO
            let visibilityCancel = this.props.history?.location?.state?.fromCancelBill || this.props?.CancelFromFO
            let LoggedData = JSON.parse(localGetItem("loggedInUserInfo"))
            return (
                <Box component={'div'}>
                    <Box component={'div'} border={'1px solid lightgray'} height={this.props.fromQuickBill ? '36vh' : '38vh'} overflow={'auto'}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='rad_bill_tbl_head'>
                                        <Box component={'div'} display={'flex'} alignItems={'center'} visibility={visibilityCancel ? 'hidden' : 'visible'}>
                                            <IconButton size='small' sx={{ height: '1.2vw' }} emed_tid='rad_bill_all_dlt_btn'
                                                onClick={() => { this.setState({ deletePopup: true }) }}
                                            >
                                                <Box component={'img'} src={ImagePaths.Delete.default} height={'1.2vw'} width={'1.2vw'} />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                    <TableCell className='rad_bill_tbl_head'>
                                        <Typography fontWeight={600} fontSize={'0.9vw'}>Description</Typography>
                                    </TableCell>
                                    <TableCell className='rad_bill_tbl_head'>
                                        <Box component={'div'} display={'flex'}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'}>Units</Typography>
                                            <Tooltip placement='top' title='Edit'>
                                                <IconButton size='small' sx={{ height: '1.2vw', visibility: visibilityCancel ? 'hidden' : 'visible' }} emed_tid='rad_bill_unt_btn' onClick={() => this.setState({ editUnit: !this.state.editUnit })}>
                                                    <Box component={'img'} src={ImagePaths.NewEditcon.default} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                    <TableCell className='rad_bill_tbl_head' align='right'>
                                        <Box component={'div'} display={'flex'}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'}>Rate Per Unit</Typography>
                                            <Tooltip placement='top' title='Edit'>
                                                <IconButton size='small' sx={{ height: '1.2vw', visibility: visibilityCancel ? 'hidden' : 'visible' }} emed_tid='rad_bill_rate_btn' onClick={() => this.setState({ editRate: !this.state.editRate })} >
                                                    <Box component={'img'} src={ImagePaths.NewEditcon.default} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                    <TableCell className='rad_bill_tbl_head' align='right'>
                                        <Box component={'div'} display={'flex'}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'}>Discount</Typography>
                                            <Tooltip placement='top' title='Edit'>
                                                <IconButton size='small' sx={{ height: '1.2vw', visibility: visibilityCancel ? 'hidden' : 'visible' }} emed_tid='rad_bill_rate_btn' onClick={() => this.setState({ editDiscount: !this.state.editDiscount })} >
                                                    <Box component={'img'} src={ImagePaths.NewEditcon.default} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                    <TableCell className='rad_bill_tbl_head' align='right'>
                                        <Typography fontWeight={600} fontSize={'0.9vw'}>Total Amount</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.radBillingList?.length > 0 ? this.state.radBillingList?.map((item, index) => {
                                    let serviceName = `${item?.service_type ? item?.service_type : '-'} - ${item?.service_name ? item?.service_name : '-'}`
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Box component={'div'} display={'flex'} alignItems={'center'} visibility={visibilityCancel ? 'hidden' : 'visible'}>
                                                    <IconButton size='small' height={'1.2vw'}
                                                        onClick={() => this.setState({ deletePopupTwo: true, deletedDatas: item, deletedIndex: index })}
                                                    >
                                                        <Box component={'img'} src={ImagePaths.Delete.default} height={'1.2vw'} width={'1.2vw'} />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                {serviceName ? serviceName?.length > 20 ?
                                                    <Tooltip placement='top' title={serviceName} arrow><Typography fontSize={'0.9vw'}>{serviceName?.slice(0, 20) + '...'}</Typography></Tooltip> :
                                                    <Typography fontSize={'0.9vw'}>{serviceName}</Typography> : '-'
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {this.state.editUnit && item?.service_unit_edit ?
                                                    <Box component={'div'} display={'flex'} flexDirection={'row'}>
                                                        <Button className='eMed_Bill_add_btn' emed_tid='rad_bill_dec_btn'
                                                            onClick={() => { this.onChangeUnitHandler(item, 'decrease', index) }}
                                                        >
                                                            <Typography>-</Typography>
                                                        </Button>
                                                        <Typography paddingRight={"1vw"}>{item.service_unit}</Typography>
                                                        <Button className='eMed_Bill_add_btn' emed_tid='rad_bill_inc_btn'
                                                            onClick={() => { this.onChangeUnitHandler(item, 'increase', index) }}
                                                        >
                                                            <Typography>+</Typography>
                                                        </Button>
                                                    </Box> :
                                                    <Typography>{item?.service_unit ? item?.service_unit : 1}</Typography>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {this.state.editRate && item?.service_rate_edit ?
                                                    item?.is_doctor_wise ?
                                                        <Button size='small' sx={{ textTransform: "capitalize" }} onClick={() => { this.addShare(item, index) }}>{`Edit Share ${item.service_rate_per_unit ? `(${AmountFormat(item?.service_rate_per_unit)})` : `${CurrencySymbol} 0.00`}`}</Button> :
                                                        <Box component={'div'}>
                                                            {this.textBox('ratePerUnit', item?.service_rate_per_unit, '8vw', '1.7vw', CurrencySymbol, index)}
                                                        </Box>
                                                    :
                                                    <Typography>{item?.service_rate_per_unit ? AmountFormat(item?.service_rate_per_unit)?.replace(`${CurrencySymbol}`, "") : `0.00`}</Typography>}
                                            </TableCell>
                                            <TableCell>
                                                {this.state.editDiscount && item?.service_discount_edit ?
                                                    item?.is_doctor_wise ?
                                                        <Button size='small' sx={{ textTransform: "capitalize" }} onClick={() => { this.onClickAddDiscount(item, index) }}>{`Edit Discount ( ${item.discount_amount ? `${AmountFormat(item?.discount_amount)}` : `${CurrencySymbol} 0.00`} )`}</Button> :
                                                        <Box component={'div'}>
                                                            {this.textBox('discountPercent', item?.discount_percentage_w_r, '4vw', '1.7vw', '%', index)}
                                                            {this.textBox('discountAmount', item?.discount_amount, '4vw', '1.7vw', CurrencySymbol, index)}
                                                        </Box> :
                                                    <Typography>{item?.discount_amount ? AmountFormat(item?.discount_amount)?.replace(`${CurrencySymbol}`, "") : `0.00`}</Typography>}
                                            </TableCell>
                                            <TableCell>
                                                <Typography align='right'>{item?.service_total_amount ? AmountFormat(item?.service_total_amount - (item?.discount_amount ? item?.discount_amount : 0)) : `${CurrencySymbol} 0.00`}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }) :
                                    <TableRow>
                                        <TableCell colSpan={6} align='center'>No Records To Be Shown</TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box component={'div'} border={'1px solid lightgray'} display={'flex'} height={'18vh'} alignItems={'center'}>
                        <Box component={'div'} flex={0.63}>
                            {this.renderLftFooter()}
                        </Box>
                        <Box component={'div'} flex={0.37} p={'0.3vw'}>
                            {this.renderRgtFooter()}
                        </Box>
                    </Box>
                    <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} border={'1px solid lightgray'}>
                        <Box component={'div'} ml={'1vw'} display={'flex'} alignItems={'center'} visibility={LoggedData?.module_name === "front_office" ? !CheckAccessFunc('front_office', 'Billing', 'Radiology Billing', null, 'Tab')?.editAccess ? 'hidden' : 'visible' : 'visible'}>
                            <FormControlLabel control={<Checkbox disabled={(amountValid === this.state.payableAmount || this.props.history?.location?.state?.fromModifyBill || this.props?.ModifyFromFO || this.props?.history?.location?.state?.fromCancelBill || this.props?.CancelFromFO)} checked={this.state.isCredit || this.props.history?.location?.state?.fromModifyBill ? true : this.props?.ModifyFromFO ? true : false} onChange={(e) => { this.handleCheckBox(e, "isCredit") }} size='small' />} label={'Add to Credit'} />
                            <Tooltip title={'Change Credit Type'} placement='top'>
                                <IconButton size='small'
                                    disabled={(amountValid === this.state.payableAmount || this.props.history?.location?.state?.fromModifyBill || this.props?.ModifyFromFO || this.props?.history?.location?.state?.fromCancelBill || this.props?.CancelFromFO)}
                                    onClick={() => {
                                        this.setState({
                                            creditChange: true,
                                        }, () => {
                                            this.getInsuranceDetails()
                                            this.getCorporateDetails()
                                        })
                                    }}>
                                    <img style={{ width: '1.2vw', height: '1.2vw' }} src={!this.state.isCredit ? ImagePaths.outlinedEditDis.default : ImagePaths.ChangeCredit.default} alt='icon' />
                                </IconButton>
                            </Tooltip>
                            {((states?.paymentCreditType == "Insurance" || states?.paymentCreditType == "Insurance credit")) ?
                                <div style={{ marginLeft: "0.5vw" }}>
                                    {this.state.InsName?.length > 25 ?
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ marginRight: "0.5vw", fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>Insurance Patient : </div>
                                            <Tooltip placement='top' title={this.state.InsName} arrow>
                                                <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{"(" + this.state.InsName.slice(0, 25) + "..."}</div>
                                            </Tooltip>
                                            <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{` | ${this.state.InsNO} )`}</div>
                                        </div>
                                        :
                                        <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{`Insurance Patient : ${this.state.InsName}  |  ${this.state.InsNO}`}</div>}
                                </div>
                                : null}
                            {((states?.paymentCreditType == "Corporate" || states?.paymentCreditType == "Corprate credit")) ?
                                <div style={{ marginLeft: "0.5vw" }}>
                                    {this.state.CropName?.length > 25 ?
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ marginRight: "0.5vw", fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>Corporate Patient : </div>
                                            <Tooltip placement='top' title={this.state.CropName} arrow>
                                                <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{"( " + this.state.CropName.slice(0, 25) + "..."}</div>
                                            </Tooltip>
                                            <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{` | ${this.state.CropNo} )`}</div>
                                        </div>
                                        :
                                        <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{`Corporate Patient : ${this.state.CropName}  |  ${this.state.CropNo}`}</div>}
                                </div>
                                : null}
                        </Box>
                        <Box component={'div'} display={'flex'} height={'2.8vw'} alignItems={'center'} visibility={LoggedData?.module_name === "front_office" ? !CheckAccessFunc('front_office', 'Billing', 'Radiology Billing', null, 'Tab')?.editAccess ? 'hidden' : 'visible' : 'visible'}>
                            <Button emed_tid='rad_lft_clr_btn' variant='outlined' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw', visibility: (visibility || visibilityCancel) ? 'hidden' : 'visible' }}
                                onClick={() => this.onClearLeftHandler()}>Clear</Button>
                            <Button emed_tid='rad_lft_sav_btn' variant='outlined' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw', visibility: (visibility || visibilityCancel) ? 'hidden' : 'visible' }}
                                // disabled={CheckAccessFunc('Radiology', "Settings", 'Billing', 'Create Bill', "SubTab")?.editAccess ? this.state.postLoad || paymentSelected : true}
                                disabled={((LoggedData?.module_name === "front_office" ? !(CheckAccessFunc('front_office', 'Billing', 'Radiology Billing', null, 'Tab')?.editAccess) : false) || this.state.postLoad || paymentSelected)}
                                onClick={() => this.setState({ isCredit: false }, () => {
                                    this.props.fromQuickBill ? this.DemoValidation(true) : this.postSaveAndCreditBill({}, {}, true)
                                }
                                )}>Save As Draft</Button>
                            <Button emed_tid='rad_lft_recv_btn' variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw', visibility: (visibility || visibilityCancel) ? 'hidden' : 'visible' }}
                                disabled={(LoggedData?.module_name === "front_office" ? !CheckAccessFunc('front_office', 'Billing', 'Radiology Billing', null, 'Tab')?.editAccess : false) ||  this.state.postLoad || checkInvalidSelection || amountValid > this.state.payableAmount || !(this.state.isCredit ? true : (amountValid === this.state.payableAmount && this.state.radBillingList.length > 0))}
                                // disabled={this.state.postLoad || (!(paymentSelected && !this.state.isCredit && amountValid === this.state.payableAmount))}
                                onClick={() => {
                                    if (this.state.isCredit) {
                                        // if (paymentSelected && (amountValid < this.state.payableAmount)) {
                                        //     this.props.fromQuickBill ? this.DemoValidation() : this.postRadBillData()
                                        // } else {
                                        this.props.fromQuickBill ? this.DemoValidation(true) : this.postSaveAndCreditBill({}, {}, true)
                                        // }
                                    } else {
                                        this.props.fromQuickBill ? this.DemoValidation() : this.postSaveAndCreditBill({}, {}, false)
                                    }
                                }}>{this.state.isCredit ? 'Add to Credit' : 'Receive Payment'}</Button>
                            {visibilityCancel && !visibility ?
                                <Button emed_tid='rad_lft_cacl_btn' variant='contained' color='error'
                                    sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw', width: '10vw' }}
                                    disabled={(this.state.receiveAmount === 0) ? false : (((amountValid !== this.state.payableAmount)) ? true : false)}
                                    onClick={() => {
                                        if (this.state.DemographicData?.SelectedRadiology === null && isUser?.module_name === "front_office") {
                                            this.errorMessage("Select Radiology")
                                        } else {
                                            this.setState({ cancelBillClicked: true })
                                        }
                                    }}>{'Cancel'}</Button> : null
                            }
                            {!visibilityCancel && visibility ?
                                <Button emed_tid='rad_lft_cacl_btn' variant='contained'
                                    sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw', width: '10vw' }}
                                    onClick={() => { this.setState({ isCredit: true }, () => this.postSaveAndCreditBill({}, {}, true)) }}>{'Save Changes'}</Button> : null}
                        </Box>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    getInsuranceDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            insuranceData: response.data.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status) {
                        this.errorMessage(error.response.data.message, 'error')
                    } else {
                        this.errorMessage(error.message, 'error')
                    }
                })
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }
    getCorporateDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            corporateData: response.data.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status) {
                        this.errorMessage(error.response.data.message, 'error')
                    } else {
                        this.errorMessage(error.message, 'error')
                    }
                })
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }
    renderCreditType = () => {
        let { patientData } = this.state
        let details = this.state.patientData
        let ins_name = this.state.patientupdData?.insurance_company_name ? this.state.patientupdData?.insurance_company_name : details?.insurance_company_name ? details?.insurance_company_name : this.state.InsName
        let ins_no = this.state.patientupdData?.policy_no ? this.state.patientupdData?.policy_no : details?.policy_number ? details?.policy_number : this.state.InsNO
        let comp_name = this.state.patientupdData?.company_name ? this.state.patientupdData?.company_name : details?.company_name ? details?.company_name : this.state.CropName
        let employee_no = this.state.patientupdData?.employee_id ? this.state.patientupdData?.employee_id : this.state.patientupdData?.id_no ? this.state.patientupdData?.id_no : details?.id_no ? details?.id_no : this.state.CropNo
        let paymentCreditType = this.state.paymentCreditType == 'Insurance credit' ? 'Insurance credit' : this.state.paymentCreditType == 'Corprate credit' ? 'Corprate credit' : 'Patient credit'
        return (
            <Box>
                <Box component={'div'} display={'flex'} flexDirection={'row'} width={'40vw'} justifyContent={'space-between'}>
                    <Typography fontSize={'1vw'} fontWeight={'bold'}>Select the Credit Type</Typography>
                    <IconButton size='small' onClick={() => {
                        this.setState({
                            creditChange: false,
                            addInsurance: false,
                            addCorporate: false,
                            paymentCreditType: this.state?.selPayemtnCreditType ? this.state?.selPayemtnCreditType : this.state.patientData?.primary_payment_type ? this.state.patientData?.primary_payment_type : this.state.patientupdData?.primary_payment_type ? this.state.patientupdData?.primary_payment_type : '',
                            insuranceName: null,
                            insuranceNo: '',
                            corpName: null,
                            employeeNo: '',
                            InsName: ins_name ? ins_name : this.state.DemographicData?.insurance_company_name ? this.state.DemographicData?.insurance_company_name : '',
                            InsNO: ins_no ? ins_no : this.state.DemographicData?.policy_no ? this.state.DemographicData?.policy_no : '',
                            CropName: comp_name ? comp_name : this.state?.DemographicData?.company_name ? this.state?.DemographicData?.company_name : '',
                            CropNo: employee_no ? employee_no : this.state?.DemographicData?.id_no ? this.state?.DemographicData?.id_no : '',
                            CompanyID: this.state.CompanyID ? this.state.CompanyID : this.state?.DemographicData?.employer_id ? this.state?.DemographicData?.employer_id : ''

                        })
                    }}>
                        <img src={ImagePaths.Close.default} alt="close" style={{ width: '1vw', height: '1vw' }} />
                    </IconButton>
                </Box>
                <Box component={'div'} sx={{ height: '5vw', marginTop: '2vw', display: 'flex' }}>
                    <Box component={'div'} sx={{ width: '13vw', height: '5vw', borderRight: '1px solid lightgray', marginLeft: '1vw' }}>
                        <Typography>Patient Credit</Typography>
                        <FormControlLabel value={'Patient credit'} control={<Radio checked={paymentCreditType === 'Patient credit'} size='small' />}
                            onClick={(e) => {
                                this.setState({
                                    paymentCreditType: e.target.value,
                                    addInsurance: false,
                                    addCorporate: false,
                                    corpName: null,
                                    employeeNo: '',
                                    insuranceName: null,
                                    insuranceNo: ''
                                })
                            }} label="Patient Credit" />
                    </Box>
                    {this.state.patientupdData?.ip_admission_id ?
                        <Box component={'div'} sx={{ width: '15vw', height: '5vw', borderRight: '1px solid lightgray', marginLeft: '1vw' }}>
                            <Box display={'flex'} justifyContent={'space-between'}>
                                <Typography>Insurace Credit</Typography>
                                {ins_name ? <Tooltip title={'edit'} placement='top'>
                                    <IconButton size='small' onClick={() => {
                                        this.setState({
                                            addInsurance: true,
                                            addCorporate: false,
                                            paymentCreditType: 'Insurance credit',
                                            insuranceName: ins_name,
                                            insuranceNo: ins_no,
                                            corpName: null,
                                            employeeNo: ''
                                        })
                                    }}>
                                        <img style={{ width: '.9vw', height: '.9vw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                                    </IconButton>
                                </Tooltip> : null}
                            </Box>
                            {ins_name ?
                                <FormControlLabel value={'Insurance credit'} control={<Radio checked={paymentCreditType === 'Insurance credit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addCorporate: false, InsName: ins_name, InsNo: ins_no }) }}
                                    label={<Box>
                                        {ins_name?.length > 18 ?
                                            <Tooltip title={ins_name} placement='top'><Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{ins_name.slice(0, 18) + '...'}</Typography></Tooltip>
                                            : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{ins_name}</Typography>}
                                        {ins_no?.length > 18 ?
                                            <Tooltip title={ins_no} placement='top'><Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{ins_no.slice(0, 18) + '...'}</Typography></Tooltip>
                                            : <Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{ins_no}</Typography>}
                                    </Box>} /> :
                                <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                                    onClick={() => {
                                        this.setState({
                                            addInsurance: true,
                                            addCorporate: false,
                                            paymentCreditType: 'Insurance credit',
                                            insuranceName: null,
                                            insuranceNo: ''
                                        })
                                    }}
                                >Add Insurance</Button>}
                        </Box>
                        : null}
                    <Box component={'div'} sx={{ width: '15vw', height: '5vw', marginLeft: '1vw' }}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography>Corporate Credit</Typography>
                            {comp_name ? <Tooltip title={'edit'} placement='top'>
                                <IconButton size='small' onClick={() => {
                                    this.setState({
                                        addCorporate: true,
                                        addInsurance: false,
                                        paymentCreditType: 'Corprate credit',
                                        corpName: comp_name,
                                        employeeNo: employee_no,
                                        insuranceName: null,
                                        insuranceNo: '',

                                    })
                                }}>
                                    <img style={{ width: '.9vw', height: '.9vw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                                </IconButton>
                            </Tooltip> : null}
                        </Box>
                        {comp_name ?
                            <FormControlLabel value={'Corprate credit'} control={<Radio checked={paymentCreditType === 'Corprate credit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addInsurance: false, CropName: comp_name, CropNo: employee_no }) }}
                                label={<Box>
                                    {comp_name?.length > 18 ?
                                        <Tooltip title={comp_name} placement='top'><Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{comp_name.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{comp_name}</Typography>}
                                    {employee_no?.length > 18 ?
                                        <Tooltip title={employee_no} placement='top'><Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{employee_no.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{employee_no}</Typography>}
                                </Box>} /> :
                            <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    this.setState({
                                        addCorporate: true,
                                        addInsurance: false,
                                        paymentCreditType: 'Corprate credit',
                                    })
                                }}
                            >
                                Add Corporate</Button>}
                    </Box>
                </Box>
                {this.state.addInsurance ?
                    <Box marginTop={'1vw'} display={'flex'} height={'5vw'}>
                        <Autocomplete
                            size='small'
                            id="combo-box-demo-ins"
                            options={this.state.insuranceData}
                            getOptionLabel={(options) => typeof (options) === 'string' ? options : options.insurance_name}
                            onChange={(e, value) => {
                                this.setState({
                                    insuranceName: value,
                                })
                            }}
                            value={this.state.insuranceName}
                            sx={{ width: '13vw', marginRight: '1vw' }}
                            renderInput={(params) => <TextField {...params} label="Insurance Company Name" />}
                        />
                        <TextField
                            size='small'
                            label="Insurance No"
                            variant="outlined"
                            sx={{ width: '13vw' }}
                            value={this.state.insuranceNo}
                            onChange={(e) => {
                                let alphanum = CommonValidation.alphanumValidation(e.target.value)
                                if (e.target.value === "" || alphanum) {
                                    this.setState({
                                        insuranceNo: e.target.value
                                    })
                                }
                            }}
                        />
                    </Box> :
                    this.state.addCorporate ?
                        <Box marginTop={'1vw'} display={'flex'} height={'5vw'}>
                            <Autocomplete
                                size='small'
                                id="combo-box-demo-ins"
                                options={this.state.corporateData}
                                getOptionLabel={(options) => typeof (options) === 'string' ? options : options.company_name}
                                onChange={(e, value) => {
                                    this.setState({
                                        corpName: value,
                                    })
                                }}
                                value={this.state.corpName}
                                sx={{ width: '13vw', marginRight: '1vw' }}
                                renderInput={(params) => <TextField {...params} label="Corporate Company Name" />}
                            />
                            <TextField
                                size='small'
                                label="Employee ID"
                                variant="outlined"
                                sx={{ width: '13vw' }}
                                value={this.state.employeeNo}
                                onChange={(e) => {
                                    let alphanum = CommonValidation.alphanumValidation(e.target.value)
                                    if (e.target.value === "" || alphanum) {
                                        this.setState({
                                            employeeNo: e.target.value
                                        })
                                    }
                                }} />
                        </Box> : <Box marginTop={'1vw'} display={'flex'} height={'5vw'}></Box>}
                <Box marginTop={'2vw'} display={'flex'} justifyContent={'flex-end'}>
                    <Stack spacing={2} direction="row">
                        <Button size='small' sx={{ textTransform: 'capitalize' }} variant="outlined"
                            onClick={() => {
                                this.setState({
                                    creditChange: false,
                                    addInsurance: false,
                                    addCorporate: false,
                                    paymentCreditType: this.state?.selPayemtnCreditType ? this.state?.selPayemtnCreditType : this.state.patientData?.primary_payment_type,
                                    insuranceName: null,
                                    insuranceNo: '',
                                    corpName: null,
                                    employeeNo: '',
                                    InsName: this.state.InsName ? this.state.InsName : this.state.DemographicData?.insurance_company_name ? this.state.DemographicData?.insurance_company_name : '',
                                    InsNO: this.state.InsNO ? this.state.InsNO : this.state.DemographicData?.policy_no ? this.state.DemographicData?.policy_no : '',
                                    CropName: this.state.CropName ? this.state.CropName : this.state?.DemographicData?.company_name ? this.state?.DemographicData?.company_name : '',
                                    CropNo: this.state.CropNo ? this.state.CropNo : this.state?.DemographicData?.id_no ? this.state?.DemographicData?.id_no : '',
                                    CompanyID: this.state.CompanyID ? this.state.CompanyID : this.state?.DemographicData?.employer_id ? this.state?.DemographicData?.employer_id : ''

                                })
                            }}>Back</Button>
                        <Button size='small' sx={{ textTransform: 'capitalize' }} variant="contained" onClick={() => {
                            if (this.state.addInsurance && (!this.state.insuranceName || !this.state.insuranceNo)) {
                                this.errorMessage("Invalid Insurance Details", 'error')
                            } else if (this.state.addCorporate && (!this.state.corpName || !this.state.employeeNo)) {
                                this.errorMessage("Invalid Corporate Details", 'error')
                            } else {
                                this.setState({
                                    creditChange: false,
                                    // InsName:this.state.insuranceName?.insurance_name,
                                    // CropName: this.state.corpName?.company_name,
                                    // InsNO:this.state.insuranceNo,
                                    // CropNo:this.state.employeeNo,
                                    paymentCreditType: this.state.paymentCreditType == 'Insurance credit' ? 'Insurance credit' : this.state.paymentCreditType == 'Corprate credit' ? 'Corprate credit' : 'Patient credit',
                                    selPayemtnCreditType: this.state.paymentCreditType,
                                    InsName: this.state.insuranceName?.insurance_name ? this.state.insuranceName?.insurance_name : ins_name ? ins_name : this.state.DemographicData?.insurance_company_name ? this.state.DemographicData?.insurance_company_name : '',
                                    InsNO: this.state.insuranceNo ? this.state.insuranceNo : ins_no ? ins_no : this.state.DemographicData?.policy_no ? this.state.DemographicData?.policy_no : '',
                                    CropName: this.state.corpName?.company_name ? this.state.corpName?.company_name : comp_name ? comp_name : this.state?.DemographicData?.company_name ? this.state?.DemographicData?.company_name : '',
                                    CropNo: this.state.employeeNo ? this.state.employeeNo : employee_no ? employee_no : this.state?.DemographicData?.id_no ? this.state?.DemographicData?.id_no : '',
                                    CompanyID: this.state.corpName?.id ? this.state.corpName?.id : this.state.CompanyID ? this.state.CompanyID : this.state?.DemographicData?.employer_id ? this.state?.DemographicData?.employer_id : ''
                                })
                            }
                        }}>Submit</Button>
                    </Stack>
                </Box>
            </Box>
        )
    }
    onChangeUnitHandler = (item, key, index) => {
        try {
            switch (key) {
                case 'decrease':
                    if (item?.service_unit > 1) {
                        item.service_unit--
                        item.service_total_amount = item.service_unit * item.service_rate_per_unit
                        this.setState({ radBillingList: this.state.radBillingList }, () => {
                            if (item?.is_doctor_wise) {
                                this.CalculateServiceValues(index)
                            } else {
                                this.CalculateBillValuse(index)
                            }
                        })
                    }
                    break;
                case 'increase':
                    if (item?.service_unit >= 1) {
                        item.service_unit++
                        item.service_total_amount = item.service_unit * item.service_rate_per_unit
                        this.setState({ radBillingList: this.state.radBillingList }, () => {
                            if (item?.is_doctor_wise) {
                                this.CalculateServiceValues(index)
                            } else {
                                this.CalculateBillValuse(index)
                            }
                        })
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateDiscountAmt = () => {
        try {
            this.state.overAllDiscountAmt = CommonValidation.calculatePercentageWithoutRoundoff(+this.state.subAmount, +this.state.overAllDiscountPercent)
            this.state.overAllDiscountPercent = CommonValidation.amountPercentageWithoutRoundoff(+this.state.overAllDiscountAmt, +this.state.subAmount)
            this.setState({
                overAllDiscountAmt: +this.state.overAllDiscountAmt,
                overAllDiscountPercent: +this.state.overAllDiscountPercent,
                overAllDiscountPercent_w_r: CommonValidation.formatAmount(this.state.overAllDiscountPercent),
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    feeValue = () => {
        try {
            let doctorFee = 0; let hospitalFee = 0;
            let duplicateSelectedValue = JSON.parse(this.state.dupliSelectedData)
            let ipServiceFee = duplicateSelectedValue?.service_charges
            doctorFee = +ipServiceFee?.original_doctor_fee
            hospitalFee = +ipServiceFee?.original_hospital_fee

            return { doctorFee, hospitalFee };
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }

    shareValue = () => {
        try {
            let states = this.state
            let doctorFee = 0; let hospitalFee = 0; let wardName = '';
            let discountDoctorFee = 0; let discountHospitalFee = 0;
            let ipServiceFee = states.selectedData?.service_charges
            doctorFee = CommonValidation.formatAmount(ipServiceFee?.original_doctor_fee)
            hospitalFee = CommonValidation.formatAmount(ipServiceFee?.original_hospital_fee)
            wardName = ipServiceFee?.selected_ward_name
            discountDoctorFee = CommonValidation.formatAmount(ipServiceFee?.discount_doctor_fee)
            discountHospitalFee = CommonValidation.formatAmount(ipServiceFee?.discount_hospital_fee)
            return { doctorFee, hospitalFee, wardName, discountDoctorFee, discountHospitalFee };
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }

    onClickAddDiscount = (item, index) => {
        try {
            let ipServiceFee = item?.service_charges
            let discountFee = ipServiceFee?.discount_doctor_fee ? ipServiceFee?.discount_doctor_fee : 0
            let discountHospitalFee = ipServiceFee?.discount_hospital_fee ? ipServiceFee?.discount_hospital_fee : 0

            ipServiceFee.original_doctor_fee = ipServiceFee.original_doctor_fee ? ipServiceFee.original_doctor_fee : ipServiceFee?.doctor_fee
            ipServiceFee.original_hospital_fee = ipServiceFee.original_hospital_fee ? ipServiceFee.original_hospital_fee : ipServiceFee?.hospital_fee
            ipServiceFee.share_discount = true
            this.setState({
                serviceDiscountpopup: true,
                selectedData: JSON.parse(JSON.stringify(item)),
                dupliSelectedData: JSON.stringify(item),
                selectedType: item?.amount_type,
                discountIndex: index
            }, () => {
                if (this.state.selectedType === 'rupee') {
                    this.setState({
                        discountDoctorFee: ipServiceFee?.discount_doctor_fee ? ipServiceFee?.discount_doctor_fee : 0,
                        discountHospitalFee: ipServiceFee?.discount_hospital_fee ? ipServiceFee?.discount_hospital_fee : 0
                    })
                } else {
                    this.setState({
                        discountPercentDoctorFee: ipServiceFee?.discount_doctor_percentage ? ipServiceFee?.discount_doctor_percentage : 0,
                        discountPercentDoctorFee_w_r: ipServiceFee?.discount_doctor_percentage ? CommonValidation.formatAmount(ipServiceFee?.discount_doctor_percentage) : 0,
                        discountPercentHospitalFee: ipServiceFee?.discount_hospital_percentage ? ipServiceFee?.discount_hospital_percentage : 0,
                        discountPercentHospitalFee_w_r: ipServiceFee?.discount_hospital_percentage ? CommonValidation.formatAmount(ipServiceFee?.discount_hospital_percentage) : 0
                    })
                }
            })

            this.setState({
                IndivDiscountComments: item?.individual_discount_comments ? item?.individual_discount_comments : ""
            })
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }

    CalculateBillValuse = (index) => {
        let { radBillingList } = this.state
        // radBillingList[index]['service_total_amount'] = +radBillingList[index]['service_rate_per_unit'] * radBillingList[index]['service_unit']
        radBillingList[index]['discount_amount'] = CommonValidation.calculatePercentage(radBillingList[index]["service_total_amount"], radBillingList[index]['discount_percentage'])
        radBillingList[index]['discount_percentage'] = CommonValidation.amountPercentageWithoutRoundoff(radBillingList[index]['discount_amount'], radBillingList[index]['service_total_amount'])
        radBillingList[index]['discount_percentage_w_r'] = CommonValidation.amountPercentage(radBillingList[index]['discount_amount'], radBillingList[index]['service_total_amount'])
        this.setState({ radBillingList })
    }

    onChangeHandler = (e, names, index) => {
        try {
            let value = e.target.value
            let { radBillingList } = this.state
            switch (names) {
                case 'ratePerUnit':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        radBillingList[index]['service_rate_per_unit'] = value
                        radBillingList[index]['service_total_amount'] = value * radBillingList[index]['service_unit']
                        this.setState({ radBillingList }, () => { this.CalculateBillValuse(index) })
                    }
                    break;
                case 'discountAmount':
                    if ((CommonValidation.NumberWithDot(value) && +value <= radBillingList[index]['service_total_amount']) || (value === "") || (value === 0)) {
                        radBillingList[index]['discount_percentage'] = CommonValidation.amountPercentageWithoutRoundoff(value, radBillingList[index]['service_total_amount'])
                        radBillingList[index]['discount_percentage_w_r'] = CommonValidation.amountPercentage(value, radBillingList[index]['service_total_amount'])
                        radBillingList[index]['discount_amount'] = value
                        // radBillingList[index]['total_price'] = radBillingList[index]['service_total_amount'] - value
                        this.setState({ radBillingList }, () => {
                            this.CalculateBillValuse(index)
                        })
                    }
                    break;
                case 'discountPercent':
                    if ((CommonValidation.DecimalNumber(value) && +value <= 100) || (value === "") || (value === 0)) {
                        radBillingList[index]['discount_amount'] = CommonValidation.calculatePercentage(value, radBillingList[index]['service_total_amount'])
                        radBillingList[index]['discount_percentage'] = value
                        radBillingList[index]['discount_percentage_w_r'] = value
                        radBillingList[index]['total_price'] = radBillingList[index]['service_total_amount'] - CommonValidation.calculatePercentage(value, radBillingList[index]['service_total_amount'])
                        this.setState({ radBillingList }, () => {
                            // this.calculateDiscountAmt()
                            this.setState({
                                overAllDiscountAmt: '',
                                overAllDiscountPercent: '',
                                overAllDiscountPercent_w_r:''
                            })
                        })
                    }
                    break;
                case 'overAllDiscountAmt':
                    if ((CommonValidation.NumberWithDot(value) && value >= 1 && value <= this.state.concessionRestrictAmt) || value === "") {
                        this.state.overAllDiscountPercent = CommonValidation.amountPercentageWithoutRoundoff(value, this.state.subAmount)
                        this.state.overAllDiscountPercent_w_r = CommonValidation.amountPercentage(value, this.state.subAmount)
                        this.setState({
                            overAllDiscountAmt: value,
                            discountReason: value ? this.state.discountReason : ""
                        }, () => {
                            if (+this.state.overAllDiscountPercent === 100) {
                                this.setState({
                                    isCard: false, isCash: false,
                                    isUPI: false, isBankTranfer: false,
                                    isCheque: false, CashAmount: "", ReceivedCashAmount: "",CashBalanceAmount: "",
                                    CardAmount: "", CardTransactionNumber: "",
                                    UPINumber: "", UPIAmount: "",
                                    BankTfrAmount: "", BankTfrNumber: "",
                                    ChequeNumber: "", ChequeAmount: "",
                                })
                            }
                        })
                    }
                    break;
                case 'overAllDiscountPercent':
                    let percentRestrict = +this.state.billingConcessionType === 1 ? 100 : CommonValidation.amountPercentage(this.state.concessionRestrictAmt, this.state.subAmount)
                    if ((CommonValidation.DecimalNumber(value) && value >= 1 && +value <= percentRestrict) || value === "") {
                        this.state.overAllDiscountAmt = CommonValidation.calculatePercentage(value, this.state.subAmount)
                        this.setState({
                            overAllDiscountPercent: value,
                            overAllDiscountPercent_w_r:value,
                            discountReason: value ? this.state.discountReason : "",
                        }, () => {
                            if (+value === 100) {
                                this.setState({
                                    isCard: false, isCash: false,
                                    isUPI: false, isBankTranfer: false,
                                    isCheque: false, CashAmount: "", ReceivedCashAmount: "", CashBalanceAmount: "",
                                    CardAmount: "", CardTransactionNumber: "",
                                    UPINumber: "", UPIAmount: "",
                                    BankTfrAmount: "", BankTfrNumber: "",
                                    ChequeNumber: "", ChequeAmount: "",
                                })
                            }
                        })
                    }
                    break;
                case 'searchServiceName':
                    this.setState({
                        searchServiceName: value
                    }, () => {
                        let duplicateServiceName = JSON.parse(this.state.dupServiceNameList)
                        if (this.state.searchServiceName !== '') {
                            duplicateServiceName = duplicateServiceName.filter((item) => {
                                return (
                                    (item?.service_name === '' ? '' : item?.service_name?.toLowerCase()?.includes(this.state.searchServiceName?.toLowerCase())))
                            })
                            this.setState({ serviceTypeList: duplicateServiceName, selectedServiceName: '' })
                        } else {
                            this.setState({ serviceTypeList: duplicateServiceName, selectedServiceName: '' })
                        }
                    })
                    break;
                case 'searchServiceType':
                    this.setState({
                        searchServiceType: value
                    }, () => {
                        let dupServiceData = JSON.parse(this.state.dupServiceData)
                        if (this.state.searchServiceType !== '') {
                            dupServiceData = dupServiceData.filter((item) => {
                                return (
                                    (item?.service_type === '' ? '' : item?.service_type?.toLowerCase()?.includes(this.state.searchServiceType?.toLowerCase())))
                            })
                            this.setState({ serviceData: dupServiceData, selectedServiceType: '', selectedServiceName: '', serviceTypeList: [] })
                        } else {
                            this.setState({ serviceData: dupServiceData, selectedServiceType: '', selectedServiceName: '', serviceTypeList: [] })
                        }
                    })
                    break;
                default:
                    this.setState({
                        [names]: value
                    })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    textBox = (names, value, width, height, placeholder, index) => {
        try {
            let visibilityCancel = this.props.history?.location?.state?.fromCancelBill || this.props?.CancelFromFO
            return (
                <TextField
                    autoComplete='off'
                    placeholder={placeholder}
                    size='small'
                    sx={{ width: width }}
                    name={names}
                    value={value}
                    onChange={(e) => this.onChangeHandler(e, names, index)}
                    InputProps={{ style: { height: height } }}
                    inputProps={{ emed_tid: names }}
                    disabled={visibilityCancel || ((names === "overAllDiscountAmt" || names === "overAllDiscountPercent") && this.state.subAmount === 0)}
                    onBlur={(e) => { this.concessionManditoryPopup(names) }}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    concessionManditoryPopup = (names) => {
        if ((this.state.overAllDiscountPercent || this.state.overAllDiscountAmt) && (this.state.discountReason === "")) {
            if (names === 'overAllDiscountPercent' || names === 'overAllDiscountAmt') {
                this.setState({ discountReasonPopup: true })
            }
        }
    }

    onClickChipHandler = (item, serviceCode = false) => {
        try {
            if (this.state.value === "one") {
                this.setState({
                    selectedServiceType: item?.service_type,
                    serviceNameData: item?.service_data
                })
            } else {
                let amount = this.returnServiceAmount(item)
                if (serviceCode) {
                    this.setState({
                        serviceCodeText: '',
                        serviceNameCodeText: '',
                        serviceTypeCodeText: '',
                        serviceCodeAmt: amount ? amount : 0,
                        serviceCodeData: item,
                        selectedServiceCodeText: item?.rad_service_code,
                        selectedServiceNameCodeText: item?.service_name,
                        selectedServiceTypeCodeText: item?.service_type,
                        serviceNameList: item,
                    })
                } else {
                    this.setState({
                        serviceCodeText: item?.rad_service_code,
                        serviceNameCodeText: item?.service_name,
                        serviceTypeCodeText: item?.service_type,
                        serviceCodeData: null,
                        serviceCodeAmt: amount ? amount : 0,
                        selectedServiceCodeText: '',
                        selectedServiceNameCodeText: '',
                        selectedServiceTypeCodeText: '',
                        serviceNameList: item,
                    })
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkRightValidation = () => {
        try {
            let message = null;
            if (this.state.value === "one") {
                if (this.state.selectedServiceType === '') {
                    message = 'Select Service Type'
                } else if (this.state.selectedServiceName === '') {
                    message = 'Select Service Name'
                } else if (this.state.radBillingList?.length > 0) {
                    this.state.radBillingList?.forEach((element, index) => {
                        if (((element.service_type === this.state.selectedServiceType) && (element?.service_name === this.state.selectedServiceName))) {
                            message = 'Service Type and Service Name already exists'
                        }
                    })
                } else {
                    message = null
                }
                return message;
            } else {
                let selectedServiceType = this.state.serviceTypeCodeText ? this.state.serviceTypeCodeText : this.state.selectedServiceTypeCodeText
                let selectedServiceName = this.state.serviceNameCodeText ? this.state.serviceNameCodeText : this.state.selectedServiceNameCodeText
                if (selectedServiceName === "" || selectedServiceType === '') {
                    message = 'Select Service'
                } else if (this.state.radBillingList?.length > 0) {
                    this.state.radBillingList?.forEach((element, index) => {
                        if (((element.service_type === selectedServiceType) && (element?.service_name === selectedServiceName))) {
                            message = 'Service Type and Service Name already exists'
                        }
                    })
                } else {
                    message = null
                }
                return message;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    onAddServiceHandler = () => {
        try {
            let ServiceFee = this.state.serviceNameList?.is_emergency ? this.state.serviceNameList?.service_data?.emergency_charges : this.state.serviceNameList?.is_holiyday ? this.state.serviceNameList?.service_data?.holiday_charges : this.state.serviceNameList?.service_data?.normal_charges

            let radBillingList = {
                service_id: this.state.serviceNameList?.id ? this.state.serviceNameList?.id : null,
                service_name: this.state.serviceNameList?.service_name ? this.state.serviceNameList?.service_name : '',
                service_type: this.state.serviceNameList?.service_type ? this.state.serviceNameList?.service_type : '',
                service_amount: ServiceFee?.total ? ServiceFee?.total : 0,
                total_price: ServiceFee?.total ? ServiceFee?.total : 0,
                service_total_amount: ServiceFee?.total ? ServiceFee?.total : 0,
                service_rate_per_unit: ServiceFee?.total ? ServiceFee?.total : 0,
                service_unit: 1,
                service_unit_edit: this.state.serviceNameList?.is_edit_unit ? this.state.serviceNameList?.is_edit_unit : false,
                service_rate_edit: this.state.serviceNameList?.is_edit_amount ? this.state.serviceNameList?.is_edit_amount : false,
                service_discount_edit: this.state.serviceNameList?.is_edit_discount ? this.state.serviceNameList?.is_edit_discount : false,
                is_doctor_wise: this.state.serviceNameList?.config_type === "Doctor Wise" ? true : false,
                amount_type: 'rupee',
                discount_amount: "",
                discount_percentage: "",
                discount_percentage_w_r: "",
                service_charges: {},
            }
            if (this.state.serviceNameList?.config_type === "Doctor Wise") {
                radBillingList['service_charges'] = {
                    total_amount: ServiceFee?.total ? ServiceFee?.total : 0,
                    original_doctor_fee: ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0,
                    original_hospital_fee: ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0,
                    doctor_fee: ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0,
                    hospital_fee: ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0,
                    doctor_percentage: CommonValidation.amountPercentage(ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0, ServiceFee?.total ? ServiceFee?.total : 0),
                    hospital_percentage: CommonValidation.amountPercentage(ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0, ServiceFee?.total ? ServiceFee?.total : 0),
                    discount_doctor_fee: 0,
                    discount_hospital_fee: 0,
                    discount_doctor_percentage: 0,
                    discount_hospital_percentage: 0,
                }
            }

            let check = this.checkRightValidation()
            if (check === null) {
                let data = CommonValidation.updateOrInsertList(this.state.radBillingList, radBillingList, null)
                this.setState({
                    radBillingList: data
                }, () => {
                    this.onClearRightHandler()
                    this.setState({ overAllDiscountAmt: '', overAllDiscountPercent: '', overAllDiscountPercent_w_r: '' })
                })
            } else {
                this.errorMessage(check)
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearRightHandler = () => {
        try {
            this.setState({
                selectedServiceAmount: 0,
                selectedServiceName: '',
                selectedServiceType: '',
                serviceNameList: {},
                searchServiceName: '',
                searchServiceType: '',
                serviceTypeList: [],
                serviceData: JSON.parse(this.state.dupServiceData),
                serviceCodeText: '',
                serviceNameCodeText: '',
                serviceTypeCodeText: '',
                serviceCodeAmt: 0,
                serviceCodeData: null,
                selectedServiceCodeText: '',
                selectedServiceNameCodeText: '',
                selectedServiceTypeCodeText: '',
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    returnServiceAmount(data) {
        if (data?.is_emergency) {
            return data?.service_data?.emergency_charges?.total ? data?.service_data?.emergency_charges?.total : 0
        } else if (data?.is_holiyday) {
            return data?.service_data?.holiday_charges?.total ? data?.service_data?.holiday_charges?.total : 0
        } else {
            return data?.service_data?.normal_charges?.total ? data?.service_data?.normal_charges?.total : 0
        }
    }

    rightBottomCard = (serviceAmount) => {
        let LoggedData = JSON.parse(localGetItem("loggedInUserInfo"))
        return (
            <Box>
                <Box component={'div'} display={'flex'} justifyContent={'center'} gap={'0.3vw'} p={'0.3vw'}>
                    <Box component={'div'} width={'15vw'} sx={{ backgroundColor: Colors.Background }} p={'0.3vw'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} mb={'0.5vw'}>Bill Date & Time</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                open={this.state.DateOpen}
                                disabled
                                onOpen={() => { this.setState({ DateOpen: true }) }}
                                onClose={() => { this.setState({ DateOpen: false }) }}
                                inputFormat='DD-MM-YYYY & hh:mm A'
                                value={this.state.billDateAndTime}
                                minDate={new Date()}
                                maxDate={new Date()}
                                onChange={(newDate) => {
                                    if (newDate === null) {
                                        this.setState({ billDateAndTime: new Date() })
                                    }
                                    else {
                                        this.setState({ billDateAndTime: newDate })
                                    }
                                }}
                                renderInput={(params) => <TextField size='small' autoComplete="off"
                                    onKeyDown={(e) => e.preventDefault()}
                                    onClick={() => { this.setState({ DateOpen: true }) }}
                                    {...params}
                                    fullWidth
                                    variant='standard'
                                    sx={{ width: '13.5vw' }}
                                />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box component={'div'} width={'15vw'} sx={{ backgroundColor: Colors.Background }} p={'0.3vw'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} mb={'0.5vw'}>Service Amount</Typography>
                        <Typography fontWeight={600}>{serviceAmount ? AmountFormat(serviceAmount) : AmountFormat(0)}</Typography>
                    </Box>
                </Box>
                <Box component={'div'} borderTop={'1px solid lightgray'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'2.6vw'} sx={{ backgroundColor: Colors.Background }}>
                    <Button variant='outlined' sx={{ height: '2vw', textTransform: 'capitalize', mr: '1vw' }} emed_tid='rad_rgt_clr_btn'
                        onClick={() => this.onClearRightHandler()}
                    >Clear</Button>
                    <Button variant='contained' sx={{ height: '2vw', textTransform: 'capitalize', mr: '1vw' }} emed_tid='rad_rgt_add_btn'
                        disabled={LoggedData?.module_name === "front_office" ? !CheckAccessFunc('front_office', 'Billing', 'Radiology Billing', null, 'Tab')?.editAccess : false}
                        onClick={() => this.onAddServiceHandler()}
                    >Add</Button>
                </Box>
            </Box>
        )
    }

    serviceDescriptionTab = () => {
        try {
            let LoggedData = JSON.parse(localGetItem("loggedInUserInfo"))
            return (
                <Box component={'div'}>
                    <Box component={'div'} height={this.props.fromQuickBill ? '49vh' : '51vh'}>
                        <Box component={'div'} height={this.props.fromQuickBill ? '38vh' : '40vh'} overflow={'auto'}>
                            <Box component={'div'} m={'0.3vw'}>
                                {this.textBox('searchServiceType', this.state.searchServiceType, '20vw', '4.5vh', 'Search Service Type')}
                                <Box component={'div'}>
                                    {this.state.serviceData?.length > 0 ? this.state.serviceData?.map((item, index) => (
                                        <Chip
                                            className='eMed_chip'
                                            size="small"
                                            label={item.service_type?.length > 15 ? <Tooltip placement='top' title={item.service_type}><div style={{ fontSize: '0.7vw' }}>{item.service_type?.slice(0, 15) + '..'}</div></Tooltip> : item.service_type}
                                            key={index}
                                            onClick={() => {
                                                this.setState({
                                                    selectedServiceType: item?.service_type,
                                                    serviceTypeList: item?.data,
                                                    dupServiceNameList: JSON.stringify(item?.data),
                                                    selectedServiceAmount: 0,
                                                    selectedServiceName: ''
                                                })
                                            }}
                                            variant={item.service_type !== this.state.selectedServiceType ? 'outlined' : 'contained'}
                                            color={'primary'}
                                            clickable
                                            emed_tid='rad_srv_type'
                                        />
                                    )) : <Typography display={'flex'} justifyContent={'center'} position={'relative'} mt={'1vw'}>{"No Records To Be Shown"}</Typography>}
                                </Box>
                            </Box>
                            <Box border={'1px solid lightgray'} />
                            <Box component={'div'} m={'0.3vw'}>
                                {this.textBox('searchServiceName', this.state.searchServiceName, '20vw', '4.5vh', 'Search Service Name')}
                                <Box component={'div'}>
                                    {this.state.serviceTypeList?.length > 0 ? this.state.serviceTypeList?.map((item, index) => (
                                        <Chip
                                            className='eMed_chip'
                                            size="small"
                                            label={item.service_name?.length > 15 ? <Tooltip placement='top' title={item.service_name}><div style={{ fontSize: '0.7vw' }}>{item.service_name?.slice(0, 15) + '..'}</div></Tooltip> : item.service_name}
                                            key={index}
                                            onClick={() => {
                                                this.setState({
                                                    selectedServiceName: item?.service_name,
                                                    selectedServiceAmount: this.returnServiceAmount(item),
                                                    serviceNameList: item
                                                })
                                            }}
                                            variant={item.service_name !== this.state.selectedServiceName ? 'outlined' : 'contained'}
                                            color={'primary'}
                                            clickable
                                            emed_tid='rad_srv_name'
                                        />
                                    )) : <Typography display={'flex'} justifyContent={'center'} position={'relative'} mt={'1vw'}>{"No Records To Be Shown"}</Typography>}
                                </Box>
                            </Box>
                        </Box>
                        {this.rightBottomCard(this.state.selectedServiceAmount)}
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    serviceCodeTab = () => {
        try {
            return (
                <Box component={'div'}>
                    <Box component={'div'} height={'38dvh'}>
                        <Box component={'div'} p={'0.5vw'} borderBottom={'2px solid lightgray'}>
                            <Autocomplete
                                size='small'
                                sx={{ width: '25vw' }}
                                options={this.state.serviceCodeDataList}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : `${option?.rad_service_code ? option?.rad_service_code : '-'} - ${option?.service_type} - ${option?.service_name}`}
                                value={this.state.serviceCodeData}
                                onChange={(e, value) => { this.onClickChipHandler(value, true) }}
                                renderInput={(params) => <TextField {...params} placeholder='Search Service Code...' InputProps={{ ...params.InputProps, emed_tid: 'srv_code_srh' }} />}
                            />
                            <Box component={'div'}>
                                {this.state.frequentServiceCodeList?.length > 0 ? this.state.frequentServiceCodeList?.map((item, index) => (
                                    <Chip
                                        className='eMed_chip'
                                        label={item.rad_service_code}
                                        size="small"
                                        key={index}
                                        onClick={() => { this.onClickChipHandler(item) }}
                                        variant={item?.rad_service_code != this.state.serviceCodeText ? 'outlined' : 'contained'}
                                        color={'primary'}
                                    />
                                )) : null}
                            </Box>
                        </Box>
                        <Box component={'div'} pl={'0.5vw'}>
                            <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'4dvh'} display={'flex'} alignItems={'center'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>Service Details</Typography>
                            </Box>
                            <Box component={'div'}>
                                <Box component={'div'} display={'flex'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} flex={'0.25'}>Service Code :</Typography>
                                    <Typography flex={'0.75'} fontSize={'0.9vw'} fontWeight={600}>{this.state.serviceCodeText ? this.state.serviceCodeText : this.state.selectedServiceCodeText ? this.state.selectedServiceCodeText : '-'}</Typography>
                                </Box>
                                <Box component={'div'} display={'flex'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} flex={'0.25'}>Service Type :</Typography>
                                    <Typography flex={'0.75'} fontSize={'0.9vw'} fontWeight={600}>{this.state.serviceTypeCodeText ? this.state.serviceTypeCodeText : this.state.selectedServiceTypeCodeText}</Typography>
                                </Box>
                                <Box component={'div'} display={'flex'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} flex={'0.25'}>Service Name :</Typography>
                                    <Typography flex={'0.75'} fontSize={'0.9vw'} fontWeight={600}>{this.state.serviceNameCodeText ? this.state.serviceNameCodeText : this.state.selectedServiceNameCodeText}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {this.rightBottomCard(this.state.serviceCodeAmt)}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderRgtScreen = () => {
        try {
            return (
                <Box component={'div'} border={'1px solid lightgray'}>
                    <Tabs
                        value={this.state.value}
                        onChange={(e, newValue) => this.setState({
                            value: newValue
                        })}
                        sx={{ backgroundColor: Colors.DataTblHeaderbg }}
                        className='eMed_bill_Tab'
                    >
                        <Tab value="one" label="Service Description" className='eMed_tab_header eMed_bill_Tab' />
                        {this.state.showCode ?
                            <Tab value="two" label="Service Code" className='eMed_tab_header eMed_bill_Tab' /> : null}
                    </Tabs>
                    {this.state.value === "one" ? this.serviceDescriptionTab() : this.state.value === 'two' ? this.serviceCodeTab() : ""}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    GetBillPrint = () => {
        try {
            RestAPIService.getAllPrint(Serviceurls.RAD_BILL_POST + '?bill_summary_id=' + this.state.billSummaryId + "&export_type=pdf").
                then((response) => {
                    // const file = new Blob(
                    //     [response.data],
                    //     { type: 'application/pdf' });
                    // const fileURL = URL.createObjectURL(file);
                    // window.open(fileURL);
                    OpenDirectPrint(response)
                    this.onClearLeftHandler()
                    this.BillingSucessAction()
                }).catch((error) => {
                    this.errorMessage(error.response?.data?.message, false);
                })
        } catch (error) {
            this.errorMessage(error.message, false)
        }
    }

    sendeBill() {
        try {
            this.setState({ isEbill: true })
            let PatientID = this.state.patientData?.patient_id ? this.state.patientData?.patient_id : this.state.DemographicData ? this.state.DemographicData?.PatientID : null
            let data = {
                "patient_id": PatientID,
                "rad_bill_summary_id": this.state.billSummaryId,
                "is_sms": true,
                "is_email": false
            }
            RestAPIService.create(data, Serviceurls.SMS_SEND).
                then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            isEbill: false,
                            isSMSText: 'SMS Sended Successfully!'
                        })
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                    this.setState({
                        isEbill: false,
                        isSMSText: '',
                    })
                })
        } catch (e) {
            this.errorMessage(e.message)
            this.setState({
                isEbill: false,
                isSMSText: '',
            })
        }
    }

    renderBillSuccessPopup = () => {
        try {
            return (
                <><Dialog
                    className="emed_DialogboxOrder"
                    open={this.state.receivePaymentPopup}
                    // onClose={this.setState({ receivePaymentPopup: false })}
                    maxWidth={"md"}
                >
                    <div className='Lab_Home_Dialogboxwidth'>
                        <img className="eMed_dilboxAnimations" alt="Sucess" src={Success} />
                        <h3>{this.state.isSMSText ? this.state.isSMSText : !this.state.isCredit ? "Payment Received Successfully" : "Credit Added Successfully"}</h3>
                        {/* <FormControlLabel labelPlacement="start" className='eMed_create_switch' control={<Switch size="small" onClick={() => { this.setState({ smsActive: true }, () => this.postSms()) }} />} label="Receive SMS" /> */}
                        <div className="Lab_home_pop_buttom_view" style={{ width: '20vw', justifyContent: 'center' }}>
                            <Button variant="outlined" size="small" sx={{ height: '2vw' }} id="eMed_Btn_Text" onClick={() => { this.setState({ receivePaymentPopup: false, isCredit: false }, () => { this.BillingSucessAction() }) }}>{('Next Bill')}</Button>
                            <Button variant="contained" size="small" sx={{ marginLeft: "0.5vw", height: '2vw' }}
                                id="eMed_Btn_Text" disabled={this.state.disableBtn}
                                onClick={() => { this.GetBillPrint() }}
                            >{("Print Bill")}</Button>
                            {(this.state.allowSms && !this.state.isCredit) ?
                                <Button variant="contained" size="small" disabled={this.state.isSMSText} sx={{ textTransform: 'none', marginLeft: '.5vw', height: '2vw' }}
                                    onClick={() => {
                                        this.setState({ isCredit: false })
                                        if (!this.state.isEbill) {
                                            this.sendeBill()
                                        }
                                    }}>
                                    {this.state.isEbill ? <CircularProgress sx={{ color: 'white' }} size={20} /> : "Send eBill"}
                                </Button> : null}
                        </div>
                    </div>
                </Dialog></>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onSaveDiscountAmount = () => {
        try {
            let states = this.state
            let doctorFee = this.state.discountDoctorFee ? this.state.discountDoctorFee : 0
            let hospitalFee = this.state.discountHospitalFee ? this.state.discountHospitalFee : 0
            let totalDiscountAmount = (+doctorFee) + (+hospitalFee)
            let newDiscount = 0; let totalAmount = 0;
            let ipServiceFee = states.selectedData?.service_charges

            let feeDoctor = this.state.discountPercentDoctorFee ? this.state.discountPercentDoctorFee : ipServiceFee?.discount_doctor_fee ? ipServiceFee?.discount_doctor_fee : 0
            let feeHospital = this.state.discountPercentHospitalFee ? this.state.discountPercentHospitalFee : ipServiceFee?.discount_hospital_fee ? ipServiceFee?.discount_hospital_percentage : 0

            // if (feeDoctor !== 0 && feeHospital !== 0) {
            let doctorAmount = CommonValidation.calculatePercentage(+states.discountPercentDoctorFee, ipServiceFee?.original_doctor_fee)
            let hospitalAmount = CommonValidation.calculatePercentage(+states.discountPercentHospitalFee, ipServiceFee?.original_hospital_fee)

                totalAmount = +ipServiceFee?.original_doctor_fee + +ipServiceFee?.original_hospital_fee
                newDiscount = doctorAmount + hospitalAmount
                let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
                let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
                let discountPercent = CommonValidation.amountPercentageWithoutRoundoff(newDiscount,totalAmount)
                states.selectedData.discount_amount = this.state.selectedType === 'rupee' ? (CommonValidation.formatAmount(+doctorFee) + CommonValidation.formatAmount(+hospitalFee)) : CommonValidation.formatAmount(newDiscount)
                states.selectedData.discount_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(totalDiscountAmount, +states.selectedData?.service_rate_per_unit) : discountPercent
                states.selectedData.discount_percentage_w_r = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.service_rate_per_unit) : CommonValidation.formatAmount(discountPercent)

            // } else if (feeDoctor !== 0 && feeHospital === 0) {
            //     newDiscount = +feeDoctor
            //     totalAmount = +ipServiceFee?.original_doctor_fee + +ipServiceFee?.original_hospital_fee
            //     let discountPercentAmount = CommonValidation.calculatePercentage(newDiscount, +totalAmount)
            //     let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            //     let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            //     let discountPercent = doctorPercentage + hospitalPercentage
            //     states.selectedData.discount_amount = this.state.selectedType === 'rupee' ? (CommonValidation.formatAmount(+doctorFee) + CommonValidation.formatAmount(+hospitalFee)) : CommonValidation.formatAmount(discountPercentAmount)
            //     states.selectedData.discount_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.service_rate_per_unit) : CommonValidation.formatAmount(discountPercent)

            // } else {
            //     newDiscount = feeHospital
            //     totalAmount = +ipServiceFee?.original_doctor_fee + +ipServiceFee?.original_hospital_fee
            //     let discountPercentAmount = CommonValidation.calculatePercentage(newDiscount, +totalAmount)
            //     let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            //     let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            //     let discountPercent = doctorPercentage + hospitalPercentage
            //     states.selectedData.discount_amount = this.state.selectedType === 'rupee' ? (CommonValidation.formatAmount(+doctorFee) + CommonValidation.formatAmount(+hospitalFee)) : CommonValidation.formatAmount(discountPercentAmount)
            //     states.selectedData.discount_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.service_rate_per_unit) : CommonValidation.formatAmount(discountPercent)

            // }
            ipServiceFee.discount_doctor_fee = this.state.selectedType === 'rupee' ? +doctorFee : CommonValidation.calculatePercentage(this.state.discountPercentDoctorFee, ipServiceFee?.original_doctor_fee)
            ipServiceFee.discount_hospital_fee = this.state.selectedType === 'rupee' ? +hospitalFee : CommonValidation.calculatePercentage(this.state.discountPercentHospitalFee, ipServiceFee?.original_hospital_fee)
            ipServiceFee.discount_doctor_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(this.state.discountDoctorFee ? +this.state.discountDoctorFee : 0, ipServiceFee?.original_doctor_fee) : this.state.discountPercentDoctorFee ? +this.state.discountPercentDoctorFee : 0
            ipServiceFee.discount_doctor_percentage_w_r = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(this.state.discountDoctorFee ? +this.state.discountDoctorFee : 0, ipServiceFee?.original_doctor_fee) : this.state.discountPercentDoctorFee_w_r ? +this.state.discountPercentDoctorFee_w_r : 0
            ipServiceFee.discount_hospital_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(this.state.discountHospitalFee ? +this.state.discountHospitalFee : 0, ipServiceFee?.original_hospital_fee) : this.state.discountPercentHospitalFee ? +this.state.discountPercentHospitalFee : 0
            ipServiceFee.discount_hospital_percentage_w_r = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(this.state.discountHospitalFee ? +this.state.discountHospitalFee : 0, ipServiceFee?.original_hospital_fee) : this.state.discountPercentHospitalFee_w_r ? +this.state.discountPercentHospitalFee_w_r : 0
            ipServiceFee.original_doctor_fee = +ipServiceFee.original_doctor_fee
            ipServiceFee.original_hospital_fee = +ipServiceFee.original_hospital_fee
            ipServiceFee.doctor_fee = CommonValidation.formatAmount((+ipServiceFee.original_doctor_fee) - (ipServiceFee.discount_doctor_fee ? ipServiceFee.discount_doctor_fee : +doctorFee))
            ipServiceFee.hospital_fee = CommonValidation.formatAmount((+ipServiceFee.original_hospital_fee) - (ipServiceFee.discount_hospital_fee ? ipServiceFee.discount_hospital_fee : +hospitalFee))
            // ipServiceFee.doctor_percentage = ipServiceFee.discount_doctor_percentage ? ipServiceFee.discount_doctor_percentage : CommonValidation.amountPercentage(ipServiceFee?.old_doctor_fee, states.selectedData?.service_rate_per_unit)
            // ipServiceFee.hospital_percentage = ipServiceFee.discount_hospital_percentage ? ipServiceFee.discount_hospital_percentage : CommonValidation.amountPercentage(ipServiceFee?.old_hospital_fee, states.selectedData?.service_rate_per_unit)
            states.selectedData.amount_type = this.state.selectedType
            ipServiceFee.total_amount = ipServiceFee.doctor_fee + ipServiceFee.hospital_fee
            states.selectedData.individual_discount_comments = states.IndivDiscountComments

            states.selectedData.service_charges = ipServiceFee
            states.radBillingList[this.state.discountIndex] = states.selectedData

            if ((states.discountDoctorFee || states.discountHospitalFee || states.discountPercentDoctorFee || states.discountPercentHospitalFee) > 0) {
                states.selectedData.isSplitBlock = true
            }
            this.setState({
                selectedData: this.state.selectedData,
                serviceDiscountpopup: false,
                discountIndex: null
            }, () => {
                // this.calculateDiscountAmt()
                this.setState({
                    IndivDiscountComments: '',
                    discountDoctorFee: '',
                    discountHospitalFee: '',
                    discountPercentDoctorFee: '',
                    discountPercentDoctorFee_w_r: '',
                    discountPercentHospitalFee: '',
                    discountPercentHospitalFee_w_r: '',
                    selectedType: 'rupee',
                    overAllDiscountAmt: '',
                    overAllDiscountPercent: '',
                    overAllDiscountPercent_w_r: '',
                })
            })
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }

    onChangeDiscountHandler = (e, names) => {
        try {
            let value = e.target.value
            let fee = this.feeValue()
            switch (names) {
                case 'discountDoctorFee':
                    if (this.state.selectedType === 'rupee') {
                        if ((CommonValidation.NumberWithDot(value) && +value <= fee.doctorFee) || value === '') {
                            this.state.discountPercentDoctorFee = CommonValidation.amountPercentageWithoutRoundoff(+value, fee.doctorFee)
                            this.state.discountPercentDoctorFee_w_r = CommonValidation.amountPercentage(+value, fee.doctorFee)
                            this.setState({ discountDoctorFee: +value }, () => {
                                let ipServiceFee = this.state.selectedData?.service_charges
                                ipServiceFee.discount_doctor_fee = value ? value : 0
                                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ selectedData: this.state.selectedData })
                            })
                        }
                    } else {
                        if ((CommonValidation.NumberWithDot(value) && +value <= 100) || value === '') {
                            this.state.discountDoctorFee = CommonValidation.calculatePercentage(+value, fee.doctorFee)
                            this.setState({ discountPercentDoctorFee: +value,discountPercentDoctorFee_w_r: +value }, () => {
                                let ipServiceFee = this.state.selectedData?.service_charges
                                ipServiceFee.discount_doctor_fee = value ? CommonValidation.calculatePercentage(value, fee.doctorFee) : 0
                                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ selectedData: this.state.selectedData })
                            })
                        }
                    }
                    break;
                case 'discountHospitalFee':
                    if (this.state.selectedType === 'rupee') {
                        if ((CommonValidation.NumberWithDot(value) && +value <= fee.hospitalFee) || value === '') {
                            this.state.discountPercentHospitalFee = CommonValidation.amountPercentageWithoutRoundoff(+value, fee.hospitalFee)
                            this.state.discountPercentHospitalFee_w_r = CommonValidation.amountPercentage(+value, fee.hospitalFee)
                            this.setState({ discountHospitalFee: +value }, () => {
                                let ipServiceFee = this.state.selectedData?.service_charges
                                ipServiceFee.discount_hospital_fee = value ? value : 0
                                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ selectedData: this.state.selectedData })
                            })
                        }
                    } else {
                        if ((CommonValidation.NumberWithDot(value) && +value <= 100) || value === '') {
                            this.state.discountHospitalFee = CommonValidation.calculatePercentage(+value, fee.hospitalFee)
                            this.setState({ discountPercentHospitalFee: +value,discountPercentHospitalFee_w_r: +value }, () => {
                                let ipServiceFee = this.state.selectedData?.service_charges
                                ipServiceFee.discount_hospital_fee = value ? CommonValidation.calculatePercentage(value, fee.hospitalFee) : 0
                                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ selectedData: this.state.selectedData })
                            })
                        }
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    splitUpTextBox = (names, values, label, width) => {
        try {
            return (
                <TextField
                    size='small'
                    sx={{ width: width }}
                    value={values}
                    label={label}
                    autoComplete='off'
                    onChange={(e) => this.onChangeDiscountHandler(e, names)}
                    InputProps={{
                        endAdornment: this.state.selectedType === 'rupee' ?
                            // <CurrencyRupee color='primary'></CurrencyRupee> :
                            CurrencySymbol :
                            <PercentIcon color='primary' />
                    }}
                />
            )
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }

    onChangeSymbol = (e) => {
        try {
            let states = this.state
            let ipServiceFee = states.selectedData?.service_charges
            ipServiceFee.discount_doctor_fee = 0;
            ipServiceFee.discount_hospital_fee = 0;
            this.setState({
                selectedType: e.target.value,
                discountDoctorFee: '',
                discountHospitalFee: '',
                discountPercentDoctorFee: '',
                discountPercentDoctorFee_w_r: '',
                discountPercentHospitalFee: '',
                discountPercentHospitalFee_w_r: '',
                selectedData: this.state.selectedData
            })
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }

    serviceIndividualDiscountPopup = () => {
        try {
            let states = this.state
            let fee = this.shareValue()
            let doctorShare = this.state.selectedType === 'rupee' ? this.state.discountDoctorFee : this.state.discountPercentDoctorFee
            let doctorShare_w_r = this.state.selectedType === 'rupee' ? this.state.discountDoctorFee : this.state.discountPercentDoctorFee_w_r
            let hospitalShare = this.state.selectedType === 'rupee' ? this.state.discountHospitalFee : this.state.discountPercentHospitalFee
            let hospitalShare_w_r = this.state.selectedType === 'rupee' ? this.state.discountHospitalFee : this.state.discountPercentHospitalFee_w_r
            return (
                <Modal open={this.state.serviceDiscountpopup}>
                    <Box className='eMed_Service_Discount'>
                        <Box m={'1vw'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>Split-Up</Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} m={'1vw'}>
                            <Box>
                                <Typography color={'gray'}>Bill Amount</Typography>
                                <Typography fontWeight={600} color={Colors.SecondaryText}>{states.selectedData?.service_amount ? AmountFormat(states.selectedData?.service_amount || 0) :
                                    states.selectedData?.service_charges?.total_amount ? AmountFormat(states.selectedData?.service_charges?.total_amount || 0) :
                                        AmountFormat(states.selectedData?.total_amount ? (states.selectedData?.total_amount || 0) : (states.selectedData?.totalAmount || 0))}</Typography>
                            </Box>
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                            <Typography fontWeight={600} color={Colors.SecondaryText}>Select Type:
                                <Select
                                    size='small'
                                    sx={{ width: '5vw', height: '2vw', ml: '1vw' }}
                                    value={this.state.selectedType}
                                    onChange={(e) => { this.onChangeSymbol(e) }}
                                >
                                    {Unit_Types.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>{item?.label}</MenuItem>
                                    ))}
                                </Select>
                            </Typography>
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'space-evenly'}>
                            {this.splitUpTextBox('discountDoctorFee', doctorShare_w_r, 'Doctor Discount', '13.5vw')}
                            {this.splitUpTextBox('discountHospitalFee', hospitalShare_w_r, 'Hospital Discount', '13.5vw')}
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} m={'1vw'}>
                            <Box>
                                <Typography fontSize={'0.8vw'} color={'gray'}>Doctor Share</Typography>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>
                                    {`${fee.doctorFee} - ${fee.discountDoctorFee ? fee.discountDoctorFee : 0} = ${((fee.doctorFee) - (+fee.discountDoctorFee)) ? AmountFormat((fee.doctorFee) - (+fee.discountDoctorFee)) : `${CurrencySymbol} 0.00`}`}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontSize={'0.8vw'} color={'gray'}>Hospital Share</Typography>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>
                                    {`${fee.hospitalFee} - ${fee.discountHospitalFee ? fee.discountHospitalFee : 0} = ${((fee.hospitalFee) - (+fee.discountHospitalFee)) ? AmountFormat(fee.hospitalFee - (+fee.discountHospitalFee)) : `${CurrencySymbol} 0.00`}`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ paddingX: '0.5vw' }}>
                            <TextField
                                label={"Discount Comments"}
                                sx={{ width: '31vw' }}
                                multiline={true}
                                onChange={(e) => {
                                    this.setState({
                                        IndivDiscountComments: e.target.value
                                    })
                                }}
                                inputProps={{ maxLength: 250 }}
                                disabled={(!doctorShare && !hospitalShare)}
                                value={this.state.IndivDiscountComments}
                                rows={2} />
                        </Box>
                        <Box display={'flex'} justifyContent={'flex-end'} m={'1vw'}>
                            <Button variant='outlined' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({
                                        serviceDiscountpopup: false,
                                        discountDoctorFee: '',
                                        discountHospitalFee: '',
                                        discountPercentDoctorFee: '',
                                        discountPercentDoctorFee_w_r: '',
                                        discountPercentHospitalFee: '',
                                        discountPercentHospitalFee_w_r: '',
                                        selectedType: 'rupee',
                                        selectedData: JSON.parse(this.state.dupliSelectedData),
                                    })
                                }}>Close</Button>
                            <Button variant='contained' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                                onClick={() => { this.onSaveDiscountAmount() }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }

    closeSplitPopup = () => {
        this.setState({ docSplitPopup: false, selectedData: JSON.parse(this.state.dupliSelectedData) })
    }

    discountReasonPopup = () => {
        try {
            return (
                <Modal open={this.state.discountReasonPopup}>
                    <Box className='eMed_Discount_Reason' sx={{ height: '33dvh' }}>
                        <Box component={'div'} height={'2vw'} display={'flex'} m={'1vw'} justifyContent={'space-between'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>Discount Reason</Typography>
                        </Box>
                        <Box m={'1vw'}>
                            <TextField
                                fullWidth
                                multiline
                                label='Reason *'
                                rows={3}
                                value={this.state.discountReason}
                                onChange={(e) => {
                                    this.setState({ discountReason: e.target.value })
                                }}
                            />
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                variant='outlined'
                                sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({ discountReasonPopup: false, discountReason: '' })
                                }}
                            >Close</Button>
                            <Button
                                variant='contained'
                                sx={{ width: '5vw', height: '2vw' }}
                                onClick={() => {
                                    if (this.state.discountReason !== '') {
                                        this.setState({ discountReasonPopup: false })
                                    } else {
                                        this.errorMessage("Reason is mandatory", 'error')
                                    }
                                }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }

    render() {
        let data = {
            lineOne: "Attender Name",
            lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
            lineFour: "Contact Number",
            LineFive: `${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
        }
                return (
            <Box component={'div'}>
                <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: this.props.fromQuickBill ? '65vh' : '73vh' }}>
                    {((this.state.patientupdData?.ip_number || this.state.patientupdData?.op_number) && this.props.fromQuickBill) ?
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mr: this.state.patientupdData?.ip_number && this.state.isFromIPList ? "8.4vw" : '7.5vw', mt: "-1vw", justifyContent: "flex-end" }}>
                            <Typography mb={'0.3vw'} fontSize={'0.9vw'} fontWeight={600}> UHID :
                                <Typography component={'span'} fontSize={'0.9vw'} fontWeight={500} color={Colors.SecondaryText}>{` ${this.state.patientupdData?.patient_account_number}`}</Typography>
                            </Typography>

                            {this.state.patientupdData?.ip_number ? <Typography mb={'0.3vw'} ml={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}> IP NO :
                                <Typography component={'span'} fontSize={'0.9vw'} fontWeight={500} color={Colors.SecondaryText}>{` ${this.state.patientupdData?.ip_number}`}</Typography>
                            </Typography> :
                                <Typography mb={'0.3vw'} ml={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}> OP NO :
                                    <Typography component={'span'} fontSize={'0.9vw'} fontWeight={500} color={Colors.SecondaryText}>{` ${this.state.patientupdData?.op_number}`}</Typography>
                                </Typography>
                            }
                        </Box>
                     : null}
                    {this.props.fromQuickBill ? null :
                        <Box component={'div'} height={"9.5vh"}>
                            <Box component={'div'} display={'flex'}>
                                <Box component={'div'} className='eMed_nur_notes_details' ml={'0.5vw'} emed_tid='rad_bill_card'>
                                    <CommonPatientDetails data={this.state.patientData} showDetailed={(this.state.patientData?.ip_number || this.state.patientData?.op_number) ? true : false}/>
                                </Box>
                                {/* <CommonPatientCard details={data} showDetailed={true} /> */}
                            </Box>
                        </Box>
                    }
                    <Box component={'div'} m={'0vw 0.5vw'} display={'flex'} gap={'0.5vw'}>
                        <Box component={'div'} flex={0.65} border={'1px solid lightgray'} sx={{ backgroundColor: Colors.white }}>
                            {this.renderLftScreen()}
                        </Box>
                        <Box component={'div'} flex={0.35} border={'1px solid lightgray'} sx={{ backgroundColor: Colors.white }}>
                            {(this.props.history?.location?.state?.fromCancelBill || this.props?.CancelFromFO) ?
                                <Box component={'div'} p={'1vw'}>
                                    <Box component={'div'} display={'flex'}>
                                        <Typography fontWeight={600}>Invoice Number : </Typography>
                                        <Typography fontWeight={600}>{this.state.invoiceNumber}</Typography>
                                    </Box>
                                    <Box component={'div'} display={'flex'} mt={'2vw'}>
                                        <Typography fontWeight={600}>Bill Date And Time : </Typography>
                                        <Typography fontWeight={600}>{this.state.cancelBillDate ? formatDateAndTimeMeth2(this.state.cancelBillDate) : "-"}</Typography>
                                    </Box>
                                </Box>
                                : this.renderRgtScreen()}
                        </Box>
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`Are you sure you want to delete ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.onDeleteHandlerLftAll.bind(this)}
                    disable={this.state.removeData}
                /> : null}
                {this.state.deletePopupTwo ? <DeletePopup
                    DeletTitle={`Are you sure you want to delete ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.onDeleteLftHandler.bind(this)}
                    disable={this.state.removeData}
                /> : null}
                {this.state.cancelBillClicked ?
                    <ReasonPopupWithRefund
                        title={"Cancel Bill"}
                        AlertPopupClose={this.ReasonPopupClose.bind(this)}
                        label={"Enter the Cancel Reason"}
                        btntext={'Close'}
                        btnvarient={'outlined'}
                        btncolor={'error'}
                        btntext1={'Confirm'}
                        btnvarient1={'contained'}
                        sendCmt={this.addCancelComments.bind(this)}
                        BillReceivedAmount={this.state.BillReceivedAmount} />
                    : null
                }
                {this.state.receivePaymentPopup ? this.renderBillSuccessPopup() : null}
                {this.state.docSplitPopup ? this.splitScreenPopup() : null}
                {this.serviceIndividualDiscountPopup()}
                {this.discountReasonPopup()}
                {this.state.creditChange ?
                    <Dialog
                        className="emed_DialogboxOrder"
                        open={this.state.creditChange}
                        maxWidth={"md"}
                    >
                        <div className='emed_Dialog_Appoin' style={{ width: '42vw', height: "45vh" }}>
                            {this.renderCreditType()}
                        </div>
                    </Dialog> : null}
            </Box>
        )
    }
}
