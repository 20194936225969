import {
  Autocomplete, Box, Button, Checkbox, FormControlLabel, FormGroup,
  IconButton, InputAdornment, Menu, MenuItem, Stack, Tab, Table, TableBody,
  TableCell, TableContainer, TableHead, Tabs, TableRow, TextField, Tooltip,
  Typography, Modal, Radio, Dialog
} from '@mui/material'
import React, { Component } from 'react'
import { ImagePaths } from '../../../Utility/ImagePaths'
import {
  BillSuccessPoPUp, CommonCorporateAndInsuranceDetails, CommonPatientDetails,
  PharmaDiscardPop, PharmacyReturnPopup, NoAccessScreen
} from '../../../Components/Common Components/CommonComponents'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { withTranslation } from 'react-i18next'
import { AmountFormat, CheckAccessFunc} from '../../../Components/CommonFunctions/CommonFunctions'
import { Colors } from '../../../Styles/Colors';
import './PharmaHome.css';
import SearchIcon from '@mui/icons-material/Search';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTime } from 'luxon'
import Loader from '../../../Components/Loader';
import PrescriptionHistoryPop from './PrescriptionHistoryPop'
import { localGetItem, localSetItem } from '../../../Utility/Services/CacheProviderService';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReturnMultipleBill from './ReturnMultipleBill'
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CurrencySymbol } from '../../../Utility/Constants'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CalculateIcon from '@mui/icons-material/Calculate';

let RoleData = null;
class PharmaHome extends Component {
  constructor(props) {
    super(props)
    this.QuantityRef = React.createRef();
    this.DrugNameRef = React.createRef();
    this.state = {
      discount: '',
      batchNo: '',
      drugDetailsData: [],
      pharmacyTblData: [
        {
          drug_name: '',
          dosage_strength: '',
          dosage_type: '',
          batch_no: '',
          expiry_date: '',
          physical_box_no: '',
          cost_per_quantity: '',
          quantity: '',
          total_cost: '',
          discount: '',
          total_price: '',
          hsn_code: '',
          stock_in_quantity: '',
          Drug_sugg_list: [],
          gst_percentage: null,
        }
      ],
      isCard: false,
      isCash: false,
      isUPI: false,
      isBankTranfer: false,
      isCheque: false,
      isInsurance: false,
      CashAmount: "",
      CardAmount: "",
      CardTransactionNumber: "",
      UPINumber: "",
      UPIAmount: "",
      BankTfrAmount: "",
      BankTfrNumber: "",
      ChequeNumber: "",
      ChequeAmount: "",
      InsuranceAmount: "",
      InsuCompanyName: "",
      InsuPolicyNumber: "",
      InsuTransactionNumber: "",
      InsuValidity: '',
      subAmount: 0,
      totalPercentage: '',
      totalDiscount: '',
      roundOff: 0,
      netAmount: 0,
      creditPayment: false,
      receivePayment: false,
      savedBill: false,
      isCredit: false,
      DurgNameCurrentIndex: null,
      QuantityCurrentIndex: null,
      PatientDetails: {},
      BillDetails: {},
      PatientSearchClicked: false,
      SearchedPatientList: [],
      DoctorsList: [],
      doctordetails: {},
      PatientMobileNumber: "",
      PatientName: "",
      invoiceDateTime: new Date(),
      Patient_Id: null,
      Bill_ID: null,
      DateTimerOpen: false,
      ReceivedCashAmount: "",
      CashBalanceAmount: "",
      isFromInvoice: false,
      isForReturn: false,
      isFromSavedBill: false,
      isForInvoiceEdit: false,
      isFromIpRequest: false,
      isFromIpReturn: false,
      isFromPrescription: false,
      ShowPrescriptonHistory: false,
      CompletedBillID: null,
      ip_transmit_id: null,
      ConfigSetting: {},
      LoggedRole: null,
      totalBuyingPrice: 0,
      ShowHistory: false,
      SearchingDrugIndex: false,
      SearchingBatchIndex: false,
      isBillsFetched: false,
      InvoiceNumber: null,
      CorpCompanyName: "",
      CorpIDNumber: "",
      PrescHistoryData: null,
      invoiceNumber: '',
      OutStandingAmount: 0,
      BillReceivedAmount: 0,
      outstandingAmt: 0,
      ReturnedNetAmount: 0,
      totalBillNetAmount: 0,
      ReceiptAmt: 0,
      AdvanceAmt: 0,
      isCurrentIP: false,
      addToAdvance: false,
      isReturnClicked: false,
      prescription_transmitted_id: null,
      typeDocName: '',
      selectedBatchListData: [],
      SelectedTabName: this.props?.MultipleReturnScreen ? "mutiple_return_bill" : "main_billing",
      MasterBillData: {},
      perAccess: {},
      isCredit_or_isPaid: true,
      DateTimeChanged: false,
      discountReasonPopup: false,
      discountReason: '',
      isSavedReason: false,
      common_uhid: false,
      creditChange: false,
      addInsurance: false,
      addCorporate: false,
      paymentCreditType:'',
      corpName: null,
      employeeNo: '',
      insuranceName: null,
      insuranceNo: '',
      CropName: '',
      CropNo:'',
      InsName:'',
      InsNO:'',
      // History State
      prescriptionList: [],
      totalPercentage_w_r:''
    }
  }

  componentDidMount() {
    // Setting Logged User Role as String in state(LoggedRole)
    RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
    if (RoleData.is_super_admin) { this.setState({ LoggedRole: "super_admin" }) }
    else if (RoleData.is_branch_admin) { this.setState({ LoggedRole: "branch_admin" }) }
    else if (RoleData.is_user) {
      this.setState({ LoggedRole: "user", perAccess: RoleData?.permission_access }, () => {
        if (localGetItem("PharmaSettings") === null) {
          // get Configuartion Data for permission from API (only user)
          // Permission were given by role basis
          this.GetPharmaSettings()
        } else {
          // get Configuartion Data for permission from Local Storage
          let Settings = JSON.parse(localGetItem("PharmaSettings"))
          this.setState({
            ConfigSetting: Settings
          })
        }
      })
    }
    // get Doctor List
    this.getDoctorsList()
    this.GetPharmaSettings()
    setInterval(() => { // DateTime refresh on every minute, when the user not change the datetime
      if (!this.state.DateTimeChanged) {
        const newMinute = new Date().getMinutes();
        if (this.state.invoiceDateTime?.getMinutes() && this.state.invoiceDateTime?.getMinutes() !== newMinute) {
          this.setState({
            invoiceDateTime: new Date(),
          });
        }
      }
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps && (this.props.history?.location?.state ? Object.keys(this.props.history?.location?.state).length > 0 : false)) {
      // Get Patient Details from Patient id
      if (this.props.history?.location?.state?.Patient_ID && this.props.history?.location?.state?.Patient_ID !== this.state.Patient_Id) {
        this.clearIntialStates(true)
        this.setState({
          Patient_Id: this.props.history?.location?.state?.Patient_ID,
        }, () => {
          this.GetPatientDetails(this.props.history?.location?.state?.Patient_ID, this.state.isBillsFetched)
        })
      }else if(!(this.props.history?.location?.state?.Patient_ID)){
        this.setState({
          PatientDetails: {},
          CropName: '',
          CropNo:  '',
          CompanyID: null ,
          InsName: '',
          InsNO: '',
          selPayemtnCreditType: "",
          paymentCreditType: ""
        })
      }

      // Get Billing Details from bil id
      if (this.props.history?.location?.state?.Bill_ID && this.props.history?.location?.state?.Bill_ID !== this.state.Bill_ID) {
        this.clearIntialStates()
        this.setState({
          Bill_ID: this.props.history?.location?.state?.Bill_ID
        }, () => {
          // For Saved Bill
          if (this.props.history?.location?.state?.isFromSavedBill) {
            this.setState({
              PatientName: this.props.history?.location?.state?.billDetails?.billing_customer_name ? this.props.history?.location?.state?.billDetails?.billing_customer_name : this.props.history?.location?.state?.billDetails?.patient_name,
              PatientMobileNumber: this.props.history?.location?.state?.billDetails?.billing_customer_mobile_no ? this.props.history?.location?.state?.billDetails?.billing_customer_mobile_no : this.props.history?.location?.state?.billDetails?.patient_mobile,
              BillDetails: this.props.history?.location?.state?.billDetails,
              isFromSavedBill: true,
            }, () => { this.GetBillingDetails(Serviceurls.PHARMA_SAVED_BILL_GET + "?id=" + this.props.history?.location?.state?.Bill_ID, true, false) })
          }
          // For Modify Invoice, Return Invoice, View Invoice
          else if (this.props.history?.location?.state?.isFromInvoice || this.props.history?.location?.state?.isForInvoiceEdit) {
            this.setState({
              PatientName: this.props.history?.location?.state?.billDetails?.billing_customer_name ? this.props.history?.location?.state?.billDetails?.billing_customer_name : this.props.history?.location?.state?.billDetails?.patient_name,
              PatientMobileNumber: this.props.history?.location?.state?.billDetails?.billing_customer_mobile_no ? this.props.history?.location?.state?.billDetails?.billing_customer_mobile_no : this.props.history?.location?.state?.billDetails?.patient_mobile,
              BillDetails: this.props.history?.location?.state?.billDetails,
              isFromInvoice: this.props.history?.location?.state?.isFromInvoice ? this.props.history?.location?.state?.isFromInvoice : false,
              isForInvoiceEdit: this.props.history?.location?.state?.isForInvoiceEdit ? this.props.history?.location?.state?.isForInvoiceEdit : false,
              isForView: this.props.history?.location?.state?.isForView ? this.props.history?.location?.state?.isForView : false
            }, () => { this.GetBillingDetails(Serviceurls.PHARMA_BILLS_GET + "?id=" + this.props.history?.location?.state?.Bill_ID, false, true) })
          }
        })
      }

      // For IP Request Bill Datas -  ( Request From FO for IP Patient Medication )
      if (this.props.history?.location?.state?.isFromIpRequest && this.props.history?.location?.state?.billDetails?.patient_id && this.props.history?.location?.state?.billDetails?.patient_id !== this.state.PatientDetails?.patient_id) {
        this.clearIntialStates()
        let LineItems = []
        this.props.history?.location?.state?.billDetails?.line_items?.forEach((item) => {
          item["drug_name"] = item?.brand_name
          item["discount"] = item?.discount_percentage
          item["total_price"] = item?.net_total_amount
          item["dosage_strength"] = item?.drug_strength
          item["batch_no"] = ""
          LineItems.push(item)
        })
        this.setState({
          isFromIpRequest: this.props.history?.location?.state?.isFromIpRequest ? this.props.history?.location?.state?.isFromIpRequest : false,
          pharmacyTblData: LineItems,
          doctordetails: { displayKey: `${this.props.history?.location?.state?.billDetails?.doctor_name}`, doctor_id: this.props.history?.location?.state?.billDetails?.doctor_id, name: `${this.props.history?.location?.state?.billDetails?.doctor_name}` },
          ip_transmit_id: this.props.history?.location?.state?.billDetails?.id ? this.props.history?.location?.state?.billDetails?.id : null,
          isBillsFetched: true,
          PatientDetails: { "patient_id": this.props.history?.location?.state?.billDetails?.patient_id },
          IpRequestAdmId: this.props.history?.location?.state?.billDetails?.ip_admission_id ? this.props.history?.location?.state?.billDetails?.ip_admission_id : null
        }, () => {
          // Get Patient Details from Patient id
          this.GetPatientDetails(this.props.history?.location?.state?.billDetails?.patient_id, false)
        })
      }

      // For IP Return Bill Datas - ( Return From FO for IP Patient Medication )
      if (this.props.history?.location?.state?.isFromIpReturn && this.props.history?.location?.state?.billDetails?.patient_id && this.props.history?.location?.state?.billDetails?.patient_id !== this.state.PatientDetails?.patient_id) {
        this.clearIntialStates()
        let LineItems = []
        this.props.history?.location?.state?.billDetails?.line_items?.forEach((item) => {
          item["drug_name"] = item?.brand_name
          item["discount"] = item?.discount_percentage
          item["total_price"] = item?.net_total_amount
          item["total_return_price"] = (item?.cost_per_quantity * item?.return_quantity) - CommonValidation.calculatePercentageWithoutRoundoff(item?.discount_percentage, (item?.return_quantity * item.cost_per_quantity))
          item["dosage_strength"] = item?.drug_strength
          item["drug_id"] = item?.drug_ms_id
          item['total_cost'] = item?.cost_per_quantity * item?.return_quantity
          LineItems.push(item)
        })
        this.setState({
          isFromIpReturn: this.props.history?.location?.state?.isFromIpReturn ? this.props.history?.location?.state?.isFromIpReturn : false,
          pharmacyTblData: LineItems,
          doctordetails: { displayKey: `${this.props.history?.location?.state?.billDetails?.doctor_name}`, doctor_id: this.props.history?.location?.state?.billDetails?.doctor_id, name: `${this.props.history?.location?.state?.billDetails?.doctor_name}` },
          ip_transmit_id: this.props.history?.location?.state?.billDetails?.id ? this.props.history?.location?.state?.billDetails?.id : null,
          BillDetails: this.props.history?.location?.state?.billDetails,
          isBillsFetched: true,
          invoiceDateTime: new Date(),
          DateTimeChanged: false,
          BillReceivedAmount: this.props.history?.location?.state?.billDetails?.received_amount ? this.props.history?.location?.state?.billDetails?.received_amount : 0, // hard coded "received amount key"
          // outstandingAmt: response.data.data?.outstanding_amount ? response.data.data?.outstanding_amount : 0,
          totalBillNetAmount: this.props.history?.location?.state?.billDetails?.net_amount ? this.props.history?.location?.state?.billDetails?.net_amount : 0,
          isCurrentIP: this.props.history?.location?.state?.billDetails?.is_current_ip ? this.props.history?.location?.state?.billDetails?.is_current_ip : false,
          // OutStandingAmount: this.props.history?.location?.state?.billDetails?.total_outstanding_amt ? this.props.history?.location?.state?.billDetails?.total_outstanding_amt : 0,
          PatientDetails: { "patient_id": this.props.history?.location?.state?.billDetails?.patient_id },
          isCash: true,
          CashAmount: this.state.ReturnedNetAmount,
          discountReason: this.props.history?.location?.state?.billDetails?.remarks ? this.props.history?.location?.state?.billDetails?.remarks : this.props.BillData?.remarks ? this.props.BillData?.remarks : '',
        }, () => {
          // Get Patient Details from Patient id
          this.GetPatientDetails(this.props.history?.location?.state?.billDetails?.patient_id, false)
          this.GetBillingDetails(Serviceurls.PHARMA_BILLS_GET + "?id=" + (this.props.history?.location?.state?.billDetails?.pharmacy_bill_summary_id ? this.props.history?.location?.state?.billDetails?.pharmacy_bill_summary_id : ""), false, false, LineItems)
          // this.GetPatientOutstandingAmount(this.props.history?.location?.state?.billDetails?.patient_id, this.props.history?.location?.state?.billDetails?.ip_admission_id)
        })
      }

      // For Prescription Bill Datas - ( From Doctor Module)
      if (this.props.history?.location?.state?.isFromPrescription && this.props.history?.location?.state?.billDetails?.id && this.props.history?.location?.state?.billDetails?.id !== this.state.PrescriptionId) {
        this.clearIntialStates()
        this.setState({
          PrescriptionId: this.props.history?.location?.state?.billDetails?.id,
          isFromPrescription: this.props.history?.location?.state?.isFromPrescription ? this.props.history?.location?.state?.isFromPrescription : false,
        }, () => {
          // Get Prescription Details
          this.GetPrescriptionDetails(this.props.history?.location?.state?.billDetails?.id)
        })
      }

      // For to Show History Button 
      if (this.props.history?.location?.state?.showHistory && this.props.history?.location?.state?.showHistory !== this.state.ShowHistory) {
        this.setState({ ShowHistory: true })
      } else if (this.props.history?.location?.state?.showHistory !== this.state.ShowHistory) {
        this.setState({ ShowHistory: false })
      }

      // For to Show History Button 
      if (this.props.history?.location?.state?.isMasterReturn && this.props.history?.location?.state?.isMasterReturn !== this.state.isMasterReturn) {
        let BillDetails = this.props.history?.location?.state?.billDetails ? this.props.history?.location?.state?.billDetails : {}
        this.setState({
          isMasterReturn: true,
          MasterBillData: BillDetails,
          // SelectedTabName : "mutiple_return_bill",
        }, () => { this.props.history.push({ pathname: "/PharmacyBilling/Return", }) })
      }

      // Clear All history states for not to retain on page reload
      setTimeout(() => { this.props.history.push({ state: {} }) }, 1500)
    }
    if (prevProps?.MultipleReturnScreen !== this.props?.MultipleReturnScreen && (this.props?.MultipleReturnScreen ? "mutiple_return_bill" : "main_billing" !== this.state.SelectedTabName)) {
      this.setState({
        SelectedTabName: this.props?.MultipleReturnScreen ? "mutiple_return_bill" : "main_billing"
      })
    }
  }

  GetPrescpHistoryData() {
    try {
      RestAPIService.getAll(Serviceurls.PHARMA_PRESCRIPTION_HISTORY + "?patient_id=" + this.state.PatientDetails?.patient_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              PrescHistoryData: response.data?.data
            })
          }
          else {
            this.errorMessage(response.data?.message)
          }
        }).catch((error) => {
          this.errorMessage(error?.message)
        })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }

  getPrescriptionListData = () => {
    try {
      RestAPIService.getAll(Serviceurls.PHARMA_PRESC_LIST_HISTORY_GET + '?patient_id=' + this.state.PatientDetails?.patient_id)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              prescriptionList: response.data.data
            })
          }
        }).catch(error => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  GetPatientOutstandingAmount(PatientId, IpAdmissionId) {
    try {
      RestAPIService.getAll(Serviceurls.PHARMA_BILLING_CREDIT_POST + "?patient_id=" + PatientId + "&module=IP" + "&module_id=" + IpAdmissionId + "&bill_status=")
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              OutStandingAmount: response?.data?.total_outstanding_amount ? response?.data?.total_outstanding_amount : 0
            })
          }
          else {
            this.errorMessage(response.data?.message)
          }
        }).catch((error) => {
          this.errorMessage(error?.message)
        })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }

  clearIntialStates(isFromPatientId = false) {
    this.setState({
      Patient_ID: isFromPatientId || this?.props?.history?.location?.state?.is_AppDetail ? null : this.state.Patient_Id,
      isFromInvoice: false,
      isForReturn: false,
      isFromSavedBill: false,
      isForInvoiceEdit: false,
      isFromIpRequest: false,
      isFromIpReturn: false,
      isFromPrescription: false,
      ShowPrescriptonHistory: false,
      InvoiceNumber: null,
      savedBill: false,
      isCredit: false,
      ShowHistory: false,
      PrescHistoryData: null,
      PrescriptionId: null,
      doctordetails: this.state.doctordetails,
      OutStandingAmount: 0,
      ReturnedNetAmount: 0,
    }, () => {
      if (this.state.isBillsFetched) {
        this.setState({
          isCard: false,
          isCash: false,
          isUPI: false,
          isBankTranfer: false,
          isCheque: false,
          isInsurance: false,
          CashAmount: "",
          CardAmount: "",
          CardTransactionNumber: "",
          UPINumber: "",
          UPIAmount: "",
          BankTfrAmount: "",
          BankTfrNumber: "",
          ChequeNumber: "",
          ChequeAmount: "",
          InsuranceAmount: "",
          InsuCompanyName: "",
          InsuPolicyNumber: "",
          InsuTransactionNumber: "",
          InsuValidity: '',
          subAmount: 0,
          totalPercentage: '',
          totalDiscount: '',
          discountReason: '',
          isSavedReason: false,
          roundOff: 0,
          netAmount: 0,
          creditPayment: false,
          receivePayment: false,
          savedBill: false,
          isCredit: false,
          prescription_transmitted_id: null,
          typeDocName: '',
          totalPercentage_w_r:''
        })
      }
    })
  }

  concessionManditoryPopup = (names) => {
    if ((this.state.totalDiscount || this.state.totalPercentage) && (this.state.discountReason === "")) {
      if (names === 'totalDiscount' || names === 'totalPercentage') {
        this.setState({ discountReasonPopup: true })
      }
    }
  }

  GetPharmaSettings() {
    try {
      RestAPIService.getAll(Serviceurls.PHARMA_CONFIG_SETTINGS).
        then((response) => {
          if (response.data.status === "succes") {
            // localSetItem("PharmaSettings", JSON.stringify(response.data?.data))
            // if (Object.keys(this.state.ConfigSetting) === 0) {
            this.setState({ ConfigSetting: response.data?.data })
            // }
            let data = {
              rtn_type: response.data.data?.return_period_type,
              rtn_value: response.data.data?.return_bill_period
            }
            localSetItem('pharmacy_rtn_period', JSON.stringify(data))
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }


  GetPatientDetails(Patient_id, RefreshTable = true, from_presc = false) {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.PHARMA_PATIENT_DETAILS_GET + "?patient_id=" + Patient_id)
        .then((response) => {
          if (response.data.status === "success") {
            let avoid_drugDiscount = this.state.ConfigSetting;
            let avoidDiscountIP = (avoid_drugDiscount?.show_discount_ip && response.data.data?.ip_admission_id);
            let avoidDiscountOP = (avoid_drugDiscount?.show_discount_op && response.data.data?.appointment_id);
            if (avoidDiscountIP && this.state.pharmacyTblData?.length > 0 && !this.state.isFromIpReturn) {
              this.state.pharmacyTblData?.forEach(item => item["discount"] = 0)
            } else if (avoidDiscountOP && this.state.pharmacyTblData?.length > 0 && !this.state.isFromIpReturn) {
              this.state.pharmacyTblData?.forEach(item => item["discount"] = 0)
            }

            if (!from_presc && !this.state.isFromIpRequest) {
              this.setState({
                doctordetails: this.state.DoctorsList?.find((item) => (item?.doctor_id === response.data.data?.doctor_id))
              })
            }
            if (response.data.data?.primary_payment_type == "Insurance credit") {
              this.setState({
                InsName: response.data.data?.insurance_company_name ? response.data.data?.insurance_company_name : '',
                InsNO: response.data.data?.policy_number ? response.data.data?.policy_number : '',
              })
            } else if (response.data.data?.primary_payment_type == "Corprate credit") {
              this.setState({
                CropName: response.data.data?.company_name ? response.data.data?.company_name : '',
                CropNo: response.data.data?.id_no ? response.data.data?.id_no : '',
                CompanyID: response.data.data?.employer_id ? response.data.data?.employer_id : null ,
              })
            }else if((response.data.data?.primary_payment_type == "") || (response.data.data?.primary_payment_type == "Patient credit")) {
              this.setState({
                CropName: '',
                CropNo:  '',
                CompanyID: null ,
                InsName: '',
                InsNO: '',
              })
            }

            this.setState({
              PatientDetails: response.data.data,
              paymentCreditType: response.data.data?.primary_payment_type,
              selPayemtnCreditType: "",
              PatientName: `${response.data.data?.first_name} ${response.data.data?.last_name ? response.data.data?.last_name : ""}`,
              PatientMobileNumber: response.data.data?.mobile_number,
              ReceivedCashAmount: "",
              CashBalanceAmount: "",
              totalPercentage: '',
              totalPercentage_w_r: '',
              totalDiscount: '',
              discountReason: '',
              isSavedReason: false,
              invoiceDateTime: new Date(),
              DateTimeChanged: false,
              Bill_ID: this.props.history?.location?.state?.isFromSavedBill ? this.state.Bill_ID : null,
              pharmacyTblData: RefreshTable ? [
                {
                  drug_name: '',
                  dosage_strength: '',
                  dosage_type: '',
                  batch_no: '',
                  expiry_date: '',
                  physical_box_no: '',
                  cost_per_quantity: '',
                  quantity: '',
                  total_cost: '',
                  discount: '',
                  total_price: '',
                  hsn_code: '',
                  stock_in_quantity: '',
                  gst_percentage: null,
                  Drug_sugg_list: [],
                }
              ] : this.state.pharmacyTblData,

            }, () => {
              if (this.state.ShowHistory && this.state.PrescHistoryData === null) {
                this.GetPrescpHistoryData()
                this.getPrescriptionListData()
              }
              this.LoaderFunction(false)
            })
          }
          else {
            this.LoaderFunction(false)
            this.errorMessage(response.data?.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          this.errorMessage(error?.message)
        })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }

  GetPrescriptionDetails(PrescriptionId) {
    try {
      RestAPIService.getAll(Serviceurls.PHARMA_PRESCRIPTION_GET + PrescriptionId + "/")
        .then((response) => {
          if (response.data.status === "success") {
            let PatientData = response.data?.data?.patient
            // PatientData["patient_id"] = response.data?.data?.patient?.id
            // PatientData["patient_account_number"] = response.data?.data?.patient_account_number
            let EmptyLine = [
              {
                drug_name: '',
                dosage_strength: '',
                dosage_type: '',
                batch_no: '',
                expiry_date: '',
                physical_box_no: '',
                cost_per_quantity: '',
                quantity: '',
                total_cost: '',
                discount: '',
                total_price: '',
                hsn_code: '',
                stock_in_quantity: '',
                gst_percentage: null,
                Drug_sugg_list: [],
              }
            ]

            let LineItems = []
            response.data.data?.prescription_lines?.forEach((item) => {
              if (item?.brand_name) {
                item["drug_name"] = item?.brand_name
                item["quantity"] = item?.quantity ? item?.quantity  : ""
                item["batch_no"] = ""
                LineItems.push(item)
              }
            })

            this.setState({
              // PatientName: response.data?.data?.patient?.name,
              // PatientMobileNumber: response.data?.data?.patient?.mobile_number,
              doctordetails: { displayKey: `${response.data.data?.doctor?.name} | ${response.data.data?.doctor?.doctor_reg_no}`, doctor_id: response.data.data?.doctor?.id, doctor_reg_no: response.data.data?.doctor?.doctor_reg_no, name: response.data.data?.doctor?.name },
              // PatientDetails: PatientData,
              pharmacyTblData: [...LineItems, ...EmptyLine],
              isBillsFetched: true,
            }, () => {
              this.GetPatientDetails(response.data?.data?.patient?.id, false, true)
              this.state.pharmacyTblData.forEach((item, index) => {
                if (item?.drug_name !== "" && item?.drug_name != null) { this.getDrugDetailsData(index, false) }
              })
            })
          }
          else {
            this.errorMessage(response.data?.message)
          }
        }).catch((error) => {
          this.errorMessage(error?.message)
        })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }


  GetBillingDetails(URL, ExtraLineItem = false, FetchPaymentDetails = false, TableData = null) {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(URL)
        .then((response) => {
          if (response.data.status === "success") {
            let EmptyLine = [
              {
                drug_name: '',
                dosage_strength: '',
                dosage_type: '',
                batch_no: '',
                expiry_date: '',
                physical_box_no: '',
                cost_per_quantity: '',
                quantity: '',
                total_cost: '',
                discount: '',
                total_price: '',
                hsn_code: '',
                stock_in_quantity: '',
                Drug_sugg_list: [],
                gst_percentage: null,
              }
            ]

            let LineItems = []
            response.data.data?.bill_line_items?.forEach((item) => {
              item["drug_name"] = item?.brand_name
              item["discount"] = item?.discount_percentage
              item["total_price"] = item?.net_total_amount
              LineItems.push(item)
            })
            this.setState({
              pharmacyTblData: TableData ? TableData : (ExtraLineItem ? [...LineItems, ...EmptyLine] : LineItems),
              totalPercentage: response.data.data?.general_discount_percentage,
              totalPercentage_w_r: CommonValidation.formatAmount(response.data.data?.general_discount_percentage),
              totalDiscount: this.state.isFromIpReturn ? CommonValidation.calculatePercentageWithoutRoundoff(response.data.data?.general_discount_percentage, this.state.subAmount) : response.data.data?.general_discount,
              discountReason: response.data.data?.remarks ? response.data.data?.remarks : '',
              invoiceDateTime: this.state.isFromSavedBill ? new Date() : response.data.data?.invoice_date,
              DateTimeChanged: this.state.isFromSavedBill ? false : true,
              doctordetails: (response.data.data?.doctor_name && response.data.data?.doctor_name !== "-") ? { displayKey: `${response.data.data?.doctor_name} | ${response.data.data?.doctor_reg_no}`, doctor_id: response.data.data?.doctor_id, doctor_reg_no: response.data.data?.doctor_reg_no, name: response.data.data?.doctor_name } : {},
              isBillsFetched: true,
              InvoiceNumber: response.data.data?.invoice_number ? response.data.data?.invoice_number : response.data.data?.temp_invoice_number,
              // OutStandingAmount: response.data.data?.total_outstanding_amt ? response.data.data?.total_outstanding_amt : 0,
              BillReceivedAmount: response.data.data?.paid_amount ? response.data.data?.paid_amount : 0, // hard coded "received amount key"
              outstandingAmt: response.data.data?.outstanding_amount ? response.data.data?.outstanding_amount : 0,
              totalBillNetAmount: response.data.data?.net_amount ? response.data.data?.net_amount : 0,
              isCurrentIP: response.data.data?.is_current_ip,
              FromMultiReturn: response.data.data?.return_status === "Multiple Bill Return" ? true : false
            }, () => {
              if (this.state.discountReason !== "") {
                this.setState({ isSavedReason: true })
              }
              if (this.state.isFromSavedBill) {
                let PatielDeatilsObj = this.state.PatientDetails
                PatielDeatilsObj.ip_admission_id = response.data.data?.ip_admission_id ? response.data.data?.ip_admission_id : null
                PatielDeatilsObj.appointment_id = PatielDeatilsObj.ip_admission_id ? null : (response.data.data?.appointment_id ? response.data.data?.appointment_id : null)
                this.setState({
                  PatientDetails: PatielDeatilsObj
                })
              }
              if (!this.state.isForView && this.state.isFromInvoice) {
                let BillArray = []
                this.state.pharmacyTblData.forEach((list, index) => {
                  list["return_quantity"] = ""
                  list["total_return_price"] = ""
                  list["total_cost"] = ""
                  // list["discount"] = ""
                  BillArray.push(list)
                })
                this.setState({
                  isForReturn: !this.state.isForReturn,
                  pharmacyTblData: BillArray,
                  isCard: false,
                  isCash: false,
                  isUPI: false,
                  isBankTranfer: false,
                  isCheque: false,
                  isInsurance: false,
                  CashAmount: "",
                  CardAmount: "",
                  UPIAmount: "",
                  BankTfrAmount: "",
                  ChequeAmount: "",
                  InsuranceAmount: "",
                  ReceivedCashAmount: "",
                  CashBalanceAmount: "",
                  CardTransactionNumber: "",
                  UPINumber: "",
                  BankTfrNumber: "",
                  ChequeNumber: "",
                  totalDiscount: 0,
                  // totalPercentage: 0,
                  isSavedReason: false,
                  invoiceDateTime: new Date(),
                  DateTimeChanged: false
                })
              }
              if (FetchPaymentDetails) {
                let PaymentMode = response.data.data?.payment_modes ? response.data.data?.payment_modes : null
                this.FetchPaymentMode(PaymentMode)
                this.setState({
                  CashAmount: response.data.data?.cash_amount ? response.data.data?.cash_amount : "",
                  CardAmount: response.data.data?.card_amount ? response.data.data?.card_amount : "",
                  UPIAmount: response.data.data?.upi_amount ? response.data.data?.upi_amount : "",
                  BankTfrAmount: response.data.data?.bank_amount ? response.data.data?.bank_amount : "",
                  ChequeAmount: response.data.data?.cheque_amount ? response.data.data?.cheque_amount : "",
                  InsuranceAmount: response.data.data?.insurance_amount ? response.data.data?.insurance_amount : "",
                  CardTransactionNumber: response.data.data?.card_number ? response.data.data?.card_number : "",
                  UPINumber: response.data.data?.transaction_id ? response.data.data?.transaction_id : "",
                  BankTfrNumber: response.data.data?.reference_no ? response.data.data?.reference_no : "",
                  InsuCompanyName: response.data.data?.insurance_company_name ? response.data.data?.insurance_company_name : "",
                  InsuPolicyNumber: response.data.data?.policy_number ? response.data.data?.policy_number : "",
                  InsuTransactionNumber: response.data.data?.transaction_number ? response.data.data?.transaction_number : "",
                  InsuValidity: response.data.data?.validity ? response.data.data?.validity : "",
                  billStatus: response.data.data?.billing_status ? response.data.data?.billing_status : '',
                })
              } else if (this.state.isFromIpReturn) {
                this.setState({ isCash: true, CashAmount: this.state.ReturnedNetAmount })
              } else {
                this.setState({
                  isCard: false,
                  isCash: false,
                  isUPI: false,
                  isBankTranfer: false,
                  isCheque: false,
                  isInsurance: false,
                  CashAmount: "",
                  CardAmount: "",
                  UPIAmount: "",
                  BankTfrAmount: "",
                  ChequeAmount: "",
                  InsuranceAmount: "",
                })
              }
            })
            this.calculateMarginProfit()
            this.LoaderFunction(false)
          }
          else {
            this.LoaderFunction(false)
            this.errorMessage(response.data?.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          this.errorMessage(error?.message)
        })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }

  FetchPaymentMode = (paymentModes) => {
    try {
      let states = this.state
      const modeToState = {
        1: "isCash",
        2: "isCard",
        3: "isUPI",
        5: "isBankTranfer",
        6: "isInsurance"
      };
      paymentModes?.forEach(mode => {
        const stateKey = modeToState[mode];
        if (stateKey) {
          states[stateKey] = true;
        }
      });
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorsList() {
    try {
      RestAPIService.getAll(Serviceurls.PHARMA_DOCTOR_LIST)
        .then((response) => {
          if (response.data.status === "success") {
            let DocList = []
            response.data?.data.forEach((item) => {
              item.displayKey = `${item?.name} | ${item?.doctor_reg_no}`
              DocList.push(item)
            })
            this.setState({
              DoctorsList: response.data?.data
            })
          }
          else {
            this.errorMessage(response.data?.message)
          }
        }).catch((error) => {
          this.errorMessage(error?.message)
        })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  DrugSearchLoader = (index) => {
    this.setState({
      SearchingDrugIndex: index
    })
  }
  BatchearchLoader = (index) => {
    this.setState({
      SearchingBatchIndex: index
    })
  }


  getBrandListData = (index) => {
    try {
      let { pharmacyTblData } = this.state
      if (pharmacyTblData[index]['drug_name'] !== "") {
        this.DrugSearchLoader(index)
        let brandName = pharmacyTblData[index]['drug_name']?.replaceAll('&', '%26')
        RestAPIService.getAll(Serviceurls.PHARMA_BILLING_BRAND_SEARCH + `?search=${CommonValidation.removeSpace(brandName)}`)
          .then((response) => {
            if (response.data.status === 'success') {
              if (response.data?.data?.length > 0) {
                pharmacyTblData[index]['Drug_sugg_list'] = response.data.data
                this.setState({ pharmacyTblData }, () => {
                  this.DrugSearchLoader(null)
                })
              } else if (response.data?.data?.length === 0) {
                this.DrugSearchLoader(null)
                this.errorMessage("No Drug Found")
              } else {
                this.DrugSearchLoader(null)
                this.errorMessage(response?.data?.message)
              }
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.DrugSearchLoader(null)
              this.errorMessage(error.response.data.message)
            } else {
              this.DrugSearchLoader(null)
              this.errorMessage(error.message)
            }
          })
      }
      else {
        this.errorMessage("Enter Brand Name")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDrugDetailsData = (index, FromDrug = false) => {
    try {
      let { pharmacyTblData, PatientDetails } = this.state
      let avoid_drugDiscount = this.state.ConfigSetting;
      let avoidDiscountIP = (avoid_drugDiscount?.show_discount_ip && PatientDetails?.ip_admission_id);
      let avoidDiscountOP = (avoid_drugDiscount?.show_discount_op && PatientDetails?.appointment_id);

      this.BatchearchLoader(index)
      let QueryParams = ""
      QueryParams = `?drug_ms_id=${this.state.pharmacyTblData[index]?.drug_ms_id ? this.state.pharmacyTblData[index]?.drug_ms_id : this.state.pharmacyTblData[index]?.drug_id}`
      if (this.state.pharmacyTblData[index]?.drug_ms_id || this.state.pharmacyTblData[index]?.drug_id) {
        RestAPIService.getAll(Serviceurls.PHARMA_BILLING_DRUG_DETAILS_GET + QueryParams)
          .then((response) => {
            if (response.data.status === 'success') {
              this.setState({ selectedBatchListData: response.data.data })
              let hsncode = response?.data?.data?.[0]?.hsn_code             
              if(this.state.ConfigSetting?.hsncode_mandatory_for_billing && !hsncode) {
                this.setState({
                  hsnerror: true
                })
                this.errorMessage("The HSN Code for this drug is not available, so billing for this drug cannot be processed.")
              }
              if (response.data.data?.length > 0) {
                pharmacyTblData[index]['Batch_Sugg_list'] = response.data.data
                if (pharmacyTblData[index]['batch_no'] === "" || !pharmacyTblData[index]['batch_no']) {
                  let paraData = response.data.data
                  pharmacyTblData[index]['drug_id'] = response.data.data[0]?.drug_id
                  if (pharmacyTblData[index]?.drug_id === pharmacyTblData[index - 1]?.drug_id && paraData?.length > 1) {
                    let sameBatch = pharmacyTblData?.some(item => ((item?.drug_id === response.data.data[1]?.drug_id) && (item?.batch_no === response.data.data[1]?.batch_no) && (item?.expiry_date === response.data.data[1]?.expiry_date) && (item?.cost_per_quantity === response.data.data[1].cost_per_quantity)))
                    if (sameBatch) {
                      for (let i = 1; i < paraData?.length; i++) {
                        if (!pharmacyTblData?.some(item => ((item?.drug_id === response.data.data[i]?.drug_id) && (item?.batch_no === response.data.data[i]?.batch_no) && (item?.expiry_date === response.data.data[i]?.expiry_date) && (item?.cost_per_quantity === response.data.data[i].cost_per_quantity)))) {
                          let totalCost = (pharmacyTblData[index]['quantity'] ? +(pharmacyTblData[index]['quantity']) : 0) * (response.data.data[i]?.cost_per_quantity ? +(response.data.data[i]?.cost_per_quantity) : 0)
                          pharmacyTblData[index]['batch_no'] = paraData[i]?.batch_no
                          pharmacyTblData[index]['cost_per_quantity'] = response.data.data[i]?.cost_per_quantity
                          pharmacyTblData[index]['expiry_date'] = response.data.data[i]?.expiry_date
                          pharmacyTblData[index]['physical_box_no'] = response.data.data[i]?.physical_box_no
                          pharmacyTblData[index]['stock_in_quantity'] = response.data.data[i]?.stock_in_quantity
                          pharmacyTblData[index]['hsn_code'] = response.data.data[i]?.hsn_code
                          pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[i]?.buying_price_per_piece
                          pharmacyTblData[index]['gst_percentage'] = response.data.data[i]?.gst_percentage
                          if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : response.data.data[i]?.sales_discount_for_bill }
                          else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : response.data.data[i]?.sales_discount_for_bill }
                          else { pharmacyTblData[index]['discount'] = response.data.data[i]?.sales_discount_for_bill }
                          pharmacyTblData[index]['schedule'] = response.data.data[i]?.schedule
                          pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                          break;
                        }
                      }
                      // this.errorMessage(`${paraData[1]?.batch_no} Batch No is Already Exists`)
                    } else {
                      let totalCost = (pharmacyTblData[index]['quantity'] ? +(pharmacyTblData[index]['quantity']) : 0) * (response.data.data[1]?.cost_per_quantity ? +(response.data.data[1]?.cost_per_quantity) : 0)
                      pharmacyTblData[index]['batch_no'] = paraData[1]?.batch_no
                      pharmacyTblData[index]['cost_per_quantity'] = response.data.data[1]?.cost_per_quantity
                      pharmacyTblData[index]['expiry_date'] = response.data.data[1]?.expiry_date
                      pharmacyTblData[index]['physical_box_no'] = response.data.data[1]?.physical_box_no
                      pharmacyTblData[index]['stock_in_quantity'] = response.data.data[1]?.stock_in_quantity
                      pharmacyTblData[index]['hsn_code'] = response.data.data[1]?.hsn_code
                      pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[1]?.buying_price_per_piece
                      pharmacyTblData[index]['gst_percentage'] = response.data.data[1]?.gst_percentage
                      if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : response.data.data[1]?.sales_discount_for_bill }
                      else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : response.data.data[1]?.sales_discount_for_bill }
                      else { pharmacyTblData[index]['discount'] = response.data.data[1]?.sales_discount_for_bill }
                      pharmacyTblData[index]['schedule'] = response.data.data[1]?.schedule
                      pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                    }
                  } else {
                    let sameBatchNo = pharmacyTblData?.some(item => (item?.drug_id === response.data.data[0]?.drug_id) && (item?.batch_no === response.data.data[0]?.batch_no) && (item?.expiry_date === response.data.data[0]?.expiry_date) && (item?.cost_per_quantity === response.data.data[0].cost_per_quantity))
                    if (sameBatchNo) {
                      for (let i = 1; i < paraData?.length; i++) {
                        if (!pharmacyTblData?.some(item => (item?.drug_id === response.data.data[i]?.drug_id) && (item?.batch_no === response.data.data[i]?.batch_no) && (item?.expiry_date === response.data.data[i]?.expiry_date) && (item?.cost_per_quantity === response.data.data[i].cost_per_quantity))) {
                          let totalCost = (pharmacyTblData[index]['quantity'] ? +(pharmacyTblData[index]['quantity']) : 0) * (response.data.data[i]?.cost_per_quantity ? +(response.data.data[i]?.cost_per_quantity) : 0)
                          pharmacyTblData[index]['batch_no'] = paraData[i]?.batch_no
                          pharmacyTblData[index]['cost_per_quantity'] = response.data.data[i]?.cost_per_quantity
                          pharmacyTblData[index]['expiry_date'] = response.data.data[i]?.expiry_date
                          pharmacyTblData[index]['physical_box_no'] = response.data.data[i]?.physical_box_no
                          pharmacyTblData[index]['stock_in_quantity'] = response.data.data[i]?.stock_in_quantity
                          pharmacyTblData[index]['hsn_code'] = response.data.data[i]?.hsn_code
                          pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[i]?.buying_price_per_piece
                          pharmacyTblData[index]['gst_percentage'] = response.data.data[i]?.gst_percentage
                          if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : response.data.data[i]?.sales_discount_for_bill }
                          else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : response.data.data[i]?.sales_discount_for_bill }
                          else { pharmacyTblData[index]['discount'] = response.data.data[i]?.sales_discount_for_bill }
                          pharmacyTblData[index]['schedule'] = response.data.data[i]?.schedule
                          pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                          break;
                        }
                      }
                    } else {
                      let totalCost = (pharmacyTblData[index]['quantity'] ? +(pharmacyTblData[index]['quantity']) : 0) * (response.data.data[0]?.cost_per_quantity ? +(response.data.data[0]?.cost_per_quantity) : 0)
                      pharmacyTblData[index]['batch_no'] = paraData[0]?.batch_no
                      pharmacyTblData[index]['cost_per_quantity'] = response.data.data[0]?.cost_per_quantity
                      pharmacyTblData[index]['expiry_date'] = response.data.data[0]?.expiry_date
                      pharmacyTblData[index]['physical_box_no'] = response.data.data[0]?.physical_box_no
                      pharmacyTblData[index]['stock_in_quantity'] = response.data.data[0]?.stock_in_quantity
                      pharmacyTblData[index]['hsn_code'] = response.data.data[0]?.hsn_code
                      pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[0]?.buying_price_per_piece
                      pharmacyTblData[index]['gst_percentage'] = response.data.data[0]?.gst_percentage
                      if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : response.data.data[0]?.sales_discount_for_bill }
                      else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : response.data.data[0]?.sales_discount_for_bill }
                      else { pharmacyTblData[index]['discount'] = response.data.data[0]?.sales_discount_for_bill }
                      pharmacyTblData[index]['schedule'] = response.data.data[0]?.schedule
                      pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                    }
                  }
                }
                this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => {
                  this.BatchearchLoader(null)
                  this.calculateDiscountTotalPrice(index)
                })
                if (FromDrug && !this.state.isFromIpRequest) {
                  this.QuantityRef?.current?.focus();
                }
              } else {
                pharmacyTblData[index]['Batch_Sugg_list'] = []
                this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => { this.BatchearchLoader(null) })
              }
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.BatchearchLoader(null)
              this.errorMessage(error.response.data.message)
            } else {
              this.BatchearchLoader(null)
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }


  postPharmacyBillData = () => {
    try {
      let states = this.state
      let url = ''
      if (this.state.receivePayment) {
        if (this.state.isForReturn || this.state.isFromIpReturn) {
          url = Serviceurls.PHARMA_RETURN_BILL
        }
        else {
          url = Serviceurls.PHARMA_BILLING_RECEIVE_PAYMENT_POST
        }
      } else if (states.addToAdvance) {
        url = Serviceurls.PHARMA_RETURN_BILL
      } else if (this.state.creditPayment) {
        url = Serviceurls.PHARMA_BILLING_RECEIVE_PAYMENT_POST
      } else if (this.state.savedBill) {
        url = Serviceurls.PHARMA_BILLING_RECEIVE_PAYMENT_POST
      }
      let Lineitems = []
      let SumOfDiscount = 0
      this.state.pharmacyTblData.forEach((item) => {
        if (item.drug_name !== "" && (this.state.isFromIpRequest ? item?.stock_in_quantity !== 0 : true) && (this.state.isForReturn ? item?.return_quantity !== "" : true)) {
          let BillItem = {
            "drug_id": item.drug_id,
            "hsn_code": item.hsn_code,
            "batch_no": item.batch_no,
            "expiry_date": item.expiry_date,
            "physical_box_no": item.physical_box_no,
            "cost_per_quantity": item.cost_per_quantity,
            "total_cost": +(+item.total_cost)?.toFixed(2),
            "discount_percentage": +item.discount,
            "gst_percentage": item.gst_percentage ? item.gst_percentage : 0,
            "quantity": (this.state.isForReturn || this.state.isFromIpReturn) ? +item.return_quantity : +item.quantity,
            "net_total_amount": (this.state.isForReturn || this.state.isFromIpReturn) ? +(+item.total_return_price)?.toFixed(2) : +(+item.total_price)?.toFixed(2),
          }
          if (this.state.isFromIpRequest) { BillItem["ip_line_item_id"] = item?.line_item_id }
          if (this.state.isFromIpReturn) { BillItem["return_id"] = item?.return_id }
          if (item?.line_item_id) { BillItem["line_item_id"] = item?.line_item_id }
          Lineitems.push(BillItem)
          var dis_amt = (+item.total_cost) * (+item.discount / 100)
          SumOfDiscount += (+dis_amt)
        }
      })
      let noDiscountAmount = 0;
      this.state.pharmacyTblData?.forEach(element => {
        // if (element.drug_name !== "" && (this.state.isFromIpRequest ? element?.stock_in_quantity !== 0 : true) && (this.state.isForReturn ? element?.return_quantity !== "" : true)) {
        noDiscountAmount = noDiscountAmount + element.total_cost
        // }
      })

      let Datetime = new Date(this.state.invoiceDateTime)
      let date = DateTime.fromJSDate(Datetime).toFormat("yyyy-MM-dd")
      let time = DateTime.fromJSDate(Datetime).toLocaleString(DateTime.TIME_24_SIMPLE)

      let PaymentTypeData = [];
      if (this.state.isCash) {
        PaymentTypeData.push(1)
      }
      if (this.state.isCard) {
        PaymentTypeData.push(2)
      }
      if (this.state.isUPI) {
        PaymentTypeData.push(3)
      }
      if (this.state.isBankTranfer) {
        PaymentTypeData.push(5)
      }

      let ReceivedAmt = 0

      if (states.isCredit || states.savedBill) {
        ReceivedAmt = 0
      } else if (states?.isForReturn || states?.isFromIpReturn) {
        if ((states.BillReceivedAmount != states.totalBillNetAmount) && states.BillReceivedAmount != 0) {
          ReceivedAmt = states.AdvanceAmt ? states.AdvanceAmt : states.ReceiptAmt
        } else if ((states.BillReceivedAmount == states.totalBillNetAmount) && states.BillReceivedAmount != 0) {
          ReceivedAmt = states.ReturnedNetAmount
        } else {
          ReceivedAmt = states.BillReceivedAmount
        }
      } else {
        ReceivedAmt = states.netAmount ? states.netAmount : 0
      }
      // if (this.state.isInsurance) {
      //   PaymentTypeData.push(6)
      // }
      
      let creditType = (states.isCredit && ( states?.paymentCreditType=="Patient credit" )) ? "Patient credit" : (states.isCredit && (states?.paymentCreditType == "Insurance" || states?.paymentCreditType == "Insurance credit")) ? "Insurance credit" : (states.isCredit && (states?.paymentCreditType == "Corporate" || states?.paymentCreditType == "Corprate credit")) ? "Corprate credit" : null
      let data = {
        "invoice_date": `${date} ${time}:00`,
        "billing_customer_name": states.PatientName,
        "add_to_advance": (states.AdvanceAmt || states.addToAdvance) ? true : false,
        "billing_customer_mobile_no": states?.PatientMobileNumber,
        "doctor_name": (states.doctordetails?.name && states.doctordetails?.doctor_id) ? states.doctordetails?.name : states?.PatientDetails?.doctor_name,
        "patient_id": states.PatientDetails?.patient_id,
        "patient_account_number": states?.PatientDetails?.patient_account_number,
        "ip_number": states?.PatientDetails?.ip_number,
        "ip_admission_id": states?.isFromIpRequest ? states?.IpRequestAdmId : states?.PatientDetails?.ip_admission_id ? states?.PatientDetails?.ip_admission_id : null,
        "appointment_id": states?.PatientDetails?.ip_admission_id ? null : (states?.PatientDetails?.appointment_id ? states?.PatientDetails?.appointment_id : null),
        "discount_amount": SumOfDiscount ? +(SumOfDiscount).toFixed(2) : 0,
        "taxable_amount": (states?.isForReturn || states?.isFromIpReturn) ? +(states.ReturnedNetAmount)?.toFixed(2) : +(states.netAmount)?.toFixed(2),
        "general_discount": +states.totalDiscount,
        "cgst_amount": 0,
        "sgst_amount": 0,
        "igst_amount": 0,
        "tax_amount": 0,
        "round_off": states.roundOff,
        "delivery_charge": 0,
        "gross_amount": +states.subAmount?.toFixed(2),
        "grand_total": +states.subAmount?.toFixed(2),
        "net_amount": (states?.isForReturn || states?.isFromIpReturn) ? +(states.ReturnedNetAmount)?.toFixed(2) : +(states.netAmount)?.toFixed(2),
        "received_amount": ReceivedAmt ? +(ReceivedAmt)?.toFixed(2) : 0,
        "balance_amount": 0,
        "bill_line_items": Lineitems,
        "employee_id": states?.paymentCreditType == "Corprate credit" ? states.CropNo : null,
        "company_name": states?.paymentCreditType == "Corprate credit" ? states.CropName : "",
        "credit_type": creditType ? creditType : "Patient credit",
        "validity": states.InsuValidity,
        "bill_type": this.state.creditPayment ? "Credit" : this.state.savedBill ? "Saved" : "Bill",
        "doctor_reg_no": states.doctordetails?.doctor_id ? states.doctordetails?.reg_no : null,
        "line_item": (states.isFromIpRequest || states.isFromIpReturn) ? Lineitems : [],
        "ip_transmit_id": (states.isFromIpRequest || states?.isFromIpReturn) ? states?.ip_transmit_id : null,
        "cash_amount": +states.CashAmount,
        "card_amount": +states.CardAmount,
        "upi_amount": +states.UPIAmount,
        "bank_amount": +states.BankTfrAmount,
        "cheque_amount": +states.ChequeAmount,
        "insurance_amount": +states.InsuranceAmount,
        "general_discount_percentage": +states?.totalPercentage,
        "payment_modes": PaymentTypeData ? PaymentTypeData : [],
        "doctor_id": states.doctordetails?.doctor_id ? states.doctordetails?.doctor_id : null,
        "doctor_reg_no": states.doctordetails?.doctor_id ? states.doctordetails?.doctor_reg_no : null,
        "card_number": states.CardTransactionNumber,
        "reference_no": states.BankTfrNumber,
        "transaction_id": states.UPINumber,
        "insurance_company_name": states?.paymentCreditType == "Insurance credit" ? states.InsName : "",
        "transaction_number": states?.InsuTransactionNumber,
        "policy_number": states?.paymentCreditType == "Insurance credit" ? states?.InsNO : null,
        "billing_status": (this.state.savedBill || this.state.isCredit) ? 'Partially Saved' : "Payment Received",
        "payment_status": (this.state.savedBill || this.state.isCredit) ? 'not paid' : "paid",
        "billing_doctor_name": !states.doctordetails?.doctor_id ? states.doctordetails?.name : null,
        "net_total_amount": noDiscountAmount ? +(+noDiscountAmount)?.toFixed(2) : 0,
        "remarks": +states?.totalPercentage != 0 ? this.state.discountReason : '',
        "employer_id":  states?.paymentCreditType == "Corprate credit" ? states.CompanyID : null,
      }
      if (states.Bill_ID) { data["bill_summary_id"] = states.Bill_ID }
      if (states.isForReturn) {
        data["invoice_number"] = states.BillDetails?.invoice_numbers
        data["invoice_sequence"] = states.BillDetails?.invoice_sequence
      }
      if (states.isFromIpReturn) {
        data["invoice_number"] = states.BillDetails?.invoice_number
      }
      if (states.isFromSavedBill) {
        data["temp_invoice_number"] = states.BillDetails?.temp_invoice_number
      }
      if (states.isFromPrescription) {
        data['transmitted_id'] = states.PrescriptionId
      }

      if (this.state?.isForInvoiceEdit) { // For Update Invoice
        RestAPIService.updateWithOutId(data, url)
          .then((response) => {
            if (response.data.status === 'success') {
              this.LoaderFunction(false)
              this.successMessage(response.data.message)
              this.setState({
                creditPayment: false,
                receivePayment: false,
                savedBill: false,
                disableBtn: false,
              }, () => {
                this.props?.getPharmaSavedBillCount(true)
                this.props?.getPharmaPrescriptionCount(true)
                this.DiscardAction(true, true)
              })
            }
          }).catch(error => {
            if (error?.response?.data?.message) {
              this.LoaderFunction(false)
              this.setState({disableBtn: false})
              this.errorMessage(error.response.data.message)
              this.setState({
                creditPayment: false,
                receivePayment: false,
                savedBill: false
              })
            } else {
              this.LoaderFunction(false)
              this.setState({disableBtn: false})
              this.errorMessage(error.message)
              this.setState({
                creditPayment: false,
                receivePayment: false,
                savedBill: false
              })
            }
            this.setState({ disableBtn: false })
          })
      } else {
        RestAPIService.create(data, url)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.setState({ disableBtn: false })
              if (this.state.receivePayment) {
                this.LoaderFunction(false)
                this.setState({disableBtn: false})
                if (this.state.isForReturn) {
                  this.setState({
                    CompletedBillID: response.data.data?.id,
                    invoiceNumber: response?.data?.data?.invoice_number
                  })
                }
                else if (this.state.isFromIpReturn) {
                  this.setState({
                    CompletedBillID: response.data.data?.id,
                  })
                }
                else {
                  this.setState({
                    CompletedBillID: this.state.isForReturn ? null : response.data.data?.id,
                    creditPayment: false,
                    receivePayment: false,
                    savedBill: false,
                    invoiceNumber: response?.data?.data?.invoice_number
                  })
                }
              } else {
                this.setState({disableBtn: false})
                if (this.state.creditPayment) {
                  this.setState({
                    CompletedBillID: this.state.isForReturn ? null : response.data.data?.id,
                    creditPayment: false,
                    receivePayment: false,
                    savedBill: false,
                    invoiceNumber: response?.data?.data?.invoice_number,
                  })
                  this.LoaderFunction(false)
                  if (this.state.isForReturn) {
                    this.setState({
                      CompletedBillID: response.data.data?.id,
                    })
                  }
                  else if (this.state.isFromIpReturn) {
                    this.setState({
                      CompletedBillID: response.data.data?.id,
                    })
                  }
                  else {
                    this.setState({
                      CompletedBillID: this.state.isForReturn ? null : response.data.data?.id,
                      creditPayment: false,
                      receivePayment: false,
                      savedBill: false,
                      invoiceNumber: response?.data?.data?.invoice_number
                    })
                  }
                } else {
                  if (this.state.savedBill) {
                    this.setState({
                      SavedSuccessPopup: true,
                      bill_summary_id_Print: response.data.data?.id ? response.data.data?.id : null
                    })
                  }
                  if (this.state.creditPayment) {
                    this.setState({
                      CompletedBillID: this.state.isForReturn ? null : response.data.data?.id,
                      creditPayment: false,
                      receivePayment: false,
                      savedBill: false,
                    })
                    this.LoaderFunction(false)
                  } else {
                    this.setState({
                      invoiceNumber: response?.data?.data?.temp_invoice_number,
                      creditPayment: false,
                      receivePayment: false,
                      savedBill: false,
                    })
                    this.props?.getPharmaSavedBillCount(true)
                    this.props?.getPharmaPrescriptionCount(true)
                    this.LoaderFunction(false)
                    this.DiscardAction(true, this.state.isFromInvoice)
                  }
                }
              }
            }
          }).catch(error => {
            if (error?.response?.data?.message) {
              this.LoaderFunction(false)
              this.setState({disableBtn: false})
              this.errorMessage(error.response.data.message)
              this.setState({
                creditPayment: false,
                receivePayment: false,
                savedBill: false
              })
            } else {
              this.LoaderFunction(false)
              this.setState({disableBtn: false})
              this.errorMessage(error.message)
              this.setState({
                creditPayment: false,
                receivePayment: false,
                savedBill: false
              })
            }
            this.setState({ disableBtn: false })
          })
      }

    } catch (error) {
      this.setState({disableBtn: false})
      this.errorMessage(error.message)
      this.setState({ disableBtn: false })
    }
  }



  calculateDiscountTotalPrice = (index) => {
    try {
      let { pharmacyTblData } = this.state
      let totalCost = pharmacyTblData[index]['total_cost']
      let discount = pharmacyTblData[index]['discount']
      let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
      pharmacyTblData[index]['total_price'] = totalCost - discountValue
      this.setState({ pharmacyTblData }, () => {
        this.calculateTotalDiscount()
        this.SetFullCashAmount()
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  calculateCostAndTotal = (value, index, FromReturn = false) => {
    try {
      if (FromReturn) {
        let { pharmacyTblData } = this.state
        pharmacyTblData[index]['total_cost'] = +value * pharmacyTblData[index]['cost_per_quantity']
        let totalCost = pharmacyTblData[index]['total_cost']
        let discount = pharmacyTblData[index]['discount']
        let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
        let FinalPrice = totalCost - discountValue
        pharmacyTblData[index]['total_return_price'] = FinalPrice
        this.setState({ pharmacyTblData }, () => this.calculateTotalDiscount())
      } else {
        let { pharmacyTblData } = this.state
        pharmacyTblData[index]['total_cost'] = +value * pharmacyTblData[index]['cost_per_quantity']
        let totalCost = pharmacyTblData[index]['total_cost']
        let discount = pharmacyTblData[index]['discount']
        let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
        let FinalPrice = totalCost - discountValue
        pharmacyTblData[index]['total_price'] = FinalPrice
        this.setState({ pharmacyTblData }, () => this.calculateTotalDiscount())
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  calculateTotalDiscount = () => {
    try {
      // console.log(this.state.subAmount,this.state.totalPercentage);
      this.state.totalDiscount = CommonValidation.calculatePercentageWithoutRoundoff(this.state.subAmount, this.state.totalPercentage)
      if (!this.state.isForReturn) {
        this.state.totalPercentage = CommonValidation.amountPercentageWithoutRoundoff(this.state.totalDiscount, this.state.subAmount)
      }
      this.setState({
        totalDiscount: this.state.totalDiscount,
        totalPercentage: this.state.totalPercentage,
        totalPercentage_w_r: CommonValidation.formatAmount(this.state.totalPercentage),
      }, () => { 
        this.SetFullCashAmount()
        this.calculateMarginProfit()
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e, index) => {
    try {
      const { name, value } = e.target
      switch (name) {
        case 'quantity':
          if (this.state.pharmacyTblData[index]['drug_name']) {
            if (((CommonValidation.numberOnly(value) && value?.length < 7) || value === '')) {
              this.state.pharmacyTblData[index]['quantity'] = value
              this.setState({
                pharmacyTblData: this.state.pharmacyTblData
              }, () => {
                this.calculateCostAndTotal(value, index, false)
                this.calculateMarginProfit()
              })
            }
          } else {
            this.errorMessage('Select Brand Name')
          }
          break;
        case 'return_quantity':
          if (this.state.pharmacyTblData[index]['drug_name']) {
            if ((CommonValidation.numberOnly(value) && value?.length < 7) && (value != 0)) {
              this.state.pharmacyTblData[index]['return_quantity'] = value
              this.setState({
                pharmacyTblData: this.state.pharmacyTblData
              }, () => {
                this.calculateCostAndTotal(value, index, true)
                this.calculateMarginProfit()
                if ((this.state?.isForReturn || this.state?.isFromIpReturn) && +this.state.netAmount === 0 && this.state.subAmount && +this.state.subAmount > 0) {
                  this.setState({
                    isCard: false,
                    isCash: true,
                    isUPI: false,
                    isBankTranfer: false,
                    isCheque: false,
                    isInsurance: false,
                    CashAmount: "",
                    CardAmount: "",
                    CardTransactionNumber: "",
                    UPINumber: "",
                    UPIAmount: "",
                    BankTfrAmount: "",
                    BankTfrNumber: "",
                    ChequeNumber: "",
                    ChequeAmount: "",
                    InsuranceAmount: "",
                    InsuCompanyName: "",
                    InsuPolicyNumber: "",
                    InsuTransactionNumber: "",
                    InsuValidity: '',
                  })
                } else if (this.state?.isForReturn || this.state?.isFromIpReturn) {
                  this.setState({ isCash: true })
                }
              })
            } else if ((value === '') || (value === 0)) {
              this.state.pharmacyTblData[index]['return_quantity'] = ''
              this.state.pharmacyTblData[index]['total_cost'] = ''
              this.state.pharmacyTblData[index]['total_return_price'] = ''
              this.setState({
                totalDiscount: 0,
                pharmacyTblData: this.state.pharmacyTblData
              })
            }
          } else {
            this.errorMessage('Select Brand Name')
          }
          break;
        case 'discount':
          if (this.state.pharmacyTblData[index]['quantity']) {
            if ((CommonValidation.DecimalNumber(value) && value <= 100) || value === '') {
              this.state.pharmacyTblData[index]['discount'] = value
              this.setState({
                pharmacyTblData: this.state.pharmacyTblData
              }, () => {
                this.calculateDiscountTotalPrice(index)
                this.calculateMarginProfit()
              })
            }
          } else {
            this.errorMessage('Enter Quantity')
          }
          break;
        case 'totalDiscount':
          if ((CommonValidation.NumberWithDot(value) && value <= this.state.subAmount) || value === "") {
            this.state.totalPercentage = CommonValidation.amountPercentageWithoutRoundoff(value, this.state.subAmount)
            this.state.totalPercentage_w_r = CommonValidation.amountPercentage(value, this.state.subAmount)
            this.setState({
              totalDiscount: value
            }, () => {
              this.calculateMarginProfit()
            })
          }
          break;
        case 'totalPercentage':
          if ((CommonValidation.DecimalNumber(value) && +value <= 100) || value === "") {
            this.state.totalDiscount = CommonValidation.calculatePercentageWithoutRoundoff(value, this.state.subAmount)
            this.setState({
              totalPercentage: value,
              totalPercentage_w_r:value
            }, () => {
              this.calculateMarginProfit()
            })
          }
          break;
        default:
          this.setState({
            [name]: value
          })
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  disabled = (names, index) => {
    try {
      let disable = true
      switch (names) {
        case 'quantity':
          if (this.state.pharmacyTblData[index]['drug_name'] && !this.state.isFromInvoice && !this.state.isFromIpReturn && ((this.state.isFromSavedBill && this.state.LoggedRole === "user") ? (RoleData?.permission_access?.pharma?.isEditSave) : true)) {
            disable = false
          }
          break;
        case 'discount':
          if (this.state.pharmacyTblData[index]['quantity'] && !this.state.isFromInvoice && !this.state.isFromIpReturn && (this.state.LoggedRole !== "user" || (RoleData?.permission_access?.pharma?.isDiscountEdit && RoleData?.permission_access?.pharma?.isEditSave))) {
            disable = false
          }
          break;
        case 'batch_no':
          if (this.state.pharmacyTblData[index]['drug_name'] && this.state.pharmacyTblData[index]['dosage_strength'] && this.state.pharmacyTblData[index]['dosage_type'] && !this.state.isFromInvoice && !this.state.isFromIpReturn && ((this.state.isFromSavedBill && this.state.LoggedRole === "user") ? (RoleData?.permission_access?.pharma?.isEditSave) : true)) {
            disable = false
          }
          break;
        case 'drug_name':
          disable = false;
          break;
        case 'return_quantity':
          disable = false;
          break;
        default:
          disable = true;
          break;
      }
      return disable;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  inputTextBox = (values, names, labels, width, index, height, disable) => {
    return (
      <TextField
        inputRef={this.state.isFromIpRequest ? null : ((names === "quantity" && this.state.QuantityCurrentIndex === index) ? this.QuantityRef : null)}
        autoFocus
        size='small'
        disabled={disable ? disable : this.disabled(names, index)}
        variant='standard'
        sx={{ width: width }}
        placeholder={labels}
        onKeyDown={(e) => { if (e.key === "Enter") { this.DrugNameRef?.current.focus() } }}
        name={names}
        autoComplete='off'
        inputProps={{
          emed_tid: labels,
          style: {
            height: height ? height : '2vh', marginTop: '0.5vw', textAlign: 'center',
            color: (names === "quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["stock_in_quantity"] < this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["stock_in_quantity"]) ? "red" : null) :
              (names === "return_quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["return_quantity"] > this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["quantity"]) ? "red" : null) : null
          }
        }}
        value={values}
        onChange={(e) => { this.onChangeHandler(e, index) }}
        error={(names === "quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["stock_in_quantity"] < this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["stock_in_quantity"])) :
          (names === "return_quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["return_quantity"] > this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["quantity"])) : false}
      />
    )
  }

  renderTextBox = (names, value, height, diasble) => {
    try {
      return (
        <TextField
          autoFocus
          variant='standard'
          autoComplete='off'
          size='small'
          InputProps={{
            startAdornment: names === "totalDiscount" ? <InputAdornment position="start">{CurrencySymbol}</InputAdornment> : null,
            endAdornment: names === "totalPercentage" ? <InputAdornment position="start">%</InputAdornment> : null
          }}
          className={'eMed_discount_txt'}
          disabled={diasble || this.state.isForReturn || this.state.isFromIpReturn || (this.state.isFromInvoice)}
          inputProps={{ style: { height: height }, emed_tid: names }}
          name={names}
          value={value}
          onChange={(e) => { this.onChangeHandler(e, names) }}
          onBlur={() => { this.concessionManditoryPopup(names) }}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  autocompleteOnChangeHandler = (newValue, names, index) => {
    try {
      let { pharmacyTblData, PatientDetails } = this.state
      let avoid_drugDiscount = this.state.ConfigSetting;
      let avoidDiscountIP = (avoid_drugDiscount?.show_discount_ip && PatientDetails?.ip_admission_id);
      let avoidDiscountOP = (avoid_drugDiscount?.show_discount_op && PatientDetails?.appointment_id);

      // if (pharmacyTblData[index][names]?.length > 0) {
      switch (names) {
        case 'drug_name':
          pharmacyTblData[index][names] = newValue['drug_name']
          pharmacyTblData[index]['dosage_strength'] = newValue['dosage_strength']
          pharmacyTblData[index]['dosage_type'] = newValue['dosage_type']
          pharmacyTblData[index]['drug_ms_id'] = newValue['drug_ms_id']
          pharmacyTblData[index]['quantity'] = ""
          pharmacyTblData[index]['total_cost'] = ""
          pharmacyTblData[index]['total_price'] = ""
          pharmacyTblData[index]['discount'] = ""
          this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => this.getDrugDetailsData(index, true))
          // this.QuantityRef?.current?.focus();
          break;
        case 'batch_no':
          let batchSelected = false
          pharmacyTblData?.forEach(element => {
            if ((element?.batch_no === newValue['batch_no']) && (element?.drug_id === newValue['drug_id'] && (element?.expiry_date === newValue['expiry_date']) && (element?.cost_per_quantity === newValue['cost_per_quantity']))) {
              batchSelected = true
            }
          })
          if (this.state.isFromIpRequest) {
            if (!batchSelected) {
              pharmacyTblData[index][names] = newValue['batch_no']
              pharmacyTblData[index]['expiry_date'] = newValue['expiry_date']
              pharmacyTblData[index]['cost_per_quantity'] = newValue['cost_per_quantity']
              pharmacyTblData[index]['stock_in_quantity'] = newValue['stock_in_quantity']
              pharmacyTblData[index]['drug_id'] = newValue['drug_id']
              pharmacyTblData[index]['gst_percentage'] = newValue['gst_percentage']
              pharmacyTblData[index]['buying_price_per_piece'] = newValue['buying_price_per_piece']
              pharmacyTblData[index]['hsn_code'] = newValue?.hsn_code ? newValue?.hsn_code : ''
              pharmacyTblData[index]['total_cost'] = newValue['cost_per_quantity'] * pharmacyTblData[index]['quantity']
              pharmacyTblData[index]['total_price'] = newValue['cost_per_quantity'] * pharmacyTblData[index]['quantity']
              if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : newValue['sales_discount_for_bill'] }
              else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : newValue['sales_discount_for_bill'] }
              else { pharmacyTblData[index]['discount'] = newValue['sales_discount_for_bill'] }
              pharmacyTblData[index]['schedule'] = newValue['schedule']
            } else {
              this.errorMessage(`${newValue['batch_no']} Batch No Already Exists`)
            }
          } else {
            if (batchSelected) {
              this.errorMessage(`${newValue['batch_no']} Batch No Already Exists`)
            } else {
              pharmacyTblData[index][names] = newValue['batch_no']
              pharmacyTblData[index]['expiry_date'] = newValue['expiry_date']
              pharmacyTblData[index]['cost_per_quantity'] = newValue['cost_per_quantity']
              pharmacyTblData[index]['stock_in_quantity'] = newValue['stock_in_quantity']
              pharmacyTblData[index]['drug_id'] = newValue['drug_id']
              pharmacyTblData[index]['buying_price_per_piece'] = newValue['buying_price_per_piece']
              pharmacyTblData[index]['hsn_code'] = newValue?.hsn_code ? newValue?.hsn_code : ''
              // pharmacyTblData[index]['quantity'] = ""
              pharmacyTblData[index]['total_cost'] = ""
              pharmacyTblData[index]['total_price'] = ""
              pharmacyTblData[index]['gst_percentage'] = newValue['gst_percentage']
              if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : newValue['sales_discount_for_bill'] }
              else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : newValue['sales_discount_for_bill'] }
              else { pharmacyTblData[index]['discount'] = newValue['sales_discount_for_bill'] }
              pharmacyTblData[index]['schedule'] = newValue['schedule']
            }
          }
          this.setState({ pharmacyTblData, QuantityCurrentIndex: this.state.isFromIpRequest ? null : index, BatchAnchor: null, CurrentBatchIndex: null }, () => {
            this.calculateCostAndTotal(pharmacyTblData[index]['quantity'], index, false)
            this.calculateMarginProfit()
            if (pharmacyTblData[index]['quantity'] === "") { this.QuantityRef?.current?.focus() }
          })
          break;
        default: break;
      }
      // }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onInputChangeAutoCompleteHandler = (value, names, index) => {
    try {
      let { pharmacyTblData } = this.state
      switch (names) {
        case 'drug_name':
          this.state.pharmacyTblData[index]['drug_name'] = value
          this.setState({ pharmacyTblData })
          break;
        case 'batch_no':
          this.state.pharmacyTblData[index]['batch_no'] = value
          this.setState({ pharmacyTblData })
          break;
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderAmountTextFields = (label, key, Icon) => {
    var states = this.state 
    let { t } = this.props;
    return (
      <TextField
        inputProps={{ emed_tid: label }}
        sx={{ padding: 0, margin: 0, width: "10vw" }}
        className='eMed_CrdPop_Amount_TextFields'
        size='small'
        disabled={this.state.forCancel || (this.state.isForReturn ? false : this.state.isFromInvoice)}
        label={t(label)}
        autoComplete='off'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={Icon} alt={label} className='eMed_action_img_small' />
            </InputAdornment>
          ),
        }}
        value={states[key]}
        onChange={(e) => {
          let number = CommonValidation.NumberWithDot(e.target.value);
          if ((number && +e.target.value > 0) || e.target.value === "") {
            states[key] = e.target.value
            this.setState({
              states
            }, () => {
              if (key === "CashAmount") {
                this.setState({
                  CashBalanceAmount: this.state.ReceivedCashAmount === "" ? "" : +e.target.value - +this.state.ReceivedCashAmount
                })
              }
            })
          }
        }}
      />
    )
  }

  renderNumberTextFields = (label, key, Right = false) => {
    var states = this.state
    let { t } = this.props;
    return (
      <TextField
        className='eMed_CrdPop_Amount_TextFields'
        sx={{ marginLeft: Right ? "1vw" : 0, width: "10vw" }}
        inputProps={{ maxLength: 20, emed_tid: label }}
        disabled={this.state.forCancel || key === "CashBalanceAmount" || (key === "ReceivedCashAmount" ? (this.state.CashAmount === "" ? true : false) : false) || (this.state.isForReturn ? false : this.state.isFromInvoice)}
        focused={key === "CashBalanceAmount"}
        size='small'
        label={t(label)}
        value={states[key]}
        autoComplete='off'
        onChange={(e) => {
          let number = CommonValidation.gstnumberValidation(e.target.value);
          let onlynumber = CommonValidation.numberOnly(e.target.value);
          if (key === "ReceivedCashAmount") {
            if (onlynumber || e.target.value === "") {
              this.setState({
                ReceivedCashAmount: e.target.value,
                CashBalanceAmount: e.target.value === "" ? "" : +this.state.CashAmount - +e.target.value
              })
            }
          }
          else {
            if (number || e.target.value === "") {
              states[key] = e.target.value.toUpperCase()
              this.setState({
                states
              })
            }
          }
        }
        }
      />
    )
  }

  handleCheckBox = (e, key) => {
    try {
      var states = this.state
      states[key] = e.target.checked
      // if (key == "isCredit" && (states?.PatientDetails?.primary_payment_type == "Insurance" || states?.PatientDetails?.primary_payment_type == "Insurance credit")) {
      //   this.setState({
      //     InsuCompanyName: states?.PatientDetails?.insurance_company_name,
      //     InsuPolicyNumber: states?.PatientDetails?.policy_number
      //   })
      // } else if (key == "isCredit" && (states?.PatientDetails?.primary_payment_type == "Corporate" || states?.PatientDetails?.primary_payment_type == "Corprate credit")) {
      //   this.setState({
      //     CorpCompanyName: states?.PatientDetails?.company_name,
      //     CorpIDNumber: states?.PatientDetails?.id_no
      //   })
      // }
      if(key === 'isCredit' && (!states?.PatientDetails?.is_billed && states.ConfigSetting?.credit_type_selection_for_billing)){
        this.setState({creditChange: true}, () => {
          this.getInsuranceDetails()
          this.getCorporateDetails()
      })
      }
      this.setState({ states }, () => {
        if (!states.isCash) { this.setState({ CashAmount: "", CashBalanceAmount: "", ReceivedCashAmount: "" }) }
        if (!states.isCard) { this.setState({ CardAmount: "", CardTransactionNumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
        if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
        if (!states.isCheque) { this.setState({ ChequeAmount: "", ChequeNumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
        if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
        // if (states.isCredit) {
        //   this.setState({
        //     totalDiscount: "",
        //     totalPercentage: ""
        //   }, () => { this.calculateMarginProfit() })
        // }
        this.SetFullCashAmount()
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  SetFullCashAmount = () => {
    let states = this.state
    let FetchingAccess = localGetItem("auto_fetch_bill_amount_to_payment")
    if (FetchingAccess === "true") {
      if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && (!states?.isFromInvoice || states?.isForReturn)) {
        if (states?.isForReturn || states?.isFromIpReturn) {
          this.setState({ CashAmount: ((+states.BillReceivedAmount === 0 || states.BillReceivedAmount != states.totalBillNetAmount) && states.subAmount && +states.subAmount > 0) ? 0 : this.state.ReturnedNetAmount })  // Fetch Full amount in Cash Amount, when only the cash is selected
        } else {
          this.setState({ CashAmount: this.state.netAmount })
        }
      }
      if (!states.isCash && states.isCard && !states.isUPI && !states.isBankTranfer && (!states?.isFromInvoice || states?.isForReturn)) {
        if (states?.isForReturn || states?.isFromIpReturn) {
          this.setState({ CardAmount: ((+states.BillReceivedAmount === 0 || states.BillReceivedAmount != states.totalBillNetAmount) && states.subAmount && +states.subAmount > 0) ? 0 : this.state.ReturnedNetAmount })
        } else {
          this.setState({ CardAmount: this.state.netAmount })
        }
      }
      if (!states.isCash && !states.isCard && states.isUPI && !states.isBankTranfer && (!states?.isFromInvoice || states?.isForReturn)) {
        if (states?.isForReturn || states?.isFromIpReturn) {
          this.setState({ UPIAmount: ((+states.BillReceivedAmount === 0 || states.BillReceivedAmount != states.totalBillNetAmount) && states.subAmount && +states.subAmount > 0) ? 0 : this.state.ReturnedNetAmount })
        } else {
          this.setState({ UPIAmount: this.state.netAmount })
        }
      }
      if (!states.isCash && !states.isCard && !states.isUPI && states.isBankTranfer && (!states?.isFromInvoice || states?.isForReturn)) {
        if (states?.isForReturn || states?.isFromIpReturn) {
          this.setState({ BankTfrAmount: ((+states.BillReceivedAmount === 0 || states.BillReceivedAmount != states.totalBillNetAmount) && states.subAmount && +states.subAmount > 0) ? 0 : this.state.ReturnedNetAmount })
        } else {
          this.setState({ BankTfrAmount: this.state.netAmount })
        }
      }
    }
    
    if (!states?.subAmount) {
      this.setState({
        isCard: false,
        isCash: false,
        isUPI: false,
        isBankTranfer: false,
        isCheque: false,
        isInsurance: false,
        CashAmount: "",
        CardAmount: "",
        CardTransactionNumber: "",
        UPINumber: "",
        UPIAmount: "",
        BankTfrAmount: "",
        BankTfrNumber: "",
        ChequeNumber: "",
        ChequeAmount: "",
        InsuranceAmount: "",
        InsuCompanyName: "",
        InsuPolicyNumber: "",
        InsuTransactionNumber: "",
        InsuValidity: '',
      })
    }
  }
  calculateMarginProfit = () => {
    var { pharmacyTblData } = this.state
    // calculate margin cumulative sum
    for (let i = 0; i < pharmacyTblData.length; i++) {
      let total_buying_price_drug = 0
      if (pharmacyTblData[i].batch_no != "") {
        let profit = parseFloat(pharmacyTblData[i]["cost_per_quantity"]) - parseFloat(pharmacyTblData[i]["buying_price_per_piece"]).toFixed(2)
        pharmacyTblData[i]["margin_profit"] = (profit * (+(pharmacyTblData[i]["quantity"])))

        total_buying_price_drug = (parseFloat(pharmacyTblData[i]["buying_price_per_piece"] * (+(pharmacyTblData[i]["quantity"]))))
        pharmacyTblData[i]["total_buying_price_per_drug"] = total_buying_price_drug

        this.setState({
          pharmacyTblData
        }, () => {
          // calculate total margin profit
          const margin_profit = this.state.pharmacyTblData && this.state.pharmacyTblData.length > 0 && this.state.pharmacyTblData.map((item) => (item.batch_no === "" ? 0 : +(item.margin_profit))).reduce((prev, curr) => (prev + curr));
          // calculate total buying price
          const total_buying_price = this.state.pharmacyTblData && this.state.pharmacyTblData.length > 0 && this.state.pharmacyTblData.map(item => (item.batch_no === "" ? 0 : +(item.total_buying_price_per_drug))).reduce((prev, curr) => (prev + curr));

          this.setState({
            totalMarginProfit: margin_profit,
            totalBuyingPrice: total_buying_price
          })
        })
      }
    }

  }

  billingCalculation = (params = [], type) => {
    try {
      let amount = 0; let payableAmount = 0; let OutStandingValue = 0;
      switch (type) {
        case 'totalServiceAmount':
          if (this.state.isForReturn || this.state.isFromIpReturn) {
            params.forEach(element => {
              amount = amount + (element.total_return_price ? element.total_return_price : 0)
            })
            if (this.state.subAmount !== +amount) {
              this.setState({
                subAmount: +amount
              }, () => {
                this.calculateTotalDiscount()
              })
            }
          } else {
            params.forEach(element => {
              amount = amount + (element.total_price ? element.total_price : 0)
            })
            if (this.state.subAmount !== +amount) {
              this.setState({
                subAmount: +amount
              }, () => {
                this.calculateTotalDiscount()
              })
            }
          }
          return AmountFormat(this.state.subAmount || 0)
        case 'marginProfit':
          let finalMarginProfit = this.state.netAmount - this.state.totalBuyingPrice
          return AmountFormat(+this.state.netAmount === 0 ? 0 : (finalMarginProfit ? parseFloat(finalMarginProfit).toFixed(2) : 0))
        case 'roundOff':
          this.state.roundOff = CommonValidation.roundOff(this.state.subAmount, this.state.totalDiscount)
          return AmountFormat(+this.state.roundOff === 0 ? 0 : (this.state.roundOff > 0 ? `+ ${this.state.roundOff || 0}` : `- ${this.state.roundOff * -1}`));
        case 'payableAmount':
          if (this.state.isForReturn || this.state.isFromIpReturn) {
            let discount = this.state.totalDiscount ? this.state.totalDiscount : 0
            let netpayableAmount = this.state.subAmount - discount
            if (this.state.ReturnedNetAmount !== (this.state.subAmount ? Math.round(+netpayableAmount) : 0)) {
              this.setState({
                ReturnedNetAmount: this.state.subAmount ? Math.round(+netpayableAmount) : 0
              }, () => { this.SetFullCashAmount() })
            }
            return AmountFormat(this.state.ReturnedNetAmount || 0)
            // OutStandingValue = netpayableAmount ? (Math.round(+netpayableAmount) - this.state.OutStandingAmount) : 0
            // if (this.state.netAmount !== (Math.round(+OutStandingValue) > 0 ? Math.round(+OutStandingValue) : 0)) {
            //   this.setState({
            //     netAmount: Math.round(+OutStandingValue) > 0 ? Math.round(+OutStandingValue) : 0
            //   }, () => { this.SetFullCashAmount() })
            // }
            // return (this.state.netAmount && this.state.netAmount > 0) ? AmountFormat(this.state.netAmount) : 0
          } else {
            let discount = this.state.totalDiscount ? this.state.totalDiscount : 0
            payableAmount = this.state.subAmount - discount
            if (this.state.netAmount !== (Math.round(+payableAmount))) {
              this.setState({
                netAmount: Math.round(+payableAmount)
              }, () => { this.SetFullCashAmount() })
            }
            return AmountFormat(this.state.netAmount || 0)
          }
        case 'ReceivedAmount':
          return (this.state.BillReceivedAmount && this.state.BillReceivedAmount > 0) ? AmountFormat(this.state.BillReceivedAmount || 0) : 0
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onAddHandler = (names, index) => {
    try {
      if (this.state.pharmacyTblData[index]['drug_name']) {
        if (names === 'quantity' || names === 'discount') {
          if (index === this.state.pharmacyTblData?.length - 1) {
            let duplicateTbl = {
              drug_name: '',
              dosage_strength: '',
              dosage_type: '',
              batch_no: '',
              expiry_date: '',
              physical_box_no: '',
              cost_per_quantity: '',
              quantity: '',
              total_cost: '',
              discount: '',
              total_price: '',
              hsn_code: '',
              stock_in_quantity: '',
              gst_percentage: null,
              Drug_sugg_list: [],
              Batch_Sugg_list: [],
            }
            this.state.pharmacyTblData = [...this.state.pharmacyTblData, duplicateTbl]
            this.setState({
              pharmacyTblData: this.state.pharmacyTblData
            }, () => {
              this.setState({
                drugDetailsData: []
              }, () => {
                // this.DrugNameRef?.current.focus()
              })
            })
          }
        }
      } else {
        this.errorMessage('Enter All Fields')
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearTableData = () => {
    let TableHasValues = false
    this.state.pharmacyTblData.forEach((list) => {
      if (list.drug_name !== "") { TableHasValues = true }
    })
    if (TableHasValues) {
      this.setState({ ShowDiscard: true })
    } else {
      this.DiscardAction(true)
    }
  }

  PharmacyPatientSearch = (ShowErrMsg = true) => {
    try {
      let { t } = this.props;
      this.setState({ isPatientisSearching: true })
      let LoggedDetails = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
      let url = ''
      if (LoggedDetails?.pharmacy_type === "Standalone Pharmacy") {
        url = Serviceurls.PHARMA_HEADER_SEARCH
      } else {
        url = Serviceurls.FO_PATIENT_SEARCH
      }
      RestAPIService.getAll(url + `?search_key=${this.state.PatientMobileNumber}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SearchedPatientList: response.data.data,
              isPatientisSearching: false,
              common_uhid: response?.data?.common_uhid ? response?.data?.common_uhid : false,
            }, () => {
              if (this.state.PatientMobileNumber !== "") {
                if (response.data.data.length > 0) {
                  this.setState({ PatientSearchClicked: true })
                } else {
                  if (ShowErrMsg) { this.errorMessage(t("NoRecordsFound")) }
                }
              }
            })
          }
          else {
            this.setState({ isPatientisSearching: false })
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.setState({ isPatientisSearching: false })
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.setState({ isPatientisSearching: false })
      this.errorMessage(e.message)
    }
  }

  renderSearch(label, statekey, img, Data = []) {
    const { t } = this.props;
    let states = this.state;
    let PatientDetails = statekey === "ipopNumber" ? (states.PatientDetails?.ip_number ? states.PatientDetails?.ip_number : (states.PatientDetails?.op_number ? states.PatientDetails?.op_number : "-")) : "-"
    let nowdate = new Date();
    let room_ward_block = `${states.PatientDetails?.bed_number}/${states.PatientDetails?.room_number}/${states.PatientDetails?.ward_name}/${states.PatientDetails?.block_name}`
    return (
      <Box className="eMed_Ip_Est_ptCard" height={"3.5vw"} width={statekey === "PatientMobileNumber" ? "16vw" : statekey === "ipopNumber" ? "19vw" : "18vw"}>
        <Box component={'div'} className='eMed_pharma_header_icon_warp'>
          <img className='eMed_pharma_header_icon_img' src={img ? img : ImagePaths.Male.default} alt="male" />
        </Box>
        <Box component={'div'} >
          {statekey === "ipopNumber" ?
            <Box display={'flex'} flexDirection={'column'} backgroundColor={states.PatientDetails?.ip_number ? '#f2d0d2' : 'white'}>
              <Box width={"16vw"} height={'2vw'} display={'flex'} flexDirection={'row'} paddingTop={states.PatientDetails?.balance_ip_pharmacy_credit_limit === 0 ? '0.5vw' : '0vw'} paddingBottom={states.PatientDetails?.balance_ip_pharmacy_credit_limit === 0 ? '0.5vw' : '0vw'}>
                {states.PatientDetails?.balance_ip_pharmacy_credit_limit === 0 ?
                  <Typography fontSize={"0.7vw"} color={"#960320"} fontWeight={500} mr={'0.3vw'}>{states.PatientDetails?.ip_number ? "IP No -" : states.PatientDetails?.op_number ? "OP No - " : "IP / OP Number"}</Typography>
                  :
                  <Box>
                    <Box component={'div'} display={'flex'}>
                      <Typography fontSize={"0.7vw"} color={states.PatientDetails?.ip_number ? "#960320" : '#685c5c'} fontWeight={500} mr={'0.3vw'}>{states.PatientDetails?.ip_number ? "IP No -" : states.PatientDetails?.op_number ? "OP No - " : "IP / OP Number"}</Typography>
                      {PatientDetails?.length > 27 ?
                        <Tooltip placement='top' title={PatientDetails} arrow>
                          <Typography fontSize={"0.7vw"} fontWeight={'bold'} marginRight={'1vw'} color={states.PatientDetails?.ip_number ? "#960320" : 'black'}>{PatientDetails.slice(0, 27) + "..."}</Typography>
                        </Tooltip>
                        : <Typography fontSize={"0.7vw"} fontWeight={'bold'} marginRight={'1vw'} color={states.PatientDetails?.ip_number ? "#960320" : 'black'}>{PatientDetails}</Typography>}
                    </Box>
                    <Typography fontSize={"0.7vw"} color={states.PatientDetails?.ip_number ? "#960320" : '#685c5c'} fontWeight={500}>{states.PatientDetails?.ip_number ? "(Credit Limit :" : null}
                      <Typography component={'span'} fontSize={"0.7vw"} marginRight={'1vw'} color={states.PatientDetails?.ip_number ? "#960320" : '#685c5c'} fontWeight={600}>{
                        states.PatientDetails?.ip_number ?
                          String(states.PatientDetails?.balance_ip_pharmacy_credit_limit)?.length > 9 ?
                            <Tooltip placement='top' title={states.PatientDetails?.balance_ip_pharmacy_credit_limit}><span style={{ fontSize: '0.7vw' }}>{` ${CurrencySymbol}  ${String(states.PatientDetails?.balance_ip_pharmacy_credit_limit)?.slice(0, 7) + '...)'}`}</span></Tooltip> :
                            ` ${CurrencySymbol} ${states.PatientDetails?.balance_ip_pharmacy_credit_limit})`
                          : null}</Typography>
                    </Typography>
                  </Box>
                }
                {states.PatientDetails?.balance_ip_pharmacy_credit_limit === 0 ? (
                  PatientDetails?.length > 27 ? (
                    <Tooltip placement='top' title={PatientDetails} arrow>
                      <Typography fontSize={"0.7vw"} fontWeight={'bold'} color={states.PatientDetails?.ip_number ? "#960320" : 'black'}>
                        {PatientDetails.slice(0, 27) + "..."}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography fontSize={"0.7vw"} fontWeight={'bold'} color={states.PatientDetails?.ip_number ? "#960320" : 'black'}>
                      {PatientDetails}
                    </Typography>
                  )
                ) : null}
              </Box>
              {states.PatientDetails?.ip_number ?
                <Box width={"16vw"} display={'flex'} flexDirection={'row'}>
                  <Typography fontSize={"0.7vw"} color={"#960320"} fontWeight={500} mr={'0.3vw'}>{"Bed No - "}</Typography>
                  <Typography fontSize={"0.7vw"} fontWeight={'bold'} color={states.PatientDetails?.ip_number ? "#960320" : 'black'}>{
                    room_ward_block.length > 35 ? <Tooltip placement="top" title={room_ward_block} arrow><Typography fontSize={"0.7vw"} fontWeight={'bold'} >{room_ward_block.slice(0, 35) + "..."}</Typography></Tooltip> : room_ward_block ? room_ward_block : "-"}</Typography>
                </Box> : null}
            </Box>
            : statekey === "doctordetails" ?
              <Autocomplete
                disabled={this.state.isFromIpRequest || this.state.isFromIpReturn || this.state.isFromPrescription || this.state.isFromInvoice}
                size='small'
                clearIcon={false}
                options={Data}
                label={t(label)}
                getOptionLabel={(item) => (typeof (item.displayKey) === "string" ? item.displayKey : "")}
                value={states[statekey] ? states[statekey] : ""}
                ListboxProps={{ style: { maxHeight: 300 } }}
                onChange={(event, newValue) => {
                  states[statekey] = newValue;
                  this.setState({ states });
                }}
                sx={{ width: '15vw' }}
                renderOption={(props, option) => (
                  <li {...props} key={option?.id}>
                    {option?.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    disabled={this.state.isFromIpRequest || this.state.isFromIpReturn || this.state.isFromPrescription}
                    size='small'
                    {...params}
                    placeholder='Enter Doctor Name Here'
                    label={t(label)}
                    onChange={(e) => {
                      var newDoc = { displayKey: e.target.value, doctor_id: "", doctor_reg_no: '', name: e.target.value };
                      this.setState({
                        doctordetails: newDoc
                      });
                    }}
                    autoComplete='off'
                    variant='standard'
                    inputProps={{
                      ...params.inputProps,
                      'emed_tid': 'doctor name',
                    }}
                  />
                )}
              />
              :
              statekey === "invoiceDateTime" ?
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    disabled={true} //this.state.isFromInvoice ---> Previous Condition
                    emed_tid="Invoice DateTime"
                    open={states.DateTimerOpen}
                    onOpen={() => { this.setState({ DateTimerOpen: true }) }}
                    onClose={() => { this.setState({ DateTimerOpen: false }) }}
                    maxDateTime={new Date(Date.now())}
                    minDate={new Date(nowdate.getFullYear(), nowdate.getMonth(), nowdate.getDate() - 1)}
                    label={t(label)}
                    value={states[statekey]}
                    inputFormat='DD-MM-YYYY & hh:mmA'
                    onChange={(value) => {
                      if (value === null) {
                        states[statekey] = new Date()
                      } else {
                        states[statekey] = value
                      }
                      states["DateTimeChanged"] = true
                      this.setState({ states })
                    }}
                    renderInput={(params) => <TextField
                      size="small" {...params}
                      variant='standard'
                      required
                      autoComplete='off'
                      onKeyDown={(e) => e.preventDefault()}
                      onClick={() => { this.setState({ DateTimerOpen: true }) }}
                      sx={{ width: "15vw" }}
                    />}
                  />
                </LocalizationProvider>
                :
                <TextField
                  disabled={this.state.isFromInvoice || this.state.isFromSavedBill || this.state.isForInvoiceEdit || this.state.isFromIpRequest || this.state.isFromIpReturn || this.state.isFromPrescription}
                  sx={{ width: statekey === "PatientMobileNumber" ? "12vw" : "15vw" }}
                  inputProps={{ maxLength: statekey === "PatientMobileNumber" ? 10 : 32, emed_tid: statekey === "PatientMobileNumber" ? "Mobile Number" : "Patient Name" }}
                  label={`${label}`}
                  variant='standard'
                  size="small"
                  autoComplete="off"
                  name={statekey}
                  value={states[statekey] ? states[statekey] : ""}
                  placeholder={statekey === "PatientMobileNumber" ? "Mobile Number" : "Patient Name"}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && event.target.value?.length === 10) {
                      this.PharmacyPatientSearch()
                    }
                  }}
                  onChange={(e) => {
                    let value = e.target.value
                    let alpha = CommonValidation.alphabetOnly(value);
                    let number = CommonValidation.numberOnly(value);
                    if (statekey === "PatientMobileNumber") {
                      if (number || value === "") {
                        states[statekey] = value
                        if (states?.PatientDetails?.patient_id) {
                          states["PatientName"] = ""
                        }
                      }
                    } else if (statekey === "PatientName") {
                      if (alpha || value === "") {
                        states[statekey] = value
                        if (states?.PatientDetails?.patient_id) {
                          states["PatientMobileNumber"] = ""
                        }
                      }
                    } else {
                      if (alpha || value === "") {
                        states[statekey] = value
                      }
                    }
                    this.setState({ states, MobileNumberAnchor: e.currentTarget, PatientDetails: {}, Patient_Id: null, Bill_ID: null, isCredit: false }, () => {
                      if (statekey === "PatientMobileNumber" && states.PatientMobileNumber.length === 10) {
                        this.PharmacyPatientSearch(false)
                      } else {
                        this.setState({ SearchedPatientList: [], common_uhid: false })
                      }
                    })
                  }}
                  InputProps={{
                    endAdornment: statekey === "PatientMobileNumber" ?
                      <InputAdornment position="end">
                        {
                          this.state.isPatientisSearching ?
                            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                              <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                            </Box> :
                            <IconButton size="small"
                              disabled={states.PatientMobileNumber === "" || this.state.isFromInvoice || this.state.isFromSavedBill || this.state.isForInvoiceEdit || this.state.isFromIpRequest || this.state.isFromIpReturn || this.state.isFromPrescription}
                              onClick={() => {
                                if (states.PatientMobileNumber.length === 10) {
                                  this.PharmacyPatientSearch()
                                }
                              }}>
                              <SearchIcon />
                            </IconButton>}
                      </InputAdornment> : null
                  }}
                />}
        </Box>
        <Menu
          open={this.state.PatientSearchClicked}
          className='eMed_Pharma_Mobile_Search_Pop'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            this.setState({
              SearchedPatientList: [],
              PatientSearchClicked: false,
              common_uhid: false,
            })
          }}
          elevation={1}
        >
          {
            this.state.SearchedPatientList.length > 0 ?
              this.state.SearchedPatientList.map((list, index) => (
                <MenuItem emed_tid="Patient_mobile_suggestions"
                  autoFocus={index === 0}
                  onClick={() => {
                    this.setState({
                      SearchedPatientList: [],
                      PatientSearchClicked: false,
                      common_uhid: false,
                    }, () => { this.props.history.push({ pathname: "/PharmacyBilling", state: { Patient_ID: list?.patient_id, showHistory: true } }) })
                  }}
                  key={index}>
                  <Box><CommonPatientDetails data={list} isCommonUhid={this.state.common_uhid} /></Box>
                </MenuItem>
              )) : null
          }
        </Menu>
      </Box>
    )
  }

  deleteLineItems = (index = null) => {
    try {
      let BillingLineItems = []
      this.state.pharmacyTblData.forEach((item) => { if (item.drug_name !== "") { BillingLineItems.push(item) } })
      if (index === null || (index === 0 && BillingLineItems.length === 1)) {
        this.setState({
          pharmacyTblData: [
            {
              drug_name: '',
              dosage_strength: '',
              dosage_type: '',
              batch_no: '',
              expiry_date: '',
              physical_box_no: '',
              cost_per_quantity: '',
              quantity: '',
              total_cost: '',
              discount: '',
              total_price: '',
              hsn_code: '',
              stock_in_quantity: '',
              gst_percentage: null,
              Drug_sugg_list: [],
            }
          ],
          isCard: false,
          isCash: false,
          isUPI: false,
          isBankTranfer: false,
          isCheque: false,
          isInsurance: false,
          CashAmount: "",
          CardAmount: "",
          CardTransactionNumber: "",
          UPINumber: "",
          UPIAmount: "",
          BankTfrAmount: "",
          BankTfrNumber: "",
          ChequeNumber: "",
          ChequeAmount: "",
          InsuranceAmount: "",
          InsuCompanyName: "",
          InsuPolicyNumber: "",
          InsuTransactionNumber: "",
          InsuValidity: '',
          subAmount: 0,
          totalPercentage: '',
          totalDiscount: '',
          discountReason: '',
          isSavedReason: false,
          roundOff: 0,
          netAmount: 0,
          ReturnedNetAmount: 0,
          totalPercentage_w_r:''
        }, () => {
        })
      } else {
        if (index !== this.state.pharmacyTblData.length - 1) {
          this.state.pharmacyTblData.splice(index, 1)
          this.setState({ pharmacyTblData: this.state.pharmacyTblData, totalDiscount: "" }, () => {
            this.calculateTotalDiscount()
          })
        } else {
          this.state.pharmacyTblData[index] = {
            drug_name: '',
            dosage_strength: '',
            dosage_type: '',
            batch_no: '',
            expiry_date: '',
            physical_box_no: '',
            cost_per_quantity: '',
            quantity: '',
            total_cost: '',
            discount: '',
            total_price: '',
            hsn_code: '',
            stock_in_quantity: '',
            Drug_sugg_list: [],
          }
          this.setState({ pharmacyTblData: this.state.pharmacyTblData, totalDiscount: "" }, () => {
            this.calculateTotalDiscount()
          })
        }
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  FullAmountEntered() {
    try {
      let states = this.state;
      let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
      let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
      let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
      let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
      let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
      let InsuranceAmt = states.InsuranceAmount === "" ? 0 : +states.InsuranceAmount;
      if ((CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt + InsuranceAmt) == +states.netAmount && +states.netAmount !== 0 && ((!states.isCash || CashAmt) && (!states.isCard || CardAmt) && (!states.isUPI || UPIAmt) && (!states.isBankTranfer || bankTransferAmt) && (!states.isCheque || ChequeAmt))) {
        // if (
        //   (CardAmt === 0 || states.CardTransactionNumber !== "") &&
        //   (UPIAmt === 0 || states.UPINumber !== "") &&
        //   (bankTransferAmt === 0 || states.BankTfrNumber !== "") &&
        //   (ChequeAmt === 0 || states.ChequeNumber !== "") &&
        //   (InsuranceAmt === 0 || states.InsuCompanyName !== "" || states.InsuPolicyNumber !== "")
        // ) {
        if (!states.isFromInvoice) {
          return true
        } else if (states.isForReturn) {
          return true
        } else {
          return false
        }

        // }
      } else if ((states?.isForReturn || states?.isFromIpReturn) && (+states.BillReceivedAmount === 0 || states.BillReceivedAmount != states.totalBillNetAmount) && states.subAmount && +states.subAmount > 0) {
        return true
      } else if (((states?.isForReturn || states?.isFromIpReturn) && ((CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt + InsuranceAmt) == states.ReturnedNetAmount) || states.addToAdvance) && states.subAmount && +states.subAmount > 0) {
        return true
      }
      else {
        return false
      }
    }
    catch (e) {
      return false
    }
  }

  BillItemsIsValid = () => {
    try {
      let Flag = false
      let BillingLineItems = []
      this.state.pharmacyTblData.forEach((item) => { if (item.drug_name !== "" && (this.state.isFromIpRequest ? item?.stock_in_quantity !== 0 : true)) { BillingLineItems.push(item) } })
      if (BillingLineItems.length > 0) {
        for (let i = 0; i < BillingLineItems.length; i++) {
          if ((BillingLineItems[i]["drug_name"] !== "" && BillingLineItems[i]["batch_no"] !== "" && BillingLineItems[i]["quantity"] !== "" && BillingLineItems[i]["quantity"] !== "0") && BillingLineItems.length > 0) {
            if (this.state.isForReturn || this.state.isFromIpReturn) {
              if ((BillingLineItems[i]["return_quantity"] <= BillingLineItems[i]["quantity"]) && BillingLineItems[i]["return_quantity"] !== "" && BillingLineItems[i]["quantity"] !== "" && BillingLineItems[i]["quantity"] !== "0") {
                Flag = true
              } else {
                if ((BillingLineItems[i]["return_quantity"] > BillingLineItems[i]["quantity"])) {
                  Flag = false
                  this.errorMessage(`${BillingLineItems[i]["drug_name"]} Return Quantity is More then Actual Quantity`)
                  this.setState({disableBtn: false})
                  break
                }
              }
            } else {
              if ((BillingLineItems[i]["quantity"] <= BillingLineItems[i]["stock_in_quantity"] || !BillingLineItems[i]["stock_in_quantity"]) && BillingLineItems[i]["quantity"] !== "") {
                Flag = true
              } else {
                if (BillingLineItems[i]["quantity"] > BillingLineItems[i]["stock_in_quantity"]) {
                  Flag = false
                  this.errorMessage(`${BillingLineItems[i]["drug_name"]} Quantity is More then Stock Quantity`)
                  this.setState({disableBtn: false})
                  break
                }
              }
            }
          } else {
            if (BillingLineItems[i]["drug_name"] === "") {
               this.errorMessage("No drug were Entered") 
               this.setState({disableBtn: false})
               }
            else if (BillingLineItems[i]["batch_no"] === "") {
               this.errorMessage(`${BillingLineItems[i]["drug_name"]} "Batch were Not Selected`) 
               this.setState({disableBtn: false})
              }
            else if (BillingLineItems[i]["quantity"] === "" || BillingLineItems[i]["quantity"] === "0") {
               this.errorMessage(`${BillingLineItems[i]["drug_name"]} Quantity is Not Entered`) 
               this.setState({disableBtn: false})
              }
            else {
               this.errorMessage("Invalid Bill Items")
               this.setState({disableBtn: false})
             }
            Flag = false
            break
          }
        }
        return Flag
      } else {
        Flag = false
        this.errorMessage("No Drug were Added")
        this.setState({disableBtn: false})
        return Flag
      }

    } catch (e) {
      this.errorMessage(e.message)
      this.setState({disableBtn: false})
    }
  }

  Validation = () => {
    try {
      let states = this.state;
      let isHaveScheduledDrug = false
      let hasHSNError = false
      for (let i = 0; i < this.state.pharmacyTblData.length; i++) {
        if (this.state.pharmacyTblData[i]?.drug_name !== "") {
          if (this.state.ConfigSetting?.hsncode_mandatory_for_billing && (!this.state.pharmacyTblData[i]?.hsn_code || this.state.pharmacyTblData[i]?.hsn_code === "")) {
            this.errorMessage(`HSN Code not available for drug: ${this.state.pharmacyTblData[i]?.drug_name}`);
            this.setState({ disableBtn: false, isLoader: false });
            hasHSNError = true;
          }
          if (this.isScheduledDrug(i)) {
            isHaveScheduledDrug = true;
          }
        }
        if (hasHSNError) {
          return; 
        }
      }
      let DoctorValidation = isHaveScheduledDrug ? ((states.doctordetails?.name || states.doctordetails?.doctor_id) ? true : false) : true
      if (states.PatientName !== "" && ((DoctorValidation || !isHaveScheduledDrug) || this.state.isForReturn || this.state.isFromIpReturn) && this.BillItemsIsValid() && (this.state.totalDiscount === '' || (this.state.totalDiscount !== '' && this.state.discountReason !== ""))) {
        if ((this.state.isForReturn || this.state.isFromIpReturn) && !states.addToAdvance && states.BillReceivedAmount != 0 && states.BillReceivedAmount != states.totalBillNetAmount) {
          this.setState({
            isReturnClicked: true,
            isLoader: false,
            disableBtn : false
          })
        } else {
          this.postPharmacyBillData()
        }
      } else {
        this.LoaderFunction(false)
        if (states.PatientName === "") {
           this.errorMessage("Enter Patient Name") 
           this.setState({disableBtn: false})
          }
        else if (!DoctorValidation && !this.state.isForReturn && !this.state.isFromIpReturn) {
           this.errorMessage("Bill Contains a Scheduled drug, so please select the doctor")
           this.setState({disableBtn: false}) 
          }
        else if (states.discountReason === "" && this.state.totalDiscount) {
           this.errorMessage("Reason is mandatory.Click Note icon to enter reason") 
           this.setState({disableBtn: false})
          }
      }
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
      this.setState({disableBtn: false})
    }
  }

  PrintInvoice() {
    try {
      RestAPIService.PrintPDF(Serviceurls.PHARMA_BILLS_GET + "?id=" + this.state.Bill_ID + "&export=pdf").
        then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          }
        }).catch((error) => {
          console.log(error.message)
        })
    } catch (e) {
      console.log(e.message)
    }
  }
  getInsuranceDetails = () => {
    try {
        RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST)
            .then((response) => {
                if (response.data.status === 'success') {
                    this.setState({
                        insuranceData: response.data.data,
                    })
                }
            }).catch((error) => {
                if (error?.response?.data?.status) {
                    this.errorMessage(error.response.data.message, 'error')
                } else {
                    this.errorMessage(error.message, 'error')
                }
            })
    } catch (error) {
        this.errorMessage(error.message, 'error')
    }
  }
  getCorporateDetails = () => {
      try {
          RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE)
              .then((response) => {
                  if (response.data.status === 'success') {
                      this.setState({
                          corporateData: response.data.data,
                      })
                  }
              }).catch((error) => {
                  if (error?.response?.data?.status) {
                      this.errorMessage(error.response.data.message, 'error')
                  } else {
                      this.errorMessage(error.message, 'error')
                  }
              })
      } catch (error) {
          this.errorMessage(error.message, 'error')
      }
  }
  renderCreditType = () => {
    let { patientData } = this.state
    let details = this.state.PatientDetails 
    let ins_name = details?.insurance_name ? details?.insurance_name : details?.insurance_company_name ? details?.insurance_company_name : this.state.InsName 
    let ins_no = details?.policy_no ? details?.policy_no : details?.policy_number ? details?.policy_number : this.state.InsNO
    let comp_name = details?.company_name ? details?.company_name : this.state.CropName
    let employee_no = details?.employee_id ? details?.employee_id : details?.id_no ? details?.id_no : this.state.CropNo
    let paymentCreditType = this.state.paymentCreditType == 'Insurance credit' ? 'Insurance credit' : this.state.paymentCreditType == 'Corprate credit' ? 'Corprate credit' : 'Patient credit'
    return (
        <Box>
            <Box component={'div'} display={'flex'} flexDirection={'row'} width={'40vw'} justifyContent={'space-between'}>
                <Typography fontSize={'1vw'} fontWeight={'bold'}>Select the Credit Type</Typography>
                <IconButton size='small' onClick={() => {
                    this.setState({
                        creditChange: false,
                        addInsurance: false,
                        addCorporate: false,
                        paymentCreditType: this.state?.selPayemtnCreditType ? this.state?.selPayemtnCreditType : this.state.PatientDetails?.primary_payment_type ,
                        insuranceName: null,
                        insuranceNo: '',
                        corpName: null,
                        employeeNo: '',
                        InsName: ins_name ? ins_name : this.state.PatientDetails?.insurance_company_name ? this.state.PatientDetails?.insurance_company_name : '',
                        InsNO: ins_no ? ins_no : this.state.PatientDetails?.policy_no ? this.state.PatientDetails?.policy_no : '',
                        CropName: comp_name ? comp_name : this.state?.PatientDetails?.company_name ? this.state?.PatientDetails?.company_name : '',
                        CropNo: employee_no ? employee_no : this.state?.PatientDetails?.id_no ? this.state?.PatientDetails?.id_no : '',
                        CompanyID: this.state.CompanyID ? this.state.CompanyID : this.state?.PatientDetails?.employer_id ? this.state?.PatientDetails?.employer_id : ''
                    })
                }}>
                    <img src={ImagePaths.Close.default} alt="close" style={{ width: '1vw', height: '1vw' }} />
                </IconButton>
            </Box>
            <Box component={'div'} sx={{ height: '5vw', marginTop: '2vw', display: 'flex' }}>
                <Box component={'div'} sx={{ width: '13vw', height: '5vw', borderRight: '1px solid lightgray', marginLeft: '1vw' }}>
                    <Typography>Patient Credit</Typography>
                    <FormControlLabel value={'Patient credit'} control={<Radio checked={paymentCreditType === 'Patient credit'} size='small' />}
                        onClick={(e) => {
                            this.setState({
                                paymentCreditType: e.target.value,
                                addInsurance: false,
                                addCorporate: false,
                                corpName: null,
                                employeeNo: '',
                                insuranceName: null,
                                insuranceNo: ''
                            })
                        }} label="Patient Credit" />
                </Box>
                { this.state.PatientDetails?.ip_admission_id ?
                <Box component={'div'} sx={{ width: '15vw', height: '5vw', borderRight: '1px solid lightgray', marginLeft: '1vw' }}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography>Insurace Credit</Typography>
                        {ins_name ? <Tooltip title={'edit'} placement='top'>
                            <IconButton size='small' onClick={() => {
                                this.setState({
                                    addInsurance: true,
                                    addCorporate: false,
                                    paymentCreditType: 'Insurance credit',
                                    insuranceName: ins_name,
                                    insuranceNo: ins_no,
                                    corpName: null,
                                    employeeNo: ''
                                })
                            }}>
                                <img style={{ width: '.9vw', height: '.9vw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                            </IconButton>
                        </Tooltip> : null}
                    </Box>
                    {ins_name ?
                        <FormControlLabel value={'Insurance credit'} control={<Radio checked={paymentCreditType === 'Insurance credit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addCorporate: false,InsName: ins_name, InsNo: ins_no}) }}
                            label={<Box>
                                {ins_name?.length > 18 ?
                                    <Tooltip title={ins_name} placement='top'><Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{ins_name.slice(0, 18) + '...'}</Typography></Tooltip>
                                    : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{ins_name}</Typography>}
                                {ins_no?.length > 18 ?
                                    <Tooltip title={ins_no} placement='top'><Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{ins_no.slice(0, 18) + '...'}</Typography></Tooltip>
                                    : <Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{ins_no}</Typography>}
                            </Box>} /> :
                        <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                            onClick={() => {
                                this.setState({
                                    addInsurance: true,
                                    addCorporate: false,
                                    paymentCreditType: 'Insurance credit',
                                    insuranceName: null,
                                    insuranceNo: ''
                                })
                            }}
                        >Add Insurance</Button>}
                </Box>
                : null }
                <Box component={'div'} sx={{ width: '15vw', height: '5vw', marginLeft: '1vw' }}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography>Corporate Credit</Typography>
                        {comp_name ? <Tooltip title={'edit'} placement='top'>
                            <IconButton size='small' onClick={() => {
                                this.setState({
                                    addCorporate: true,
                                    addInsurance: false,
                                    paymentCreditType: 'Corprate credit',
                                    corpName: comp_name,
                                    employeeNo: employee_no,
                                    insuranceName: null,
                                    insuranceNo: '',

                                })
                            }}>
                                <img style={{ width: '.9vw', height: '.9vw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                            </IconButton>
                        </Tooltip> : null}
                    </Box>
                    {comp_name ?
                        <FormControlLabel value={'Corprate credit'} control={<Radio checked={paymentCreditType === 'Corprate credit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addInsurance: false, CropName: comp_name,CropNo: employee_no}) }}
                            label={<Box>
                                {comp_name?.length > 18 ?
                                    <Tooltip title={comp_name} placement='top'><Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{comp_name.slice(0, 18) + '...'}</Typography></Tooltip>
                                    : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{comp_name}</Typography>}
                                {employee_no?.length > 18 ?
                                    <Tooltip title={employee_no} placement='top'><Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{employee_no.slice(0, 18) + '...'}</Typography></Tooltip>
                                    : <Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{employee_no}</Typography>}
                            </Box>} /> :
                        <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                            onClick={() => {
                                this.setState({
                                    addCorporate: true,
                                    addInsurance: false,
                                    paymentCreditType: 'Corprate credit',
                                })
                            }}
                        >
                            Add Corporate</Button>}
                </Box>
            </Box>
            {this.state.addInsurance ?
                <Box marginTop={'1vw'} display={'flex'} height={'5vw'}>
                    <Autocomplete
                        size='small'
                        id="combo-box-demo-ins"
                        options={this.state.insuranceData}
                        getOptionLabel={(options) => typeof (options) === 'string' ? options : options.insurance_name}
                        onChange={(e, value) => {
                            this.setState({
                                insuranceName: value,
                            })
                        }}
                        value={this.state.insuranceName}
                        sx={{ width: '13vw', marginRight: '1vw' }}
                        renderInput={(params) => <TextField {...params} label="Insurance Company Name" />}
                    />
                    <TextField
                        size='small'
                        label="Insurance No"
                        variant="outlined"
                        sx={{ width: '13vw' }}
                        value={this.state.insuranceNo}
                        onChange={(e) => {
                            let alphanum = CommonValidation.alphanumValidation(e.target.value)
                            if (e.target.value === "" || alphanum) {
                                this.setState({
                                    insuranceNo: e.target.value
                                })
                            }
                        }}
                    />
                </Box> :
                this.state.addCorporate ?
                    <Box marginTop={'1vw'} display={'flex'} height={'5vw'}>
                        <Autocomplete
                            size='small'
                            id="combo-box-demo-ins"
                            options={this.state.corporateData}
                            getOptionLabel={(options) => typeof (options) === 'string' ? options : options.company_name}
                            onChange={(e, value) => {
                                this.setState({
                                    corpName: value,
                                })
                            }}
                            value={this.state.corpName}
                            sx={{ width: '13vw', marginRight: '1vw' }}
                            renderInput={(params) => <TextField {...params} label="Corporate Company Name" />}
                        />
                        <TextField
                            size='small'
                            label="Employee ID"
                            variant="outlined"
                            sx={{ width: '13vw' }}
                            value={this.state.employeeNo}
                            onChange={(e) => {
                                let alphanum = CommonValidation.alphanumValidation(e.target.value)
                                if (e.target.value === "" || alphanum) {
                                    this.setState({
                                        employeeNo: e.target.value
                                    })
                                }
                            }} />
                    </Box> : <Box marginTop={'1vw'} display={'flex'} height={'5vw'}></Box>}
            <Box marginTop={'2vw'} display={'flex'} justifyContent={'flex-end'}>
                <Stack spacing={2} direction="row">
                    <Button size='small' sx={{ textTransform: 'capitalize' }} variant="outlined"
                        onClick={() => {
                            this.setState({
                                creditChange: false,
                                addInsurance: false,
                                addCorporate: false,
                                paymentCreditType: this.state?.selPayemtnCreditType ? this.state?.selPayemtnCreditType : this.state.PatientDetails?.primary_payment_type ,
                                insuranceName: null,
                                insuranceNo: '',
                                corpName: null,
                                employeeNo: '',
                                InsName: ins_name ? ins_name : this.state.PatientDetails?.insurance_company_name ? this.state.PatientDetails?.insurance_company_name : '',
                                InsNO: ins_no ? ins_no : this.state.PatientDetails?.policy_no ? this.state.PatientDetails?.policy_no : '',
                                CropName: comp_name ? comp_name : this.state?.PatientDetails?.company_name ? this.state?.PatientDetails?.company_name : '',
                                CropNo: employee_no ? employee_no : this.state?.PatientDetails?.id_no ? this.state?.PatientDetails?.id_no : '',
                                CompanyID: this.state.CompanyID ? this.state.CompanyID : this.state?.PatientDetails?.employer_id ? this.state?.PatientDetails?.employer_id : ''

                            })
                        }}>Back</Button>
                    <Button size='small' sx={{ textTransform: 'capitalize' }} variant="contained" onClick={() => {
                        if (this.state.addInsurance && (!this.state.insuranceName || !this.state.insuranceNo)) {
                            this.errorMessage("Invalid Insurance Details", 'error')
                        } else if (this.state.addCorporate && (!this.state.corpName || !this.state.employeeNo)) {
                            this.errorMessage("Invalid Corporate Details", 'error')
                        } else {
                            this.setState({
                                creditChange: false,
                                // InsName:this.state.insuranceName?.insurance_name,
                                // CropName: this.state.corpName?.company_name,
                                // InsNO:this.state.insuranceNo,
                                // CropNo:this.state.employeeNo,
                                paymentCreditType:this.state.paymentCreditType == 'Insurance credit' ? 'Insurance credit' : this.state.paymentCreditType == 'Corprate credit' ? 'Corprate credit' : 'Patient credit',      
                                selPayemtnCreditType: this.state.paymentCreditType,
                                InsName: this.state.insuranceName?.insurance_name ? this.state.insuranceName?.insurance_name : ins_name ? ins_name : this.state.PatientDetails?.insurance_company_name ? this.state.PatientDetails?.insurance_company_name : '',
                                InsNO: this.state.insuranceNo ? this.state.insuranceNo : ins_no ? ins_no : this.state.PatientDetails?.policy_no ? this.state.PatientDetails?.policy_no : '',
                                CropName: this.state.corpName?.company_name ? this.state.corpName?.company_name : comp_name ? comp_name : this.state?.PatientDetails?.company_name ? this.state?.PatientDetails?.company_name : '',
                                CropNo: this.state.employeeNo ? this.state.employeeNo : employee_no ? employee_no : this.state?.PatientDetails?.id_no ? this.state?.PatientDetails?.id_no : '',
                                CompanyID: this.state.corpName?.id ? this.state.corpName?.id : this.state.CompanyID ? this.state.CompanyID :  this.state?.PatientDetails?.employer_id ? this.state?.PatientDetails?.employer_id : ''
                            })
                        }
                    }}>Submit</Button>
                </Stack>
            </Box>
        </Box>
    )
}

  renderPaymentButton = () => {
    let states = this.state
    let enableIPaddAdvance = (states.BillReceivedAmount == states.totalBillNetAmount && states.isCurrentIP) ? true : false
    try {
      let LoggedDetails = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
      return (
        <Box component={'div'} display={'flex'} sx={{ backgroundColor: Colors.Background }}>
          <Box flex={0.7} display={'flex'} alignItems={'center'} marginLeft={'1vw'} >
            {(LoggedDetails?.pharmacy_type === "Inhouse Pharmacy" && !(!this.state.PatientDetails?.patient_id || this.state.isFromInvoice || this.state.isFromIpReturn || this.state.isForInvoiceEdit)) ?
              <FormGroup >
                <box display="row">
                <FormControlLabel disabled={this.state.netAmount === 0 ? true : !this.state.PatientDetails?.patient_id || this.state.isFromInvoice || this.state.isFromIpReturn || this.state.isForInvoiceEdit || this.state.isCash || this.state.isCard || this.state.isUPI || this.state.isBankTranfer || this.state.isInsurance || RoleData?.permission_access?.pharma?.is_credit === false || this.state.subAmount <= 0} className='' control={<Checkbox emed_tid={"add_credit_bill"} checked={this.state.isCredit} onChange={(e) => { this.handleCheckBox(e, "isCredit") }} />} label={'Add To Credit'} />
                <Tooltip title={'Change Credit Type'} placement='top'>
                        <IconButton size='small'
                            disabled={!this.state.PatientDetails?.patient_id || this.state.isFromInvoice || this.state.isFromIpReturn || this.state.isForInvoiceEdit || this.state.isCash || this.state.isCard || this.state.isUPI || this.state.isBankTranfer || this.state.isInsurance || RoleData?.permission_access?.pharma?.is_credit === false || this.state.subAmount <= 0 || this.state.isCredit == false} 
                            onClick={() => {
                                this.setState({
                                    creditChange: true,
                                }, () => {
                                    this.getInsuranceDetails()
                                    this.getCorporateDetails()
                                })
                            }}>
                            <img style={{ width: '1.2vw', height: '1.2vw' }} src={!this.state.isCredit ? ImagePaths.outlinedEditDis.default : ImagePaths.ChangeCredit.default} alt='icon' />
                        </IconButton>
                    </Tooltip>
                  </box>
              </FormGroup> : 
              (enableIPaddAdvance && states.isForReturn) ? 
              <FormGroup>
                <FormControlLabel disabled={!this.state.PatientDetails?.patient_id || this.state.isFromIpReturn || this.state.isForInvoiceEdit || this.state.isCash || this.state.isCard || this.state.isUPI || this.state.isBankTranfer || this.state.isInsurance || (!states.subAmount)} className='' control={<Checkbox emed_tid={"add_to_adv"} checked={this.state.addToAdvance} onChange={(e) => { this.handleCheckBox(e, "addToAdvance") }} />} label={'Add To IP Advance'} />
              </FormGroup> : null
            }
            {(states?.paymentCreditType == "Insurance" || states?.paymentCreditType == "Insurance credit"  ) ?
              <div style={{ marginLeft: "2.5vw" }}>
                {this.state.InsName?.length > 25 ?
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ marginRight: "0.5vw", fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>Insurance Patient : </div>
                    <Tooltip placement='top' title={this.state.InsName} arrow>
                      <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{this.state.InsName.slice(0, 25) + "..."}</div>
                    </Tooltip>
                    <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{` | ${this.state.InsNO}`}</div>
                  </div>
                  :
                  <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{`Insurance Patient : ${this.state.InsName}  |  ${this.state.InsNO}`}</div>}
              </div>
              : null}
            {(states?.paymentCreditType == "Corporate" || states?.paymentCreditType == "Corprate credit") ?
              <div style={{ marginLeft: "0.5vw" }}>
                {this.state.CropName?.length > 25 ?
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ marginRight: "0.5vw", fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>Corporate Patient : </div>
                    <Tooltip placement='top' title={this.state.CropName} arrow>
                      <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{this.state.CropName.slice(0, 25) + "..."}</div>
                    </Tooltip>
                    <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{` | ${this.state.CropNo}`}</div>
                  </div>
                  :
                  <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{`Corporate Patient : ${this.state.CropName}  |  ${this.state.CropNo}`}</div>}
              </div>
              : null}
            {/* {((states?.isForReturn || states?.isFromIpReturn) && +states.ReturnedNetAmount > 0 && +states.OutStandingAmount > 0) ?
              <Box component={'div'} pl={'1vw'} marginTop={'0.5vw'} overflow={'scroll'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <ErrorOutlineIcon sx={{ fontSize: "1.3vw", marginRight: '0.5vw', color: 'red' }} />
                <Typography className='eMed_ReturnCredit_Bill_Label'> fontSize={"0.9vw"}>{`${CurrencySymbol} ${states?.ReturnedNetAmount ? (states?.ReturnedNetAmount < states?.OutStandingAmount ? states?.ReturnedNetAmount : states?.OutStandingAmount) : 0} will be deducted from Outstanding Amount`}</Typography>
              </Box> : null}
            {
              (states.BillDetails?.billing_status === "Returned" && states.BillDetails?.net_amount > states.BillDetails?.received_amount) ?
                <Box component={'div'} pl={'1vw'} marginTop={'0.5vw'} overflow={'scroll'}>
                  <Typography fontSize={"0.9vw"}>{`${CurrencySymbol} ${states.BillDetails?.net_amount - states.BillDetails?.received_amount} were deducted from Outstanding Amount`}</Typography>
                </Box> : null
            } */}
          </Box>
          <Box flex={0.7} display={'flex'} justifyContent={'flex-end'}>
            {(this.state.ShowHistory && ((this.state.PrescHistoryData && this.state.PrescHistoryData?.length > 0) || (this.state.prescriptionList?.length > 0))) ?
              <Button emed_tid="bill_history_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize' }}
                size='small'
                variant='contained'
                onClick={() => { this.setState({ ShowPrescriptonHistory: true }) }}
              >History</Button> : null}
            <Button emed_tid="bill_clear_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize' }}
              disabled={this.state.isForReturn ? false : this.state.isFromInvoice}
              size='small'
              variant='outlined'
              onClick={() => this.onClearTableData()}
            >Clear</Button>

            <Button emed_tid="bill_saved_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize' }}
              size='small'
              variant='outlined'
              disabled={(this.state?.isLoader || this.state.disableBtn) ? true : (this.state.LoggedRole === "user" && this.state.isFromIpReturn && !this.state.perAccess?.pharma?.isReturnBill) ? true : (this.state.isCard || this.state.isCash || this.state.isUPI || this.state.isBankTranfer || this.state.isInsurance || this.state.isFromInvoice || this.state.isForInvoiceEdit || this.state.isCredit)}
              onClick={() => { this.setState({ disableBtn : true , savedBill: true }, () => this.Validation()) }}
            >Save as Draft</Button>
            {this.state.isFromInvoice ?
              <Button emed_tid="bill_print_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize' }}
                size='small'
                variant='outlined'
                onClick={() => { this.PrintInvoice() }}
              >Print Invoice</Button> : null}
            <Button emed_tid="bill_receive_payment_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize', width: "10vw" }}
              size='small'
              variant='contained'
              disabled={(this.state.isForReturn || this.state.isFromIpReturn) ? !this.FullAmountEntered() : (this.state.netAmount === 0 ? false : (this.state?.isLoader || this.state.disableBtn) ? true : (this.state.LoggedRole === "user" && this.state.isFromIpReturn && !this.state.perAccess?.pharma?.isReturnBill) ? true : (!this.state.isCredit && (this.state.isForReturn ? !this.FullAmountEntered() : (this.state.isFromInvoice ? true : !this.FullAmountEntered()))))}
              onClick={() => {
                this.LoaderFunction(true)
                this.setState({disableBtn : true},()=>{
                  if (this.state.isCredit) {
                    this.setState({ creditPayment: true }, () => this.Validation())
                  } else if (states.addToAdvance) {
                    this.Validation()
                  } else {
                    this.setState({ receivePayment: true }, () => this.Validation())
                  }
                })
              }}
            >{this.state.isCredit ? 'Add To Credit' : (this.state.isForReturn || this.state.isFromIpReturn) ? "Return" : this.state.isForInvoiceEdit ? "Update Invoice" : 'Receive Payment'}</Button>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }



  customPharmaFooter = (params) => {
    try {
      const { t } = this.props
      let states = this.state
      let disable = this.state.subAmount ? false : true
      return (
        <Box component={'div'} height={'14.5vw'} className='eMed_Phrama_Table_Container'>
          <Box component={'div'} display={'flex'}>
            <Box flex={0.75} overflow={'scroll'}>
              <Box component={'div'} border={'1px solid lightgray'} pl={'1vw'} marginTop={'0.5vw'} height={'10vw'} overflow={'scroll'} visibility={(states?.isForReturn || states?.isFromIpReturn) && (+states.BillReceivedAmount === 0 || states.BillReceivedAmount != states.totalBillNetAmount) && states.subAmount && +states.subAmount > 0 ? "hidden" : "visible"}>
                <Box component={'div'} style={{ marginTop: "0.5vw" }} className='eMed_Pharma_payment_card'>
                  <Typography fontWeight={600} fontSize={'0.9vw'}>Mode Of Payment</Typography>
                </Box>
                <Box component={'div'} className=''>
                  <Box>
                    <FormControlLabel disabled={(this.state.LoggedRole === "user" && this.state.isFromIpReturn && !this.state.perAccess?.pharma?.isReturnBill) ? true : (this.state.isCredit || this.state.subAmount <= 0 || states.addToAdvance) ? true : ((this.state.isForReturn) ? false : this.state.isFromInvoice)} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="cash_checkbox" checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' />} label={t("Cash")} />
                    {(this.state.isForReturn || this.state.isFromIpReturn) ? null :
                      <FormControlLabel disabled={(this.state.LoggedRole === "user" && this.state.isFromIpReturn && !this.state.perAccess?.pharma?.isReturnBill) ? true : (this.state.isCredit || this.state.subAmount <= 0 || states.addToAdvance) ? true : (this.state.isForReturn ? false : this.state.isFromInvoice)} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="card_checkbox" checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} size='small' />} label={t("Card")} />
                    }
                    <FormControlLabel disabled={(this.state.LoggedRole === "user" && this.state.isFromIpReturn && !this.state.perAccess?.pharma?.isReturnBill) ? true : (this.state.isCredit || this.state.subAmount <= 0 || states.addToAdvance) ? true : (this.state.isForReturn ? false : this.state.isFromInvoice)} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="upi_checkbox" checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={t("UPI")} />
                    <FormControlLabel disabled={(this.state.LoggedRole === "user" && this.state.isFromIpReturn && !this.state.perAccess?.pharma?.isReturnBill) ? true : (this.state.isCredit || this.state.subAmount <= 0 || states.addToAdvance) ? true : (this.state.isForReturn ? false : this.state.isFromInvoice)} className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10vw" }} control={<Checkbox emed_tid="bank_trans_checkbox" checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={t("BankTransfer")} />
                    {/* {(this.state.isForReturn || this.state.isFromIpReturn) ? null :
                      <FormControlLabel disabled={this.state.isCredit ? true : (this.state.isForReturn ? false : this.state.isFromInvoice)} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isInsurance} onChange={(e) => { this.handleCheckBox(e, "isInsurance") }} size='small' />} label={t("Insurance")} />
                    } */}
                  </Box>
                  <Box component={'div'} display={this.state.FromMultiReturn ? "none" : 'flex'} ml={'0.5vw'}>
                    {
                      this.state.isCash ?
                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                          {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                          {states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && !states.isInsurance ?
                            <>
                              {
                                this.state.openCashBalancebox ?
                                  <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"} alignItems={'center'}>
                                    {this.renderNumberTextFields("Cash Collected", "ReceivedCashAmount", true)}
                                    {this.renderNumberTextFields("Balance Amount", "CashBalanceAmount", true)}
                                    <Box onClick={() => { this.setState({ openCashBalancebox: false }) }} variant='outlined' sx={{ marginTop: "1vw", marginLeft: "0.5vw", cursor: 'pointer' }} size='small'>
                                      <img src={ImagePaths.ClearIcon.default} style={{ height: '1.5vw', width: "1.5vw" }} alt='close' />
                                    </Box>                                    </Box> :
                                  <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"} alignItems={'center'}>
                                    <IconButton onClick={() => { this.setState({ openCashBalancebox: true }) }}><CalculateIcon color='primary' /></IconButton>
                                  </Box>
                              }
                            </> : null
                          }
                        </Box> : null
                    }
                    {
                      this.state.isCard ?
                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                          {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                          {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                        </Box>
                        : null
                    }
                    {
                      this.state.isUPI ?
                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                          {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                          {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                        </Box>
                        : null
                    }
                    {
                      this.state.isBankTranfer ?
                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                          {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                          {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                        </Box>
                        : null
                    }
                    {
                      this.state.isCheque ?
                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                          {this.renderAmountTextFields("ChequeAmount", "ChequeAmount", ImagePaths.ChequeIcon.default)}
                          {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumber", true)}
                        </Box>
                        : null
                    }
                    {
                      this.state.isInsurance ?
                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                          {this.renderAmountTextFields("InsuranceAmount", "InsuranceAmount", ImagePaths.ChequeIcon.default)}
                          {this.renderNumberTextFields("Company Name *", "InsuCompanyName", true)}
                          {this.renderNumberTextFields("Policy Number *", "InsuPolicyNumber", true)}
                          {this.renderNumberTextFields("Transaction Number", "InsuTransactionNumber", true)}
                          {this.renderNumberTextFields("Validity", "InsuValidity", true)}
                        </Box>
                        : null
                    }
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box component={'div'} flex={0.25}>
              <Box component={'div'} className='eMed_bill_tbl_footer'>
                <Typography mt={0.5} fontSize={'0.9vw'}>{t("Sub Amount")}</Typography>
                <Typography emed_tid="total_sub_amount" mt={0.5} mr={'0.8vw'}>{this.billingCalculation(params, 'totalServiceAmount')}</Typography>
              </Box>
              {this.state.isForReturn || this.state.isFromIpReturn ?
                <Box component={'div'} className='eMed_bill_tbl_footer'>
                  <Typography mt={0.5} fontSize={'0.9vw'}>{t("Received Amount")}</Typography>
                  <Typography emed_tid="total_sub_amount" mt={0.5} mr={'0.8vw'}>{this.billingCalculation(params, 'ReceivedAmount')}</Typography>
                </Box> : null}
              <Box component={'div'} className='eMed_bill_tbl_footer'>
                <Typography mt={0.5} fontSize={'0.9vw'}>{`${t("Discount")} %`}</Typography>
                {this.renderTextBox('totalPercentage', (this.state.totalPercentage_w_r ? this.state.totalPercentage_w_r : ""), '2vh', disable)}
                {this.renderTextBox('totalDiscount', this.state.totalDiscount, '2vh', disable)}
                {(this.state.totalPercentage || this.state.totalDiscount) && !this.state.isForView && !this.state.isForReturn ?
                  <Tooltip placement='top' title={this.state.discountReason ? this.state.discountReason : ""}>
                    <Box onClick={() => this.setState({ discountReasonPopup: true, discountReason: this.state.discountReason ? this.state.discountReason : "" })} component={'img'} src={ImagePaths.LabReportOff.default} width={'1.5vw'} height={'1.5vw'} sx={{ cursor: 'pointer' }} alignSelf={'center'} />
                  </Tooltip> :
                  <Box component={'img'} src={ImagePaths.BillTransUser.default} width={'1vw'} height={'1vw'} alignSelf={'center'} />
                }
              </Box>
              <Box component={'div'} className='eMed_bill_tbl_footer'>
                <Typography mt={0.5} fontSize={'0.9vw'}>{t("RoundOff")}</Typography>
                <Typography emed_tid="total_roundoff_amount" mt={0.5} mr={'0.8vw'}>
                  {this.billingCalculation(params, 'roundOff')}
                </Typography>
              </Box>
              <Box component={'div'} className='eMed_bill_tbl_footer' borderTop={"2px solid gray"}>
                <Typography mt={0.5} fontSize={'0.9vw'} fontWeight='600'>{(this.state.isForReturn || this.state.isFromIpReturn || this.state.BillDetails?.billing_status === "Returned") ? t("Refundable Amount") : t("PayableAmount")}</Typography>
                <Typography emed_tid="total_payable_amount" mt={0.5} mr={'0.8vw'} fontWeight='600'>
                  {this.billingCalculation(params, 'payableAmount')}
                </Typography>
              </Box>
              <Box height={"2vw"} sx={{ display: (this.state.isForReturn || this.state.isFromIpReturn || ((this.state.LoggedRole === "user") ? (!RoleData?.permission_access?.pharma?.isViewMargin) : false)) ? "none" : "flex" }} component={'div'} className='eMed_bill_tbl_footer'>
                <Typography mt={0.5} fontSize={'0.9vw'} >{t("Margin Profit")}</Typography>
                <Typography emed_tid="total_margin_profit" mt={0.5} mr={'0.8vw'}>
                  {this.billingCalculation(params, 'marginProfit')}
                </Typography>
              </Box>
            </Box>
          </Box>
          {this.renderPaymentButton()}
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  discountReasonPopup = () => {
    try {
        return (
            <Modal open={this.state.discountReasonPopup}>
                <Box className='eMed_Discount_Reason'>
                    <Box component={'div'} height={'2vw'} display={'flex'} m={'1vw'} justifyContent={'space-between'}>
                        <Typography color={Colors.SecondaryText} fontWeight={600}>Concession Reason</Typography>
                    </Box>
                    <Box m={'1vw'}>
                        <TextField
                            fullWidth
                            multiline
                            label='Reason *'
                            rows={3}
                            value={this.state.discountReason}
                            onChange={(e) => {
                                this.setState({ discountReason: e.target.value })
                            }}
                        />
                    </Box>
                    <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            variant='outlined'
                            sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                            onClick={() => {
                                this.setState({ discountReasonPopup: false })
                                if(!this.state.isSavedReason){
                                  this.setState({discountReason: ''})
                                }
                            }}
                        >Close</Button>
                        <Button
                            variant='contained'
                            sx={{ width: '5vw', height: '2vw' }}
                            onClick={() => {
                                if (this.state.discountReason !== '') {
                                    this.setState({ discountReasonPopup: false, isSavedReason: true})
                                } else {
                                    this.errorMessage("Reason is mandatory", 'error')
                                }
                            }}
                        >Save</Button>
                    </Box>
                </Box>
            </Modal>
        )
    } catch (error) {
        this.errorMessage(error.message, 'error')
    }
  }

  isScheduledDrug(index) {
    try {
      if (
        this.state.pharmacyTblData[index]?.schedule &&
        this.state.pharmacyTblData[index]?.schedule !== null &&
        this.state.pharmacyTblData[index]?.schedule !== "" &&
        this.state.pharmacyTblData[index]?.drug_name &&
        this.state.pharmacyTblData[index]?.drug_name !== "" &&
        this.state.pharmacyTblData[index]?.batch_no &&
        this.state.pharmacyTblData[index]?.batch_no !== "") {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

  renderDrugColumn = (index, item) => {
    let frequent = "Frequently Brought Medicine"
    let { pharmacyTblData } = this.state
    return (
      <Box>
        <Tooltip title={pharmacyTblData[index]['drug_name']?.length > 18 ? (pharmacyTblData[index]['drug_name'] ? pharmacyTblData[index]['drug_name'] : "") : ""} placement="top" arrow>
          <TextField
            inputRef={this.DrugNameRef}
            disabled={this.state.isFromInvoice || this.state.isFromIpRequest || this.state.isFromIpReturn || ((this.state.isFromSavedBill && this.state.LoggedRole === "user") ? (!RoleData?.permission_access?.pharma?.isEditSave) : false)}
            size='small'
            variant='standard'
            autoComplete='off'
            value={item.drug_name}
            onChange={(e) => {
              if (e.target.value !== "") {
                pharmacyTblData[index]['drug_name'] = e.target.value
                pharmacyTblData[index]['dosage_strength'] = ""
                pharmacyTblData[index]['batch_no'] = ""
                pharmacyTblData[index]['dosage_type'] = ""
                pharmacyTblData[index]['physical_box_no'] = ""
                pharmacyTblData[index]['cost_per_quantity'] = ""
                pharmacyTblData[index]['total_cost'] = ""
                pharmacyTblData[index]['quantity'] = ""
                pharmacyTblData[index]['discount'] = ""
                pharmacyTblData[index]['total_price'] = ""
                pharmacyTblData[index]['hsn_code'] = ""
                pharmacyTblData[index]['stock_in_quantity'] = ""
                pharmacyTblData[index]['expiry_date'] = ""
                pharmacyTblData[index]['gst_percentage'] = null
                pharmacyTblData[index]['Drug_sugg_list'] = []
                delete pharmacyTblData[index]['Batch_Sugg_list']
                this.setState({ pharmacyTblData }, () => {
                  if (CommonValidation.removeSpace(pharmacyTblData[index]['drug_name'])?.length > 2) {
                    this.setState({
                      DurgNameCurrentIndex: index
                    }, () => { this.getBrandListData(index) })
                  }
                })
              } else if (e.target.value === "" && index !== (pharmacyTblData.length - 1)) {
                this.state.pharmacyTblData.splice(index, 1)
                this.setState({ pharmacyTblData: this.state.pharmacyTblData })
              } else {
                pharmacyTblData[index]['drug_name'] = e.target.value
                this.setState({ pharmacyTblData })
              }
            }}
            sx={{ width: '12.5vw', backgroundColor: (this.state.ConfigSetting?.hsncode_mandatory_for_billing && (item.batch_no && !item?.hsn_code)) ? '#f0bdbd' : 'white' }}
            className={this.isScheduledDrug(index) ? 'eMed_DrugBox_Scheduled' : null}
            onKeyDown={(e) => {
              this.setState({
                DrugNameAnchor: e.currentTarget,
                FocusDrugList: e.key === "ArrowDown" ? true : false
              }, () => {
                if (e.key === 'Enter' && pharmacyTblData[index]['drug_name']?.length > 2) {
                  this.setState({
                    DurgNameCurrentIndex: index
                  }, () => { this.getBrandListData(index) })
                }
              })
            }}
            inputProps={{ emed_tid: "bill_drug_name", style: { color: this.isScheduledDrug(index) ? 'rgb(150, 13, 11)' : null, paddingLeft: '0.4vw' } }}
            InputProps={{
              endAdornment: this.state.SearchingDrugIndex === index ? <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment> : null,
            }}
            error={(this.state.ConfigSetting?.hsncode_mandatory_for_billing && (item.batch_no && !item?.hsn_code))}
          />
        </Tooltip>


        <Menu
          open={this.state.DurgNameCurrentIndex === index && item.Drug_sugg_list && item.Drug_sugg_list?.length > 0 && item.drug_name !== ""}
          className="eMed_Drug_Sugg_Pop"
          anchorEl={this.state.DrugNameAnchor}
          onClose={() => {
            this.setState({ DurgNameCurrentIndex: null })
          }}
          autoFocus={false}
          disableAutoFocusItem
          disableAutoFocus
        >
          <Box width={"26vw"} >
            <Box sx={{ display: 'flex', backgroundColor: Colors.ThemeLightColor, paddingY: '0.3vw' }}>
              <Tooltip placement='top' title={frequent}><Typography width={"70%"} fontWeight={'600'} fontSize={"0.8vw"} paddingLeft={'1vw'}>{frequent}</Typography></Tooltip>
              <Typography width={"30%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>Quantity</Typography>
            </Box>
            <Box maxHeight={'14vw'} overflow={'scroll'}>
              {
                (item.Drug_sugg_list && item.Drug_sugg_list.length > 0) ?
                  item.Drug_sugg_list.map((list, Drugindex) => (
                    <MenuItem
                      onKeyDown={(e) => {
                        if (Drugindex === item.Drug_sugg_list.length - 1 && e.key === "ArrowDown") { e.stopPropagation() }
                        else if (Drugindex === 0 && e.key === "ArrowUp") {
                          e.stopPropagation()
                          this.setState({ DurgNameCurrentIndex: index })
                        }
                      }}
                      emed_tid="bill_drug_sugg_li"
                      key={Drugindex}
                      autoFocus={this.state.FocusDrugList && Drugindex === 0}
                      onClick={() => {
                        this.setState({
                          DurgNameCurrentIndex: null
                        }, () => {
                          this.onAddHandler("quantity", index)
                          this.autocompleteOnChangeHandler(list, "drug_name", index)
                        })
                      }}
                      sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box width={"70%"}>
                        <CommonCorporateAndInsuranceDetails data={list} fromPharmaBilling={true} />
                      </Box>
                      <Typography width={"30%"} fontSize={"0.8vw"} textAlign={"center"}>{list?.in_stock}</Typography>
                    </MenuItem>
                  )) : null
              }
            </Box>
          </Box>
        </Menu>
      </Box>
    )
  }
  renderBatchColumn = (index, item) => {
    return (
      <Box>
        <TextField
          inputRef={this.BatchNameRef}
          disabled={this.disabled('batch_no', index)}
          size='small'
          variant='standard'
          autoComplete='off'
          value={item.batch_no}
          onKeyDown={(e) => {
            e.preventDefault()
            // this.setState({ BatchAnchor: e.currentTarget })
          }}
          onFocus={(e) => {
            this.setState({
              BatchAnchor: e.currentTarget,
              CurrentBatchIndex: index
            }, () => {
              e.preventDefault()
              if ((!this.state.pharmacyTblData[index]['Batch_Sugg_list'])) {
                this.getDrugDetailsData(index, false)
              }
            })
          }}
          inputProps={{ emed_tid: "bill_batch_name", style: { paddingLeft: '0.4vw' } }}
          InputProps={{
            endAdornment: this.state.SearchingBatchIndex === index ? <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment> : <InputAdornment position="end"><ArrowDropDownIcon size={15} /></InputAdornment>,
          }}

        />
        <Menu
          open={this.state.CurrentBatchIndex === index}
          className="eMed_Drug_Sugg_Pop"
          anchorEl={this.state.BatchAnchor}
          onClose={() => {
            this.setState({ BatchAnchor: null, CurrentBatchIndex: null })
          }}
          autoFocus={true}
          disableAutoFocusItem
          disableAutoFocus
          disableRestoreFocus
        >
          <Box width={"30vw"} >
            <Box sx={{ display: 'flex', backgroundColor: Colors.ThemeLightColor, paddingY: '0.4vw' }} emed_tid={"bill_batch_no_items"}>
              <Typography width={"35%"} fontWeight={600} fontSize={'0.8vw'} paddingLeft={'1vw'}>Batch No</Typography>
              <Typography width={"20%"} fontWeight={600} fontSize={'0.8vw'} >Expiry Date</Typography>
              <Typography width={"25%"} fontWeight={600} fontSize={'0.8vw'}>Avl/Qty</Typography>
              <Typography width={"20%"} fontWeight={600} fontSize={'0.8vw'} >{`Cost/Qty  (${CurrencySymbol})`}</Typography>
            </Box>
            <Box maxHeight={'14vw'} overflow={'scroll'}>
              {
                (item.Batch_Sugg_list && item.Batch_Sugg_list.length > 0) ?
                  item.Batch_Sugg_list.map((list, Drugindex) => {
                    var datePart = list?.expiry_date ? list?.expiry_date.match(/\d+/g) : []
                    var ExpiryDate = datePart.length === 3 ? datePart[1] + '-' + datePart[0] : "-";
                    return (
                      <MenuItem
                        onKeyDown={(e) => {
                          if (Drugindex === item.Batch_Sugg_list.length - 1 && e.key === "ArrowDown") { e.stopPropagation() }
                          else if (Drugindex === 0 && e.key === "ArrowUp") {
                            e.stopPropagation()
                            this.setState({ DurgNameCurrentIndex: index })
                          }
                        }}
                        emed_tid={`batch_suggestion`}
                        key={Drugindex}
                        autoFocus={true}
                        onClick={() => { this.autocompleteOnChangeHandler(list, 'batch_no', index) }}
                        sx={[{ display: 'flex', flexDirection: 'row', backgroundColor: list.batch_expiry_alert ? '#EE9E4F' : 'white', '&:hover': list.batch_expiry_alert ? { backgroundColor: '#f0bc89' } : null }]}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
                          {list?.batch_no ? list?.batch_no?.length > 12 ?
                            <Tooltip placement='top' title={list?.batch_no} arrow>
                              <Typography width={"35%"} fontSize={'0.8vw'}>{list?.batch_no.slice(0, 10) + "..."}</Typography>
                            </Tooltip>
                            : <Typography width={"35%"} fontSize={'0.8vw'}>{list?.batch_no}</Typography> : "-"}
                          <Typography width={"20%"} fontSize={'0.8vw'}>{ExpiryDate}</Typography>
                          <Typography width={"25%"} fontSize={'0.8vw'}>{list?.stock_in_quantity}</Typography>
                          <Typography width={"20%"} fontSize={'0.8vw'} textAlign={'center'}>{AmountFormat(list?.cost_per_quantity || 0)?.replace(`${CurrencySymbol}`, "")}</Typography>
                        </Box>
                      </MenuItem>
                    )
                  }) : <Typography textAlign={"center"} paddingY={"0.5vw"} width={"100%"} fontSize={'0.8vw'}>{"No Batches Found"}</Typography>
              }
            </Box>
          </Box>
        </Menu>
      </Box>
    )
  }

  DiscardAction = (flag, MovetoInvoice = false, MoveToReturnTab = false) => {
    if (flag) {
      const EmptyBillItem = [
        {
          drug_name: '',
          dosage_strength: '',
          dosage_type: '',
          batch_no: '',
          expiry_date: '',
          physical_box_no: '',
          cost_per_quantity: '',
          quantity: '',
          total_cost: '',
          discount: '',
          total_price: '',
          hsn_code: '',
          stock_in_quantity: '',
          gst_percentage: null,
          Drug_sugg_list: [],
        }
      ]
      try {
        this.setState({
          pharmacyTblData: EmptyBillItem,
          drugDetailsData: [],
          isCard: false,
          isCash: false,
          isUPI: false,
          isBankTranfer: false,
          isCheque: false,
          isInsurance: false,
          ChequeAmount: '',
          CashAmount: '',
          CardAmount: '',
          BankTfrAmount: '',
          UPIAmount: '',
          CardTransactionNumber: '',
          ChequeNumber: '',
          BankTfrNumber: '',
          UPINumber: '',
          netAmount: 0,
          subAmount: 0,
          roundOff: 0,
          totalDiscount: '',
          totalPercentage: '',
          DurgNameCurrentIndex: null,
          PatientDetails: {},
          BillDetails: {},
          PatientSearchClicked: false,
          SearchedPatientList: [],
          doctordetails: {},
          PatientMobileNumber: "",
          PatientName: "",
          invoiceDateTime: new Date(),
          DateTimeChanged: false,
          ShowDiscard: false,
          Patient_Id: null,
          ReceivedCashAmount: "",
          CashBalanceAmount: "",
          isFromInvoice: false,
          isForReturn: false,
          isFromSavedBill: false,
          isForInvoiceEdit: false,
          isFromIpRequest: false,
          isFromIpReturn: false,
          isFromPrescription: false,
          ShowHistory: false,
          ShowPrescriptonHistory: false,
          CompletedBillID: null,
          Bill_ID: null,
          SearchingDrugIndex: null,
          SearchingBatchIndex: null,
          isBillsFetched: false,
          InvoiceNumber: null,
          isCredit: false,
          savedBill: false,
          PrescHistoryData: null,
          PrescriptionId: null,
          OutStandingAmount: 0,
          BillReceivedAmount: 0,
          ReturnedNetAmount: 0,
          IpRequestAdmId: null,
          MasterBillData: {},
          discountReason: "",
          isSavedReason: false,
          common_uhid: false,
          creditChange: false,
          addInsurance: false,
          addCorporate: false,
          paymentCreditType:'',
          corpName: null,
          employeeNo: '',
          insuranceName: null,
          insuranceNo: '',
          CropName: '',
          CropNo:'',
          InsName:'',
          InsNO:'',
          totalPercentage_w_r:''
        }, () => {
          if (MovetoInvoice) {
            this.props.history.push({ pathname: '/PharmacyInvoice/Invoice' })
          } else if (MoveToReturnTab) {
            this.props.history.push({ pathname: "/PharmacyBilling/Return", })
          } else {
            this.props.history.push({ pathname: "/PharmacyBilling", state: {} })
          }
        })
      } catch (error) {
        this.errorMessage(error.message)
      }
    } else {
      this.setState({ ShowDiscard: false })
    }
  }

  clearSinglePayment = (item) => {
    if (item === "2") {
      this.setState({
        CardTransactionNumber: "",
      })
    } else if (item === "4") {
      this.setState({
        UPINumber: "",
      })
    } else if (item === "5") {
      this.setState({
        BankTfrNumber: ""
      })
    } else if (item === "6") {
      this.setState({
        InsuCompanyName: "",
        InsuPolicyNumber: "",
        InsuTransactionNumber: "",
        InsuValidity: "",
      })
    }
    this.setState({
      secondAmount: ""
    })
  }

  ClosePopUp() {
    this.setState({ CompletedBillID: null }, () => {
      if (this.state.isFromSavedBill) { this.props?.getPharmaSavedBillCount(true) }
      if (this.state.isFromPrescription) { this.props?.getPharmaPrescriptionCount(true) }
      this.DiscardAction(true, this.state.isFromInvoice)
    })
  }

  HistoryClosePopUp(DrugList = []) {
    this.setState({ ShowPrescriptonHistory: false }, () => {
      if (DrugList.length > 0) {
        let BillingLineItems = []
        this.state.pharmacyTblData.forEach((item) => { if (item.drug_name !== "") { BillingLineItems.push(item) } })
        BillingLineItems.push({
          drug_name: '',
          dosage_strength: '',
          dosage_type: '',
          batch_no: '',
          expiry_date: '',
          physical_box_no: '',
          cost_per_quantity: '',
          quantity: '',
          total_cost: '',
          discount: '',
          total_price: '',
          hsn_code: '',
          stock_in_quantity: '',
          gst_percentage: null,
          Drug_sugg_list: [],
        })
        DrugList.forEach((item) => {
          item["dosage_strength"] = item?.drug_strength
          item["dosage_type"] = item?.dosagetype
          item["discount"] = 0
          item["quantity"] = ""
          item["is_from_history"] = true
        })
        this.setState({ pharmacyTblData: [...DrugList, ...BillingLineItems] })
      }
    })
  }

  ReturnPartialBill = (data) => {

    this.setState({
      isCash: data?.isCash,
      isUPI: data?.isUPI,
      isBankTranfer: data?.isBankTranfer,
      CashAmount: data?.CashAmount ? +data?.CashAmount : 0,
      UPIAmount: data?.UPIAmount ? +data?.UPIAmount : 0,
      BankTfrAmount: data?.BankTfrAmount ? +data?.BankTfrAmount : 0,
      BankTfrNumber: data?.BankTfrNumber ? data?.BankTfrNumber : "",
      UPINumber: data?.UPINumber ? data?.UPINumber : "",
      ReceiptAmt: data?.refundAmt ? data?.refundAmt : 0,
      AdvanceAmt: data?.AdvanceAmt ? data?.AdvanceAmt : 0,
      isReturnClicked: false,
      isLoader: true
    }, () => {
      this.postPharmacyBillData()
    })
  }

  ReasonPopupClose = () => {
    this.setState({
      isReturnClicked: false
    })
  }

  closeSavedSuccessPopup = () => {
    this.setState({
      SavedSuccessPopup: false,
      bill_summary_id_Print: null,
    })
  }

  printSalesBillPrint = () => {
    try {
      RestAPIService.getAllPrint(Serviceurls.PHARMA_SALES_BILL_PRINT + "?bill_summary_id=" + this.state.bill_summary_id_Print + "&export_type=pdf").
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          // this.closeSavedSuccessPopup()
        }).catch((error) => {
          // this.closeSavedSuccessPopup()
          this.errorMessage(error?.message)
        })
    } catch (error) {
      // this.closeSavedSuccessPopup()
      this.errorMessage(error?.message)
    }
  }

  printTokenPrint = () => {
    try {
      RestAPIService.getAllPrint(Serviceurls.PHARMA_BILL_TOKEN_PRINT + "?bill_summary_id=" + this.state.bill_summary_id_Print + "&export_type=pdf").
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          // this.closeSavedSuccessPopup()
        }).catch((error) => {
          // this.closeSavedSuccessPopup()
          this.errorMessage(error?.message)
        })
    } catch (error) {
      // this.closeSavedSuccessPopup()
      this.errorMessage(error?.message)
    }
  }

  render() {
    var { BillReceivedAmount, outstandingAmt, ReturnedNetAmount, totalBillNetAmount } = this.state
    let { t } = this.props
    let condition = 0
    let PendingAmt = outstandingAmt
    if (!PendingAmt && BillReceivedAmount && (BillReceivedAmount != totalBillNetAmount)) {
      PendingAmt = totalBillNetAmount - BillReceivedAmount
    }
    if (BillReceivedAmount == 0) {
      condition = 6
    } else if (BillReceivedAmount == totalBillNetAmount) {
      condition = 5
    } else if ((ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount < PendingAmt) || (ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount == PendingAmt)) {
      condition = 4
    } else if (ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount > PendingAmt) {
      condition = 3
    } else if ((ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount > PendingAmt) || (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount > PendingAmt)) {
      condition = 2
    } else if ((ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount < PendingAmt) || (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount == PendingAmt) || (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount < PendingAmt) || (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount == PendingAmt)) {
      condition = 1
    }
    let rtnData = localGetItem('pharmacy_rtn_period')
    let rtnDetails = JSON.parse(rtnData)
    return (
      <Box component={'div'} className='eMed_Pharma_Billing_container'>
        <Box component={'div'} className='eMed_Pharma_Header_Card'>
          {(this.state.isFromInvoice || this.state.isForInvoiceEdit) ?
            <Stack direction="row" spacing={1} alignItems="center"><IconButton size="small" onClick={() => {
              this.props.history.push({ pathname: '/PharmacyInvoice/Invoice' })
            }} ><img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"} /></IconButton><Typography sx={{ color: "#616161" }}> {t("Back To Invoice")}</Typography></Stack> :
            // <Typography color={'#616161'} display={'flex'} ml={'1vw'} alignItems={'center'} fontWeight={600}>Billing</Typography>
            <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <Tabs
                value={this.state.SelectedTabName}
                onChange={(e, newValue) => this.setState({
                  SelectedTabName: newValue,
                }, () => {
                  if (this.state.SelectedTabName === "mutiple_return_bill") {
                    this.DiscardAction(true, false, true)
                  } else {
                    this.DiscardAction(true, false, false)
                    this.props.history.push({ pathname: "/PharmacyBilling", })
                  }
                })}
                className='eMed_bill_Tab'
              >
                <Tab value="main_billing" label="Billing" className='eMed_tab_header eMed_bill_Tab' />
                {rtnDetails?.rtn_type === 'No Return' ? null :
                  <Tab value="mutiple_return_bill" label="Return Bill" className='eMed_tab_header eMed_bill_Tab' />
                }
              </Tabs>
              {this.state.SelectedTabName === "mutiple_return_bill" ? <Box>
                {/* <FormControlLabel control={<Checkbox size='small' checked={this.state.isCredit_or_isPaid} onChange={(e) => {
                  this.setState({ isCredit_or_isPaid: e.target.checked })
                }} />} label={t("Allow Only Paid or Credit Bill")} /> */}
                <Typography fontSize={'0.95vw'} fontWeight={600}>{"Either Credit bill or Complete bill"}</Typography>
              </Box> : null}
            </Box>
          }

          {
            (this.state.InvoiceNumber && this.state.SelectedTabName === "main_billing") ?
              <Box marginRight={"1vw"} width={'25vw'}>
                <Typography fontSize={'0.7vw'}>Invoice Number</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{this.state.InvoiceNumber ? this.state.InvoiceNumber : '-'}</Typography>
              </Box> : null
          }
        </Box>
        {
          this.state.SelectedTabName === "mutiple_return_bill" ? (CheckAccessFunc("Pharmacy", "Billing", "Return Bills", null, "Tab")?.viewAccess ? <ReturnMultipleBill MasterBillData={this.state.MasterBillData} history={this.props.history} Returntype={this.state.isCredit_or_isPaid} /> : <NoAccessScreen />) :
            <>
              <Box component={'div'} height={'5vw'} className='eMEd_pharma_Home_header'>
                {this.renderSearch((this.state.PatientDetails?.patient_tag && this.state.PatientDetails?.patient_tag !== "") ? `Patient Mobile - (PT : ${this.state.PatientDetails?.patient_tag})` : "Patient Mobile", "PatientMobileNumber", ImagePaths.MobileCardIcon.default)}
                {this.renderSearch((this.state.PatientDetails?.patient_account_number && this.state.PatientDetails?.patient_account_number !== "null") ? `Patient Name*   -   (UHID : ${this.state.PatientDetails?.patient_account_number})` : "Patient Name *", "PatientName", ImagePaths.AvatarCardIcon.default)}
                {this.renderSearch("Consultant Name | Reg No", "doctordetails", ImagePaths.Doctor_Icon.default, this.state.DoctorsList)}
                {this.renderSearch("IP / OP Number", "ipopNumber", ImagePaths.IPNumberCard.default, this.state.DoctorsList)}
                {this.renderSearch("Invoice Date & Time", "invoiceDateTime", ImagePaths.CalenderIcon.default,)}
              </Box>
              <Box component={'div'}>
                <Box component={'div'}>
                  <TableContainer className='eMed_Phrama_Table_Container'>
                    <Table stickyHeader size='small' sx={{ overflowY: 'scroll' }}>
                      <TableHead>
                        <TableRow sx={{ display: 'flex' }}>
                          {(this.state.isFromInvoice || this.state.isFromIpRequest || this.state.isFromIpReturn || ((this.state.isFromSavedBill && this.state.LoggedRole === "user") ? (!RoleData?.permission_access?.pharma?.isEditSave) : false)) ? null :
                            <TableCell sx={{ flex: 0.01 }} className='eMed_Pharma_tbl_header'>
                              <Button emed_tid={"bill_delete_all"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems()}>
                                <Box component={'img'} mt={'0.2vw'} src={ImagePaths.Delete.default} alt='del' height={'1.3vw'} width={'1.3vw'} />
                              </Button>
                            </TableCell>}
                          <TableCell sx={{ flex: 0.01 }} className='eMed_Pharma_tbl_header'>S.No</TableCell>
                          <TableCell sx={{ flex: 0.17 }} className='eMed_Pharma_tbl_header'>Brand Name</TableCell>
                          <TableCell sx={{ flex: this.state.isForReturn ? 0.068 : 0.078 }} align='center' className='eMed_Pharma_tbl_header'>Strength</TableCell>
                          <TableCell align='center' sx={{ flex: 0.083 }} className='eMed_Pharma_tbl_header'>Type</TableCell>
                          <TableCell sx={{ flex: 0.119 }} className='eMed_Pharma_tbl_header'>Batch-Avl Qty</TableCell>
                          <TableCell align='left' sx={{ flex: 0.08 }} className='eMed_Pharma_tbl_header'>{this.state.isForReturn ? 'Exp. Date' : 'Expiry Date'}</TableCell>
                          <TableCell sx={{ flex: 0.08 }} className='eMed_Pharma_tbl_header'>Box No.</TableCell>
                          <TableCell sx={{ flex: this.state.isForReturn ? 0.073 : 0.08 }} align='left' className='eMed_Pharma_tbl_header'>Cost/Qty</TableCell>
                          <TableCell align='left' sx={{ flex: this.state.isForReturn ? 0.07 : 0.08 }} className='eMed_Pharma_tbl_header'>
                            <Tooltip placement='top' title='Quantity'><div>Qty</div></Tooltip>
                          </TableCell>
                          {this.state.isForReturn ? <TableCell align='center' sx={{ flex: 0.11 }} className='eMed_Pharma_tbl_header'>
                            <Tooltip placement='top' title='Return Quantity'><div>Rtn. Qty</div></Tooltip>
                          </TableCell> : null}
                          <TableCell sx={{ flex: this.state.isForReturn ? 0.09 : 0.083 }} className='eMed_Pharma_tbl_header'>{`Total Cost`}</TableCell>
                          <TableCell sx={{ flex: 0.08 }} className='eMed_Pharma_tbl_header'>
                            <Tooltip placement='top' title='Discount'><div>{`Dis.%`}</div></Tooltip>
                          </TableCell>
                          <TableCell align='right' sx={{ flex: this.state.isForReturn ? 0.1 : 0.083 }} className='eMed_Pharma_tbl_header'>{`Total (${CurrencySymbol})`}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.pharmacyTblData.map((item, index) => {
                          var datePart = item?.expiry_date ? item?.expiry_date.match(/\d+/g) : []
                          var ExpiryDate = datePart.length === 3 ? datePart[1] + '-' + datePart[0] : "-";
                          return (
                            <Tooltip title={this.state.isFromIpRequest && item?.stock_in_quantity === 0 ? "No Stock Available" : ""} placement='top' arrow>
                              <TableRow sx={{ display: 'flex', backgroundColor: this.state.isFromIpRequest && item?.stock_in_quantity === 0 ? Colors.disableComponentColor : null }} key={index}>
                                {(this.state.isFromInvoice || this.state.isFromIpRequest || this.state.isFromIpReturn || ((this.state.isFromSavedBill && this.state.LoggedRole === "user") ? (!RoleData?.permission_access?.pharma?.isEditSave) : false)) ? null :
                                  <TableCell sx={{ flex: 0.01 }}>
                                    <Button emed_tid={"bill_delete_item"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems(index)}>
                                      <Box component={'img'} mt={'0.5vw'} src={ImagePaths.Delete.default} alt='del' height={'1.1vw'} width={'1.1vw'} />
                                    </Button>
                                  </TableCell>
                                }
                                <TableCell align='center' sx={{ flex: 0.01, paddingTop: '0.8vw' }}>{index + 1}</TableCell>
                                <TableCell sx={{ flex: 0.17 }}>{this.renderDrugColumn(index, item)}</TableCell>
                                <TableCell align='center' emed_tid="bill_dosage_strength" sx={{ flex: this.state.isForReturn ? 0.068 : 0.078, paddingTop: '0.8vw' }} className='eMed_Pharma_tbl_Body'>{item?.dosage_strength ? item?.dosage_strength : '-'}</TableCell>
                                <TableCell align='center' emed_tid="bill_dosage_type" sx={{ flex: 0.083, paddingTop: '0.8vw' }}>
                                  {item?.dosage_type ? item?.dosage_type?.length > 10 ?
                                    <Tooltip placement='top' title={item?.dosage_type} arrow>
                                      <div style={{ fontSize: "0.875rem" }}>{item?.dosage_type.slice(0, 10) + "..."}</div></Tooltip> :
                                    item?.dosage_type : "-"}
                                </TableCell>
                                <TableCell sx={{ flex: this.state.isForReturn ? 0.14 : 0.119 }}>
                                  {this.renderBatchColumn(index, item)}
                                </TableCell>
                                <TableCell align='center' emed_tid="bill_expiry_date" sx={{ flex: 0.08, paddingTop: '0.8vw' }}>{ExpiryDate}</TableCell>
                                <TableCell align='center' emed_tid="bill_physical_box_no" sx={{ flex: 0.08, paddingTop: '0.8vw' }}>{item?.physical_box_no ? item?.physical_box_no : '-'}</TableCell>
                                <TableCell emed_tid="bill_cost_per_quantity" align='center' sx={{ flex: 0.08, paddingTop: '0.8vw' }}>{AmountFormat(item?.cost_per_quantity || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                                <TableCell sx={{ flex: 0.08 }}>
                                  {this.inputTextBox(this.state.isFromIpReturn ? item?.return_quantity : item?.quantity, 'quantity', 'Quantity', '3vw', index, '2vh', item?.cost_per_quantity ? false : true)}
                                </TableCell>
                                {this.state.isForReturn ?
                                  <TableCell sx={{ flex: 0.083 }}>
                                    {this.inputTextBox(item?.return_quantity, 'return_quantity', 'Return Qty', '3vw', index)}
                                  </TableCell>
                                  : null}
                                <TableCell emed_tid="bill_total_cost" align='center' sx={{ flex: this.state.isForReturn ? 0.073 : 0.083, paddingTop: '0.8vw' }}>{AmountFormat(item?.total_cost|| 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                                <TableCell sx={{ flex: 0.08 }}>
                                  {this.inputTextBox(item?.discount, 'discount', 'Discount', '3vw', index)}
                                </TableCell>
                                <TableCell align='right' emed_tid="bill_total_price" sx={{ flex: this.state.isForReturn ? 0.12 : 0.083, paddingTop: '0.8vw' }}>
                                  {(this.state.isForReturn || this.state.isFromIpReturn) ?
                                   (item?.total_price ? 
                                   ` ${AmountFormat(item?.total_return_price|| 0)?.replace(`${CurrencySymbol}`, "")} / ${AmountFormat(item?.total_price|| 0)?.replace(`${CurrencySymbol}`, "")}` : '0.00') 
                                   : (AmountFormat(item?.total_price|| 0)?.replace(`${CurrencySymbol}`, ""))}
                                </TableCell>
                              </TableRow>
                            </Tooltip>)
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {this.customPharmaFooter(this.state.pharmacyTblData)}
                  {this.discountReasonPopup()}
                  {this.state.isErrorMsg ?
                    <ToastMsg
                      severity={'error'}
                      msg={this.state.isErrorMsgText}
                      msgPop={this.msgClose.bind(this)}
                    />
                    : null}  
                  {this.state.successMsg ?
                    <ToastMsg
                      severity={'success'}
                      msg={this.state.successMsgText}
                      msgPop={this.msgClose.bind(this)}
                      testId={this.state.invoiceNumber}
                    />
                    : null}
                  <Loader loaderOpen={this.state.isLoader} />
                  {this.state.ShowDiscard ? <PharmaDiscardPop SendAction={this.DiscardAction.bind(this)} /> : null}
                  {this.state.CompletedBillID !== null ? <BillSuccessPoPUp isDirectPrint={true} PatientID={this.state.Patient_Id} isCredit={this.state.isCredit} InvoiceId={this.state.CompletedBillID} isFromPharmacy={true} ClosePopUp={this.ClosePopUp.bind(this)} testID={"Ph_BillSuc"} CloseBtnText={(this.state.isForReturn || this.state.isFromIpReturn) ? "Back" : null} successTxt={this.state.isCredit ? "Credit Added Successfully" : this.state.isForReturn ? "Return Processed Sucessfully" : null} /> : null}
                  {(this.state.SavedSuccessPopup && RoleData?.show_pharmacy_sales_token) ?
                    <BillSuccessPoPUp  isDirectPrint={true} successTxt="Bill Saved Successfully" ClosePopUp={this.closeSavedSuccessPopup} fromPharmaSaved={true} printOneTxt="Sales Bill Print" printTwoTxt='Token Print' printOne={this.printSalesBillPrint} printTwo={this.printTokenPrint} error={this.errorMessage.bind(this)} />
                    : null}
                  {this.state.ShowPrescriptonHistory ? <PrescriptionHistoryPop PatientDetails={this.state.PatientDetails} HistoryClosePopUp={this.HistoryClosePopUp.bind(this)} BillingList={this.state.pharmacyTblData} PrescHistoryData={this.state.PrescHistoryData} prescriptionList={this.state.prescriptionList} /> : null}
                  {this.state.isReturnClicked ?
                    <PharmacyReturnPopup
                      title={"Return Bill"}
                      AlertPopupClose={this.ReasonPopupClose.bind(this)}
                      // label={"Enter the Cancel Reason"}
                      btntext={'Close'}
                      btnvarient={'outlined'}
                      btncolor={'primary'}
                      btntext1={'Confirm'}
                      btnvarient1={'contained'}
                      // sendCmt={this.addCancelComments.bind(this)}
                      returnAmt={this.state.ReturnedNetAmount}
                      paidAmt={this.state.BillReceivedAmount}
                      outstandingAmt={PendingAmt}
                      // BillReceivedAmount={this.state.BillReceivedAmount} 
                      PharmaReturnData={this.ReturnPartialBill}
                      isCurrentIP={this.state.isCurrentIP}
                      condition={condition}
                    /> : null}
                    {this.state.creditChange ?
                    <Dialog
                        className="emed_DialogboxOrder"
                        open={this.state.creditChange}
                        maxWidth={"md"}
                    >
                        <div className='emed_Dialog_Appoin' style={{ width: '42vw', height: "45vh" }}>
                            {this.renderCreditType()}
                        </div>
                    </Dialog> : null}
                </Box>
              </Box>
            </>
        }

      </Box>
    )
  }
}
export default withTranslation()(PharmaHome)