import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import ShowComponents from '../Common/ShowComponent';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';

class DocVitalsLeft extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDeleteCliked: false,
            SelectedList: {},
            PatientData: this.props?.PatientData,
            height: '',
            weight: '',
            bp_d: '',
            bp_s: '',
            temp: '',
            spo2: '',
            pulserate: '',
            bloodglucouse: '',
            tempUnit: 'F',
            obs_ExpectedDeliveryDate: null,
            obs_LastMenstrualPeriod: null,
            gestational_weeks: "",
            gestational_days: "",
        }
    }

    componentDidUpdate(prevprops) {
        if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.getDoctorNotesVitalData() }
    }

    getDoctorNotesVitalData = () => {
        try {
            let appointmentId = this.props.PatientData?.appointment_id
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_VITALS + `?appointment_id=${appointmentId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        if (response.data.data?.length > 0) {
                            this.setState({
                                vitalsData: response.data.data,
                                height: response.data.data[0]?.height_cm,
                                weight: response.data.data[0]?.weight_kg,
                                bp_d: response.data.data[0]?.blood_pressure_diastolic,
                                bp_s: response.data.data[0]?.blood_pressure_systolic,
                                temp: response.data.data[0]?.temperature,
                                spo2: response.data.data[0]?.pulse_oximetry,
                                pulserate: response.data.data[0]?.pulse_rate,
                                bloodglucouse: response.data.data[0]?.blood_sugar,
                                tempUnit: response.data.data[0]?.temperature_unit,
                                bmi: response.data.data[0]?.bmi,
                                headCircumference: response.data.data[0]?.head_circumference,
                                obs_ExpectedDeliveryDate: response.data.data[0]?.expected_date_of_delivery,
                                obs_LastMenstrualPeriod: response.data.data[0]?.last_menstrual_period,
                                gestational_weeks: response.data.data[0]?.gestational_age_weeks,
                                gestational_days: response.data.data[0]?.gestational_age_days,
                            })
                        }
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    bmiHelperText = (bmi) => {
        try {
            let message = null
            if (bmi <= 18.5) {
                message = 'Mild Thinness'
            } else if (bmi > 18.5 && bmi <= 25) {
                message = 'Normal'
            } else if (bmi > 25 && bmi <= 30) {
                message = 'Overweight'
            } else if (bmi > 30) {
                message = 'Obesity'
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        try {
            let states = this.state
            const { t } = this.props
            let bpsColor = this.state.bp_s <= 80 ? "green" : "red"
            let bpdColor = this.state.bp_d <= 120 ? "green" : "red"
            let tempColor = ((this.state.tempUnit === 'F' && (this.state.temp >= 97 && this.state.temp <= 99)) || (this.state.tempUnit === 'C' && (this.state.temp >= 36.1 && this.state.temp <= 37.2))) ? 'green' : 'red'
            let spo2Color = (this.state.spo2 >= 95 && this.state.spo2 <= 100) ? "green" : "red"
            let rateColor = (this.state.pulserate >= 60 && this.state.pulserate) ? 'green' : 'red'
            let bloodColor = this.state.bloodglucouse <= 200 ? 'green' : 'red'
            return (
                <ShowComponents onIntersection={this.getDoctorNotesVitalData.bind(this)}>
                    <Box height={(this.props?.UserInfo?.service_type === "PD" || this.props?.UserInfo?.service_type === "OBGYN") ? "43vh" : '30vh'} overflow={'auto'}>
                        <Box className='eMed_Vitals_header_div'>
                            <Typography width={'15%'}>{'Height'}</Typography>
                            <Typography width={'15%'}>{'Weight'}</Typography>
                            <Typography width={'20%'}>{'BMI'}</Typography>
                            <Typography width={'25%'}>{'Systolic Blood Pressure'}</Typography>
                            <Typography width={'25%'}>{'Diastolic Blood Pressure'}</Typography>
                        </Box>
                        <Box className='eMed_Vitals_body_div'>
                            <Typography width={'15%'} sx={{ fontWeight : 600}}>{states?.height ? `${states?.height} cm` : "-"}</Typography>
                            <Typography width={'15%'} sx={{ fontWeight : 600}}>{states?.weight ? `${states?.weight} kg` : "-"}</Typography>
                            <Box width={'20%'} sx={{ backgroundColor: this.state.bmi ? this.state.bmi < 18.5 ? '#FFCCCB' : ((this.state.bmi >= 18.5) && (this.state.bmi <= 25)) ? 'lightgreen' : ((this.state.bmi > 25) && (this.state.bmi <= 30)) ? '#FBB917' : '#FF6863' : "white", paddingLeft: "1vw", marginRight: "2.5vw", borderRadius: "1vw" }}>
                                <Typography sx={{ fontWeight: 600 }}>{states?.bmi ? `${states?.bmi}` : "-"}</Typography>
                                <Typography sx={{ fontSize:"0.8vw"}}>{this.state.bmi ? this.bmiHelperText(this.state.bmi) : ""}</Typography>
                            </Box>
                            <Typography width={'25%'} sx={{ fontWeight : 600 , color : bpsColor}}>{states?.bp_s ? `${states?.bp_s} mm/Hg` : "-"}</Typography>
                            <Typography width={'25%'} sx={{ fontWeight : 600 , color : bpdColor}}>{states?.bp_d ? `${states?.bp_d} mm/Hg` : "-"}</Typography>
                        </Box>
                        <Box className='eMed_Vitals_header_div'>
                            <Typography width={'25%'}>{'Temperature'}</Typography>
                            <Typography width={'25%'}>{'Pulse Oximetry'}</Typography>
                            <Typography width={'25%'}>{'Pulse Rate'}</Typography>
                            <Typography width={'25%'}>{'Random Blood Glucose'}</Typography>
                        </Box>
                        <Box className='eMed_Vitals_body_div'>
                            <Typography width={'25%'} sx={{ fontWeight : 600 , color : tempColor}}>{states?.temp ? `${states?.temp} \u00b0  ${states?.tempUnit}` : "-"}</Typography>
                            <Typography width={'25%'} sx={{ fontWeight : 600 , color : spo2Color}}>{states?.spo2 ? `${states?.spo2} %` : "-"}</Typography>
                            <Typography width={'25%'} sx={{ fontWeight : 600 , color : rateColor}}>{states?.pulserate ? `${states?.pulserate} /min` : "-"}</Typography>
                            <Typography width={'25%'} sx={{ fontWeight : 600 , color : bloodColor}}>{states?.bloodglucouse ? `${states?.bloodglucouse} mg/dl` : "-"}</Typography>
                        </Box>
                        {this.props?.UserInfo?.service_type === "OBGYN" ?
                            <>
                                <Box className='eMed_Vitals_header_div'>
                                    <Typography width={'25%'}>{'Last Menstrual Period'}</Typography>
                                    <Typography width={'25%'}>{'Expected date of delivery'}</Typography>
                                    <Typography width={'25%'}>{'Gestational Age'}</Typography>
                                </Box>
                                <Box className='eMed_Vitals_body_div'>
                                    <Typography width={'25%'} sx={{ fontWeight: 600 }}>{states?.obs_LastMenstrualPeriod ? formatDate(states?.obs_LastMenstrualPeriod) : "-"}</Typography>
                                    <Typography width={'25%'} sx={{ fontWeight: 600 }}>{states?.obs_ExpectedDeliveryDate ? formatDate(states?.obs_ExpectedDeliveryDate) : "-"}</Typography>
                                    <Typography width={'25%'} sx={{ fontWeight: 600 }}>{(states?.gestational_weeks || states?.gestational_days) ?
                                        `${states?.gestational_weeks || 0} ${states?.gestational_weeks == 1 ? 'Week' : 'Weeks'}
                                     ${states?.gestational_days || 0} ${states?.gestational_days == 1 ? 'Day' : 'Days'}` : "-"}</Typography>
                                </Box>
                            </>
                            : null}
                        {this.props?.UserInfo?.service_type === "PD" ?
                            <>
                                <Box className='eMed_Vitals_header_div'>
                                    <Typography width={'25%'}>{'Head Circumference'}</Typography>
                                </Box>
                                <Box className='eMed_Vitals_body_div'>
                                    <Typography width={'25%'} sx={{ fontWeight : 600}}>{states?.headCircumference ? `${states?.headCircumference} cm` : "-"}</Typography>
                                </Box>
                            </>
                            : null}
                    </Box>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </ShowComponents>
            )
        }
        catch (e) {

        }
    }
}
export default withTranslation()(DocVitalsLeft)

