import React from 'react';
import { Box, Button, Paper, Stack, Grid, Typography } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { Colors } from '../../../Styles/Colors'

const reportsList = [
    {
        title: 'Sales Reports', icon: ImagePaths.opConfig.default,
        reports: [
            { report_names: 'Sales Overview', path: '/OTReports/SalesOverview' },
            { report_names: 'Sales Detailed', path: '/OTReports/SalesDetailed' },
            { report_names: 'Sales Bill Tax Wise', path: '/OTReports/SalesBillTaxWise' },
        ]
    },
    {
        title: 'Patient Reports', icon: ImagePaths.demographicConfig.default,
        reports: [
            { report_names: 'Surgery Report', path: '/OTReports/SurgeryReport' },
            { report_names: 'TAT Report', path: '/OTReports/TATReport' },
            { report_names: 'Cancelled Report', path: "/OTReports/OTCancelledReport" },
            { report_names: 'Stock Value Detail', path: "/OTReports/OTStockValueDetail" },
        ]
    },
];

const OTReportsHome = (props) => {

    const { t } = props;

    const renderReports = (item) => {
        return (<Box component="div" className="eMed_rts_card">
            <Paper className="eMed_rts_content">
                <Grid container className="eMed_rts_content_top">
                    {/* Top Section */}
                    <Grid item xs={12} className="eMed_rts_txt_one">
                        <Stack direction="row" alignItems="center">
                            <img src={item?.icon} alt="" className="eMed_rts_icon" />
                            <Typography fontWeight="600">{item?.title}</Typography>
                        </Stack>
                    </Grid>

                    {/* Bottom Section */}
                    <Grid item xs={12} className="eMed_rts_content_btm">
                        {item?.reports.map((list, index) => (
                            <Box
                                key={index}
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                {index > 0 && (
                                    <Typography color={Colors.themeDark}>|</Typography>
                                )}
                                <Button
                                    className="eMed_reports_text_btn"
                                    emed_tid={`ot_rpt_${list.report_names.replace(/ /g, "")}`}
                                    onClick={() => props.history.push({ pathname: list.path })}
                                >
                                    {list.report_names}
                                </Button>
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
        </Box>)
    }

    return (
        <Box component={'div'} className='eMed_rts_home_container' overflow={'scroll'}>
            <Box position={'static'}>
                <Typography fontWeight={'600'} padding='1dvw' borderBottom={'1px solid lightgray'}>{t("Reports")}</Typography>
            </Box>
            <Box>
                {reportsList?.map((item, index) => {
                    return (<Stack key={index}>{renderReports(item)}</Stack>)
                })}
            </Box>
        </Box>
    )
}

export default withTranslation()(OTReportsHome)