import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonCorporateAndInsuranceDetails, CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';

class StockCorrectionLog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      stockCorrectionData: [],
      page: 0,
      pageSize: 10,
      rowCount: 0,
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
        'isActive': 'All'
      },
      pageName: 'stockCorrectionLog',
      filterOpen: false
    }
  }

  componentDidMount() {
    this.getStockCorrectionLogData()
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => { this.getStockCorrectionLogData() })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
      </Box>

    )
  }

  getStockCorrectionLogData = () => {
    try {
      this.LoaderFunction(true)
      let active_status = this.state.filterDatas.isActive === 'All' ? '' : this.state.filterDatas.isActive
      RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_STOCK_CORRECTION_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&is_active=${active_status}`)
        .then((response) => {
          if (response.data.status === 'Success') {
            this.setState({
              stockCorrectionData: response.data.data
            }, ()=>{ this.LoaderFunction(false) })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getStockCorrectionLogExcel = () => {
    try {
      let active_status = this.state.filterDatas.isActive === 'All' ? '' : this.state.filterDatas.isActive
      this.setState({disableBtn: true})
      RestAPIService.excelGet(Serviceurls.PHARMA_INVENTORY_STOCK_CORRECTION_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&is_active=${active_status}&export_type=excel`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"]?.split("filename=")[1]
            // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            this.setState({disableBtn: false})
          }
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message);
      this.setState({disableBtn: false})
    }
  }

  StockCorrectionLogPrint = () => {
    try {
      this.setState({disabled: true})
      let active_status = this.state.filterDatas.isActive === 'All' ? '' : this.state.filterDatas.isActive
      RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_STOCK_CORRECTION_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&is_active=${active_status}&export_type=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disabled: false})
        }).catch((error) => {
          if(error?.response?.data?.message){
            this.errorMessage(error?.response?.data?.message)
          }else{
            this.errorMessage(error.message)
          }
          this.setState({disabled: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disabled: false})
    }
  }

  render() {
    const { t } = this.props
    this.state.stockCorrectionData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'brand_name', headerName: "Drug Details", flex: 0.111,
        renderCell: (params) => (<Box component={'div'}>
          <CommonCorporateAndInsuranceDetails fromPharma={true} data={params?.row} />
        </Box>)
      },
      {
        field: 'dosage_strength', headerName: "Dosage Strength", flex: 0.111, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.dosage_strength?.length > 12 ? <Tooltip placement='top' title={params?.row?.dosage_strength} arrow><div>{params?.row?.dosage_strength?.slice(0, 12) + '...'}</div></Tooltip> :
            params?.row?.dosage_strength ? params?.row?.dosage_strength : '-'}
        </Box>)
      },
      {
        field: 'batch_no', headerName: "Batch No.", flex: 0.111, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.batch_no?.length > 12 ? <Tooltip placement='top' title={params?.row?.batch_no} arrow><div>{params?.row?.batch_no?.slice(0, 12) + '...'}</div></Tooltip> :
            params?.row?.batch_no ? params?.row?.batch_no : '-'}
        </Box>)
      },
      {
        field: 'expiry_date', headerName: "Expiry Date", flex: 0.111, headerAlign: 'center', align: 'center',
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.expiry_date?.length > 12 ? <Tooltip placement='top' title={params?.row?.expiry_date} arrow><div>{params?.row?.expiry_date?.slice(0, 12) + '...'}</div></Tooltip> :
            params?.row?.expiry_date ? params?.row?.expiry_date : '-'}
        </Box>)
      },
      {
        field: 'schedule', headerName: `Schedule`, flex: 0.111,
        renderCell: (params) => (<Box component={'div'}>{params.row?.schedule ? params.row?.schedule : '-'}</Box>)
      },
      {
        field: 'modified_by', headerName: `Updated By`, flex: 0.111,
        renderCell: (params) => (<Box component={'div'}>{params.row?.modified_by ? params.row?.modified_by : '-'}</Box>)
      },
      {
        field: 'previous_quantity', headerName: `Previous Quantity`, flex: 0.111, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params.row?.previous_quantity ? params.row?.previous_quantity : '0'}</Box>)
      },
      {
        field: 'adjusted_quantity', headerName: `Adjusted Quantity`, flex: 0.111, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params.row?.adjusted_quantity ? params.row?.adjusted_quantity : '0'}</Box>)
      },
      {
        field: 'available_quantity', headerName: `Avaliable Quantity`, flex: 0.111, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params.row?.available_quantity ? params.row?.available_quantity : '0'}</Box>)
      },
      {
        field: 'comments', headerName: "Reason", flex: 0.111,
        renderCell: ({ row }) => (<Box component={'div'}>
          {row?.comments?.length > 12 ?
            <Tooltip placement='top' title={row?.comments} arrow>
              <div>{row?.comments?.slice(0, 12) + '...'}</div>
            </Tooltip> :
            row?.comments || '-'}
        </Box>)
      },
    ]
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Pharma_rts_header' sx={{ height : "7vh"}}>
          <Box component={'div'} flex={0.8} display={'flex'}>

          </Box>
          <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            <Button emed_tid = "rpt_stkCrt_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            <Button className='eMed_Pharma_rts_btn' disabled={this.state.disabled}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} onClick={() => this.StockCorrectionLogPrint()}/>
            </Button>
            <Button  emed_tid = "rpt_stkCrt_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getStockCorrectionLogExcel()}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
            </Button>
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card' sx={{ height : "72vh"}}>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.stockCorrectionData}
            columns={columns}
            page={this.state.page}
            pageSize={this.state.pageSize}
            getRowId={(row) => row['sno']}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            rowCount={this.state.stockCorrectionData?.length}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            disableSelectionOnClick
            pagination
            loading={this.state.isLoader}
          />
        </Box>

        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
        </Drawer>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(StockCorrectionLog)