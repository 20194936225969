import React, { Component } from "react";
import ShowComponents from "./ShowComponent";
import { Colors } from "../../../../Styles/Colors";
import { Box, Button, TextField, Typography, Tooltip } from "@mui/material";
import { AnteriorImage, Bucket_Name } from "../../../../Utility/Constants";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import { SketchField, Tools } from "react-sketch-draw";
import CreateIcon from "@material-ui/icons/Create";
import LineIcon from "@material-ui/icons/BorderColor";
import RectIcon from "@material-ui/icons/CropLandscape";
import CircleIcon from "@material-ui/icons/RadioButtonUnchecked";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import AddIcon from "@material-ui/icons/AddOutlined";
import SprayIcon from "@material-ui/icons/BlurCircular";
import UndoIcon from "@material-ui/icons/UndoOutlined";
import RedoIcon from "@material-ui/icons/RedoOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import SaveIcon from "@material-ui/icons/Save";
import { DeletePopup } from "../../../../Components/Common Components/CommonComponents";
import Loader from "../../../../Components/Loader"


var AWS = require("aws-sdk");
var s3 = new AWS.S3({
    region: "ap-south-1",
});

const styles = {
    control: {
        width: "24px",
        height: "24px",
    },

    control_space_1: {
        width: "24px",
    },
}
export default class DocAnteriorSegment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            patientDetails: {},
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            isLocked: this.props.isLocked,
            lineWidth: 3,
            lineColor: "#000000",
            fillColor: "red",
            backgroundColor: "#FFF",
            shadowWidth: 0,
            shadowOffset: 0,
            tool: Tools.Pencil,
            enableRemoveSelected: false,
            fillWithColor: false,
            fillWithBackgroundColor: false,
            drawings: [],
            canUndo: false,
            canRedo: false,
            controlledSize: false,
            stretched: true,
            stretchedX: false,
            stretchedY: false,
            originX: "left",
            originY: "top",
            imageUrl: "",
            expandTools: false,
            expandControls: false,
            expandColors: false,
            expandBack: false,
            expandImages: false,
            expandControlled: false,
            text: "",
            enableCopyPaste: false,
            controlledValue: null,
            templateUrl: AnteriorImage,
            data: [],
            background_image: "",
            openPopup: false,
            selectedColor: "#000000",
            anteriorDetails: [],
            LidsRightEye: "",
            ConjunctivaRightEye: "",
            ScleraRightEye: "",
            CorneaRightEye: "",
            AnteriorChambRightEye: "",
            PupilRightEye: "",
            IrisRightEye: "",
            LensRightEye: "",
            LidsLeftEye: "",
            ConjunctivaLeftEye: "",
            ScleraLeftEye: "",
            CorneaLeftEye: "",
            AnteriorChambLeftEye: "",
            PupilLeftEye: "",
            IrisLeftEye: "",
            LensLeftEye: "",
            ExtraocularRightEye: "",
            ExtraocularLeftEye: "",
            rightEye: false,
            lefytEye: false,
            normal: false,
            isDeleteCliked: false,
            AnteriorData: [],
            isLoader: false
        }
    }


    apiCalls = () => {
        this._getImage();
        this.PreviousAntDiagram();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    _onSketchChange = () => {
        let prev = this.state.canUndo;
        let now = this._sketch.canUndo();
        if (prev !== now) {
            this.setState({ canUndo: now });
        }
    };

    popupClose = () => {
        this.setState({
            isDeleteCliked: false
        })
    }

    PreviousAntDiagram = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_ANTERIOR_PREVIOUS_DIAGRAM + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&doctor_id=${this.props.doctorID}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            AnteriorData: response?.data?.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    _getImage = () => {
        try {
            RestAPIService.getAll(Serviceurls.OPTHALMOLOGY_ANTERIOR_SEGMENT_IMAGE + `?appointment_id=${this.state.appointmentId}&patient_id=${this.state.patientId}`)
                .then((response => {
                    if (response?.data?.status === 'success') {
                        this.setState({ isLoader: false })
                        var Anterior = response.data.data ? response.data.data : [];
                        var field = this.state;
                        try {
                            field["data"] = JSON.parse(JSON.stringify(Anterior));
                            this.setState({ field });

                            if (Anterior.anterior_image_key) {
                                this._setBackgroundImage(
                                    Anterior.anterior_image_key
                                );
                            } else {
                                this.setState({ background_image: AnteriorImage });
                                if (AnteriorImage) {
                                    this._sketch.setBackgroundFromDataUrl(AnteriorImage);
                                }
                            }
                        } catch (e) {
                            field["data"] = [];
                            if (AnteriorImage) {
                                this._sketch.setBackgroundFromDataUrl(AnteriorImage);
                            }
                            this.setState({
                                field,
                            });
                        }
                    }
                })).catch((error) => {
                    this.errorMessage(error.message)
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    CopyPreviousData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_ANTERIOR_PREVIOUS_DIAGRAM + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&doctor_id=${this.props.doctorID}`)
                .then((response => {
                    this.setState({ isLoader: false })
                    if (response?.data?.status === 'success') {
                        var Anterior = response.data.data ? response.data.data : [];
                        var field = this.state;
                        try {
                            field["data"] = JSON.parse(JSON.stringify(Anterior));
                            this.setState({ field });

                            if (Anterior.anterior_image_key) {
                                this._setBackgroundImage(Anterior.anterior_image_key);
                                setTimeout(() => { this._save() }, 1500)
                            } else {
                                this.setState({ background_image: AnteriorImage });
                                if (AnteriorImage) {
                                    this._sketch.setBackgroundFromDataUrl(AnteriorImage);
                                }
                            }
                        } catch (e) {
                            field["data"] = [];
                            if (AnteriorImage) {
                                this._sketch.setBackgroundFromDataUrl(AnteriorImage);
                            }
                            this.setState({
                                field,
                            });
                        }
                    }
                })).catch((error) => {
                    this.errorMessage(error.message)
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    _save = () => {
        var { drawings } = this.state
        try {
            drawings.push(this._sketch.toDataURL());
            var lengths = (drawings.length) - 1
            if (drawings.length > 0) {
                let data = new FormData();
                data.append('appointment_id', this.state.appointmentId);
                data.append('patient_id', this.state.patientId);
                data.append('image', this.dataURItoBlob(drawings[lengths]));
                data.append('image_json', (this.state.iscopy ? this.state.AnteriorData.image_json : JSON.stringify(this._sketch)));

                RestAPIService.create(data, Serviceurls.OPTHALMOLOGY_ANTERIOR_SEGMENT_IMAGE)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.setState({
                                isDeleteCliked: false, iscopy: false
                            }, () => {
                                // if (this.state.iscopy) {
                                //     this.CopyPreviousData()
                                // } else {
                                this._getImage()
                                // }
                            })
                        }
                    }).catch((error) => {
                        this.errorMessage(error.message)
                    })
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    _addText = () => {
        this.setState({ tool: Tools.Select });
        this._sketch.addText(this.state.text);
    };

    async _setBackgroundImage(doc_key) {
        try {
            var self = this;
            const response = await RestAPIService.getAllTwo(Serviceurls.AWS_URL_GET + "?doc_key=" + encodeURIComponent(doc_key));
    
            if (response.data.status === "success") {
                const Data = response?.data;
                self.setState({ background_image: Data?.data });
                if (Data?.data) {
                    self._sketch.setBackgroundFromDataUrl(
                        Data.data
                    );
                }
            }
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: "Error fetching S3 URL"
            });
        }
    }

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];

        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    };

    _undo = () => {
        this._sketch.undo();
        this.setState({
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo(),
        });
    };

    _redo = () => {
        this._sketch.redo();
        this.setState({
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo(),
        });
    };

    _clear = () => {
        this._sketch.clear();
        this._sketch.setBackgroundFromDataUrl(this.state.templateUrl);
        this.setState({
            controlledValue: null,
            backgroundColor: "#FFF",
            fillWithBackgroundColor: false,
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo(),
            isDeleteCliked: false,
            isLoader: true
        }, () => {
            if (this.state.iscopy) {
                setTimeout(() => { this.CopyPreviousData() }, 1500)
            } else {
                setTimeout(() => { this._save() }, 1500)
            }
        });
    };

    _copy = () => {
        try {
            this._sketch.copy();
            this._sketch.paste();
        } catch (error) {
            this.setState({
                enableRemoveSelected: false,
                enableCopyPaste: false,
            });
        }
    };
    _removeSelected = () => {
        try {
            this._sketch.removeSelected();
        } catch (error) {
            this.setState({
                enableRemoveSelected: false,
                enableCopyPaste: false,
            });
        }
    };

    _removeMe = (index) => {
        let drawings = this.state.drawings;
        drawings.splice(index, 1);
        this.setState({ drawings: drawings });
    };


    _changeColor = (color) => {
        let { tool } = this.state;

        this.setState({
            lineColor: color,
            tool: Tools.Pan
        }, () => {
            this.setState({
                tool: tool
            });
        });
    };

    _selectTool = () => {
        this.setState({ tool: Tools.Select });
        this.setState({
            enableRemoveSelected: true,
            enableCopyPaste: true,
        });
    };

    renderAnteriorSegmentImage = () => {
        let { controlledValue } = this.state;
        return (
            <div style={{ backgroundColor: Colors.white, border: "1px solid black" }}>
                <SketchField
                    name={"sketchfield"}
                    ref={(c) => (this._sketch = c)}
                    tool={this.state.tool}
                    lineColor={this.state.lineColor}
                    lineWidth={this.state.lineWidth}
                    fillColor={
                        this.state.fillWithColor
                            ? this.state
                                .fillColor
                            : "transparent"
                    }
                    backgroundColor={
                        this.state
                            .fillWithBackgroundColor
                            ? this.state
                                .backgroundColor
                            : "transparent"
                    }
                    width={700}
                    height={300}
                    value={controlledValue}
                    undoSteps={2000}
                    forceValue
                    onChange={this._onSketchChange}
                />
            </div>
        )
    }

    copyCheckBoxValues(key) {
        if (key == "rightEye") {
            if (this.state.lefytEye) {
                this.setState({ rightEye: !this.state.rightEye, normal: true }, () => {
                    if (!this.state.rightEye) {
                        this.setState({ normal: false }, () => { this.copyValues("rightEye") })
                    } else {
                        this.copyValues("rightEye")
                    }
                })
            } else {
                this.setState({ rightEye: !this.state.rightEye }, () => { this.copyValues("rightEye") })
            }
        } else if (key == "leftEye") {
            if (this.state.rightEye) {
                this.setState({ lefytEye: !this.state.lefytEye, normal: true }, () => {
                    if (!this.state.lefytEye) {
                        this.setState({ normal: false }, () => { this.copyValues("leftEye") })
                    } else {
                        this.copyValues("leftEye")
                    }
                })
            } else {
                this.setState({ lefytEye: !this.state.lefytEye }, () => { this.copyValues("leftEye") })
            }
        } else if (key == "normal") {
            if (this.state.rightEye && this.state.lefytEye) {
                this.setState({
                    rightEye: false,
                    lefytEye: false,
                    normal: false
                }, () => { this.copyValues("normal") })
            } else {
                this.setState({
                    rightEye: true,
                    lefytEye: true,
                    normal: true
                }, () => { this.copyValues("normal") })
            }
        }
    }

    copyValues = (type) => {
        if (type == "leftEye") {
            if (this.state.lefytEye) {
                var states = this.state
                states["LidsLeftEye"] = "Normal";
                states["ConjunctivaLeftEye"] = "Normal";
                states["ScleraLeftEye"] = "Normal";
                states["CorneaLeftEye"] = "Clear";
                states["AnteriorChambLeftEye"] = "Normal Depth";
                states["PupilLeftEye"] = "Normal Size reacting to light";
                states["IrisLeftEye"] = "Normal Color & Pattern";
                states["LensLeftEye"] = "Clear";
                states["ExtraocularLeftEye"] = "Normal";
                this.setState({ states })
            } else {
                var states = this.state
                states["LidsLeftEye"] = "";
                states["ConjunctivaLeftEye"] = "";
                states["ScleraLeftEye"] = "";
                states["CorneaLeftEye"] = "";
                states["AnteriorChambLeftEye"] = "";
                states["PupilLeftEye"] = "";
                states["IrisLeftEye"] = "";
                states["LensLeftEye"] = "";
                states["ExtraocularLeftEye"] = "";
                this.setState({ states })
            }
        } else if (type == "rightEye") {
            if (this.state.rightEye) {
                var states = this.state
                states["LidsRightEye"] = "Normal";
                states["ConjunctivaRightEye"] = "Normal";
                states["ScleraRightEye"] = "Normal";
                states["CorneaRightEye"] = "Clear";
                states["AnteriorChambRightEye"] = "Normal Depth";
                states["PupilRightEye"] = "Normal Size reacting to light";
                states["IrisRightEye"] = "Normal Color & Pattern";
                states["LensRightEye"] = "Clear";
                states["ExtraocularRightEye"] = "Normal";
                this.setState({ states })
            } else {
                var states = this.state
                states["LidsRightEye"] = "";
                states["ConjunctivaRightEye"] = "";
                states["ScleraRightEye"] = "";
                states["CorneaRightEye"] = "";
                states["AnteriorChambRightEye"] = "";
                states["PupilRightEye"] = "";
                states["IrisRightEye"] = "";
                states["LensRightEye"] = "";
                states["ExtraocularRightEye"] = "";
                this.setState({ states })
            }
        } else if (type == "normal") {
            if (this.state.normal) {
                var states = this.state;
                states["LidsLeftEye"] = "Normal";
                states["ConjunctivaLeftEye"] = "Normal";
                states["ScleraLeftEye"] = "Normal";
                states["CorneaLeftEye"] = "Clear";
                states["AnteriorChambLeftEye"] = "Normal Depth";
                states["PupilLeftEye"] = "Normal Size reacting to light";
                states["IrisLeftEye"] = "Normal Color & Pattern";
                states["LensLeftEye"] = "Clear";
                states["ExtraocularLeftEye"] = "Normal";

                states["LidsRightEye"] = "Normal";
                states["ConjunctivaRightEye"] = "Normal";
                states["ScleraRightEye"] = "Normal";
                states["CorneaRightEye"] = "Clear";
                states["AnteriorChambRightEye"] = "Normal Depth";
                states["PupilRightEye"] = "Normal Size reacting to light";
                states["IrisRightEye"] = "Normal Color & Pattern";
                states["LensRightEye"] = "Clear";
                states["ExtraocularRightEye"] = "Normal";
                this.setState({ states })
            } else {
                var states = this.state;
                states["LidsLeftEye"] = "";
                states["ConjunctivaLeftEye"] = "";
                states["ScleraLeftEye"] = "";
                states["CorneaLeftEye"] = "";
                states["AnteriorChambLeftEye"] = "";
                states["PupilLeftEye"] = "";
                states["IrisLeftEye"] = "";
                states["LensLeftEye"] = "";
                states["ExtraocularLeftEye"] = "";

                states["LidsRightEye"] = "";
                states["ConjunctivaRightEye"] = "";
                states["ScleraRightEye"] = "";
                states["CorneaRightEye"] = "";
                states["AnteriorChambRightEye"] = "";
                states["PupilRightEye"] = "";
                states["IrisRightEye"] = "";
                states["LensRightEye"] = "";
                states["ExtraocularRightEye"] = "";
                this.setState({ states })
            }
        }
    }

    renderTextBox = (key) => {
        let states = this.state
        return (
            <TextField
                inputProps={{ emed_tid: "" }}
                sx={{ width: '80%' }}
                size='small'
                placeholder='Type Remark'
                autoComplete='off'
                value={states[key]}
                onChange={(e) => {
                    states[key] = e.target.value
                    this.setState({ states })
                }}
            />
        )
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white, marginTop: "0.5vw", marginLeft: "0.5vw" }}>
                    <Box component={'div'} p={'0.5vw'} sx={{ backgroundColor: Colors.Background, display: "flex", flexDirection: "row", alignItems: "center" }} height={'2.5vw'}>
                        <Typography fontWeight={600}>Anterior Segment Diagram</Typography>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1vw" }}>
                            <div className="eMed_icon_styles" style={{ backgroundColor: Object.keys(this.state.AnteriorData).length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }}
                                onClick={() => {
                                    if (Object.keys(this.state.AnteriorData).length > 0 && !this.props.isLocked) {
                                        this.setState({ isLoader: true, iscopy: true }, () => { this._clear() })
                                    }
                                }}>
                                <Tooltip placement='top' title='Copy Previous Anterior Diagram'>
                                    <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Box component={'div'} p={'0.5vw'}>
                    <Box component={'div'} p={'0.5vw'}>
                        <Box component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "0.5vw", marginLeft: "-1vw" }}>
                            {this.renderAnteriorSegmentImage()}
                        </Box>

                        <div style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "0.5vw", flexDirection: "row", justifyContent: "center" }}>
                            <div>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#000000" ? "1px solid black" : null }} onClick={() => { this._changeColor("#000000") }}>
                                    <Box component={'img'} src={ImagePaths.Black.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#F19C38" ? "1px solid black" : null }} onClick={() => { this._changeColor("#F19C38") }}>
                                    <Box component={'img'} src={ImagePaths.Orange.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#4A95EC" ? "1px solid black" : null }} onClick={() => { this._changeColor("#4A95EC") }}>
                                    <Box component={'img'} src={ImagePaths.Blue.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#67AC5B" ? "1px solid black" : null }} onClick={() => { this._changeColor("#67AC5B") }}>
                                    <Box component={'img'} src={ImagePaths.Green.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#E15241" ? "1px solid black" : null }} onClick={() => { this._changeColor("#E15241") }}>
                                    <Box component={'img'} src={ImagePaths.Red.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#74574A" ? "1px solid black" : null }} onClick={() => { this._changeColor("#74574A") }}>
                                    <Box component={'img'} src={ImagePaths.Brown.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                                <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#FFFFFF" ? "1px solid black" : null }} onClick={() => { this._changeColor("#FFFFFF") }}>
                                    <Box component={'img'} src={ImagePaths.White.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                            </div>
                            <div style={{ marginLeft: "4vw", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Tooltip placement="top" title={'Spray'}>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.setState({
                                                tool: Tools.Spray,
                                                lineWidth: 10,

                                            }, () => { this._changeColor("#000000") });
                                        }}
                                    >
                                        <SprayIcon style={styles.control} />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title={"Pencil"}>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.setState({
                                                tool: Tools.Pencil,
                                                lineWidth: 3,
                                            }, () => { this._changeColor("#000000") });
                                        }}
                                    >
                                        <CreateIcon
                                            style={styles.control}
                                        />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title={"Line Pencil"}>
                                    <Button sx={{ marginTop: "0.5vw" }} color="primary"
                                        onClick={() => {
                                            this.setState({
                                                tool: Tools.Line,
                                                lineWidth: 3,
                                            }, () => { this._changeColor("#000000") });
                                        }}
                                    >
                                        <LineIcon
                                            style={styles.control}
                                        />
                                    </Button>
                                </Tooltip>

                                <Tooltip placement="top" title={"Rectangle"}>

                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.setState({
                                                tool: Tools.Rectangle,
                                                lineWidth: 3,
                                            }, () => { this._changeColor("#000000") });
                                        }}
                                    >
                                        <RectIcon
                                            style={styles.control}
                                        />
                                    </Button>
                                </Tooltip>

                                <Tooltip placement="top" title={"Circle"}>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.setState({
                                                tool: Tools.Circle,
                                                lineWidth: 3,
                                            }, () => { this._changeColor("#000000") });
                                        }}
                                    >
                                        <CircleIcon
                                            style={styles.control}
                                        />
                                    </Button>
                                </Tooltip>

                                <Tooltip placement="top" title={"Brush"}>
                                    <Button
                                        color="primary"
                                        style={{ marginLeft: "-0.5vw" }}
                                        onClick={() => {
                                            this.setState({
                                                tool: Tools.Pencil,
                                                lineWidth: 10,
                                            }, () => { this._changeColor("#000000") });
                                        }}
                                    >
                                        <Box component={'img'} src={ImagePaths.Brush.default} height={'1.4vw'} />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "center" }}>

                            <Tooltip placement="top" title={"Eraser"}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.setState({
                                            tool: Tools.Pencil,
                                            lineWidth: 10,
                                        }, () => { this._changeColor("#FFFFFF") });
                                    }}
                                >
                                    <Box component={'img'} src={ImagePaths.Eraser.default} height={'1.2vw'} width={'1.2vw'} />
                                </Button>
                            </Tooltip>

                            <Tooltip placement="top" title={"Undo"}>
                                <Button
                                    color="primary"
                                    disabled={!this.state.canUndo}
                                    onClick={() => { this._undo() }}
                                >
                                    <UndoIcon
                                        style={styles.control}
                                    />
                                </Button>
                            </Tooltip>

                            <Tooltip placement="top" title={"Redo"}>
                                <Button
                                    color="primary"
                                    disabled={!this.state.canRedo}
                                    onClick={() => { this._redo() }}
                                >
                                    <RedoIcon
                                        style={styles.control}
                                    />
                                </Button>
                            </Tooltip>

                            <div style={{ justifyContent: "center" }}>
                                <TextField id="standard-basic" variant="standard" onChange={(e) => { this.setState({ text: e.target.value }) }} />
                                <div style={{ fontSize: 10, color: "grey", marginTop: 5 }} >Enter text to add</div>
                            </div>

                            <Tooltip placement="top" title={"Add Text"}>
                                <Button
                                    color="primary"
                                    onClick={() => { this._addText() }}
                                >
                                    <AddIcon
                                        style={styles.control}
                                    />
                                </Button>
                            </Tooltip>

                            <Tooltip placement="top" title={"Save"}>
                                <Button disabled={this.state.isLocked || this.props.isLocked} onClick={() => { this._save() }}>
                                    <SaveIcon style={styles.control} />
                                </Button>
                            </Tooltip>

                            <Tooltip placement="top" title={"Trash"}>
                                <Button
                                    color="primary"
                                    disabled={this.state.isLocked || this.props.isLocked}
                                    onClick={() => {
                                        this.setState({
                                            isDeleteCliked: true
                                        })
                                    }}
                                >
                                    <DeleteIcon
                                        style={styles.control}
                                    />
                                </Button>
                            </Tooltip>
                        </div>
                    </Box>
                </Box>
                {this.state.isDeleteCliked ? <DeletePopup
                    DeletTitle={"Are you sure you want to delete Anterior Segment ?"}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this._clear.bind(this)}
                /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </ShowComponents>
        )
    }
}