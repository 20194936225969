import React, { Component } from 'react';
import './Billings.css';
import {
    IconButton, Tooltip, Box, Stack, Button, Typography, TextField,
    Paper, TableCell, TableContainer, Table, TableHead,
    TableRow, TableBody, InputAdornment
} from '@mui/material';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { DateTime } from "luxon";
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import Loader from '../../../Components/Loader';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CommonPopUp, ReasonPopupWithRefund } from '../../../Components/Common Components/CommonComponents';
import { Colors } from '../../../Styles/Colors';
import { CurrencySymbol } from '../../../Utility/Constants';
import { DataGrid } from '@mui/x-data-grid';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import { AmountFormat, CheckAccessFunc, convertDateTime } from '../../../Components/CommonFunctions/CommonFunctions';

class UserDayEndSalesReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            total_sales: "",
            total_cash: "",
            total_card: "",
            total_upi: "",
            total_banktransfer: "",
            total_cheque: "",
            total_credit: "",
            total_return: "",
            total_cancel: "",
            total: "",
            TwoThounsandCounts: "",
            FiftyCounts: "",
            FiveHundredCounts: "",
            TwentyCounts: "",
            TwoHundredCounts: "",
            TenCounts: "",
            HundredCounts: "",
            OneCounts: "",
            TwoCounts: "",
            FiveCount: "",
            datePicker: new Date(),
            HistoryData: [],
        }
    }

    componentDidMount() {
        this.GetUserSalesData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetUserSalesData() {
        try {
            this.LoaderFunction(true)
            var StateDate = new Date(this.state.datePicker)
            var date = DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd')
            RestAPIService.getAll(`${Serviceurls.PHARMACY_USER_DAYEND_SALES}?date=${date}&denomination_module=${this.props.from}`).
                then((response) => {
                    if (response.data.status === "success") {
                        let ResponseData = response?.data?.data ? response?.data?.data : {}
                        let DenominationData = ResponseData?.denomination ? ResponseData?.denomination : {}
                        this.setState({
                            total_sales: ResponseData?.total_sales ? ResponseData?.total_sales : 0,
                            total_cash: ResponseData?.total_cash ? ResponseData?.total_cash : 0,
                            total_upi: ResponseData?.total_upi ? ResponseData?.total_upi : 0,
                            total_card: ResponseData?.total_card ? ResponseData?.total_card : 0,
                            total_cheque: ResponseData?.total_cheque ? ResponseData?.total_cheque : 0,
                            total_banktransfer: ResponseData?.total_bank_transfer ? ResponseData?.total_bank_transfer : 0,
                            total_credit: ResponseData?.total_credit ? ResponseData?.total_credit : 0,
                            total_return: ResponseData?.total_return ? ResponseData?.total_return : 0,
                            total_cancel: ResponseData?.total_cancel ? ResponseData?.total_cancel : 0,
                            total: ResponseData?.total ? ResponseData?.total : 0,
                            TwoThounsandCounts: DenominationData?.TwoThounsandCounts ? DenominationData?.TwoThounsandCounts : "",
                            FiftyCounts: DenominationData?.FiftyCounts ? DenominationData?.FiftyCounts : "",
                            FiveHundredCounts: DenominationData?.FiveHundredCounts ? DenominationData?.FiveHundredCounts : "",
                            TwentyCounts: DenominationData?.TwentyCounts ? DenominationData?.TwentyCounts : "",
                            TwoHundredCounts: DenominationData?.TwoHundredCounts ? DenominationData?.TwoHundredCounts : "",
                            TenCounts: DenominationData?.TenCounts ? DenominationData?.TenCounts : "",
                            HundredCounts: DenominationData?.HundredCounts ? DenominationData?.HundredCounts : "",
                            FiveCount: DenominationData?.FiveCount ? DenominationData?.FiveCount : "",
                            TwoCounts: DenominationData?.TwoCounts ? DenominationData?.TwoCounts : "",
                            OneCounts: DenominationData?.OneCounts ? DenominationData?.OneCounts : "",
                            DataId: ResponseData?.id ? ResponseData?.id : null,
                            RecentAddedComments : ResponseData?.comments ? ResponseData?.comments : null
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    getHistoryData() {
        try {
            this.LoaderFunction(true)
            var StateDate = new Date(this.state.datePicker)
            var date = DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd')
            RestAPIService.getAll(`${Serviceurls.PHARMACY_USER_DAYEND_SALES}?date=${date}&id=${this.state.DataId}&is_history=true&denomination_module=${this.props.from}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            HistoryData: response?.data?.data ? response?.data?.data : [],
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    returnAmountTextBox = (stateKey) => {
        let states = this.state
        return (
            <TextField
                value={AmountFormat(states[stateKey] || 0)?.replace(`${CurrencySymbol}`, "")}
                size='small'
                inputProps={{ readOnly: true }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.RupeeOff.default}
                                alt={'amount'} style={{ width: `${CurrencySymbol === '$' ? 1.5 : 1}vw`, height: `${CurrencySymbol === '$' ? 1.5 : 1}vw` }} />
                        </InputAdornment>
                    ),
                }}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && +e.target.value > 0) || e.target.value === "") {
                        states[stateKey] = e.target.value
                        this.setState({
                            states
                        })
                    }
                }}
            />
        )
    }

    renderCountsText = (Amount, Key, Right = false) => {
        var states = this.state
        let TotalAmt = (Amount ? +Amount : 0) * (states[Key] ? +states[Key] : 0)
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        let EditAccess = true
        if(this.props.from === "Pharmacy"){
            EditAccess = CheckAccessFunc("Pharmacy", "Billing Transaction", "Day End Denomination", null, "Tab")?.editAccess
        }else if(this.props.from === "Clinic"){
            EditAccess = CheckAccessFunc("front_office", "Billing Transaction", "Day End Denomination", null, "Tab")?.editAccess
        }else if(this.props.from === "Radiology"){
            EditAccess = CheckAccessFunc("Radiology", "Billing Transaction", "Day End Denomination", null, "Tab")?.editAccess
        }else if(this.props.from === "Laboratory"){
            EditAccess = true
        }
        return (
            <Box component={"div"} marginLeft={Right ? "3vw" : "1.2vw"} className="eMed_CrdPop_Denomi_invidual_div" width={"20vw"}>
                <Typography className='eMed_countsAmount_Label'>{Amount}</Typography>
                <Typography>x</Typography>
                <TextField
                    placeholder="Counts"
                    size='small'
                    emed_tid={Key}
                    disabled={(RoleData?.is_user && this.isNotToday(states?.datePicker)) || !EditAccess}
                    inputProps={{ maxLength: 4 }}
                    className='eMed_crdPop_amountTextField'
                    sx={{ width : null}}
                    variant='standard'
                    value={states[Key]}
                    onChange={(e) => {
                        let number = CommonValidation.numberOnly(e.target.value);
                        if (number || e.target.value === "") {
                            states[Key] = e.target.value
                            this.setState({
                                states
                            })
                        }
                    }}
                />
                <Typography>=</Typography>
                <Typography sx={{ width: '4dvw', fontWeight: 600 }}>{AmountFormat(TotalAmt || 0)?.replace(`${CurrencySymbol}`, "")}</Typography>
            </Box>
        )
    }

    returnTotal() {
        try {
            let Amount = 0
            let states = this.state
            if (states.TwoThounsandCounts != "") {
                Amount += +states.TwoThounsandCounts * 2000
            }
            if (states.FiveHundredCounts != "") {
                Amount += +states.FiveHundredCounts * 500
            }
            if (states.TwoHundredCounts != "") {
                Amount += +states.TwoHundredCounts * 200
            }
            if (states.HundredCounts != "") {
                Amount += +states.HundredCounts * 100
            }
            if (states.FiftyCounts != "") {
                Amount += +states.FiftyCounts * 50
            }
            if (states.TwentyCounts != "") {
                Amount += +states.TwentyCounts * 20
            }
            if (states.TenCounts != "") {
                Amount += +states.TenCounts * 10
            }
            if (states.FiveCount != "") {
                Amount += +states.FiveCount * 5
            }
            if (states.TwoCounts != "") {
                Amount += +states.TwoCounts * 2
            }
            if (states.OneCounts != "") {
                Amount += +states.OneCounts * 1
            }
            return Amount;
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    isNotToday(date) {
        const today = new Date();
        const givenDate = date;

        return today.toISOString().slice(0, 10) !== givenDate.toISOString().slice(0, 10);
    }

    _getHistory() {
        this.state.HistoryData.forEach((element, index) => { element.sno = index + 1 })
        const { t } = this.props;
        const historyColumn = [
            {
                field: "modified_by", width: 150, headerName: t("Updated By"),
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.modified_by ? params?.row?.modified_by?.length > 12 ?
                        <Tooltip placement='top' title={params?.row?.modified_by} arrow>
                            <div>{params?.row?.modified_by.slice(0, 12) + "..."}</div></Tooltip> :
                        params?.row?.modified_by : "-"}
                </Box>)
            },
            {
                field: "modified_date", width: 200, headerName: t("ModifiedDate"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_date ? convertDateTime(params?.row?.modified_date) : "-"}</Box>)
            },
            {
                field: "comments", width: 150, headerName: t(`Comments`), headerAlign: "center", align: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.comments ? params?.row?.comments?.length > 16 ?
                        <Tooltip placement='top' title={params?.row?.comments} arrow>
                            <div>{params?.row?.comments.slice(0, 15) + "..."}</div></Tooltip> :
                        params?.row?.comments : "-"}</Box>)
            },
            {
                field: "Total_amount", width: 150, headerName: t(`Total Amount`), headerAlign: "center", align: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.denomination?.Total_amount ? AmountFormat(params?.row?.denomination?.Total_amount|| 0)?.replace(`${CurrencySymbol}`, "") : "-"}</Box>)
            },
            {
                field: "2000", width: 150, headerName: t(`${CurrencySymbol} 2000`), headerAlign: "center", align: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.denomination?.TwoThounsandCounts ? params?.row?.denomination?.TwoThounsandCounts : "-"}</Box>)
            },
            {
                field: "500", width: 150, headerName: t(`${CurrencySymbol} 500`), headerAlign: "center", align: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.denomination?.FiveHundredCounts ? params?.row?.denomination?.FiveHundredCounts : "-"}</Box>)
            },
            {
                field: "200", width: 150, headerName: t(`${CurrencySymbol} 200`), headerAlign: "center", align: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.denomination?.TwoHundredCounts ? params?.row?.denomination?.TwoHundredCounts : "-"}</Box>)
            },
            {
                field: "100", width: 150, headerName: t(`${CurrencySymbol} 100`), headerAlign: "center", align: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.denomination?.HundredCounts ? params?.row?.denomination?.HundredCounts : "-"}</Box>)
            },
            {
                field: "50", width: 150, headerName: t(`${CurrencySymbol} 50`), headerAlign: "center", align: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.denomination?.FiftyCounts ? params?.row?.denomination?.FiftyCounts : "-"}</Box>)
            },
            {
                field: "20", width: 150, headerName: t(`${CurrencySymbol} 20`), headerAlign: "center", align: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.denomination?.TwentyCounts ? params?.row?.denomination?.TwentyCounts : "-"}</Box>)
            },
            {
                field: "10", width: 150, headerName: t(`${CurrencySymbol} 10`), headerAlign: "center", align: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.denomination?.TenCounts ? params?.row?.denomination?.TenCounts : "-"}</Box>)
            },
            {
                field: "5", width: 150, headerName: t(`${CurrencySymbol} 5`), headerAlign: "center", align: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.denomination?.FiveCount ? params?.row?.denomination?.FiveCount : "-"}</Box>)
            },
            {
                field: "2", width: 150, headerName: t(`${CurrencySymbol} 2`), headerAlign: "center", align: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.denomination?.TwoCounts ? params?.row?.denomination?.TwoCounts : "-"}</Box>)
            },
            {
                field: "1", width: 150, headerName: t(`${CurrencySymbol} 1`), headerAlign: "center", align: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.denomination?.OneCounts ? params?.row?.denomination?.OneCounts : "-"}</Box>)
            },
        ];
        return (
            <Box id="eMed_ipsrvConfig_accTbl">
                <DataGrid
                    rows={this.state.HistoryData}
                    columns={historyColumn}
                    getRowId={(row) => row.sno}
                    disableColumnMenu
                    hideFooter
                    components={{
                        NoRowsOverlay: () => (
                            <Stack className='eMed_conf_nodata'>
                                {t("NoRecordsFound")}
                            </Stack>
                        )
                    }}
                    headerHeight={40}
                    disableSelectionOnClick
                />
            </Box>
        )
    }

    historyPopUpClose() {
        this.setState({
            isHistoryClicked: false,
            HistoryData: [],
            EditCommentsPop : false
        })
    }

    PostDayEndDenomination = (comments = null) => {
        try {
            var states = this.state
            let Aptime = DateTime.fromJSDate(this.state.datePicker).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let invoice_date = `${DateTime.fromJSDate(this.state.datePicker).toFormat("yyyy-MM-dd")} ${Aptime}`
            var data = {
                "date": invoice_date,
                "total_sales": this.state.total_sales ? +this.state.total_sales : 0,
                "total_cash": this.state.total_cash ? +this.state.total_cash : 0,
                "total_upi": this.state.total_upi ? +this.state.total_upi : 0,
                "total_card": this.state.total_card ? +this.state.total_card : 0,
                "total_cheque": this.state.total_cheque ? +this.state.total_cheque : 0,
                "total_bank_transfer": this.state.total_banktransfer ? +this.state.total_banktransfer : 0,
                "total_credit": this.state.total_credit ? +this.state.total_credit : 0,
                "total_return": this.state.total_credit ? +this.state.total_credit : 0,
                "total": this.state.total ? +this.state.total : 0,
                "denomination_module": this.props.from,
                "denomination": {
                    TwoThounsandCounts: this.state.TwoThounsandCounts ? +this.state.TwoThounsandCounts : 0,
                    FiftyCounts: this.state.FiftyCounts ? +this.state.FiftyCounts : 0,
                    FiveHundredCounts: this.state.FiveHundredCounts ? +this.state.FiveHundredCounts : 0,
                    TwentyCounts: this.state.TwentyCounts ? +this.state.TwentyCounts : 0,
                    TwoHundredCounts: this.state.TwoHundredCounts ? +this.state.TwoHundredCounts : 0,
                    TenCounts: this.state.TenCounts ? +this.state.TenCounts : 0,
                    HundredCounts: this.state.HundredCounts ? +this.state.HundredCounts : 0,
                    FiveCount: this.state.FiveCount ? +this.state.FiveCount : 0,
                    TwoCounts: this.state.TwoCounts ? +this.state.TwoCounts : 0,
                    OneCounts: this.state.OneCounts ? +this.state.OneCounts : 0,
                    Total_amount: isNaN(this.returnTotal()) ? 0 : this.returnTotal(),
                },
            }
            if (this.state.DataId) { 
                data["id"] = this.state.DataId
                data["comments"] = comments
             }
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.PHARMACY_USER_DAYEND_SALES).
                then((response) => {
                    if (response.data.status === "success") {
                        this.GetUserSalesData()
                        this.setState({
                            EditCommentsPop: false
                        })
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    ClearAlldata() {
        this.setState({
            TwoThounsandCounts: "",
            FiftyCounts: "",
            FiveHundredCounts: "",
            TwentyCounts: "",
            TwoHundredCounts: "",
            TenCounts: "",
            HundredCounts: "",
            OneCounts: "",
            TwoCounts: "",
            FiveCount: "",
        })
    }

    PrintDayEndData = () => {
        try {
            var StateDate = new Date(this.state.datePicker)
            var date = DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd')
            RestAPIService.getAll(`${Serviceurls.PHARMACY_USER_DAYEND_SALES}?date=${date}&export=pdf&denomination_module=${this.props.from}`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        const { t, from } = this.props;
        let states = this.state;
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        let EditAccess = true
        if(this.props.from === "Pharmacy"){
            EditAccess = CheckAccessFunc("Pharmacy", "Billing Transaction", "Day End Denomination", null, "Tab")?.editAccess
        }else if(this.props.from === "Clinic"){
            EditAccess = CheckAccessFunc("front_office", "Billing Transaction", "Day End Denomination", null, "Tab")?.editAccess
        }else if(this.props.from === "Radiology"){
            EditAccess = CheckAccessFunc("Radiology", "Billing Transaction", "Day End Denomination", null, "Tab")?.editAccess
        }else if(this.props.from === "Laboratory"){
            EditAccess = true
        }
        return (
            <Box id='eMed_IP_Appointment' sx={{ padding: "2vw" }}>
                <Paper sx={{ height: "71dvh", width: "90dvw" }}>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginX: "1vw", marginTop: "0.5vw" }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                                <Typography >{`Day End Sales Denomination Report | `}</Typography>
                                <Typography marginLeft={"0.5vw"}>{this.state.datePicker ? this.formatDate(this.state.datePicker) : "-"}</Typography>
                            </Box>
                            <Box>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        open={this.state.DatePickerOpen}
                                        onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                                        onClose={() => { this.setState({ DatePickerOpen: false }) }}
                                        value={this.state.datePicker}
                                        name={"datePicker"}
                                        inputFormat='DD-MM-YYYY'
                                        maxDate={new Date()}
                                        views={["year", "month", "day"]}
                                        onChange={
                                            (newDate) => {
                                                this.setState({
                                                    datePicker: newDate?.$d ? newDate?.$d : null
                                                }, () => {
                                                    this.GetUserSalesData()
                                                });
                                            }}
                                        renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '10vw' }} size='small' {...params} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                                    />
                                </LocalizationProvider>
                                <Tooltip title={"Print"} placement='top' arrow>
                                    <IconButton size="small" onClick={() => this.PrintDayEndData()}>
                                        <img
                                            className='eMed_action_img'
                                            src={ImagePaths.LabPrintIcon.default}
                                            alt="print"
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"History"} placement='top' arrow>
                                    <IconButton sx={{}} size="small" onClick={() => this.setState({ isHistoryClicked: true }, this.getHistoryData())}>
                                        <img
                                            className='eMed_action_img'
                                            src={ImagePaths.ResultHistory.default}
                                            alt="history"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', borderRight: "1px solid gray" }}>
                            <Box sx={{ borderRight: "1px solid gray", flex: 0.4 }}>
                                <Typography sx={{ fontWeight: 600, margin: "0.3vw", marginLeft: '1vw' }}>Sales Category</Typography>
                                <TableContainer style={{height: "60vh", overflow: "auto" }}>
                                    <Table size='small' stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "60%" }} id="emedhub_directpoTable_headTxt">Category</TableCell>
                                                <TableCell sx={{ width: "40%" }} id="emedhub_directpoTable_headTxt">Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {from === "Pharmacy" ?
                                            <TableRow>
                                                <TableCell>Total Sales </TableCell>
                                                <TableCell>{this.returnAmountTextBox("total_sales")}</TableCell>
                                            </TableRow>
                                            : null }
                                            <TableRow>
                                                <TableCell>Total Cash </TableCell>
                                                <TableCell>{this.returnAmountTextBox("total_cash")}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total Card </TableCell>
                                                <TableCell>{this.returnAmountTextBox("total_card")}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total UPI </TableCell>
                                                <TableCell>{this.returnAmountTextBox("total_upi")}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total Bank Transfer </TableCell>
                                                <TableCell>{this.returnAmountTextBox("total_banktransfer")}</TableCell>
                                            </TableRow>
                                            { from !== "Pharmacy" ?
                                            <TableRow>
                                                <TableCell>Total Cheque </TableCell>
                                                <TableCell>{this.returnAmountTextBox("total_cheque")}</TableCell>
                                            </TableRow> : null}
                                            {from === "Pharmacy" ?
                                            <TableRow>
                                                <TableCell>Total Credit </TableCell>
                                                <TableCell>{this.returnAmountTextBox("total_credit")}</TableCell>
                                            </TableRow>
                                            : null }
                                            {from === "Pharmacy" ?
                                            <TableRow>
                                                <TableCell>Total Return </TableCell>
                                                <TableCell>{this.returnAmountTextBox("total_return")}</TableCell>
                                            </TableRow>
                                            : null }
                                            <TableRow>
                                                <TableCell>Total Amount </TableCell>
                                                <TableCell>{this.returnAmountTextBox("total")}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box sx={{ flex: 0.6 }}>
                                <Typography sx={{ fontWeight: 600, margin: "0.3vw", marginLeft: '1vw' }}>Cash Denomination</Typography>
                                <Box component={"div"} className="eMed_CrdPop_Denomi_div_New" sx={{ backgroundColor: 'white', width: '100%', height: '50dvh' }}>
                                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div" sx={{ marginTop: "1vw" }}>
                                        {this.renderCountsText("2000", "TwoThounsandCounts")}
                                        {this.renderCountsText("20", "TwentyCounts", true)}
                                    </Box>
                                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div" sx={{ marginTop: "1vw" }}>
                                        {this.renderCountsText("500", "FiveHundredCounts")}
                                        {this.renderCountsText("10", "TenCounts", true)}
                                    </Box>
                                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div" sx={{ marginTop: "1vw" }}>
                                        {this.renderCountsText("200", "TwoHundredCounts")}
                                        {this.renderCountsText("5", "FiveCount", true)}
                                    </Box>
                                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div" sx={{ marginTop: "1vw" }}>
                                        {this.renderCountsText("100", "HundredCounts")}
                                        {this.renderCountsText("2", "TwoCounts", true)}
                                    </Box>
                                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div" sx={{ marginTop: "1vw" }}>
                                        {this.renderCountsText("50", "FiftyCounts")}
                                        {this.renderCountsText("1", "OneCounts", true)}
                                    </Box>
                                    <Box component={'div'} display={"flex"} flexDirection={"row"} margin={"1vw"} justifyContent={'center'} marginTop={"1.5vw"}>
                                        <Typography>{"Total"}<b style={{ marginLeft: '1vw', fontSize: '1.2vw' }}>{AmountFormat(isNaN(this.returnTotal()) ? 0 : this.returnTotal())}</b></Typography>
                                    </Box>
                                    {((+this.state.total_cash !== +this.returnTotal()) && (+this.returnTotal() != 0)) ?
                                    <Box component={'div'} display={"flex"} flexDirection={"row"} margin={"1vw"}  justifyContent={'center'} marginTop={"1.5vw"}>
                                        <Typography style= {{color: "red", fontSize: "1vw"}}>{"Note: Total Cash amount and cash denomination values not matched."} </Typography>
                                    </Box> : null }
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center', width: "100%",marginTop: "0.5vw", visibility: ((RoleData?.is_user && this.isNotToday(states?.datePicker)) || !EditAccess) ? 'hidden' : "visible" }}>
                                    <Button size='small' variant='outlined' onClick={() => { this.ClearAlldata() }}>Clear</Button>
                                    {/* <Button size='small' sx={{ marginLeft: "1vw" }} variant='outlined'>Edit</Button> */}
                                    <Button size='small' sx={{ marginLeft: "1vw" }} variant='contained' onClick={() => {
                                            if (this.state.DataId) {
                                                this.setState({
                                                    EditCommentsPop: true
                                                })
                                            } else {
                                                this.PostDayEndDenomination()
                                            }
                                    }}>Save</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {(this.state.isHistoryClicked) ?
                    <CommonPopUp width={"75vw"}
                        backgroundColor={Colors.white}
                        popUpClose={this.historyPopUpClose.bind(this)}
                        title={t("History")}
                        component={this._getHistory.bind(this)} />
                    : null
                }
                {this.state.EditCommentsPop ? 
                    <ReasonPopupWithRefund
                        title={"Comments"}
                        AlertPopupClose={this.historyPopUpClose.bind(this)}
                        label={"Enter the reason for edit"}
                        ReasonError={"Enter the reason for edit"}
                        btntext={'Close'}
                        btnvarient={'outlined'}
                        btncolor={'error'}
                        btntext1={'Confirm'}
                        btnvarient1={'contained'}
                        sendCmt={this.PostDayEndDenomination.bind(this)}/>
                : null     
                }
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(UserDayEndSalesReport);