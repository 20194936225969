import React, { Component } from 'react';
import {
    Box, Button, MenuItem, Paper, Stack, Tooltip, Menu, IconButton, Grow, Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next'
import './Billings.css';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import Drawer from '@mui/material/Drawer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BillingsFilter from './BillingsFilter';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import PrintPreviewPop from '../../../Components/Common Components/PrintPreviewPop';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc, ContentInTooltip, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions';
import { CommonGridToolBarWithFilterTextCustom } from '../../../Components/Common Components/CommonComponents';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../Styles/Colors';
import ChangePaymentMode from './ChangePaymentMode';
import { CurrencySymbol } from '../../../Utility/Constants';

class ReceiptBills extends Component {
    constructor(props) {
        super(props)
        let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null
        let PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)).map((list) => list?.pharmacy_id) : []
        let LabList = LoggedData?.laboratory_data ? JSON.parse(JSON.stringify(LoggedData?.laboratory_data)).map((list) => list?.laboratory_id) : []
        this.state = {
            page: 0,
            pageSize: 10,
            BillList: [],
            FilterOpen: false,
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "PaidAmount": "",
                "BillNumber": "",
                "PatientName": "",
                "InPatientSelected": true,
                "OutPatientSelected": true,
                "PharmacySelected": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "LaboratorySelected": true,
                "BillType": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? ['op', 'ip', 'lab','radiology'] : localGetItem("Show_Radiology_Bills_In_FO") === "false" ? ['op', 'ip', 'pharmacy', 'lab'] : ['op', 'ip', 'pharmacy', 'lab','radiology'],
                "PayMethods": [1, 2, 3, 4, 5, 6],
                "CardSelected": true,
                "CashSelected": true,
                "UPISelected": true,
                "BankTransferSelected": true,
                "InsuranceSelected": true,
                "ChequeSelected": true,
                "combined": true,
                "BillReceiptSelected": true,
                "AdvanceTypeSelected": true,
                "AdvanceRefundTypeSelected": true,
                "RefundTypeSelected": true,
                "CancelledTypeSelected": true,
                "RecieptType": ['bill', 'advance', 'refund', 'cancelled', 'advance refund'],
                "startTime": null,
                "endTime": null,
                "days":null,
                "OPBills" : true,
                "IPBills" : true,
                "LabBills" : true,
                "PharmaBills" : localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "new_Receipt_Type" : ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-", 'ot'],
                "BillModuleType": ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-", 'ot'],
                "opCreditBills" : true,
                "ipCreditBills" : true,
                "pharmaCreditBills" : localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "labCreditBills" : true,
                "AgainstCredit": 'both',
                "RadiologyBills":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "RadiologySelected":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "radiologyCreditBills":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "includeFObills" : false,
                "ReceiptFrom": "",
                "ReceiptTo": "",
                "refundNoFrom":"",
                "refundNoTo":"",
                "returnFrom":'',
                "returnTo":'',
                'UserList': [],
                'UserDetails': [],
                'MultiPharmaIds': localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? [] : PharmacyList,
                'MultiLabIds': LabList,
            },
            totalAmount: 0,
            noofReceipt: 0,
            BillPreviewPop: false,
            showBox: false,
            cash_amount: 0,
            card_amount: 0,
            upi_amount: 0,
            cheque_amount: 0,
            bank_amount: 0,
            insurance_amount: 0,
            showRFcard: false,
            totalRefunrAmt: 0,
            cash_Refund: 0,
            upi_Refund: 0,
            bank_Refund: 0,
            isPharmacy: false,
            ChangePaymentPopup: false,
            showNFcard: false,
            net_total: 0,
            net_cash: 0,
            net_upi: 0,
            net_bank: 0,
            showCancelcard: false,
            total_cancell: 0,
            cash_cancell: 0,
            upi_cancell: 0,
            bank_cancell: 0,
        }
    }

    componentDidMount() {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser
        this.setState({
            isPharmacy: UserData?.module_name == "Pharmacy" ? true : false
        }, () => { this.getReceiptBills()
            this.GetUserDetails()
         })
        document.addEventListener('keydown', this.handleKeyDown);
    }

    GetUserDetails = () => {
        let states = this.state
        try {
            RestAPIService.getAll(Serviceurls.DAY_END_USER_DETAIL_GET + `?show_all=true`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        states.FilterDatas["UserList"] =  response.data.data
                        this.setState({
                            states
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }

                })
        } catch (error) {
            this.errorMessage(error)
        }
    }


    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        if (event.ctrlKey && event.keyCode === 80) {
            event.preventDefault()
            this.PrintReport()
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getReceiptBills = () => {
        try {
            var states = this.state
            let User_ID = []
            states.FilterDatas.UserDetails.forEach(element => User_ID.push(element.id))
            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS)+".000";
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS)+".000";
            let pharmaSetStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let pharmaSetEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let ColledtedIn = states?.FilterDatas?.BillType ? states.FilterDatas.BillType.map((item) => (item.toUpperCase())) : []
            let NewReceiptType = states?.FilterDatas?.new_Receipt_Type ? states.FilterDatas.new_Receipt_Type.map((item) => (item.toUpperCase())) : []
            let againstCredit = states.FilterDatas.AgainstCredit === 'both' ? '' : "&against_credit=" + states.FilterDatas.AgainstCredit

            this.LoaderFunction(true)
            let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null
            let PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)).map((list) => list?.pharmacy_id) : []
            let sentAllPharmaID = ((ColledtedIn.includes("OP") || ColledtedIn.includes("IP")) && (!ColledtedIn.includes("PHARMACY"))) // hard fix for masonic issue
            RestAPIService.getAll(states.isPharmacy ? Serviceurls.PHARMACY_RECEIPT_BILL_LIST +
                "?from_date=" + states.FilterDatas.FromDate + "&to_date=" + states.FilterDatas.ToDate + "&start_time=" + pharmaSetStartTime + "&end_time=" + pharmaSetEndTime +
                "&is_combined=" + states.FilterDatas.combined + "&payment_method=" + states.FilterDatas.PayMethods + "&receipt_type=" + states.FilterDatas.RecieptType +
                "&patient_name=" + states.FilterDatas.PatientName + "&paid_amount=" + states.FilterDatas.PaidAmount + "&recept_number=" + states.FilterDatas.BillNumber + 
                againstCredit + "&receipt_no_from=" + states.FilterDatas.ReceiptFrom + "&receipt_no_to=" + states.FilterDatas.ReceiptTo + '&return_no_from=' + states.FilterDatas.returnFrom + 
                "&return_no_to=" + states.FilterDatas.returnTo + "&collected_in=" + (states.FilterDatas.includeFObills ? ["FO PH", "PHARMACY"] : ["PHARMACY"])
                :
                Serviceurls.RECEIPT_REPORT +
                "?collected_in=" + ColledtedIn +
                "&receipt_type=" + NewReceiptType +
                "&recept_number=" + states.FilterDatas.BillNumber +
                "&from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&paid_amount=" + states.FilterDatas.PaidAmount +
                "&patient_name=" + states.FilterDatas.PatientName +
                "&payment_method=" + states.FilterDatas.PayMethods +
                "&receipt_for=" + states.FilterDatas.RecieptType +
                "&is_combined=" + states.FilterDatas.combined +
                // "&bills=" + states?.FilterDatas?.BillModuleType +
                "&start_time=" + setStartTime +
                "&end_time=" + setEndTime + againstCredit + 
                "&receipt_no_from=" + states.FilterDatas?.ReceiptFrom + 
                "&receipt_no_to=" + states.FilterDatas?.ReceiptTo +
                // "&return_no_from=" + states.FilterDatas?.returnFrom + 
                // "&return_no_to=" + states.FilterDatas?.returnTo
                // "&refund_no_from=" + states.FilterDatas?.refundNoFrom + 
                // "&refund_no_to=" + states.FilterDatas?.refundNoTo
                "&user_id=" + User_ID +
                "&pharmacy_id=" + (sentAllPharmaID ? PharmacyList : states?.FilterDatas?.MultiPharmaIds) +
                "&laboratory_id=" + states?.FilterDatas?.MultiLabIds
                )
                .then(response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            BillList: response.data.data ? response.data.data : [],
                            totalAmount: response.data.total_amount ? response.data.total_amount : 0,
                            noofReceipt: response.data.total_receipt ? response.data.total_receipt : 0,
                            cash_amount: response.data.cash_amount ? response.data.cash_amount : 0,
                            card_amount: response.data.card_amount ? response.data.card_amount : 0,
                            upi_amount: response.data.upi_amount ? response.data.upi_amount : 0,
                            bank_amount: response.data.bank_amount ? response.data.bank_amount : 0,
                            cheque_amount: response.data.cheque_amount ? response.data.cheque_amount : 0,
                            insurance_amount: response.data.insurance_amount ? response.data.insurance_amount : 0,
                            totalRefunrAmt: states.isPharmacy ? (response.data.total_return ? response.data.total_return : 0) : (response.data.refund_amount ? response.data.refund_amount : 0),
                            cash_Refund: states.isPharmacy ? (response.data.return_cash ? response.data.return_cash : 0) : (response.data.total_cash_refund ? response.data.total_cash_refund : 0),
                            upi_Refund: states.isPharmacy ? (response.data.return_upi ? response.data.return_upi : 0) : (response.data.total_upi_refund ? response.data.total_upi_refund : 0),
                            bank_Refund: states.isPharmacy ? (response.data.return_bank ? response.data.return_bank : 0) : (response.data.total_bank_refund ? response.data.total_bank_refund : 0),
                            net_total: response.data.net_total ? response.data.net_total : 0,
                            net_cash: response.data.net_cash ? response.data.net_cash : 0,
                            net_upi: response.data.net_upi ? response.data.net_upi : 0,
                            net_bank: response.data.net_bank ? response.data.net_bank : 0,
                            total_cancell: response.data.cancel_amount ? response.data.cancel_amount : 0,
                            cash_cancell: response.data.total_cash_cancel ? response.data.total_cash_cancel : 0,                            
                            upi_cancell: response.data.total_upi_cancel ? response.data.total_upi_cancel : 0,                        
                            bank_cancell: response.data.total_bank_cancel ? response.data.total_bank_cancel : 0,                        
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false)
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(e.message)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    PrintReport = () => {
        try {
            var states = this.state
            let User_ID = []
            states.FilterDatas.UserDetails.forEach(element => User_ID.push(element.id))
            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS)+".000";
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS)+".000";
            let pharmaSetStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let pharmaSetEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let APIURl = ""
            let ColledtedIn = states?.FilterDatas?.BillType ? states.FilterDatas.BillType.map((item) => (item.toUpperCase())) : []
            let NewReceiptType = states?.FilterDatas?.new_Receipt_Type ? states.FilterDatas.new_Receipt_Type.map((item) => (item.toUpperCase())) : []
            let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null
            let PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)).map((list) => list?.pharmacy_id) : []
            let sentAllPharmaID = ((ColledtedIn.includes("OP") || ColledtedIn.includes("IP")) && (!ColledtedIn.includes("PHARMACY"))) // hard fix for masonic issue
            if (this.state.isPharmacy) {
                APIURl = Serviceurls.PHARMACY_RECEIPT_BILL_LIST +
                    "?from_date=" + states.FilterDatas.FromDate + "&to_date=" + states.FilterDatas.ToDate + "&start_time=" + pharmaSetStartTime + "&end_time=" + pharmaSetEndTime +
                    "&is_combined=" + states.FilterDatas.combined + "&payment_method=" + states.FilterDatas.PayMethods + "&receipt_type=" + states.FilterDatas.RecieptType +
                    "&patient_name=" + states.FilterDatas.PatientName + "&paid_amount=" + states.FilterDatas.PaidAmount + "&recept_number=" + states.FilterDatas.BillNumber + 
                    "&receipt_no_from=" + states.FilterDatas.ReceiptFrom + "&receipt_no_to=" + states.FilterDatas.ReceiptTo + '&return_no_from=' + states.FilterDatas.returnFrom + 
                    "&return_no_to=" + states.FilterDatas.returnTo + "&is_from_dayend=" + (this.props?.isForReports ? true : false) + "&collected_in=" + (states.FilterDatas.includeFObills ? ["FO PH", "PHARMACY"] : ["PHARMACY"])
            } else {
                APIURl = Serviceurls.RECEIPT_REPORT +
                    "?collected_in=" + ColledtedIn +
                    "&receipt_type=" + NewReceiptType +
                    "&recept_number=" + states.FilterDatas.BillNumber +
                    "&from_date=" + states.FilterDatas.FromDate +
                    "&to_date=" + states.FilterDatas.ToDate +
                    "&paid_amount=" + states.FilterDatas.PaidAmount +
                    "&patient_name=" + states.FilterDatas.PatientName +
                    "&payment_method=" + states.FilterDatas.PayMethods +
                    "&receipt_for=" + states.FilterDatas.RecieptType +
                    "&is_combined=" + states.FilterDatas.combined +
                    "&start_time=" + setStartTime +
                    "&receipt_no_from=" + states.FilterDatas?.ReceiptFrom + 
                    "&receipt_no_to=" + states.FilterDatas?.ReceiptTo + 
                    "&end_time=" + setEndTime +
                    "&user_id=" + User_ID +
                    "&pharmacy_id=" + (sentAllPharmaID ? PharmacyList : states?.FilterDatas?.MultiPharmaIds) +
                    "&laboratory_id=" + states?.FilterDatas?.MultiLabIds
            }
            this.setState({disableBtn: true})
            RestAPIService.getAll(APIURl + "&export_type=pdf")
                .then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    this.errorMessage(error.response?.data?.message)
                    this.setState({disableBtn: false})
                })
        } catch (e) {
            this.errorMessage(e.message)
            this.setState({disableBtn: false})
        }
    }

    exportReport = () => {
        try {
            var states = this.state
            let User_ID = []
            states.FilterDatas.UserDetails.forEach(element => User_ID.push(element.id))
            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS)+".000";
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS)+".000";
            let pharmaSetStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let pharmaSetEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let APIURl = ""
            let ColledtedIn = states?.FilterDatas?.BillType ? states.FilterDatas.BillType.map((item) => (item.toUpperCase())) : []
            let NewReceiptType = states?.FilterDatas?.new_Receipt_Type ? states.FilterDatas.new_Receipt_Type.map((item) => (item.toUpperCase())) : []
            let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null
            let PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)).map((list) => list?.pharmacy_id) : []
            let sentAllPharmaID = ((ColledtedIn.includes("OP") || ColledtedIn.includes("IP")) && (!ColledtedIn.includes("PHARMACY"))) // hard fix for masonic issue            
            if (this.state.isPharmacy) {
                APIURl = Serviceurls.PHARMACY_RECEIPT_BILL_LIST +
                    "?from_date=" + states.FilterDatas.FromDate + "&to_date=" + states.FilterDatas.ToDate + "&start_time=" + pharmaSetStartTime + "&end_time=" + pharmaSetEndTime +
                    "&is_combined=" + states.FilterDatas.combined + "&payment_method=" + states.FilterDatas.PayMethods + "&receipt_type=" + states.FilterDatas.RecieptType +
                    "&patient_name=" + states.FilterDatas.PatientName + "&paid_amount=" + states.FilterDatas.PaidAmount + "&recept_number=" + states.FilterDatas.BillNumber + "&collected_in=" + (states.FilterDatas.includeFObills ? ["FO PH", "PHARMACY"] : ["PHARMACY"]) +
                    "&receipt_no_from=" + states.FilterDatas.ReceiptFrom + "&receipt_no_to=" + states.FilterDatas.ReceiptTo + '&return_no_from=' + states.FilterDatas.returnFrom + 
                    "&return_no_to=" + states.FilterDatas.returnTo 
            } else {
                APIURl = Serviceurls.RECEIPT_REPORT +
                    "?collected_in=" + ColledtedIn +
                    "&receipt_type=" + NewReceiptType +
                    "&recept_number=" + states.FilterDatas.BillNumber +
                    "&from_date=" + states.FilterDatas.FromDate +
                    "&to_date=" + states.FilterDatas.ToDate +
                    "&paid_amount=" + states.FilterDatas.PaidAmount +
                    "&patient_name=" + states.FilterDatas.PatientName +
                    "&payment_method=" + states.FilterDatas.PayMethods +
                    "&receipt_for=" + states.FilterDatas.RecieptType +
                    "&is_combined=" + states.FilterDatas.combined +
                    "&start_time=" + setStartTime +
                    "&receipt_no_from=" + states.FilterDatas?.ReceiptFrom + 
                    "&receipt_no_to=" + states.FilterDatas?.ReceiptTo + 
                    "&end_time=" + setEndTime + 
                    "&user_id=" + User_ID +
                    "&pharmacy_id=" + (sentAllPharmaID ? PharmacyList : states?.FilterDatas?.MultiPharmaIds) +
                    "&laboratory_id=" + states?.FilterDatas?.MultiLabIds
            }
            RestAPIService.excelGet(APIURl + "&export_type=excel").
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }


    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
            ChangePaymentPopup: false,
        }, () => {
            if (Getapicall && message !== "") {
                this.successMessage(message)
                this.getReceiptBills()
            }
        })
    }
    MenuItem = (data) => {
        const { t } = this.props
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        let PaymentChangeAccess = RoleData?.is_user ? (RoleData?.permission_access?.common?.change_payment_mode ? RoleData?.permission_access?.common?.change_payment_mode : false) : true
        const multi_option = [
            { value: "ChangePaymentMode", label: t("Change Payment Mode") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        disabled={this.state.ChangePaymentPopup}
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option}
                                disabled={option.value === "ChangePaymentMode" ? ((data?.row?.amount_received && PaymentChangeAccess) ? false : true) : false}
                                onClick={() => {
                                    this.setState({ anchorEl: null }, () => {
                                        if (option.value === "ChangePaymentMode") {
                                            this.setState({ ChangePaymentPopup: true, SelectedBill: data?.row })
                                        }
                                    })
                                }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => { this.getReceiptBills() })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilterDatas.FromDate) },
            { label: "To Date", value: formatDate(this.state.FilterDatas.ToDate) },
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
    
        var ExportData = [];
        if (this.state.isPharmacy) {    
            this.state.BillList?.length > 0 && this.state.BillList.map((item) => {
                ExportData.push({
                    'Receipt Details':item.receipt_number,
                    'Patient Details':item.patient_name,
                    "Bill No.":item.description?.replace(/\n/g, '| '),
                    'Bill Type':item.receipt_type,
                    'Receipt For':item.receipt_for,
                    'Payment Details':item.amount_received,
                    'Collected By':item.created_by,
                });
            });
        } else {
            this.state.BillList?.length > 0 && this.state.BillList.map((item) => {
                ExportData.push({ 
                    'Receipt Details': item.receipt_number, 
                    'Patient Details': item.patient_name, 
                    "Bill No.": item.description?.replace(/\n/g, '| ')?.replace(",","."),
                    'Bill Type': item.receipt_type ,
                    'Receipt For': item.receipt_for,
                    'Payment Details': item.amount_received ,
                    'Collected By': item.created_by, 
                });
            });
        }
        return (
            <Box>
                <CommonGridToolBarWithFilterTextCustom data={ExportData} title={this.state.isPharmacy ? "Pharmacy Receipt Bills" : "FO Receipt Bills"} FilterTextArray={TextArray} filename={"ReceiptBills"} />
            </Box>
        );
    }
    

    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    PaymentModeChanged(message) {
        this.setState({
            ChangePaymentPopup: false,
        }, () => {
            this.successMessage(message)
            this.getReceiptBills()
        })
    }

    render() {
        const { t } = this.props
        this.state.BillList.forEach((element, index) => element.sno = index + 1)
        // Commented for New UI has Changed
        // const columns = [
        //     {
        //         field: "invoice_date", flex: 0.08, headerName: t("RDate"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date?.length > 10 ?
        //             <Tooltip placement='top' title={params?.row?.invoice_date}><div>{params?.row?.invoice_date?.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.invoice_date ? params?.row?.invoice_date : "-"}</Box>)
        //     },
        //     {
        //         field: "receipt_number", flex: 0.09, headerName: t("RNo"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.receipt_number ? params.row.receipt_number : "-"}</Box>)
        //     },
        //     {
        //         field: "description", flex: 0.12, headerName: `${t("Description")}`, headerAlign: "center", align: "center",
        //         renderCell: (params) => {
        //             const billArr = params?.row?.description ? params?.row?.description?.split("/") : [];
        //             return (
        //                 <Tooltip placement='top' title={params?.row?.description} arrow>
        //                     <Box component={'div'}>{billArr?.length > 0 ? '...' + billArr?.slice(-2)?.join("/") : "-"}</Box>
        //                 </Tooltip>
        //             )}
        //     },
        //     {
        //         field: "receipt_type", flex: 0.11, headerName: t("BillType"),headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box textAlign={"center"}>
        //             <Typography fontSize={"0.9vw"} >{params?.row?.receipt_type ? params?.row?.receipt_type : "-"}</Typography>
        //             <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>{` Paid in : ${params?.row?.collected_in ? params?.row?.collected_in : "-"}`}</Typography>
        //         </Box>)
        //     },
        //     {
        //         field: "receipt_for", flex: 0.08, headerName: t("ReceiptType"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'} textAlign={"center"}>{params?.row?.receipt_for ? (params?.row?.receipt_for === "Bill Receipt" && params?.row?.against_credit) ? <Box>
        //             <Typography fontSize={"0.9vw"}>Bill Receipt</Typography>
        //             <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>Against Credit</Typography>
        //         </Box> : params?.row?.receipt_for : "-"}</Box>)
        //     },
        //     {
        //         field: "patient_name", flex: 0.111, headerName: t("PatientName"),
        //         renderCell: (params) => (<Box component={'div'}>
        //             {params?.row?.patient_name ? params?.row?.patient_name?.length > 15 ?
        //                 <Tooltip placement='top' title={params?.row?.patient_name} arrow>
        //                     <div>{params?.row?.patient_name.slice(0, 15) + "..."}</div></Tooltip> :
        //                 params?.row?.patient_name : "-"}</Box>)
        //     },
        //     {
        //         field: "patient_uhid", flex: 0.1, headerName: t("UHID"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>
        //             {params?.row?.patient_uhid ? params?.row?.patient_uhid?.length > 15 ?
        //                 <Tooltip placement='top' title={params?.row?.patient_uhid} arrow>
        //                     <div>{params?.row?.patient_uhid.slice(0, 15) + "..."}</div></Tooltip> :
        //                 params?.row?.patient_uhid : "-"}</Box>)
        //     },
        //     {
        //         field: "payment_mode_types", flex: 0.09, headerName: t("PaymentType"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>
        //             {params?.row?.payment_mode_types ? params?.row?.payment_mode_types?.length > 14 ?
        //                 <Tooltip placement='top' title={params?.row?.payment_mode_types} arrow>
        //                     <div>{params?.row?.payment_mode_types.slice(0, 12) + "..."}</div></Tooltip> :
        //                 params?.row?.payment_mode_types : "-"}</Box>)
        //     },
        //     {
        //         field: "amount_received", flex: 0.08, headerName: `${t("Amount")} (${CurrencySymbol})`, type: "number",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.amount_received ? AmountFormat(params.row.amount_received)?.replace(`${CurrencySymbol}`, "") : "0"}</Box>)
        //     },
        //     {
        //         field: "created_by", flex: 0.10, headerName: t("CollecBy"),
        //         renderCell: (params) => (<Box component={'div'}>
        //             {params?.row?.created_by ? params?.row?.created_by?.length > 18 ?
        //                 <Tooltip placement='top' title={params?.row?.created_by} arrow>
        //                     <div>{params?.row?.created_by.slice(0, 18) + "..."}</div></Tooltip> :
        //                 params?.row?.created_by : "-"}</Box>)
        //     },
        //     {
        //         field: "action", flex: 0.08, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        //         renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        //             <Tooltip title={t("PrintBill")} placement="top" arrow>
        //                 <Button onClick={() => { this.setState({ BillPreviewPop: true, SelectedBill: params.row }) }} className='eMed_usrconf_btn'>
        //                     <img src={ImagePaths.PrintIcons.default} alt="Create Bill" className='eMed_action_img' />
        //                 </Button>
        //             </Tooltip>
        //             {this.MenuItem(params)}
        //         </Box>
        //     }
        // ]

        const columns = [
            {
                field: 'receipt_number', headerName: 'Receipt Details', flex: 0.125,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_lab_rcpt_dtls'>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.receipt_number ?
                                params?.row?.receipt_number?.length > 18 ?
                                    <Tooltip placement='top' title={params?.row?.receipt_number}><div style={{ fontSize: '0.9vw', fontWeight: 600 }}>{ '...' + params?.row?.receipt_number?.slice(-10)}</div></Tooltip> :
                                    params?.row?.receipt_number : '-'}</Typography>
                            <Typography color={Colors.grayShade} fontSize={'0.8vw'}>{params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: 'patient_name', headerName: 'Patient Details', flex: 0.125,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_fo_pat_dtls'>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.patient_name ?
                                params?.row?.patient_name?.length > 15 ?
                                    <Tooltip placement='top' title={params?.row?.patient_name}><div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{params?.row?.patient_name?.slice(0, 15) + '...'}</div></Tooltip> :
                                    params?.row?.patient_name : '-'}</Typography>
                            <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.patient_uhid ? params?.row?.patient_uhid : '-'}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: "description", flex: 0.2, headerName: `${t("Bill No.")}`, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    const billArr = params?.row?.description ? params?.row?.description?.split("/") : [];
                    return (
                        <Tooltip placement='top' title={params?.row?.description} arrow>
                            <Box component={'div'} emed_tid='emed_fo_descp' fontSize={'0.9vw'} fontWeight={600}>{params?.row?.description ? '...' + params?.row?.description.slice(-35) : "-"}</Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'receipt_type', headerName: 'Bill Type', flex: 0.11,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_fo_rcpt_type'>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.receipt_type}</Typography>
                            <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>{` Paid in : ${params?.row?.collected_in ? params?.row?.collected_in : "-"}`}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: 'receipt_for', headerName: 'Receipt For', flex: 0.10,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_fo_rcpt_type'>
                            {params?.row?.receipt_for ? (params?.row?.receipt_for === "Bill Receipt" && params?.row?.against_credit) ? <Box>
                                <Typography fontSize={"0.9vw"} fontWeight={600}>Bill Receipt</Typography>
                                <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>Against Credit</Typography>
                            </Box> : <Typography fontWeight={600} fontSize={'0.9vw'}>{params?.row?.receipt_for ? params?.row?.receipt_for : '-'}</Typography> : "-"}
                        </Box>
                    )
                }
            },
            {
                field: 'amount_received', headerName: `${'Payment Details'} (${CurrencySymbol})`, flex: 0.125, headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_fo_pay_dtls'>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.amount_received ? AmountFormat(params.row.amount_received)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Typography>
                            <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.payment_mode_types ? params?.row?.payment_mode_types?.length > 14 ?
                                <Tooltip placement='top' title={params?.row?.payment_mode_types} arrow>
                                    <div>{params?.row?.payment_mode_types.slice(0, 12) + "..."}</div></Tooltip> :
                                params?.row?.payment_mode_types : "-"}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: 'created_by', headerName: 'Collected By', flex: 0.11,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_fo_coll_by'>
                            {ContentInTooltip((params?.row?.created_by ? params?.row?.created_by : "-"), 15, '0.9vw', 600)}
                            {(params?.row?.pharmacy_name && localGetItem("multiPharmacy") === "true") ? ContentInTooltip(params?.row?.pharmacy_name ? params?.row?.pharmacy_name : "-", 10, "0.8vw") :
                                (params?.row?.laboratory_name && localGetItem("multiLaboratory") === "true") ? ContentInTooltip(params?.row?.laboratory_name ? params?.row?.laboratory_name : "-", 10, "0.8vw") : ""}
                        </Box>
                    )
                }
            },
            {
                field: "action", flex: 0.105, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
                renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <Tooltip title={t("PrintBill")} placement="top" arrow>
                        <Button onClick={() => { this.setState({ BillPreviewPop: true, SelectedBill: params.row }) }} className='eMed_usrconf_btn' emed_tid='emed_fo_rcpt_prt_btn'>
                            <img src={ImagePaths.PrintIcons.default} alt="Create Bill" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                    {this.MenuItem(params)}
                </Box>
            }
        ]

        // const Pharmacycolumns = [
        //     {
        //         field: "invoice_date", flex: 0.111, headerName: t("RDate"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? params?.row?.invoice_date : "-"}</Box>)
        //     },
        //     {
        //         field: "patient_name", flex: 0.1, headerName: t("PatientName"), headerAlign: "left", align: "left",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_name ? params.row.patient_name : "-"}</Box>)
        //     },
        //     {
        //         field: "receipt_number", flex: 0.1, headerName: t("RNo"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.receipt_number ? params.row.receipt_number : "-"}</Box>)
        //     },
        //     {
        //         field: "service_id", flex: 0.14, headerName: `${t("Service ID")}`, headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>
        //             {params?.row?.service_id ? params?.row?.service_id?.length > 22 ?
        //                 <Tooltip placement='top' title={params?.row?.service_id} arrow>
        //                     <div>{params?.row?.service_id.slice(0, 21) + "..."}</div></Tooltip> :
        //                 params?.row?.service_id : "-"}</Box>)
        //     },
        //     {
        //         field: "receipt_type", flex: 0.08, headerName: t("BillType"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.receipt_type ? params?.row?.receipt_type : "-"}</Box>)
        //     },
        //     {
        //         field: "receipt_for", flex: 0.08, headerName: t("ReceiptType"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'} textAlign={"center"}>{params?.row?.receipt_for ? (params?.row?.receipt_for === "Bill" && params?.row?.against_credit) ? <Box>
        //             <Typography fontSize={"0.9vw"}>Bill</Typography>
        //             <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>Against Credit</Typography>
        //         </Box> : params?.row?.receipt_for : "-"}</Box>)
        //     },
        //     {
        //         field: "payment_mode_types", flex: 0.13, headerName: t("PaymentType"), headerAlign: "center", align: "center",
        //         renderCell: (params) => (<Box component={'div'}>
        //             {params?.row?.payment_mode_types ? params?.row?.payment_mode_types?.length > 20 ?
        //                 <Tooltip placement='top' title={params?.row?.payment_mode_types} arrow>
        //                     <div>{params?.row?.payment_mode_types.slice(0, 20) + "..."}</div></Tooltip> :
        //                 params?.row?.payment_mode_types : "-"}</Box>)
        //     },
        //     {
        //         field: "amount_received", flex: 0.08, headerName: `${t("Amount")} (${CurrencySymbol})`, type: "number",
        //         renderCell: (params) => (<Box component={'div'}>{params?.row?.amount_received ? AmountFormat(params.row.amount_received)?.replace(`${CurrencySymbol}`, "") : "0"}</Box>)
        //     },
        //     {
        //         field: "action", flex: 0.08, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        //         renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        //             <Tooltip title={t("PrintBill")} placement="top" arrow>
        //                 <Button emed_tid={'rcp_prt_btn'} onClick={() => { this.setState({ BillPreviewPop: true, SelectedBill: params.row }) }} className='eMed_usrconf_btn'>
        //                     <img src={ImagePaths.PrintIcons.default} alt="Create Bill" className='eMed_action_img' />
        //                 </Button>
        //             </Tooltip>
        //             {this.props?.isForReports ? null : this.MenuItem(params)}
        //         </Box>
        //     }
        // ]

        const Pharmacycolumns = [
            {
                field: 'receipt_number', headerName: 'Receipt Details', flex: 0.125,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_lab_rcpt_dtls'>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.receipt_number ?
                                params?.row?.receipt_number?.length > 18 ?
                                    <Tooltip placement='top' title={params?.row?.receipt_number}><div style={{ fontSize: '0.9vw', fontWeight: 600 }}>{ "..." + params?.row?.receipt_number?.slice(-10)}</div></Tooltip> :
                                    params?.row?.receipt_number : '-'}</Typography>
                            <Typography color={Colors.grayShade} fontSize={'0.8vw'}>{params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: 'patient_name', headerName: 'Patient Details', flex: 0.125,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_phar_pat_dtls'>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.patient_name ?
                                params?.row?.patient_name?.length > 15 ?
                                    <Tooltip placement='top' title={params?.row?.patient_name}><div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{params?.row?.patient_name?.slice(0, 15) + '...'}</div></Tooltip> :
                                    params?.row?.patient_name : '-'}</Typography>
                            <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.patient_uhid ? params?.row?.patient_uhid : '-'}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: "description", flex: 0.2, headerName: `${t("Bill No.")}`, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    const billArr = params?.row?.description ? params?.row?.description?.split("/") : [];
                    return (
                        <Tooltip placement='top' title={params?.row?.description} arrow>
                            <Box component={'div'} emed_tid='emed_fo_descp' fontSize={'0.9vw'} fontWeight={600}>{params?.row?.description ? '...' + params?.row?.description.slice(-35) : "-"}</Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'receipt_type', headerName: 'Bill Type', flex: 0.11,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_phar_bill_type'>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.receipt_type}</Typography>
                            <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>{` Paid in : ${params?.row?.collected_in ? params?.row?.collected_in : "-"}`}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: 'receipt_for', headerName: 'Receipt For', flex: 0.10,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_phar_rcpt_type'>
                            {params?.row?.receipt_for ? (params?.row?.receipt_for === "Bill Receipt" && params?.row?.against_credit) ? <Box>
                                <Typography fontSize={"0.9vw"} fontWeight={600}>Bill Receipt</Typography>
                                <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>Against Credit</Typography>
                            </Box> : <Typography fontWeight={600} fontSize={'0.9vw'}>{params?.row?.receipt_for ? params?.row?.receipt_for : '-'}</Typography> : "-"}
                        </Box>
                    )
                }
            },
            {
                field: 'amount_received', headerName: `${'Payment Details'} (${CurrencySymbol})`, flex: 0.125, headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_phar_pay_dtls'>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.amount_received ? AmountFormat(params.row.amount_received)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Typography>
                            <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.payment_mode_types ? params?.row?.payment_mode_types?.length > 14 ?
                                <Tooltip placement='top' title={params?.row?.payment_mode_types} arrow>
                                    <div>{params?.row?.payment_mode_types.slice(0, 12) + "..."}</div></Tooltip> :
                                params?.row?.payment_mode_types : "-"}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: 'created_by', headerName: 'Collected By', flex: 0.11,
                renderCell: (params) => {
                    return (
                        <Box component={'div'} emed_tid='emed_phar_coll_by'>
                            <Typography fontWeight={600} fontSize={'0.9vw'}>{params?.row?.created_by ? params?.row?.created_by?.length > 15 ?
                                <Tooltip placement='top' title={params?.row?.created_by}><div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{params?.row?.created_by?.slice(0, 15) + '...'}</div></Tooltip> :
                                params?.row?.created_by : '-'}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: "action", flex: 0.105, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
                renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <Tooltip title={t("PrintBill")} placement="top" arrow>
                        <Button onClick={() => { this.setState({ BillPreviewPop: true, SelectedBill: params.row }) }} className='eMed_usrconf_btn' emed_tid='emed_phar_rcpt_prt_btn'>
                            <img src={ImagePaths.PrintIcons.default} alt="Create Bill" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                    {this.MenuItem(params)}
                </Box>
            }
        ]
        return (
            <Box component={"div"} className='eMed_Main_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Receiptcon'  sx={{ flex: 0.97 }}>
                        {AmountsCard(t("NR"), this.state.noofReceipt, true, "white", false, null, 'rec_no_recpt')}
                        <Button className='eMed_Recpt_header_amt_card' sx={{ marginLeft: "0.1vw", }} onClick={() => { this.setState({ showBox: !this.state.showBox, showRFcard: false, showNFcard: false, showCancelcard: false, }) }}>{AmountsCard(t("ReceivedAmount"), this.state.totalAmount, false, this.state.showBox ? Colors.Primary : "white", true, this.state.showBox ? "white" : Colors.Primary, 'rec_recev_amt')}</Button>
                        <Button className='eMed_Recpt_header_amt_card'  onClick={() => { this.setState({ showRFcard: !this.state.showRFcard, showBox: false, showNFcard: false, showCancelcard: false, }) }}>{AmountsCard(t(this.state.isPharmacy ? "Returned Amount" : "RefundAmount"), this.state.totalRefunrAmt, false, this.state.showRFcard ? Colors.Primary : "white", true, this.state.showRFcard ? "white" : Colors.Primary, 'rec_retn_amt')}</Button>
                        {this.state.isPharmacy ? null : <Button className='eMed_Recpt_header_amt_card' onClick={() => { this.setState({ showCancelcard: !this.state.showCancelcard, showBox: false, showRFcard: false, showNFcard: false, }) }}>{AmountsCard(t("Cancelled Amount"), this.state.total_cancell, false, this.state.showCancelcard ? Colors.Primary : "white", true, this.state.showCancelcard ? "white" : Colors.Primary, 'rec_cancell_amt')}</Button>}
                        <Button className='eMed_Recpt_header_amt_card' onClick={() => { this.setState({ showNFcard: !this.state.showNFcard, showBox: false, showRFcard: false, showCancelcard: false }) }}>{AmountsCard(t("Net Amount"), this.state.net_total, false, this.state.showNFcard ? Colors.Primary : "white", true, this.state.showNFcard ? "white" : Colors.Primary, 'rec_net_amt')}</Button>
                        <Box component={'div'} sx={{ width: '26vw', overflow: 'scroll', marginLeft: '1vw', height: "5.5vw", display: 'flex', alignItems: 'center'}} >
                            {this.state.showBox ?
                                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 500 } : {})}>{AmountsCard(t("Cash"), this.state.cash_amount, false, "white", false, null, 'rec_recev_cash')}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 1000 } : {})}>{AmountsCard(t("Card"), this.state.card_amount, false, "white", false, null, 'rec_recev_card')}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 1500 } : {})}>{AmountsCard(t("UPI"), this.state.upi_amount, false, "white", false, null, 'rec_recev_upi')}</Grow>
                                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 2000 } : {})}>{AmountsCard(t("Bank"), this.state.bank_amount, false, "white", false, null, 'rec_recev_bank')}</Grow>
                                    {this.state.isPharmacy ? null : <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 2500 } : {})}>{AmountsCard(t("Cheque"), this.state.cheque_amount, false, "white", false, null, 'rec_recev_cheque')}</Grow>}
                                    {/* {this.state.isPharmacy ? null : <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showBox ? { timeout: 3000 } : {})}>{AmountsCard(t("Insurance"), this.state.insurance_amount, false, "white", false, null, 'rec_recev_insurance')}</Grow>} */}
                                </Box> : null}
                            {this.state.showRFcard ?
                                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                    <Grow in={this.state.showRFcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showRFcard ? { timeout: 500 } : {})}>{AmountsCard(t("Cash"), this.state.cash_Refund, false, "white", false, null, 'rec_rtn_cash')}</Grow>
                                    <Grow in={this.state.showRFcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showRFcard ? { timeout: 1000 } : {})}>{AmountsCard(t("UPI"), this.state.upi_Refund, false, "white", false, null, 'rec_rtn_upi')}</Grow>
                                    <Grow in={this.state.showRFcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showRFcard ? { timeout: 1500 } : {})}>{AmountsCard(t("Bank"), this.state.bank_Refund, false, "white", false, null, 'rec_rtn_bank')}</Grow>
                                </Box> : null}
                            {this.state.showNFcard ?
                                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                    <Grow in={this.state.showNFcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showNFcard ? { timeout: 500 } : {})}>{AmountsCard(t("Cash"), this.state.net_cash, false, "white", false, null, 'rec_net_cash')}</Grow>
                                    <Grow in={this.state.showNFcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showNFcard ? { timeout: 1000 } : {})}>{AmountsCard(t("Card"), this.state.card_amount, false, "white", false, null, 'rec_net_card')}</Grow>
                                    <Grow in={this.state.showNFcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showNFcard ? { timeout: 1500 } : {})}>{AmountsCard(t("UPI"), this.state.net_upi, false, "white", false, null, 'rec_net_upi')}</Grow>
                                    <Grow in={this.state.showNFcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showNFcard ? { timeout: 2000 } : {})}>{AmountsCard(t("Bank"), this.state.net_bank, false, "white", false, null, 'rec_net_bank')}</Grow>
                                    {this.state.isPharmacy ? null : <Grow in={this.state.showNFcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showNFcard ? { timeout: 2500 } : {})}>{AmountsCard(t("Cheque"), this.state.cheque_amount, false, "white", false, null, 'rec_net_cheque')}</Grow>}
                                </Box> : null}
                                {!this.state.isPharmacy && this.state.showCancelcard ?
                                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                                    <Grow in={this.state.showCancelcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showCancelcard ? { timeout: 500 } : {})}>{AmountsCard(t("Cash"), this.state.cash_cancell, false, "white", false, null, 'rec_rtn_cash')}</Grow>
                                    <Grow in={this.state.showCancelcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showCancelcard ? { timeout: 1000 } : {})}>{AmountsCard(t("UPI"), this.state.upi_cancell, false, "white", false, null, 'rec_rtn_upi')}</Grow>
                                    <Grow in={this.state.showCancelcard} style={{ transformOrigin: '0 0 0' }}
                                        {...(this.state.showCancelcard ? { timeout: 1500 } : {})}>{AmountsCard(t("Bank"), this.state.bank_cancell, false, "white", false, null, 'rec_rtn_bank')}</Grow>
                                </Box> : null}
                        </Box>
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container' flex={0.03}>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Tooltip title="Filter" placement='top' arrow>
                                <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                    <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                        <Tooltip title="Print" placement='top' arrow>
                            <Button disabled={this.state.disableBtn} onClick={() => { this.PrintReport() }} className='eMed_usrconf_btn' emed_tid='Pharmacy_Receipt_OverAllPrint_Btn'>
                                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                            </Button></Tooltip>
                        <Tooltip title="Export" placement='top' arrow>
                            <Button onClick={() => { this.exportReport() }} className='eMed_usrconf_btn'>
                                <img src={ImagePaths.ExportIcon.default} alt="upload" className='eMed_action_img' />
                            </Button></Tooltip>
                    </Box>
                </Box>
                <Box component={"div"} className='eMed_Table_Container'>
                    <Stack component={"div"}>
                        <Paper className='eMed_Table_Wrapper' >
                            <DataGrid
                                rows={this.state.BillList}
                                classes={{ main: 'recpt_bill' }}
                                columns={this.state.isPharmacy ? Pharmacycolumns : columns}
                                getRowId={(row) => row['sno']}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                hideFooterSelectedRowCount
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                components={{
                                    Toolbar: this.gridToolBar,
                                    NoRowsOverlay: () => (
                                        <Stack className='eMed_conf_nodata'>
                                            {t("NoRecordsFound")}
                                        </Stack>
                                    )
                                }}
                                rowsPerPageOptions={[10, 20, 30]}
                                disableSelectionOnClick
                                pagination
                                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Stack>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <BillingsFilter PageName={"ReceiptBills"} FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} fromReceipt={true} ClearData={this.clearFilter.bind(this)} Pharmacy={this.state.isPharmacy} FromFoReceptsBills={true}/>
                </Drawer>
                {
                    this.state.BillPreviewPop ?
                        <PrintPreviewPop
                            URL={Serviceurls.INVOICE_PRINT}
                            Title={t("BillPreview")}
                            BillData={this.state.SelectedBill}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            ModifyBillButton={CheckAccessFunc("front_office", "Billing Transaction", "Receipt Bills", null, "Tab")?.editAccess ? (this.state.SelectedBill.bill_types === "In Patient" || this.state.SelectedBill.bill_types === "Out Patient") : false}
                            cancelBillButton={CheckAccessFunc("front_office", "Billing Transaction", "Receipt Bills", null, "Tab")?.editAccess ? (this.state.SelectedBill.bill_types === "In Patient" || this.state.SelectedBill.bill_types === "Out Patient") : false}
                            PrintButton={false}
                            Pharmacy={this.state.isPharmacy}
                            history={this.props.history} /> : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {/* <Loader loaderOpen={this.state.isLoader} /> */}
                {
                    this.state.ChangePaymentPopup ? <ChangePaymentMode BillData={this.state.SelectedBill} PaymentModeChanged={this.PaymentModeChanged.bind(this)} ClosePopUp={this.ClosePopUp.bind(this)} /> : null
                }
            </Box>
        )
    }
}

export default withTranslation()(ReceiptBills)