import React, { Component } from "react";
import {
    Box, Tabs, Tab, Modal, Paper, Typography, IconButton, Button,
    DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog,
    Card, Tooltip, TextField, Stack, FormControlLabel, Radio, RadioGroup,
    Checkbox, FormHelperText, Switch, InputAdornment, MenuItem,
    ButtonGroup, CircularProgress, Autocomplete, Badge, styled
} from "@mui/material";
import { ImagePaths } from '../../Utility/ImagePaths';
import {
    DataGrid, GridCsvExportMenuItem, GridToolbarColumnsButton, GridToolbarContainer,
    GridToolbarDensitySelector, GridToolbarExportContainer, GridToolbarFilterButton,
    GridToolbarQuickFilter
} from '@mui/x-data-grid';
import "./commonComponents.css";
import { Colors } from "../../Styles/Colors";
import { useStopwatch } from 'react-timer-hook';
import { useState } from "react";
import { formatDate, OpenDirectPrint, timeOnlyConvert } from "../CommonFunctions/CommonFunctions";
import CommonValidation from "../CommonFunctions/CommonValidation";
import RestAPIService from "../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../Utility/API/Serviceurls";
import { cmt_Char_limit } from "../../Utility/Constants";
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { clearCachevalue, localGetItem } from "../../Utility/Services/CacheProviderService";
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import ToastMsg from "../ToastMsg/ToastMsg";
import '../../App.css';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicOutlinedIcon from '@mui/icons-material/FormatItalicOutlined';
import FormatUnderlinedOutlinedIcon from '@mui/icons-material/FormatUnderlinedOutlined';
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import FormatAlignCenterOutlinedIcon from '@mui/icons-material/FormatAlignCenterOutlined';
import FormatAlignRightOutlinedIcon from '@mui/icons-material/FormatAlignRightOutlined';
import { AmountFormat } from "../CommonFunctions/CommonFunctions";
import VoiceToText from "./VoiceToText";
import  MicIcon from '@mui/icons-material/Mic';
import {  grey } from '@mui/material/colors';
import { text } from "@fortawesome/fontawesome-svg-core";

export class CommonNavigationHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: props.menu && props.menu.length > 0 ? props.menu : [],
            tabId: props.selectedTabID ? props.selectedTabID : 0
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.selectedTabID != this.props.selectedTabID) {
            this.setState({
                tabId: this.props.selectedTabID
            })
        }
    }
    handleChange = (event, newValue) => {
        this.setState({
            tabId: newValue
        }, () => {
            this.props.NavigateScreen(newValue);
        })
    };
    render() {
        let { menu } = this.state
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={this.state.tabId}
                        onChange={this.handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{ minHeight: '1vw' }}
                    >
                        {menu && menu.length > 0 && menu.map((data) => (
                            <Tab sx={{ textTransform: 'capitalize', minHeight: '1vw', fontWeight: 600 }} key={data.id} value={data.id} label={data.TabName} />
                        )
                        )}
                    </Tabs>
                </Box>
            </Box>
        )
    }
}

export class CommonTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.rows.length > 0 ? this.props.rows : [],
            columns: this.props.columns.length > 0 ? this.props.columns : []
        }
    }
    render() {
        return (
            <div style={{ height: "18.75vw", width: '100%' }}>
                <DataGrid rows={this.state.rows} columns={this.state.columns} />
            </div>
        )
    }
}
export class ClickableCard extends Component {
    render() {
        return (
            <Button emed_tid={this.props.testID} className="eMed_Click_card_hover" disabled={this.props.disable}>
                <Card elevation={3} onClick={() => { this.props.onClick() }} className="eMed_Clickable_card" sx={{ backgroundColor: this.props.isSelected ? Colors.ThemeColor : "" }}>
                    <div style={{ color: this.props.isSelected ? "white" : '' }}>{ }</div>
                    <div style={{ color: this.props.isSelected ? "white" : '', }} >
                        <img style={{ width: this.props.width ? this.props.width : "2vw", height: this.props.height ? this.props.height : "2vw" }} src={this.props.icon} alt={this.props.label} />
                    </div>
                    <div className="eMed_Clickable_label" style={{ color: this.props.isSelected ? "white" : '' }}>{this.props.label}</div>
                </Card>
            </Button>
        )
    }
}
export class AppointmentCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Count: this.props.count ? this.props.count : "0",
            Label: this.props.label ? this.props.label : "-",
            Color: this.props.color ? this.props.color : "",
            isSelectedCard: this.props.isSelectedCard ? this.props.isSelectedCard : false
        }
    }
    componentDidUpdate() {
        if (this.props.count != this.state.Count) {
            this.setState({
                Count: this.props.count
            })
        }
    }
    render() {
        return (
            <Button className="eMed_App_Card Common_Btn_Min_Width" sx={{padding:'0.5dvw 0.25dvw'}} onClick={() => { this.props.onClick() }}>
                <Card elevation={3} className='eMed_ApoinmtCards_div' sx={{ backgroundColor: this.props.isSelected ? Colors.EditBtnColor : "" }} emed_tid={this.props.emed_tid}>
                    <div className="eMed_ApoinmtCards_Content">
                        {this.props.icon ? <img className="eMed_ApoinmtCards_icon" src={this.props.icon} alt={this.state.Label} /> : null}
                        <div className="eMed_ApoinmtCards_count">{this.state.Count}</div>
                    </div>
                    <div >{}</div>
                    {this.state.Label?.length > 10 ?
                        <Tooltip title={this.state.Label} placement={'top'} arrow>
                            <div className="eMed_ApoinmtCards_label">{(this.state.Label ? this.state.Label?.slice(0, 10) + '...' : '-')}</div>
                        </Tooltip> :
                        <div className="eMed_ApoinmtCards_label">{this.state.Label ? this.state.Label : '-'}</div>}
                    <div className="eMed_ApoinmtCards_color" style={{ backgroundColor: this.props.color, height: this.props.isSelectedCard ? '15%' : '5%' }}></div>
                </Card>
            </Button>
        )
    }
}
export class CommonEditButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size: this.props.size ? this.props.size : "1.3vw"
        }
    }
    render() {
        return (
            <Tooltip title="Edit" placement="top" arrow>
                <Button emed_tid={this.props.testID} className="eMed_Edit_btn eMed_usrconf_btn" disabled={this.props.disable} size='small'>
                    <img src={this.props.disable ? ImagePaths.DisabledEdit.default : ImagePaths.LabEditButton.default} style={{ height: this.state.size, width: this.state.size }} alt='Edit'
                        onClick={() => { this.props.onClick() }} />
                </Button>
            </Tooltip>
        )
    }
}
export class CommonDeleteButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size: this.props.size ? this.props.size : "1.3vw"
        }
    }
    render() {
        return (
            <Tooltip title="Delete" placement="top" arrow>
                <IconButton emed_tid={this.props.testID} className="eMed_Edit_btn eMed_usrconf_btn" disabled={this.props.disable} size='small' onClick={() => { this.props.onClick() }}>
                    <img src={this.props.disable ? ImagePaths.DisabledDelete.default : ImagePaths.LabDeleteButton.default} style={{ height: this.state.size, width: this.state.size }} alt='Delete' />
                </IconButton>
            </Tooltip>
        )
    }
}



export class CommonPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true
        }
    }

    handleClose = () => {
        this.props.popUpClose();
    }
    buttonOneAction = () => {
        this.props.buttonOneAction();
    }
    buttonTwoAction = () => {
        this.setState({ disableBtn: this.props?.disableBtn2 === false ? false : true}, ()=>{
            setTimeout(()=>{ this.setState({ disableBtn : false })}, 1500)
            this.props.buttonTwoAction()
        })
    }
    render() {
        let { open } = this.state;
        let width = this.props.width ? this.props.width : "85vw"
        let height = this.props.height ? this.props.height : null
        let testID = this.props.testID ? this.props.testID : "test"
        return (
            <Modal open={open}>
                <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: width, height: height }}>
                    <div id="emedhub_popup_header" style={{ backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : Colors.DataTblHeaderbg }}>
                        <Stack direction="row" spacing={2} alignItems={"center"}>
                            <Typography sx={{ color: "#616161", fontWeight: 600 }}>{this.props.title}</Typography>
                            {this.props.titleIconImg ? <Tooltip placement="top" title={this.props.iconTitle} arrow>
                                <IconButton emed_tid={`cmn_${testID}_${this.props.iconTitle}`} size="small" onClick={() => this.props.IconAction()}>
                                    <img className="emedhub_directPo_img" src={this.props.titleIconImg} alt={this.props.iconTitle} />
                                </IconButton>
                            </Tooltip> : null}
                        </Stack>
                        <IconButton emed_tid={`cmn_${testID}_close`} onClick={this.handleClose} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                    </div>
                    <div id="emedhub_popup_content" emed_tid={`cmn_${testID}_cnt`}>
                        {this.props.component()}
                    </div>
                    {this.props.footerTitle1 || this.props.buttonOne ?
                        <Stack id="emedhub_popup_footer" direction="row" justifyContent={this.props.footerTitle1 ? "space-between" : this.props.justifyRight ? "flex-end" : "center"} alignItems="center" sx={{ height: this.props.footerTitle1 ? "4vw" : "3vw" }}>
                            {this.props.footerTitle1 ?
                                <div style={{ marginLeft: "0.5vw" }}>
                                    <div className="emedhub_popup_footer_subdiv">
                                        <div className="emedhub_popup_bulletin"></div>
                                        <p className="emedhub_popup_footer_Text emedhub_popup_footer_Text1">{this.props.footerTitle1}</p>
                                        <p className="emedhub_popup_footer_Text emedhub_popup_footer_Text2">{this.props.footerContent1}</p>
                                    </div>
                                    {
                                        this.props.footerTitle2 ?
                                            <div className="emedhub_popup_footer_subdiv">
                                                <div className="emedhub_popup_bulletin"></div>
                                                <p className="emedhub_popup_footer_Text emedhub_popup_footer_Text1">{this.props.footerTitle2}</p>
                                                <p className="emedhub_popup_footer_Text emedhub_popup_footer_Text2">{this.props.footerContent2}</p>
                                            </div>
                                            : null
                                    }
                                </div>
                                : null
                            }
                            <div>
                                <Button emed_tid={`cmn_${testID}_${this.props.buttonOne}`} size="small" sx={{ marginRight: this.props.buttonTwo ? "1vw" : 0, textTransform: "capitalize" }} variant={this.props.buttonTwo ? "outlined" : "contained"} onClick={() => { this.buttonOneAction() }}>{this.props.buttonOne}</Button>
                                {this.props.buttonTwo ? <Button emed_tid={`cmn_${testID}_${this.props.buttonTwo}`} variant="contained" size="small" disabled={this.props.disableBtn2 || this.state.disableBtn} sx={{ textTransform: "capitalize" }} onClick={() => { this.buttonTwoAction() }}>{this.props.buttonTwo}</Button> : null}
                            </div>
                        </Stack>
                        : null
                    }
                </Paper>
            </Modal>
        )
    }
}

export class DeletePopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deleteDialog: true,
            disableBtn: false,
        }
    }

    handleClose = () => {
        this.setState({ disableBtn: false })
        this.props.deleteAlertPopupClose();
    };

    removeFunction = () => {
        this.props.removeData(false)
        setTimeout(() => {
            this.setState({ disableBtn: false })
        }, 1500);
    }

    render() {
        let testID = this.props.testID ? this.props.testID : "test"
        return (
            <Dialog
                open={this.state.deleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0.5vw', marginBottom: '0vw' }}>
                    <img emed_tid={`dlt_${testID}_close`} className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={this.handleClose} />
                </div>
                <DialogTitle id="alert-dialog-title">
                    {this.props.DeletTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ fontSize: '0.8vw' }} id="alert-dialog-description">
                        {this.props.DeletContent ? this.props.DeletContent : ""}
                    </DialogContentText>
                </DialogContent>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <DialogContent>
                        {
                            this.props.DeleteNotify !== undefined ?
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className="eMed_DeletedPopup_bullet"></div>
                                    <div style={{ fontSize: '0.6vw', color: '#616161', marginRight: '0.5vw' }} id="alert-dialog-description">
                                        {this.props.ButtonText ? this.props.ButtonText : "Remove"}
                                    </div>
                                    <div className="eMed_DeletedPopup_Border"></div>
                                    <DialogContentText sx={{ fontSize: '0.6vw', marginLeft: '0.5vw', fontWeight: 600,color:this.props.color }} id="alert-dialog-description">
                                        {this.props.DeleteNotify}
                                    </DialogContentText>
                                </div> : null
                        }

                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {this.props?.BillType==="Saved Bill" ? <Button disabled={this.props.disable} emed_tid={`dlt_${testID}_${this.props.ButtonText1 ? this.props.ButtonText1 : "Cancel"}`} color="error" size="small" variant="contained" sx={{ textTransform: "capitalize" }} onClick={this.handleClose}>
                            {this.props?.ButtonText1 ? this.props?.ButtonText1 : "Cancel"}
                        </Button> : null}
                        <Button disabled={this.props.disable ? this.props.disable : this.state.disableBtn} emed_tid={`dlt_${testID}_${this.props.ButtonText ? this.props.ButtonText : "Remove"}`} color="error" size="small" variant="contained" sx={{ textTransform: "capitalize" }} onClick={() => this.setState({ disableBtn: true }, () => this.removeFunction() )}>
                            {this.props.ButtonText ? this.props.ButtonText : "Remove"}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        )
    }
}
export class CommonGridToolBarWithButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isButtonClick: false,
            isToggled: false
        }
    }
    render() {
        let isToggle = this.props.toggleLeft && this.props.toggleRight;
        return (
            <GridToolbarContainer className={isToggle ? 'eMed_tgleBar_grid' : 'eMed_refdoc_grid'} >
                {this.props.ViewBtn ? <Button variant='contained' size="small" id="eMed_recPayment_btn" onClick={() => {
                    this.setState({
                        isButtonClick: true
                    }, () => {
                        this.props.onClickCommonButton(this.state.isButtonClick)
                    })
                }} endIcon={this.props.endIcon}>{this.props.label}</Button>
                    : isToggle ? <Box className="eMed_tgleBar_grid_action">
                        <label id="eMed_tgleBar_grid_txt" style={{ paddingRight: '0.8vw', paddingTop: '0.1vw' }}>{this.props.toggleLeft}</label>
                        <FormControlLabel
                            value="end"
                            id="eMed_tgleBar_grid_txt"
                            control={
                                <Switch
                                    size='small'
                                    color="primary"
                                    checked={this.state.isToggled}
                                    onChange={(e) => {
                                        this.setState({ isToggled: e.target.checked },
                                            () => { this.props.onClickToggleBtn(this.state.isToggled) })
                                    }} />}
                            label={this.props.toggleRight}
                            labelPlacement="end"
                        />
                    </Box>
                        : null}
                <div>
                    <Tooltip placement='top' title='Show/Hide Column' arrow><GridToolbarColumnsButton /></Tooltip>
                    <GridToolbarFilterButton placement='top' />
                    <Tooltip placement='top' title='Table Density' arrow><GridToolbarDensitySelector /></Tooltip>
                    <Tooltip placement='top' title='Export' arrow><GridToolbarExportContainer>
                        <GridCsvExportMenuItem />
                    </GridToolbarExportContainer></Tooltip>
                    <GridToolbarQuickFilter />
                </div>
            </GridToolbarContainer>
        )
    }
}
export class CommonGridToolBarWithFilterText extends Component {
    constructor(props) {
        super(props)

    }
    render() {
        return (
            <GridToolbarContainer className='eMed_refdoc_grid' >
                {this.props.isDelete ? <Box sx={{display: 'flex', width: '60vw'}}>
                    <Button size="small" variant="contained" color="error" sx={{textTransform: 'capitalize'}} onClick={()=>{this.props.removeData()}}>Delete</Button>
                </Box> : null}
                {this.props.FName ?
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Typography fontSize={'0.8vw'} color={"#888888"} marginX={"0.3vw"}>Name: </Typography> <Typography fontSize={'0.9vw'} color={"#616161"} marginX={"0.3vw"} fontWeight={600}>{this.props.FName}</Typography>
                    </Box> : null}
                {this.props.FilterTextArray && this.props.FilterTextArray.length > 0 ?
                    this.props.FilterTextArray.map((list, index) => (
                        <Box key={index} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <Typography fontSize={'0.8vw'} color={"#888888"} marginX={"0.3vw"}>{list.label} : </Typography> <Typography fontSize={'0.9vw'} color={"#616161"} marginX={"0.3vw"} fontWeight={600}>{list.value}</Typography>
                        </Box>
                    )) : null}
                <Tooltip placement='top' title='Show/Hide Column' arrow><GridToolbarColumnsButton /></Tooltip>
                <GridToolbarFilterButton placement='top' />
                <Tooltip placement='top' title='Table Density' arrow><GridToolbarDensitySelector /></Tooltip>
                <Tooltip placement='top' title='Export' arrow><GridToolbarExportContainer>
                    {this.props.noExport ? null : <GridCsvExportMenuItem />}
                </GridToolbarExportContainer></Tooltip>
                {
                    this.props?.onChangingSearch ?  // for custom search text box with value and onchange
                        <GridToolbarQuickFilter placeholder={this.props.searchHolder ? this.props.searchHolder : "Search"} value={this.props?.value} onChange={(e) => {
                            this.props.onChangetrigger(e.target.value)
                        }} />
                        :
                        <GridToolbarQuickFilter placeholder={this.props.searchHolder ? this.props.searchHolder : "Search"} />
                }
            </GridToolbarContainer>
        )
    }
}

export class CommonGridToolBarWithFilterTextCustom extends Component {
    constructor(props) {
        super(props)
    }
    convertArrayToCSV = (data) => {
        const headers = Object.keys(data[0]);
        var FromPeriod = this.props?.FilterTextArray ? this.props?.FilterTextArray[0]?.value  : null
        var ToPeriod = this.props?.FilterTextArray ? this.props?.FilterTextArray[1]?.value  : null
        var title = this.props?.title ? this.props?.title : ""
        if(title ==="Insurance Patient Report" || title === "Counsellor Home" || title === "DotorModule Home" || title === "OT Home" || this.props.withoutheaderSpace){
            const csvContent = [
                headers?.join(','),
                ...data.map((row) => headers.map((header) => row[header])?.join(',')),
              ]?.join('\n');
          
            return csvContent;
        }else{
            const titleRow = [title]
            const extraRow1 = FromPeriod ? ['From Date', FromPeriod] : []
            const extraRow2 = ToPeriod ? ['To Date', ToPeriod] : []
            const csvContent = [
                titleRow?.join(','),
                extraRow1?.join(','),
                extraRow2?.join(','),
                headers?.join(','),
                ...data.map((row) => headers.map((header) => row[header])?.join(',')),
            ]?.join('\n');

            return csvContent;
        }
        
    }

    downloadCSV = (csvContent, fileName) => {
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }
    
      filterClick = () => {
        const csvContent =  this.convertArrayToCSV(this.props.data);
        const fileName = `${this.props.filename}.csv`;
        this.downloadCSV(csvContent, fileName);
    }
    render() {
        return (
            <GridToolbarContainer className='eMed_refdoc_grid' >
                {this.props.FilterTextArray && this.props.FilterTextArray.length > 0 ?
                    this.props.FilterTextArray.map((list, index) => (
                        <Box key={index} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <Typography fontSize={'0.8vw'} color={"#888888"} marginX={"0.3vw"}>{list.label} : </Typography> <Typography fontSize={'0.9vw'} color={"#616161"} marginX={"0.3vw"} fontWeight={600}>{list.value}</Typography>
                        </Box>
                    )) : null}
                <Tooltip placement='top' title='Show/Hide Column' arrow><GridToolbarColumnsButton /></Tooltip>
                <GridToolbarFilterButton placement='top' />
                <Tooltip placement='top' title='Table Density' arrow><GridToolbarDensitySelector /></Tooltip>
                <Tooltip placement='top' title='Export' arrow>
                    <GridToolbarExportContainer>
                        <MenuItem disabled={this.props.data == 0} onClick={this.filterClick} color='secondary' size='small'>{"Download CSV"}</MenuItem>
                    {/* <Button ></Button> */}
                </GridToolbarExportContainer>
                </Tooltip>
                <GridToolbarQuickFilter placeholder={this.props.searchHolder ? this.props.searchHolder : "Search"} />
            </GridToolbarContainer>
        )
    }
}
export class CommonGridToolBarWithFilterTextCustomRevenue extends Component {
    constructor(props) {
        super(props)

    }
    downloadCSV = (csvContent, fileName) => {
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }
    DashBoardconvertArrayToCSV = (data) => {
        const headers = Object.keys(data[0])  
        const csvContent = [
            headers.join(','),
            ...data.map((row) => headers.map((header) => row[header]).join(',')),
        ].join('\n');
        return csvContent;
    }
    DashBoardfilterClick = () =>{
        const csvContent = this.DashBoardconvertArrayToCSV(this.props.data);
        const fileName = `${this.props.filename}.csv`;
        this.downloadCSV(csvContent, fileName);
    }
    render() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '5vh' }} >
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Typography fontSize={'0.85vw'} fontWeight={600} marginX={"0.3vw"}>{this.props.title}</Typography>
                </Box>
                <Tooltip placement='top' title='Export' arrow>
                    <GridToolbarExportContainer>
                        <MenuItem disabled={this.props.data == 0} color='secondary' size='small' onClick={this.DashBoardfilterClick}>{"Download CSV"}  </MenuItem>
                    {/* <Button ></Button> */}
                </GridToolbarExportContainer>
                </Tooltip>
            </GridToolbarContainer>
        )
    }
}


export class CommonGridToolBarTitleWithExport extends Component {
    constructor(props) {
        super(props)

    }
    render() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '5vh' }} >
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Typography fontSize={'0.85vw'} fontWeight={600} marginX={"0.3vw"}>{this.props.title}</Typography>
                </Box>
                <Tooltip placement='top' title='Export' arrow><GridToolbarExportContainer>
                    <GridCsvExportMenuItem />
                </GridToolbarExportContainer></Tooltip>
            </GridToolbarContainer>
        )
    }
}
export default class CommonGridHeader {
    static CommonToolBar = () => {
        return (
            <GridToolbarContainer className='eMed_refdoc_grid' >
                <Tooltip placement='top' title='Show/Hide Column' arrow><GridToolbarColumnsButton /></Tooltip>
                <GridToolbarFilterButton placement='top' />
                <Tooltip placement='top' title='Table Density' arrow><GridToolbarDensitySelector /></Tooltip>
                <Tooltip placement='top' title='Export' arrow><GridToolbarExportContainer>
                    <GridCsvExportMenuItem />
                </GridToolbarExportContainer></Tooltip>
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        )
    }

}
export class CommonGridHeaderOnlySearch {
    static CommonToolBarOnlySearch = () => {
        return (
            <GridToolbarContainer className='eMed_refdoc_grid' >
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        )
    }

}

export class CommonPatientDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { data } = this.props
        let fullName = this.props.fromPurchase ? `${data.first_name} ${data.last_name}` : data?.login_fname + " " + data?.login_lname;
        let admission_date = data?.admission_date ? formatDate(data?.admission_date) : data?.approx_check_in_date ? formatDate(data?.approx_check_in_date) : data?.appointment_date ? formatDate(data?.appointment_date) : "-";
        let surgery_date = data?.surgery_date ? formatDate(data?.surgery_date) : "-";
        let admissionDetails = `${admission_date} | ${data?.admission_time ? data?.admission_time : "-"}`
        let SurgeryDetails = `${surgery_date} | ${data?.surgery_time ? this.props.isFromOTList === true ? timeOnlyConvert(data?.surgery_time) : data?.surgery_time : "-"}`
        let PatientAccNumber = data?.patient_account_numbers ? data?.patient_account_numbers : data?.patient_account_number ? data?.patient_account_number : data?.uhid ? data?.uhid : data?.patient_acc_no ? data?.patient_acc_no : data?.patient_uhid ? data?.patient_uhid : data?.patient__patient_account_number ? data?.patient__patient_account_number :  "-";
        let photo_url = data?.patient_photo_url || data?.photo_url || data?.patient__photo_url;
        let patient_name = data?.patient_name ? data?.patient_name : data?.first_name ? data?.first_name : data?.patient_names ? data?.patient_names : data?.name ? data?.name : "-";
        let mobile_number = data?.patient_mobile_number || data?.patient__mobile_number || data?.mobile_number || data?.patients_mobile_number || data?.patient_mobile_no || data?.patients_mobile;
        let patient_age = data?.patient_age?.toString()?.includes("Y") ? data?.patient_age : data?.age?.toString()?.includes("Y") ? data?.age : data?.patient_age_str;
        let patient_gender = data?.patient_gender || data?.gender || data?.patient__gender;
        let whatGender = ((data?.patient_gender === 'Male' || data?.patient_gender === 'm' || data?.gender === "m" || data?.gender === "M" || data?.gender === 'Male' || data?.patient__gender === "m") ? "M" : (data?.patient_gender === 'Female' || data?.patient_gender === 'f' || data?.gender === "f" || data?.gender === "Female" || data?.gender === "F" || data?.patient__gender === "f") ? "F" : (data?.patient_gender === "Trans" || data?.patient_gender === 't' || data?.gender === "t" || data?.gender === "T" || data?.patient__gender === "t") ? "T" : "-");
        let branchName = data?.branch_name ? data?.branch_name : null
        return (
            <div className="eMed_Com_Patient_Details">
                {this.props.disablePhoto ? null :
                    <div>
                        {this.props.fromUserAccess ?
                            <div className="eMed_Com_Patient_Avatar">
                                <img className="eMed_Com_Patient_AvaIcon" style={{ borderColor: (data?.patient_type === "new_patient" || data?.patient__patient_type === 1) ? "#F1584A" : (data?.patient_type === "old_patient") || (data?.patient_type === "establish_patient") || (data?.patient__patient_type === 3) ? "#800080" : '#29B6F6' }}
                                    src={data?.user_image ? data?.user_image : ImagePaths.Male.default} alt={'Avatar'} />
                            </div> :
                            <div className="eMed_Com_Patient_Avatar">
                                <img className="eMed_Com_Patient_AvaIcon" style={{ borderColor: (data?.patient_type === "new_patient" || data?.patient__patient_type === 1) ? "#F1584A" : (data?.patient_type === "old_patient") || (data?.patient_type === "establish_patient") || (data?.patient__patient_type === 3) ? "#800080" : '#29B6F6' }}
                                    src={photo_url === null || photo_url === "" || photo_url === undefined ? data?.patient_gender === "Male" || data?.patient_gender === "m" || data?.gender === "m" || data?.gender === "Male" ? ImagePaths.Male.default : ImagePaths.Female.default : photo_url} alt={'Avatar'} />
                            </div>}
                    </div>
                }
                <div>
                    <Box component={'div'} className="eMed_Pateint_Detail_LongData">
                        {this.props.fromUserAccess ?
                            <Box component={'div'}>
                                <Typography component={'div'}>
                                    {(fullName?.length) > 20 ?
                                        <Tooltip title={fullName} placement={'top'}>
                                            <div><Typography id='eMed_Com_Patient_Name' style={{ fontSize: this.props.packageDetail ? "1.25vw" : "0.8vw" }}>{((fullName) ? ((fullName.slice(0, 20) + '...')) : '-')}</Typography>
                                            </div></Tooltip> : <Typography id='eMed_Com_Patient_Name' style={{ fontSize: this.props.packageDetail ? "1.25vw" : "0.8vw" }}>{(fullName ? fullName : '-')}</Typography>}
                                </Typography>
                                <Typography id='eMed_Com_Patient_UhidText'>{(data?.phone_number ? data?.phone_number : "-")}</Typography>
                            </Box> :
                            <Box component={'div'}>
                                <Typography component={'div'}>
                                    {(patient_name?.length) > 20 ?
                                        <Tooltip title={patient_name} placement={'top'} arrow>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <Typography id='eMed_Com_Patient_Name' style={{ fontSize: this.props.packageDetail ? "1.25vw" : "0.8vw" }}>{(patient_name ? ((patient_name?.slice(0, 17) + '...')) : '-') + ' ' + (patient_age ? patient_age : '-') + '/' + (patient_gender ? whatGender : '-')}</Typography>
                                                {this.props.icon ? <div style={{ marginLeft: "0.5vw" }}>
                                                    {whatGender === "M" ? <MaleIcon sx={{ fontSize: "1vw", color: '#616161' }} /> : whatGender === "F" ? <FemaleIcon sx={{ fontSize: "1vw", color: '#616161' }} /> : null}
                                                </div> : null}
                                            </div>
                                        </Tooltip> :
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <Typography id='eMed_Com_Patient_Name' style={{ fontSize: this.props.packageDetail ? "1.25vw" : "0.8vw" }}>{(patient_name ? patient_name : '-') + ' ' + (patient_age ? patient_age : '-') + '/' + (patient_gender ? whatGender : '-')}</Typography>
                                            {this.props.icon ? <div style={{ marginLeft: "0.5vw" }}>
                                                {whatGender === "M" ? <MaleIcon sx={{ fontSize: "1vw", color: '#616161' }} /> : whatGender === "F" ? <FemaleIcon sx={{ fontSize: "1vw", color: '#616161' }} /> : null}
                                            </div> : null}
                                        </div>
                                    }
                                </Typography>
                                <Typography id='eMed_Com_Patient_UhidText'>{(PatientAccNumber) + ' | ' + (mobile_number ? mobile_number : '-')}</Typography>
                                {this.props.isCommonUhid ? <Typography id='eMed_Com_Patient_UhidText'>Branch Name: {branchName?.length > 22 ? <Tooltip placement="top" title={branchName} arrow><span id='eMed_Com_Patient_UhidText'>{branchName?.slice(0, 19) + "..."}</span></Tooltip> : branchName ? branchName : "-"}</Typography>: null}
                            </Box>
                        }

                        {this.props.showDetailed === true ?
                            <Box component={'div'}>
                                {this.props?.fromDoctor ?
                                    <Typography id='eMed_Com_Patient_Name'>
                                        {data?.op_number?.length > 15 ?
                                            <Tooltip title={data?.op_number} placement={'top'} arrow>
                                                <Typography id='eMed_Com_Patient_Name'>{data?.op_number ? data?.op_number?.length > 9 ? ('...' + data?.op_number.slice(10)) : data?.op_number : '-'}</Typography>
                                            </Tooltip> : <Typography id='eMed_Com_Patient_Name'>{data?.op_number ? data?.op_number : '-'}</Typography>}
                                    </Typography>
                                    :
                                    <Typography id='eMed_Com_Patient_Name'>
                                        {(data?.ip_number?.length > 15) || (data?.op_number?.length > 15) ?
                                            <Tooltip title={data?.ip_number ? data?.ip_number : data?.op_number} placement={'top'} arrow>
                                                <Typography id='eMed_Com_Patient_Name'>{data?.ip_number ? (data?.ip_number?.length > 9 ? ('...' + data?.ip_number.slice(10)) : data?.ip_number) : data?.op_number ? data?.op_number?.length > 9 ? ('...' + data?.op_number.slice(10)) : data?.op_number : '-'}</Typography>
                                            </Tooltip> : <Typography id='eMed_Com_Patient_Name'>{data?.ip_number ? data?.ip_number : data?.op_number ? data?.op_number : '-'}</Typography>}
                                    </Typography>
                                }
                                <Typography id='eMed_Com_Patient_UhidText'>{admission_date ? `| ${admission_date}` : '-'}</Typography>
                            </Box> : null}
                    </Box>
                    {
                        this.props.showTag === true ?
                            <Typography component={'div'}>
                                {data?.patient_tag_name?.length > 15 ?
                                    <Tooltip title={data?.patient_tag_name} placement={'top'} arrow>
                                        <Typography id='eMed_Com_Patient_UhidText'>{'Patient Tag: ' + (data?.patient_tag_name ? data?.patient_tag_name.slice(0, 15) + '...' : '-')}</Typography>
                                    </Tooltip> : <Typography id='eMed_Com_Patient_UhidText' style={{fontSize:'0.8vw' , fontWeight:"bold" , color : data?.patient_tag_color ? data?.patient_tag_color : "#000000"}}>{'Tag: ' + (data?.patient_tag_name ? data?.patient_tag_name : '-')}</Typography>}
                            </Typography> : null
                    }
                    {
                        this.props.showAdmission === true ?
                            <Typography component={'div'}>
                                {admissionDetails?.length > 15 ?
                                    <Tooltip title={admissionDetails} placement={'top'} arrow>
                                        <Typography id='eMed_Com_Patient_UhidText'>{this.props.showAproxDate ? `Approx.Admission:` : `Admission Date&Time: `}<span id='eMed_Com_Patient_admissionTxt'>{admissionDetails ? admissionDetails.slice(0, 13) + '...' : '-'}</span></Typography>
                                    </Tooltip> : <Typography id='eMed_Com_Patient_UhidText'>{this.props.showAproxDate ? `Approx.Admission:` : `Admission Date&Time: `}<span id='eMed_Com_Patient_admissionTxt'>{admissionDetails ? admissionDetails : '-'}</span></Typography>}
                            </Typography> : null
                    }
                    {
                        this.props.showAdmissionDateOnly === true ?
                            <Box component={'div'}>
                                <Typography id='eMed_Com_Patient_UhidText'>{this.props.showAproxDate ? `Approx.Admission:` : `Admission Date&Time: `}<span id='eMed_Com_Patient_admissionTxt'>{admission_date ? admission_date : '-'}</span></Typography>
                            </Box> : null
                    }
                    {
                        this.props.isFromOTList === true ?
                            <Typography component={'div'}>
                                {SurgeryDetails?.length > 15 ?
                                    <Tooltip title={SurgeryDetails} placement={'top'} arrow>
                                        <Typography id='eMed_Com_Patient_UhidText'>{`Surgery Date&Time: `}<span id='eMed_Com_Patient_admissionTxt'>{SurgeryDetails ? SurgeryDetails.slice(0, 13) + '...' : '-'}</span></Typography>
                                    </Tooltip> : <Typography id='eMed_Com_Patient_UhidText'>{`Surgery Date&Time: `}<span id='eMed_Com_Patient_admissionTxt'>{SurgeryDetails ? SurgeryDetails : '-'}</span></Typography>}
                            </Typography> : null
                    }
                </div>
            </div>
        )
    }
}
export class BasicPatientDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { data } = this.props
        return (
            <div className="eMed_Com_Patient_Details">
                <div className="eMed_Com_Patient_Avatar">
                    <img className="eMed_Com_Patient_AvaIcon" style={{ borderColor: Colors.themeDark }}
                        src={data.photo_url === null || data.photo_url === "" ? ImagePaths.Male.default : data.photo_url} alt={'Avatar'} />
                </div>
                <div>
                    <Typography component={'div'}>
                        {data?.patient_name ? data?.patient_name?.length > 20 ?
                            <Tooltip placement='top' title={data?.patient_name} arrow>
                                <div>{data?.patient_name.slice(0, 20) + "..."}</div></Tooltip> :
                            data?.patient_name : "-"}
                    </Typography>
                    <Typography component={'div'}>
                        {data?.text ? data?.text?.length > 20 ?
                            <Tooltip placement='top' title={data?.text} arrow>
                                <div>{data?.text.slice(0, 20) + "..."}</div></Tooltip> :
                            data?.text : "-"}
                    </Typography>
                </div>
            </div>
        )
    }
}

export function StopWatch(props) {
    const [Start, setStart] = useState(false)
    const {
        seconds,
        minutes,
        hours,
        start,
        pause
    } = useStopwatch({ autoStart: true });
    return (
        <div>
            {props.data.status == "Arrived" && props.selected_id === props.data.id ?
                <div style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '100px' }}>
                        <p>{hours + ': ' + minutes + ': ' + seconds}</p>
                        {console.log(hours + ': ' + minutes + ': ' + seconds)}
                    </div>
                </div> : <p>{0 + ': ' + 0 + ': ' + 0}</p>}
        </div>
    )
}

export class ReasonPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dialogBox: true,
            comments: this.props.defaultComment || '',
            ReasonError: false,
            isBtnClked: false,
        }
    }

    closePopup = () => {
        this.props.AlertPopupClose();
    };

    // removeFunction = () => {
    //     this.props.removeData()
    // }

    render() {
        let testID = this.props.testID ? this.props.testID : "test";
        const is_cmt_mandatory = this.props.isCmtMandatory === false ? false : true;
        return (
            <Dialog
                open={this.state.dialogBox}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                    <div>
                        {this.props.title}
                    </div>
                    <div className="eMed_DialogBox_Close">
                        <img emed_tid={`rsn_${testID}_close`} className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={this.closePopup} />
                    </div>
                </div>
                <DialogContent sx={{ width: "30vw" }}>
                    <TextField
                        sx={{ width: '26vw' }}
                        multiline
                        rows={3}
                        inputProps={{ maxLength: cmt_Char_limit, emed_tid: `rsn_${testID}_txt` }}
                        placeholder={this.props.label}
                        variant="outlined"
                        value = {this.state.comments || ""}
                        onChange={(e) => {
                            this.setState({ comments: e.target.value, ReasonError: false })
                        }}
                        error={this.state.ReasonError}
                        helperText={this.state.ReasonError ? "Enter the Reason" : ""} />
                    {this.props.DeleteNotify ?
                        <div className="eMed_DelCnf_msg">
                            <div className="eMed_DeletedPopup_bullet"></div>
                            <div className="eMed_Delcnf_txt" id="alert-dialog-description">
                                Remove
                            </div>
                            <div className="eMed_DeletedPopup_Border"></div>
                            <DialogContentText sx={{ fontSize: '0.6vw', marginLeft: '0.5vw',color:this.props.color }} id="alert-dialog-description">
                                {this.props.DeleteNotify}
                            </DialogContentText>
                        </div> : null}
                </DialogContent>
                <DialogActions id="eMed_DialogBox_Btn">
                    <Button emed_tid={`rsn_${testID}_${this.props.btntext}`} size="small" variant={this.props.btnvarient} id="eMed_Dia_Btn" color="error" onClick={this.closePopup}>
                        {this.props.btntext}
                    </Button>
                    <Button emed_tid={`rsn_${testID}_${this.props.btntext1}`} disabled={this.props.btntextDis || this.state.isBtnClked}
                        color={this.props.btncolor} size="small" variant={this.props.btnvarient1} id="eMed_Dia_Btn"
                        onClick={() => {
                            if (is_cmt_mandatory) {
                                if (CommonValidation.removeSpace(this.state.comments) !== "") {
                                    this.props.sendCmt(CommonValidation.removeSpace(this.state.comments))
                                } else {
                                    this.setState({ ReasonError: true })
                                }
                            } else {
                                this.setState({isBtnClked: true})
                                this.props.sendCmt(CommonValidation.removeSpace(this.state.comments))
                            }
                        }}>
                        {this.props.btntext1}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export class ReasonPopupWithRefund extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dialogBox: true,
            comments: '',
            ReasonError: "",
            isCash: this.props?.paymentMode === 1 ? true : false,
            isUPI: this.props?.paymentMode === 3 ? true : false,
            isBankTranfer: this.props?.paymentMode === 5 ? true : false,
            CashAmount: this.props?.paymentMode === 1 ? this.props?.payableAmount : "",
            UPINumber: "",
            UPIAmount: this.props?.paymentMode === 3 ? this.props?.payableAmount : "",
            BankTfrAmount: this.props?.paymentMode === 5 ? this.props?.payableAmount : "",
            BankTfrNumber: "",
            totalPayableAmount: this.props?.payableAmount,
            receiptType : "Cancel",
            BtnDisable : false
        }
    }

    closePopup = () => {
        this.props.AlertPopupClose();
    };

    renderAmountTextFields = (label, key, Icon) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                sx={{ padding: 0, margin: 0, width: "10vw" }}
                className='eMed_CrdPop_Amount_TextFields'
                size='small'
                // disabled={this.state.forCancel}
                label={label}
                autoComplete='off'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={Icon} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && +e.target.value > 0) || e.target.value === "") {
                        states[key] = e.target.value
                        this.setState({
                            states,
                            ReasonError: ""
                        })
                    }
                }}
            />
        )
    }

    renderNumberTextFields = (label, key, Right = false) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                className='eMed_CrdPop_Amount_TextFields'
                sx={{ marginLeft: Right ? "1vw" : 0, width: "10vw" }}
                inputProps={{ maxLength: 20 }}
                // disabled={this.state.forCancel}
                size='small'
                label={label}
                value={states[key]}
                autoComplete='off'
                onChange={(e) => {
                    let number = CommonValidation.gstnumberValidation(e.target.value);
                    if (number || e.target.value === "") {
                        states[key] = e.target.value.toUpperCase()
                        this.setState({
                            states,
                            ReasonError: ""
                        })
                    }
                }}
            />
        )
    }

    handleCheckBox = (e, key) => {
        var states = this.state
        states[key] = e.target.checked
        this.setState({ states }, () => {
            if (!states.isCash) { this.setState({ CashAmount: "" }) }
            if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "" }) }
            if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "" }) }
            if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer) {
                this.setState({ CashAmount: this.state.totalPayableAmount, })  // Fetch Full amount in Cash Amount, when only the cash is selected
            }
        })
    }

    FullAmountEntered() {
        try {
            let states = this.state;
            let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
            let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
            let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
            if (((CashAmt + UPIAmt + bankTransferAmt) == +states.totalPayableAmount) || this.state.receiptType === "Advance") {
                if (
                    ((UPIAmt === 0 || states.UPINumber !== "") &&
                    (bankTransferAmt === 0 || states.BankTfrNumber !== ""))||
                    this.state.receiptType === "Advance"
                ) {
                    if (this.props?.isForLab) {
                        this.props.cancelPost(this.state)
                    } else {
                        this.props.IPRefundPost(this.state)
                    }
                } else {
                    this.setState({
                        BtnDisable : false,
                        ReasonError: "Enter Transaction Details"
                    })
                }
            }
            else {
                this.setState({
                    BtnDisable : false,
                    ReasonError: "Amount Not Matched"
                })
            }
        }
        catch (e) {
            console.log(e?.message);
        }
    }

    render() {
        return (
            <Dialog
                open={this.state.dialogBox}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                    <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>
                        {this.props.title}
                    </Typography>
                    <div className="eMed_DialogBox_Close">
                        <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={this.closePopup} />
                    </div>
                </div>
                <DialogContent sx={{ width: "30vw" }}>
                    {this.props.BillReceivedAmount ?
                        <RadioGroup
                            value={this.state.receiptType}
                            sx={{ display: 'flex', flexDirection: 'row', marginBottom: '1vw' }}
                            onChange={(e) => {
                                this.setState({ receiptType: e.target.value }, ()=>{
                                    if(this.state.receiptType === "Advance"){
                                        this.setState({
                                            isCash: false,
                                            isUPI: false,
                                            isBankTranfer: false,
                                            CashAmount: "",
                                            UPINumber: "",
                                            UPIAmount: "",
                                            BankTfrAmount: "",
                                            BankTfrNumber: "",
                                        })
                                    }
                                })
                            }}
                        >
                            <FormControlLabel value={'Cancel'} control={<Radio size='small' />} label="Cancel Receipt" />
                            <FormControlLabel value={"Refund"} control={<Radio size='small' />} label="Refund Receipt" />
                            {this.props?.ShowAdvance ? <FormControlLabel value={"Advance"} control={<Radio size='small' />} label="Add to Advance" /> : null}
                        </RadioGroup> : null
                    }
                    <TextField
                        sx={{ width: '26vw' }}
                        multiline
                        rows={3}
                        inputProps={{ maxLength: cmt_Char_limit }}
                        placeholder={this.props.label}
                        variant="outlined"
                        onChange={(e) => {
                            this.setState({ comments: e.target.value, ReasonError: "" })
                        }} />
                    {
                        ((this.props?.fromIP || this.props.isForLab || this.props?.fromReservation) && this.state?.totalPayableAmount !== 0 && this.state.receiptType !== "Advance") ?
                            <Box component={'div'}>
                                <Typography fontSize={"0.9vw"} paddingY={"0.5vw"}>{this.state?.totalPayableAmount ? `Total Refundable Amount - ${AmountFormat(this.state?.totalPayableAmount || 0)}` : AmountFormat(0)}</Typography>
                                <Box ml={"0.5vw"} visibility={this.state.forCancel && this.state.totalPayableAmount === 0 ? 'hidden' : 'visible'}>
                                    <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' />} label={"Cash"} />
                                    <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={"UPI"} />
                                    <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10vw" }} control={<Checkbox checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={"Bank Transfer"} />
                                </Box>

                                <Box component={'div'} display={'flex'} flexDirection={'column'} ml={'0.5vw'} overflow={'scroll'} mt={'-0.3vw'}>
                                    {
                                        this.state.isCash ?
                                            <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                                            </Box> : null
                                    }
                                    {
                                        this.state.isUPI ?
                                            <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                                {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                            </Box>
                                            : null
                                    }
                                    {
                                        this.state.isBankTranfer ?
                                            <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                                {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                            </Box>
                                            : null
                                    }
                                </Box>
                            </Box> : null
                    }
                    <Box height={'1vw'} marginTop={'0.5vw'} width={'100%'} textAlign={'center'}>
                        <Typography color={'red'} fontSize={'0.8vw'}>{this.state.ReasonError !== "" ? this.state.ReasonError : ""}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions id="eMed_DialogBox_Btn">
                    <Button size="small" variant={this.props.btnvarient} id="eMed_Dia_Btn" color="error" onClick={this.closePopup}>
                        {this.props.btntext}
                    </Button>
                    <Button color={this.props.btncolor} size="small" variant={this.props.btnvarient1} id="eMed_Dia_Btn" disabled={this.state.BtnDisable} onClick={() => {
                        if (CommonValidation.removeSpace(this.state.comments) !== "") {
                            this.setState({
                                BtnDisable : true
                            }, ()=>{
                                if (this.props?.fromIP || this.props?.isForLab || this.props?.fromReservation) {
                                    this.FullAmountEntered()
                                } else {
                                    this.props.sendCmt(CommonValidation.removeSpace(this.state.comments), this.state.receiptType )
                                }
                            })
                            
                        } else {
                            this.setState({ ReasonError: this.props?.ReasonError ? this.props?.ReasonError : "Enter reason for cancel" })
                        }

                    }}>
                        {this.props.btntext1}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export class PharmacyReturnPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dialogBox: true,
            comments: '',
            ReasonError: "",
            isCash: false,
            isUPI: false,
            isBankTranfer: false,
            CashAmount: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            returnType : 0,
            refundAmt: 0,
            AdvanceAmt: 0,
            returnAmt: this.props.returnAmt,
            paidAmt: this.props.paidAmt,
            outstandingAmt: this.props.outstandingAmt,
            isCurrentIP: this.props.isCurrentIP
        }
    }

    closePopup = () => {
        this.props.AlertPopupClose();
    };

    renderAmountTextFields = (label, key, Icon) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                sx={{ padding: 0, margin: 0, width: "10vw" }}
                className='eMed_CrdPop_Amount_TextFields'
                size='small'
                label={label}
                autoComplete='off'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={Icon} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && +e.target.value > 0) || e.target.value === "") {
                        states[key] = e.target.value
                        this.setState({
                            states,
                            ReasonError: ""
                        })
                    }
                }}
            />
        )
    }

    renderNumberTextFields = (label, key, Right = false) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                className='eMed_CrdPop_Amount_TextFields'
                sx={{ marginLeft: Right ? "1vw" : 0, width: "10vw" }}
                inputProps={{ maxLength: 20 }}
                size='small'
                label={label}
                value={states[key]}
                autoComplete='off'
                onChange={(e) => {
                    let number = CommonValidation.gstnumberValidation(e.target.value);
                    if (number || e.target.value === "") {
                        states[key] = e.target.value.toUpperCase()
                        this.setState({
                            states,
                            ReasonError: ""
                        })
                    }
                }}
            />
        )
    }

    handleCheckBox = (e, key) => {
        var states = this.state
        states[key] = e.target.checked
        this.setState({ states }, () => {
            if (!states.isCash) { this.setState({ CashAmount: "" }) }
            if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "" }) }
            if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "" }) }
            if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer) {
                this.setState({ CashAmount: this.state.refundAmt, })  // Fetch Full amount in Cash Amount, when only the cash is selected
            }
        })
    }

    FullAmountEntered() {
        try {
            let states = this.state;
            let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
            let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
            let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
            if (((CashAmt + UPIAmt + bankTransferAmt) == +states.refundAmt) || states.AdvanceAmt) {
                if (
                    (UPIAmt === 0 || states.UPINumber !== "") &&
                    (bankTransferAmt === 0 || states.BankTfrNumber !== "")
                ) {
                    this.props.PharmaReturnData(this.state)
                } else {
                    this.setState({
                        ReasonError: "Enter Transaction Details"
                    })
                }
            }
            else {
                this.setState({
                    ReasonError: "Amount Not Matched"
                })
            }
        }
        catch (e) {
            console.log(e?.message);
        }
    }

    render() {
        var {returnAmt, outstandingAmt, paidAmt, isCurrentIP} = this.state
        let returnOptions = []
        if(this.props.condition == 1){
            returnOptions = [
                {Value: 1, Label: "₹" + returnAmt + " to be Refunded", Refund: returnAmt, Advance: 0 },
                {Value: 2, Label: "₹" + returnAmt + " to be reduced from Outstanding" +" ( ₹" + outstandingAmt + " )", Refund: 0, Advance: 0 },
            ] 
            if(isCurrentIP){
                returnOptions.push(
                    {Value: 3, Label: "₹" + returnAmt + " to be added with IP Advance", Refund: 0, Advance: returnAmt }
                )
            }
        } else if(this.props.condition == 2){
            returnOptions = [
                {Value: 1, Label: "₹" + returnAmt + " to be Refunded", Refund: returnAmt, Advance: 0 },
                {Value: 2, Label: "₹" + (returnAmt - outstandingAmt) + " to be refunded & " + "₹" + outstandingAmt + " to be reduced from Outstanding" +" ( ₹" + outstandingAmt + " )", Refund: (returnAmt - outstandingAmt), Advance: 0 },
            ] 
            if(isCurrentIP){
                returnOptions.push(
                    {Value: 3, Label: "₹" + returnAmt + " to be added with IP Advance", Refund: 0, Advance: returnAmt },
                    {Value: 4, Label: "₹" + (returnAmt - outstandingAmt) + " to be added with IP Advance & " + "₹" + outstandingAmt + " to be reduced from Outstanding" +" ( ₹" + outstandingAmt + " )", Refund: 0, Advance: (returnAmt - outstandingAmt) },
                )
            }
        } else if(this.props.condition == 3){
            returnOptions = [
                {Value: 1, Label: "₹" + paidAmt + " to be refunded & " + "₹" + (returnAmt - paidAmt) + " to be reduced from Outstanding" +" ( ₹" + outstandingAmt + " )", Refund: paidAmt, Advance: 0 },
                {Value: 2, Label: "₹" + (returnAmt - outstandingAmt) + " to be refunded & " + "₹" + outstandingAmt + " to be reduced from Outstanding" +" ( ₹" + outstandingAmt + " )", Refund: (returnAmt - outstandingAmt), Advance: 0 },
            ] 
            if(isCurrentIP){
                returnOptions.push(
                    {Value: 3, Label: "₹" + paidAmt + " to be added with IP Advance & " + "₹" + (returnAmt - paidAmt) + " to be reduced from Outstanding" +" ( ₹" + outstandingAmt + " )", Refund: 0, Advance: paidAmt },
                    {Value: 4, Label: "₹" + (returnAmt - outstandingAmt) + " to be added with IP Advance & " + "₹" + outstandingAmt + " to be reduced from Outstanding" +" ( ₹" + outstandingAmt + " )", Refund: 0, Advance: (returnAmt - outstandingAmt) },
                )
            }
        } else if(this.props.condition == 4){
            returnOptions = [
                {Value: 1, Label: "₹" + returnAmt + " to be reduced from Outstanding" +" ( ₹" + outstandingAmt + " )", Refund: 0, Advance: 0 },
                {Value: 2, Label: "₹" + paidAmt + " to be refunded & " + "₹" + (returnAmt - paidAmt) + " to be reduced from Outstanding" +" ( ₹" + outstandingAmt + " )", Refund: paidAmt, Advance: 0 },
            ] 
            if(isCurrentIP){
                returnOptions.push(
                    {Value: 3, Label: "₹" + paidAmt + " to be added with IP Advance & " + "₹" + (returnAmt - paidAmt) + " to be reduced from Outstanding" +" ( ₹" + outstandingAmt + " )", Refund: 0, Advance: paidAmt },
                )
            }
        }
        return (
            <Dialog
                open={this.state.dialogBox}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                    <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>
                        {this.props.title}
                    </Typography>
                    <div className="eMed_DialogBox_Close">
                        <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={this.closePopup} />
                    </div>
                </div>
                <DialogContent sx={{ width: "30vw" }}>
                        <RadioGroup
                            value={this.state.returnType}
                            sx={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5vw' }}
                            onChange={(e) => {
                                let refundObj = returnOptions.find(li => li.Value == (e.target.value))
                                this.setState({
                                    returnType: +(e.target.value),
                                    refundAmt: refundObj.Refund,
                                    isCash: false,
                                    isUPI: false,
                                    isBankTranfer: false,
                                    CashAmount: "",
                                    UPINumber: "",
                                    UPIAmount: "",
                                    BankTfrAmount: "",
                                    BankTfrNumber: "", 
                                    AdvanceAmt: refundObj.Advance
                                    })
                            }}
                        >
                            {returnOptions.map((item) => (
                                <FormControlLabel value={item.Value} control={<Radio size='small' />} label={item.Label} />
                            ))}    
                            
                        </RadioGroup> 

                    {
                        (this.state.refundAmt) ?
                            <Box component={'div'}>
                                <Box ml={"0.5vw"} >
                                    <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' />} label={"Cash"} />
                                    <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={"UPI"} />
                                    <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10vw" }} control={<Checkbox checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={"Bank Transfer"} />
                                </Box>

                                <Box component={'div'} display={'flex'} flexDirection={'column'} ml={'0.5vw'} overflow={'scroll'} mt={'-0.3vw'}>
                                    {
                                        this.state.isCash ?
                                            <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                                            </Box> : null
                                    }
                                    {
                                        this.state.isUPI ?
                                            <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                                {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                            </Box>
                                            : null
                                    }
                                    {
                                        this.state.isBankTranfer ?
                                            <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                                {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                            </Box>
                                            : null
                                    }
                                </Box>
                            </Box> : null
                    }
                    <Box height={'1vw'} marginTop={'0.5vw'} width={'100%'} textAlign={'center'}>
                        <Typography color={'red'} fontSize={'0.8vw'}>{this.state.ReasonError !== "" ? this.state.ReasonError : ""}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions id="eMed_DialogBox_Btn">
                    <Button size="small" variant={this.props.btnvarient} id="eMed_Dia_Btn" color="error" onClick={this.closePopup}>
                        {this.props.btntext}
                    </Button>
                    <Button color={this.props.btncolor} size="small" disabled={!this.state.returnType} variant={this.props.btnvarient1} id="eMed_Dia_Btn" onClick={() => {
                        this.FullAmountEntered()
                    }}>
                        {this.props.btntext1}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export class CommonPatientCard extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { details } = this.props
        return (
            <Box className="eMed_Patient_Card" sx={{ width: this.props.showDetailed === true ? '23vw' : this.props?.smallWidth ? "10.5vw" : "14.5vw" }} emed_tid={details.lineOne}>
                <Box component={'div'} className='eMed_patient_image'>
                    <img style={{ width: details.width ? details.width : "3vw", height: details.height ? details.height : "3vw" }} src={details.image ? details.image : ImagePaths.Male.default} alt="male" />
                </Box>
                <Box component={'div'} className="eMed_Pateint_Detail_LongData">
                    <Box component={'div'} className='eMed_patient_txt' >
                        <Typography fontSize={"0.7vw"}>{details.lineOne}</Typography>
                        {details.lineTwo.length > 25 ?
                            <Tooltip title={details.lineTwo} placement='top' arrow>
                                <Typography fontSize={details.lineTwo.length > 30 ? "0.7vw" : "0.9vw"} sx={{ color: details.colorCode }} fontWeight={600}>{details.lineTwo ? details.lineTwo.slice(0, 27) + '...' : '-'}</Typography>
                            </Tooltip>
                            : <Typography fontSize={details.lineTwo.length > 25 ? "0.7vw" : "0.9vw"} sx={{ color: details.colorCode }} fontWeight={600}>{details.lineTwo}</Typography>}
                        <Typography fontSize={"0.8vw"} fontWeight={600}>{details.lineThree}</Typography>
                    </Box>
                    {this.props.showOnecard === true ?
                        <Box>
                            <Typography fontSize={"0.7vw"}>{details.secHead}</Typography>
                            <Typography fontSize={"0.8vw"} fontWeight={600}>{details.secAmt}</Typography></Box> : null}
                    {this.props.showDetailed === true ?
                        <Box component={'div'} className='eMed_patient_txt'>
                            <Typography fontSize={"0.7vw"}>{details.lineFour}</Typography>
                            <Typography fontSize={"0.9vw"} fontWeight={600}>{details.LineFive}</Typography>
                        </Box> : null}
                </Box>
                {
                    this.props.ShowCross === true ? <IconButton onClick={() => { this.props.onClick() }}><CloseIcon color="error" /></IconButton> : null
                }
            </Box>
        )
    }
}

export class ConsultantPatientCard extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { name, width } = this.props
        return (
            <Box className="eMed_Patient_Card" sx={{ width: width ? width : '25dvw' }}>
                <Box component={'div'} className='eMed_patient_image'>
                    <img style={{ width: name.width ? name.width : "3vw", height: name.height ? name.height : "3vw" }} src={name.image ? name.image : ImagePaths.Male.default} alt="male" />
                </Box>
                <Box component={'div'} className="eMed_Pateint_Detail_LenthData">
                    <Box component={'div'} className='eMed_patient_txt'>
                        <Typography fontSize={"0.7vw"}>{name.lineOne}</Typography>
                        <Typography fontSize={name.lineTwo.length > 25 ? "0.7vw" : "0.9vw"} sx={{ color: name.colorCode }} fontWeight={600}>{name.lineTwo}</Typography>
                    </Box>
                </Box>
                {
                    this.props.ShowCross === true ? <IconButton onClick={() => { this.props.onClick() }}><CloseIcon color="error" /></IconButton> : null
                }
            </Box>
        )
    }
}

export class CommonImgPreviewPop extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { details } = this.props
        return (
            <Modal open={true}>
                <Box className="eMed_ImgPreviewPop_Main">
                    <Box component={'div'} className="eMed_ImgPreviewPop_Header">
                        <Typography variant='h6'>{this.props?.title ? this.props?.title : "Image Preview"}</Typography>
                        <Button onClick={() => { this.props.PopClose() }} >

                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                        </Button>
                    </Box>
                    <Box component={'div'} className="eMed_ImgPreviewPop_Img_wrapper">
                        {
                            <img src={this.props.src} className="eMed_ImgPreviewPop_img" alt="uploadedimg" />
                        }
                    </Box>
                </Box>
            </Modal>
        )
    }
}

export class ConfirmPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cnfDialog: true,
            disableBtn: false,
        }
    }

    handleClose = () => {
        this.setState({ disableBtn: false })
        this.props.cnfAlertPopupClose();
    };

    saveData = () => {
        this.props.cnfData()
        setTimeout(() => {
            this.setState({ disableBtn: false })
        }, 1500);
    }

    render() {
        return (
            <Dialog
                open={this.state.cnfDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box component={'div'} className="eMed_cnf_Box">
                    <DialogTitle id="alert-dialog-title">
                        {this.props.cnfTitle ? this.props.cnfTitle : '-'}
                    </DialogTitle>
                    <Box component={'div'} className="eMed_cnf_BtnGr">
                        {this.props.btn1Hide ? null : <IconButton onClick={this.handleClose}>
                            <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' />
                        </IconButton>}
                    </Box>
                </Box>
                <DialogContent dividers sx={{ width: '35vw' }}>
                    {this.props.showDateTimeError ?
                        <DialogContentText sx={{ fontSize: '1vw', color: 'red' }} id="alert-dialog-description">
                            {"Do you want to continue without discharge date and time ?"}
                        </DialogContentText> : this.props.cnfContent ?
                            <DialogContentText fontWeight={this.props.contentBold} color={'#7a7676'} id="alert-dialog-description">
                                {this.props.cnfContent ? this.props.cnfContent : '-'}
                            </DialogContentText> : null}
                    {this.props.cnfContent1 ?
                        <DialogContentText sx={{ fontSize: '0.9vw', color: '#7a7676' }} id="alert-dialog-description">
                            {this.props.cnfContent1 ? this.props.cnfContent1 : '-'}
                        </DialogContentText> : null}
                </DialogContent>
                <DialogActions>
                    {this.props.btn1Hide ? null : <Button variant="outlined" color={this.props.btnType} sx={{textTransform: 'capitalize'}} onClick={this.handleClose}>{this.props.btnText1 ? this.props.btnText1 : 'Cancel'}</Button>}
                    <Button variant="contained" sx={{textTransform: 'capitalize'}} disabled={this.props?.cnfDisable ? this.props?.cnfDisable : this.state.disableBtn} onClick={() => {this.setState({ disableBtn: true }, () => this.saveData() )}}>{this.props.btnText2 ? this.props.btnText2 : 'Confirm'}</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
export class PharmaDiscardPop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            OpenModel: true
        }
    }
    render() {
        return (
            <Dialog
                open={this.state.OpenModel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent dividers sx={{ width: '25vw', height: '5vw' }}>
                    <DialogContentText id="alert-dialog-description" sx={{fontWeight: 'bold'}}>
                        Are You Sure to Discard the Bill data?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="outlined" onClick={() => {
                        this.setState({ OpenModel: false }, () => { this.props.SendAction(false) })
                    }}>No</Button>
                    <Button size="small" variant="contained" onClick={() => this.props.SendAction(true, this.props?.IncomingLineItem)}>Yes</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
export class BillSuccessPoPUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isPrintClick: false,
            allowSms: false,
        }
    }

    componentDidMount = () => {
        let smsConfig = JSON.parse(localGetItem("smsConfig"))
        let allowSms = false
        if (this.props.isFromOP && !this.props.isCredit) { allowSms = smsConfig?.op_bill_sms }
        else if (this.props.isFromsmsIP) { allowSms = smsConfig?.ip_bill_sms }
        else if (this.props.isFromPharmacy && !this.props.isCredit) { allowSms = smsConfig?.pharmacy_bill_sms }
        this.setState({ allowSms: allowSms })
    }

    PrintReceipt = (receiptNum) => {
        let ProfileName = localGetItem("loggedInUserInfo")
        let myUser = JSON.parse(ProfileName)
        try {
            if (receiptNum) {
                let Url = ''
                if (myUser.module_name === "Laboratory") {
                    Url = Serviceurls.RECEIPT_INDIV_PRINT + `?receipt_id=` + receiptNum + `&clinic_id=${localGetItem("clinicId")}`
                } else if (myUser.module_name === "Pharmacy") {
                    Url = Serviceurls.RECEIPT_PHARMACY_PRINT + `?receipt_id=` + receiptNum
                } else {
                    Url = Serviceurls.RECEIPT_INDIV_PRINT + `?receipt_id=` + receiptNum
                }
                RestAPIService.PrintPDF(Url).
                    then((response) => {
                        if (response) {
                            this.props.ClosePopUp()
                            //Create a Blob from the PDF Stream
                            const file = new Blob(
                                [response.data],
                                { type: 'application/pdf' });
                            //Build a URL from the file
                            const fileURL = URL.createObjectURL(file);
                            //Open the URL on new Window
                            window.open(fileURL);
                        }

                    }).catch((error) => {
                        this.props.error(error.message)
                    })
            }
        } catch (e) {
            this.props.ClosePopUp()
        }
    }
    PrintPharmaReceipt = (receiptNum) => {
        try {
            if (receiptNum) {
                RestAPIService.PrintPDF(Serviceurls.RECEIPT_INDIV_PRINT + `?receipt_id=` + receiptNum).
                    then((response) => {
                        if (response) {
                            this.props.ClosePopUp()
                            //Create a Blob from the PDF Stream
                            const file = new Blob(
                                [response.data],
                                { type: 'application/pdf' });
                            //Build a URL from the file
                            const fileURL = URL.createObjectURL(file);
                            //Open the URL on new Window
                            window.open(fileURL);
                        }

                    }).catch((error) => {
                        console.log(error.message)
                    })
            }
        } catch (e) {
            this.props.ClosePopUp()
        }
    }


    printInvoice() {
        try {
            this.setState({
                isPrintClick: true
            })
            if (this.props.creditreceipt || this.props.phrma_creditreceipt) {
                this.PrintReceipt(this.props.creditreceipt)
                setTimeout(() => {
                    this.PrintPharmaReceipt(this.props.phrma_creditreceipt)
                }, 2000);

            } else {
                let API_URL = ""
                let Receipt_ID = ""
                Receipt_ID = this.props.Receipt_id ? (`&receipt_id=` + this.props.Receipt_id) : ''
                if (this.props.isFromOP) {
                    API_URL = Serviceurls.OP_INVOICE_PRINT + "?invoce_id=" + this.props.InvoiceId + Receipt_ID
                }
                else if (this.props?.isFromPharmacy || this.props?.isFromSubLocation) {
                    API_URL = Serviceurls.PHARMA_BILLS_GET + "?id=" + this.props.InvoiceId + "&export=pdf"
                }
                else if (this.props?.isFromDoctor) {
                    API_URL = Serviceurls.OP_INVOICE_PRINT + "?invoce_id=" + this.props.InvoiceId + Receipt_ID + "&clinic_id=" + this.props.clinic_id
                }else if (this.props?.isFromCanteen){
                    API_URL = Serviceurls.CANTEEN_BILLING + `?id=${this.props.InvoiceId}&export_type=pdf`
                }else if (this.props?.MultiBilling){
                    API_URL = Serviceurls.FO_MULTI_BILLING_POST + `?id=${this.props.InvoiceId}${Receipt_ID}&export_type=pdf`
                }
                else {
                    API_URL = Serviceurls.IP_INVOICE_PRINT + `?ip_bill_summary_id=` + this.props.InvoiceId + Receipt_ID
                }
                RestAPIService.PrintPDF(API_URL).
                    then((response) => {
                        if (response) {
                            this.props.ClosePopUp()
                            if(this.props.isDirectPrint){
                                OpenDirectPrint(response)
                            }else{
                                // Create a Blob from the PDF Stream
                                const file = new Blob(
                                    [response.data],
                                    { type: 'application/pdf' });
                                //Build a URL from the file
                                const fileURL = URL.createObjectURL(file);
                                //Open the URL on new Window
                                window.open(fileURL);
                            }
                            
                        }
                    }).catch((error) => {
                        this.props.error(error.message)
                    })
            }
        } catch (e) {
            this.props.ClosePopUp()
        }
    }

    sendeBill() {
        try {
            this.setState({ isEbill: true })
            let data = ''
            if (this.props.isFromOP) {
                data = {
                    "patient_id": this.props.patientID,
                    "op_bill_summary_id": this.props.InvoiceId,
                    "op_receipt_id": this.props.Receipt_id,
                    "is_sms": true,
                    "is_email": false
                }
            }
            else if (this.props?.isFromPharmacy) {
                data = {
                    "patient_id": this.props.PatientID,
                    "pharmacy_bill_summary_id": this.props.InvoiceId,
                    "is_sms": true,
                    "is_email": false
                }
            }
            else if (this.props?.isFromsmsIP) {
                data = {
                    "patient_id": this.props.patientID,
                    "ip_bill_summary_id": this.props.InvoiceId,
                    "ip_receipt_id": this.props.Receipt_id,
                    "is_sms": true,
                    "is_email": false
                }
            }
            RestAPIService.create(data, Serviceurls.SMS_SEND).
                then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message,
                            isEbill: false,
                            isSMSText: 'SMS Sended Successfully!'
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.message,
                        isEbill: false,
                        isSMSText: '',
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.data.message,
                isEbill: false,
                isSMSText: '',
            })
        }
    }


    printOPHomePrint() {
        try {
            this.setState({
                isPrintClick: true
            })
            // this.props.BilllistArray.forEach((item)=>{
                RestAPIService.PrintPDF(Serviceurls.OP_INVOICE_PRINT + "?invoce_id=" + this.props.BilllistArray[0].id).
                    then((response) => {
                        if (response) {
                            //Create a Blob from the PDF Stream
                            const file = new Blob(
                                [response.data],
                                { type: 'application/pdf' });
                            //Build a URL from the file
                            const fileURL = URL.createObjectURL(file);
                            //Open the URL on new Window
                            window.open(fileURL);
                        }
                    }).catch((error) => {
                         this.props.error(error.message)
                    })
        // })
            this.props.ClosePopUp()
                
        } catch (e) {
            this.props.ClosePopUp()
        }
    }

    render() {
        let testID = this.props.testID ? this.props.testID : "Billsucc";
        return (
            <div>
                <Modal
                    open={true}
                >
                    <Box component={'div'} className="eMed_Bill_Success_Preview_Main" >
                        <img className="eMed_Bill_Success_animation" src={ImagePaths.SuccessAnimationGif.default} />
                        <Typography fontWeight={600}>{this.state.isSMSText ? this.state.isSMSText : this.props.successTxt ? this.props.successTxt : 'Payment Received Successfully !'}</Typography>
                        <Box display={'flex'} gap={'0.5vw'}>
                            <Button emed_tid={`${testID}_next`} sx={{ textTransform: 'capitalize', }} size="small" variant="outlined" onClick={() => { this.props.ClosePopUp() }}>{this.props?.CloseBtnText ? this.props?.CloseBtnText : "Next Bill"}</Button>
                            {this.props.fromPharmaSaved ?
                                <Box sx={{ display: 'flex', gap: '0.5vw' }}>
                                    <Button emed_tid={`${testID}_next`} sx={{ textTransform: 'capitalize' }} size="small" variant="contained" onClick={() => { this.setState({ isPrintClick: true }, () => this.props.printOne()) }}>{this.props?.printOneTxt ? this.props?.printOneTxt : "Print Bill"}</Button>
                                    <Button emed_tid={`${testID}_next`} sx={{ textTransform: 'capitalize' }} size="small" variant="contained" onClick={() => { this.setState({ isPrintClick: true }, () => this.props.printTwo()) }}>{this.props?.printTwoTxt ? this.props?.printTwoTxt : "Print Bill"}</Button>
                                </Box>
                                :
                                <Button emed_tid={`${testID}_print`} sx={{ textTransform: 'capitalize', marginLeft: '2vw' }} disabled={this.state.isPrintClick} size="small" variant="contained" onClick={() => { this.props.isFromOPHome ? this.printOPHomePrint() : this.printInvoice() }}>Print Bill</Button>
                            }
                            {this.state.allowSms ?
                                <Button emed_tid={`${testID}_ebill`} disabled={this.state.isSMSText} sx={{ textTransform: 'none', marginLeft: '2vw' }} size="small" variant="contained" onClick={() => { if (!this.state.isEbill) { this.sendeBill() } }}>
                                    {this.state.isEbill ? <CircularProgress sx={{ color: 'white' }} size={20} /> : "Send eBill"}
                                </Button>
                                : null}
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }
}

export class ChecKoutSuceessPop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isErrorMsg : false,
            successMsg : false
        }
    }


    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    getIpSummaryBillPrint = () => {
        try {
            RestAPIService.getAllPrint(Serviceurls.IP_ADMISSION_BILL_SUMMARY_PRINT + "?ip_admission_id=" + this.props?.ip_admission_id + "&export_type=pdf").
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({
                        selectedOption: ""
                    })
                }).catch((error) => {
                    this.errorMessage(error.response?.data?.message)
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPatientBillDetailedPrint = (params) => {
        try {
            RestAPIService.getAll(Serviceurls.IP_PATIENT_BILL_DETAILED_PRINT + "?ip_admission_id=" + this.props?.ip_admission_id + "&export_type=pdf").
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ selectedOption: "" })
                }).catch((error) => {
                    this.errorMessage(error.response?.data?.message);
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    render() {
        let testID = this.props.testID ? this.props.testID : "Billsucc";
        return (
            <div>
                <Modal
                    open={true}
                >
                    <Box component={'div'} className="eMed_Bill_Success_Preview_Main" height={"23vw"}>
                        <img className="eMed_Bill_Success_animation" src={ImagePaths.SuccessAnimationGif.default} />
                        <Typography fontWeight={600}>{"Patient Successfully Checked Out !"}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", height:"15vw" }}>
                            <Button sx={{ textTransform: 'capitalize', }} size="small" onClick={() => { this.getIpSummaryBillPrint() }}>Print Summary Bill</Button>
                            <Button sx={{ textTransform: 'capitalize', marginY:"0.5vw" }} size="small" onClick={() => { this.getPatientBillDetailedPrint() }}>Print Detailed Bill</Button>
                            <Button emed_tid={`${testID}_next`} sx={{ textTransform: 'capitalize', }} size="small" onClick={() => {
                                clearCachevalue("IsForCheckedOut");
                                clearCachevalue('IppatientData')
                                this.props.history.push({ pathname: '/IPHome' })
                            }}>{"Move To IP Home"}</Button>
                        </Box>
                        <Box>
                        </Box>
                    </Box>
                </Modal>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        )
    }
}
export class VacentRoomPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <div>
                <Modal
                    open={true}
                >
                    <Box component={'div'} className="eMed_Bill_Success_Preview_Main" >
                        <img className="eMed_Bill_Success_animation" src={ImagePaths.SuccessAnimationGif.default} />
                        <Typography fontWeight={600}>Patient is Checked-Out.</Typography>
                        <Typography fontWeight={600}>Room is Vacant</Typography>
                    </Box>
                </Modal>
            </div>
        )
    }
}

export class CommonCorporateAndInsuranceDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const { data } = this.props
        let brandName = data?.brand_name ? data?.brand_name : data?.drug_name ? data?.drug_name : '-'
        let genericName = data?.generic_name ? data?.generic_name : data?.model_name ? data?.model_name : '-'
        let dosageType = data?.dosage_type ? data?.dosage_type : data?.dosagetype ? data?.dosagetype : data?.iol_type ? data?.iol_type : '-'
        let CompanyName = data?.credit_type === "Corprate credit" ? (data?.employer_name ? data?.employer_name : "-") : (data?.credit_type === "Insurance credit" ? (data?.insurance_name ? data?.insurance_name : "-") : "-")
        let StrengthType = data?.iol_power ? data?.iol_power : data?.dosage_strength ? data?.dosage_strength : '-'
        return (
            <>{
                this.props.fromPharma ?
                    <Box component={'div'}>
                        <Typography component={'div'}>
                            {(brandName?.length) > 10 ?
                                <Tooltip title={brandName} placement={'top'} arrow>
                                    <Typography id='eMed_Com_Patient_Name'>{(brandName ? ((brandName?.slice(0, 10) + '...')) : '-')}</Typography>
                                </Tooltip> : <Typography id='eMed_Com_Patient_Name'>{(brandName ? brandName : '-')}</Typography>}
                        </Typography>
                        {genericName?.length > 10 || dosageType?.length > 10 ?
                            <Box display={"flex"}>
                                <Tooltip placement="top" title={genericName}><Typography id='eMed_Com_Patient_UhidText' component={'div'}>{genericName.slice(0, 8) + '...'}</Typography></Tooltip> |
                                <Tooltip placement="top" title={dosageType}><Typography id='eMed_Com_Patient_UhidText' component={'div'}>{dosageType.slice(0, 8)}</Typography></Tooltip>
                            </Box> :
                            <Typography id='eMed_Com_Patient_UhidText' component={'div'}>{`${genericName} | ${dosageType}`}</Typography>}
                    </Box>
                    :
                    this.props.fromPharmaBilling ?
                        <Box component={'div'}>
                            {brandName?.length > 40 ?
                                <Tooltip placement="top" title={brandName} ><div id='eMed_Com_Patient_Name'>{brandName.slice(0, 40) + '...'}</div></Tooltip> :
                                <Typography id='eMed_Com_Patient_Name'>{brandName}</Typography>}
                            <Box display={'flex'}>
                                {genericName?.length > 18 ? <Tooltip placement="top" title={genericName}><Typography fontSize={'0.8vw'}>{genericName.slice(0, 18) + '...'}</Typography></Tooltip> :
                                    <Typography fontSize={'0.8vw'}>{genericName}</Typography>} {dosageType?.length > 18 ? <Tooltip placement="top" title={dosageType}><Typography fontSize={'0.8vw'}>{`| ${dosageType.slice(0, 17) + '...'}`}</Typography></Tooltip> :
                                        <Typography fontSize={'0.8vw'}>{`| ${dosageType} `}</Typography>} { <Typography fontSize={'0.8vw'}>{`| ${StrengthType}`}</Typography> }
                            </Box>
                        </Box>
                        :
                        (data?.credit_type === "Corprate credit" || data?.credit_type === "Insurance credit") ?
                            <Box component={'div'}>
                                <Typography component={'div'}>
                                    {(data?.credit_type?.length) > 18 ?
                                        <Tooltip title={data?.credit_type} placement={'top'} arrow>
                                            <div><Typography id=''>{(data?.credit_type ? ((data?.credit_type?.slice(0, 18) + '...')) : '-')}</Typography>
                                            </div></Tooltip> :
                                        <Typography id=''>{(
                                            data?.credit_type ? data?.credit_type === "Corprate credit" ? "Corporate Credit" : data?.credit_type : '-')}</Typography>}
                                </Typography>
                                <Box>
                                    {(CompanyName?.length) > 20 ?
                                        <Tooltip title={CompanyName} placement={'top'} arrow>
                                            <Typography id='eMed_Com_Patient_UhidText'>{(CompanyName ? ((CompanyName?.slice(0, 20) + '...')) : '-')}</Typography>
                                        </Tooltip> : <Typography id='eMed_Com_Patient_UhidText'>{(CompanyName ? CompanyName : '-')}</Typography>}
                                </Box>
                            </Box>
                            : <Typography>{data?.credit_type}</Typography>
            }</>
        )
    }
}

export class CommonTimeRangePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startTime: this.props.startTime,
            endTime: this.props.endTime,
            fromDate: this.props.FromDate,
            toDate: this.props.ToDate,
            error: null
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                startTime: this.props.startTime,
                endTime: this.props.endTime,
                fromDate: this.props.FromDate,
                toDate: this.props.ToDate,
            })
        }
    }

    render() {
        return (
            <><Box component={'div'} display={'flex'} margin={'0.2vw 1vw'}>
                <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            disabled = {this.props.disable ? true : false}
                            label='Start Time'
                            value={this.state.startTime}
                            onChange={(newStartTime) => this.setState({ startTime: newStartTime },
                                () => {
                                    this.props.selectedTime(this.state.startTime, this.state.endTime)
                                })}
                            renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "HH:MM (AM|PM)" }} style={{ width: "98%" }} size="small" />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label='End Time'
                            value={this.state.endTime}
                            minTime={this.state.fromDate === this.state.toDate ? this.state.startTime : null}
                            onError={(error) => this.setState({ error: error }, () => {
                                let Error = false
                                if (this.state.error) {
                                    Error = true
                                } else {
                                    Error = false
                                }
                                this.props.selectedTime(this.state.startTime, this.state.endTime, Error)
                            }
                            )}
                            disabled={this.state.startTime === null}
                            onChange={(newEndTime) => this.setState({ endTime: newEndTime },
                                () => {
                                    let Error = false
                                    if (this.state.error) {
                                        Error = true
                                    } else {
                                        Error = false
                                    }
                                    this.props.selectedTime(this.state.startTime, this.state.endTime, Error)
                                })}
                            renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "HH:MM (AM|PM)" }} style={{ width: "95%", marginLeft: "0.9vw" }} size="small" />}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
                {this.state.startTime !== null && this.state.endTime === null ?
                    <Box component={'div'} margin={"0vw 1vw"}>
                        <FormHelperText sx={{ color: 'red', textAlign: 'center' }}>{"Select Both Start Time and End Time"}</FormHelperText>
                    </Box> : null}</>
        )
    }
}

export class CommonTimePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            time: this.props.time
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                time: this.props.time
            })
        }
    }

    render() {
        return (
            <Box component={'div'} margin={'1vw'}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        label={'Select Time'}
                        value={this.state.time}
                        onChange={(setTime) => this.setState({ time: setTime })}
                        renderInput={(props) => <TextField {...props} size="small" sx={{ width: this.props.width }} />}
                    />
                </LocalizationProvider>
            </Box>
        )
    }
}

export class PharmaConfirmPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            OpenConfirm: true,
            disableBtn :this.props.disableBtn,
        }
    }

    render() {
        return (
            <Dialog
                open={this.state.OpenConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent dividers sx={{ width: '30vw', height: '6vw' }}>
                    <DialogContentText emed_tid='pop_tlt' id="alert-dialog-description">
                        {this.props.popupTitle}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button emed_tid='pop_no' size="small" variant="outlined" onClick={() => {
                        this.setState({ OpenModel: false }, () => { this.props.handleNo() })
                    }}>No</Button>
                    <Button emed_tid='pop_yes' size="small" variant="contained" disabled={this.state.disableBtn} onClick={() => this.setState({disableBtn : true},()=>{this.props.handleYes()})}>Yes</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
export class AddNewListItemPop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Open: true,
            newName: "",
            newNameError: false,
            problemData : this.props.problemData ? this.props.problemData : ""
        }
    }
    handleChange =(e)=>{
        let { value } = e.target;
        value = value.trimStart();
        let isValid = false;

        if(this.props.problemData){
            if (value === "" ||  value) {
                this.setState({ problemData : value})
            }
        }else{
            this.setState({ newName: e.target.value})
        }
    }

    render() {
        const { t } = this.props
        
        return (
            <div>
                <Dialog open={this.state.Open} >
                    <DialogTitle className='eMed_CreditPoP_TitleDiv' sx={{ borderColor: Colors.themeDark }}>
                        <Typography marginLeft={"1vw"}>{`Add New ${this.props.title}`}</Typography>
                        <Button onClick={() => this.props.ClosePopUp(this.state.problemData)} >
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                        </Button>
                    </DialogTitle>
                    <DialogContent sx={{ padding: "2vw" }}>
                        <TextField
                            inputProps={{ maxLength: 30 }}
                            sx={{ width: "20vw", marginTop: "2vw" }}
                            size='small'
                            autoComplete="off"
                            label={this.props.title}
                            value={this.state.problemData ? this.state.problemData :  this.state.newName}
                            onChange={this.handleChange}
                            error={this.state.newNameError}
                            helperText={this.state.newNameError ? `Enter the ${this.props.title}` : ""}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant='outlined' size='small' className='eMed_Filter_Btns'
                            onClick={() => { this.setState({ newName: "",problemData : "" }) }}>{"Clear"}</Button>
                        <Button variant='contained' size='small' className='eMed_Filter_Btns'
                            onClick={() => {
                                if (CommonValidation.removeSpace(this.state.newName) !== "") {
                                    this.props.sendNewData(CommonValidation.removeSpace(this.state.newName))
                                }
                                else if (CommonValidation.removeSpace(this.state.problemData) !== "") {
                                        this.props.sendproblemData(CommonValidation.removeSpace(this.state.problemData),this.props.title)
                                        this.setState({ problemData: '' })
                                    }
                                else {
                                    this.setState({
                                        newNameError: true
                                    })
                                }
                            }}>{"Add"}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export class NoAccessScreen extends Component{
    render(){
        return <Typography className='eMed_NoAccess_Text' sx={{ paddingTop: "15.65vw" }}>No Access For This Screen</Typography>
    }
}
export class ToolsField extends Component {

    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
        this.uploadPDF = React.createRef();
        this.state = {
            BoldFont: false,
            italic: false,
            underline: false,
            justifyLeft: false,
            justifyCenter: false,
            justifyRight: false,
            isDisableDataDispatch: true,
            status :this.props.selectedServiceData?.result_status,
            isdispatch :this.props.isDispatchshown ? true :false,
            pdfUploadList: this.props.ResultPDF ? this.props.ResultPDF : [],
        }
    }

    componentDidMount() {
        this.editorRef.current.innerHTML = this.props.EditorRef
    }

    componentDidUpdate(preProps) {
        if (this.props !== preProps && this.props.EditorRef) {
            this.editorRef.current.innerHTML = this.props.EditorRef
        }
    }

    componentWillUnmount() {
        // this.editorRef.current.removeEventListener('paste', this.handlePaste);
    }

    handleStyleChange = (command) => {
        document.execCommand(command, false, null);
        this.editorRef.current.focus();
        switch (command) {
            case 'bold': this.setState({ BoldFont: !this.state.BoldFont })
                break
            case 'italic': this.setState({ italic: !this.state.italic })
                break
            case 'underline': this.setState({ underline: !this.state.underline })
                break
            default: this.setState(prevState => ({
                justifyLeft: command === 'justifyLeft' ? !prevState["justifyLeft"] : false,
                justifyCenter: command === 'justifyCenter' ? !prevState["justifyCenter"] : false,
                justifyRight: command === 'justifyRight' ? !prevState['justifyRight'] : false,
            }), () => {
                if (this.state.justifyCenter === false && this.state.justifyRight === false) {
                    this.setState({
                        justifyLeft: true
                    })
                    document.execCommand('justifyLeft', false, null);
                }

            });
                break

        }

    };

    handlePaste = (event) => {
        event.preventDefault();
        const plainText = event.clipboardData.getData('text/plain');
        const normalizedText = plainText.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');
        document.execCommand('insertText', false, normalizedText);
    };

    getTextStylesAtCursor = () => {
        this.setState({
            italic: false,
            BoldFont: false,
            underline: false,
            justifyLeft: false,
            justifyCenter: false,
            justifyRight: false,
            disable: false,
        }, () => {
            const selection = window.getSelection();
            if (!selection.rangeCount) return null;

            const range = selection.getRangeAt(0);
            const node = range.commonAncestorContainer;
            const textNode = node.nodeType === Node.TEXT_NODE ? node.parentElement : node;
            const computedStyle = window.getComputedStyle(textNode);

            const textStyle = {
                fontWeight: computedStyle.fontWeight,
                fontStyle: computedStyle.fontStyle,
                textDecoration: computedStyle.textDecoration,
                textAlign: computedStyle.textAlign
            };

            if (textStyle.fontStyle === "italic") {
                this.setState({ italic: true });
            }
            if (textStyle.fontWeight === '700') {
                this.setState({ BoldFont: true });
            }
            if (textStyle.textDecoration.includes("underline") || document.queryCommandState('underline')) {
                this.setState({ underline: true });
            }
            if (textStyle.textAlign === 'left') {
                this.setState({ justifyLeft: true });
            }
            if (textStyle.textAlign === 'center') {
                this.setState({ justifyCenter: true });
            }
            if (textStyle.textAlign === 'right') {
                this.setState({ justifyRight: true });
            }
        })
    }

    onUploadPDF = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            this.props.errorMsg("No file selected.");
            return;
        }
        if (file.type !== 'application/pdf') {
            this.props.errorMsg("Only PDF files are allowed.");
            return;
        }
        const maxSize = 3 * 1024 * 1024; // 3 MB
        if (file.size > maxSize) {
            this.props.errorMsg("File size exceeds 3 MB.");
            return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            const arrayBuffer = event.target.result; // Binary data
            const blob = new Blob([arrayBuffer], { type: file.type });
            let pdfDetails = [{
                document_file: blob,
                document_name: file.name
            }]
            this.setState({
                pdfUploadList: pdfDetails,
            });
            this.uploadPDF.current.value = "";
        };
        reader.onerror = () => {
            this.props.errorMsg("Error reading the file.");
        };
        reader.readAsArrayBuffer(file); // Read the file as binary data
    }

    arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };

    render() {               
        return (
            <Paper component={'div'} className='rad_tst_tmp_content' sx={{ height: this.props.overallHeight }} elevation={1}>
                <Box component={'div'} borderBottom={'1px solid lightgray'} pl={'0.5vw'} height={'2.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                    <Typography fontWeight={600}>{this.props.Title}</Typography>
                    <div className="toolbar" style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }}>
                        <ButtonGroup variant="text" aria-label="Basic button group" sx={{ visibility: this.props?.EditorDisable ? 'hidden' : "visible" }}>
                            <Tooltip title={"Bold"} placement={'top'} arrow><IconButton disabled={!this.editorRef?.current?.innerHTML || this.props?.EditorDisable} onClick={() => this.handleStyleChange('bold')}><FormatBoldIcon color={this.state.BoldFont ? "primary" : "black"} /></IconButton></Tooltip>
                            <Tooltip title={"Italic"} placement={'top'} arrow><IconButton disabled={!this.editorRef?.current?.innerHTML || this.props?.EditorDisable} onClick={() => this.handleStyleChange('italic')} ><FormatItalicOutlinedIcon color={this.state.italic ? "primary" : "black"} /></IconButton></Tooltip>
                            <Tooltip title={"Underline"} placement={'top'} arrow><IconButton disabled={!this.editorRef?.current?.innerHTML || this.props?.EditorDisable} onClick={() => this.handleStyleChange('underline')}><FormatUnderlinedOutlinedIcon color={this.state.underline ? "primary" : "black"} /></IconButton></Tooltip>
                            <Tooltip title={"Left Align"} placement={'top'} arrow><IconButton disabled={!this.editorRef?.current?.innerHTML || this.props?.EditorDisable} onClick={() => this.handleStyleChange('justifyLeft')}><FormatAlignLeftOutlinedIcon color={this.state.justifyLeft ? "primary" : "black"} /></IconButton></Tooltip>
                            <Tooltip title={"Center Align"} placement={'top'} arrow><IconButton disabled={!this.editorRef?.current?.innerHTML || this.props?.EditorDisable} onClick={() => this.handleStyleChange('justifyCenter')}><FormatAlignCenterOutlinedIcon color={this.state.justifyCenter ? "primary" : "black"} /></IconButton></Tooltip>
                            <Tooltip title={"Right Align"} placement={'top'} arrow><IconButton disabled={!this.editorRef?.current?.innerHTML || this.props?.EditorDisable} onClick={() => this.handleStyleChange('justifyRight')}><FormatAlignRightOutlinedIcon color={this.state.justifyRight ? "primary" : "black"} /></IconButton></Tooltip>
                        </ButtonGroup>
                    </div>
                </Box>
                <Box component={'div'}   height={this.props.height}>
                    <Box
                        onMouseUp={this.getTextStylesAtCursor}
                        onSelect={this.getTextStylesAtCursor}
                        component={'div'}
                        ref={this.editorRef}
                        contentEditable={this.props?.EditorDisable ? false : true}
                        className="editor"
                        sx={{ height: this.props.EditorHeight, overflow: "scroll" }}
                        spellcheck="false"
                        onPaste={this.handlePaste}>
                    </Box>
                </Box>
                <Box display={'flex'} justifyContent={this.props?.FromTestReport ? 'space-between' : 'flex-end'} alignItems={'center'}>
                    {this.props?.FromTestReport ?
                        <Box sx={{ marginLeft: '0.5vw', display: 'flex', gap: '0.5vw' }}>
                            <input
                                type="file"
                                accept="application/pdf"
                                ref={this.uploadPDF}
                                id="pdfInput"
                                style={{ display: 'none' }}
                                onChange={this.onUploadPDF}
                            />
                            {this.state.status === "Dispatched" ? null : <Button size="small" variant="contained" disabled={this.state.pdfUploadList?.length > 0 || this.state.status === "Completed"} onClick={() => this.uploadPDF.current.click()}>
                                Upload Pdf
                            </Button>}
                            {this.state.pdfUploadList?.length > 0 ?
                                <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    {(this.state.status === "Completed" || this.state.isdispatch || this.props?.EditorDisable) ? null : <IconButton onClick={() => { this.setState({ pdfUploadList: [] }) }}><img src={ImagePaths.CancelIcon.default} height={'15vw'} width={'15vw'} /></IconButton>}
                                    <Typography color={'#04B7B1'} display={'flex'} flexDirection={'row'} alignItems={'center'}><img src={ImagePaths.DocumentGreen.default} height={'12.5vw'} width={'12.5vw'} alt="document" color={'#04B7B1'} />
                                        {this.state.pdfUploadList[0]?.document_name ?
                                            this.state.pdfUploadList[0]?.document_name?.length > 23 ?
                                                <Tooltip title={this.state.pdfUploadList[0]?.document_name} placement='top'><div>{this.state.pdfUploadList[0]?.document_name.slice(0, 20) + '...'}</div></Tooltip>
                                                : this.state.pdfUploadList[0]?.document_name
                                            : '-'}
                                    </Typography>
                                </Box>
                                : null}
                        </Box>
                        : null}
                    <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} height={'8vh'} visibility={this.state.status === "Dispatched" ? 'hidden' : "visible"}>
                        <Button disabled={this.state.status === "Completed" ? true : false} variant='outlined' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                            onClick={() => { this.editorRef.current.innerHTML = ""; this.setState({pdfUploadList: []}) }}
                        >Clear</Button>
                        {
                            this.props?.FromTestReport ?
                                <Box>
                                    <Button disabled={this.state.status === "Completed" ? true : false} variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                                        onClick={() => { this.props.handleSaveResult(this.editorRef.current.innerHTML, true, false, this.state.pdfUploadList); }}
                                    >Save</Button>
                                    <Button disabled={this.state.status === "Completed" ? true : false} variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                                        onClick={() => { this.props.handlePostResult(this.editorRef.current.innerHTML, false, true, this.state.pdfUploadList); }}
                                    >Complete</Button>
                                    <Button disabled={this.state.status === "Completed" ? false : true} variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                                        onClick={() => { this.props.handleDispatchResult(this.editorRef.current.innerHTML,); }}
                                    >Dispatch</Button>
                                </Box>
                                :
                                <Button variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                                    onClick={() => { this.props.handlePost(this.editorRef.current.innerHTML); }}
                                >Complete</Button>
                        }

                    </Box>
                </Box>
            </Paper>
        );
    }

}
export class ToolsFieldWithMic extends Component {
    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
        this.uploadPDF = React.createRef();
        this.state = {
            // toolbar state
            bold: false,
            italic: false,
            underline: false,
            justifyLeft: false,
            justifyCenter: false,
            justifyRight: false,
            bulletList: false,
            orderedList: false,

            // editor state
            disableEdit: true,
            editedContent: '',
            isOutFocus: false,

            // mic
            listenPopUp: false,
            ClickKey: false,

            // upload file
            isDisableDataDispatch: true,
            status :this.props.selectedServiceData?.result_status,
            isdispatch :this.props.isDispatchshown ? true :false,
            pdfUploadList: this.props.ResultPDF ? this.props.ResultPDF : [],
        }
    }

    componentDidMount() {
        this.editorRef.current.innerHTML = this.props.EditorRef
    }

    componentDidUpdate(preProps) {
        if( this.props !== preProps && this.props.isFrom === "dischargeSummary" && this.state.editedContent !== this.props.EditorRef ){
            this.editorRef.current.innerHTML = this.props.EditorRef
        }
        else if (this.props !== preProps && this.props.EditorRef && !this.props.isFrom) {
            this.editorRef.current.innerHTML = this.props.EditorRef
        }

        // Refocus editor when listenPopUp is closed
        if (preProps.listenPopUp && !this.state.listenPopUp) {
            this.editorRef.current.focus();
        }
    }

    componentWillUnmount() {
        // this.editorRef.current.removeEventListener('paste', this.handlePaste);
    }

    handleStyleChange = (command) => {
        document.execCommand(command, false, null);
        this.editorRef.current.focus();
        switch (command) {
            case 'bold': this.setState({ bold: !this.state.bold })
                break
            case 'italic': this.setState({ italic: !this.state.italic })
                break
            case 'underline': this.setState({ underline: !this.state.underline })
                break
            case 'insertUnorderedList': this.setState({ bulletList: !this.state.bulletList });
                break;
            case 'insertOrderedList': this.setState({ orderedList: !this.state.orderedList });
                break;
            default: this.setState(prevState => ({
                justifyLeft: command === 'justifyLeft' ? !prevState["justifyLeft"] : false,
                justifyCenter: command === 'justifyCenter' ? !prevState["justifyCenter"] : false,
                justifyRight: command === 'justifyRight' ? !prevState['justifyRight'] : false,
            }), () => {
                if (this.state.justifyCenter === false && this.state.justifyRight === false) {
                    this.setState({
                        justifyLeft: true
                    })
                    document.execCommand('justifyLeft', false, null);
                }

            });
                break
        }
    };

    handlePaste = (event) => {
        event.preventDefault();
        const plainText = event.clipboardData.getData('text/plain');
        const normalizedText = plainText.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');
        document.execCommand('insertText', false, normalizedText);
    };
    

    clearStyleState = () => {
        this.setState({
            disableEdit: true,
            italic: false,
            bold: false,
            underline: false,
            justifyLeft: false,
            justifyCenter: false,
            justifyRight: false,
            bulletList: false,
            orderedList: false,
        })
    }

    sendSavedData = () => {
        function removeEmptyStyleTagsAndReplaceSpan(content) {
            const div = document.createElement('div');
            div.innerHTML = content;
    
            // Iterate through all elements with style attributes
            const elements = div.querySelectorAll('*[style]');
            elements.forEach((element) => {
                const style = element.getAttribute('style');
                
                // Retain only `text-align` properties (center, left, right)
                const retainedStyles = style
                    .split(';')
                    .map((rule) => rule.trim())
                    .filter((rule) => {
                        const [property, value] = rule.split(':').map((item) => item.trim());
                        return (
                            property === 'text-align' &&
                            (value === 'center' || value === 'left' || value === 'right')
                        );
                    })
                    .join('; ');
    
                if (retainedStyles) {
                    element.setAttribute('style', retainedStyles);
                } else {
                    // Remove the style attribute if no valid styles remain
                    element.removeAttribute('style');
                }
            });
    
            // Replace <span> tags with <div>
            const spans = div.querySelectorAll('span');
            spans.forEach((span) => {
                const newDiv = document.createElement('div');
                // Transfer all child nodes and attributes to the new <div>
                Array.from(span.attributes).forEach((attr) => {
                    newDiv.setAttribute(attr.name, attr.value);
                });
                while (span.firstChild) {
                    newDiv.appendChild(span.firstChild);
                }
                span.parentNode.replaceChild(newDiv, span);
            });

            if(div.innerHTML === "<br>"){
                return ""
            }else{
                return div.innerHTML; // Return sanitized HTML
            }
    
        }
    
        if (this.state.isOutFocus) {
            this.clearStyleState();
        }
    
        const cleanedContent = removeEmptyStyleTagsAndReplaceSpan(this.editorRef.current.innerHTML);
        this.setState({ editedContent: cleanedContent }, () => {
            // Get the editor content and clean it before saving
            this.props.saveData(this.props.stateName, cleanedContent, this.props.postState);
        });
    };
    

    getTextStylesAtCursor = () => {
        this.setState({
            italic: false,
            bold: false,
            underline: false,
            justifyLeft: false,
            justifyCenter: false,
            justifyRight: false,
            disableEdit: false,
        }, () => {
            const selection = window.getSelection();
            if (!selection.rangeCount) return null;

            const range = selection.getRangeAt(0);
            const node = range.commonAncestorContainer;
            const textNode = node.nodeType === Node.TEXT_NODE ? node.parentElement : node;
            const computedStyle = window.getComputedStyle(textNode);

            const textStyle = {
                fontWeight: computedStyle.fontWeight,
                fontStyle: computedStyle.fontStyle,
                textDecoration: computedStyle.textDecoration,
                textAlign: computedStyle.textAlign
            };

            if (textStyle.fontStyle === "italic" || document.queryCommandState('italic')) {
                this.setState({ italic: true });
            }
            if (textStyle.fontWeight === '700' || document.queryCommandState('bold')) {
                this.setState({ bold: true });
            }
            if (textStyle.textDecoration.includes("underline") || document.queryCommandState('underline')) {
                this.setState({ underline: true });
            }
            if (textStyle.textAlign === 'left') {
                this.setState({ justifyLeft: true });
            }
            if (textStyle.textAlign === 'center') {
                this.setState({ justifyCenter: true });
            }
            if (textStyle.textAlign === 'right') {
                this.setState({ justifyRight: true });
            }
        })
    }

    // mic
    listenPopUp = () => {
        this.setState({
            listenPopUp: false,
        });
    };

    voiceToTextgetter = (textStorage) => {
        if (textStorage !== '') {
            if (this.state.ClickKey) {
                // Append or insert the text into the editor's content
                const newContent = this.props.EditorRef + " " + textStorage;
                this.setState({ editedContent: newContent }, () => {
                    setTimeout(() => {
                        if (this.editorRef.current) {
                            this.editorRef.current.innerHTML = newContent;
    
                            // Ensure contentEditable is active and force focus
                            this.editorRef.current.setAttribute("contentEditable", "true");
                            this.editorRef.current.focus();
    
                            // Optionally ensure the cursor is at the end of the content
                            const range = document.createRange();
                            const sel = window.getSelection();
                            range.selectNodeContents(this.editorRef.current);
                            range.collapse(false);
                            sel.removeAllRanges();
                            sel.addRange(range);
                        }
                    }, 0);
                });
            }
        }
    };    

    handleMicClick = () => {
        this.setState({ ClickKey: true, listenPopUp: true, isOutFocus: true });
    };

    // upload file
    onUploadPDF = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            this.props.errorMsg("No file selected.");
            return;
        }
        if (file.type !== 'application/pdf') {
            this.props.errorMsg("Only PDF files are allowed.");
            return;
        }
        const maxSize = 3 * 1024 * 1024; // 3 MB
        if (file.size > maxSize) {
            this.props.errorMsg("File size exceeds 3 MB.");
            return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            const arrayBuffer = event.target.result; // Binary data
            const blob = new Blob([arrayBuffer], { type: file.type });
            let pdfDetails = [{
                document_file: blob,
                document_name: file.name
            }]
            this.setState({
                pdfUploadList: pdfDetails,
            });
            this.uploadPDF.current.value = "";
        };
        reader.onerror = () => {
            this.props.errorMsg("Error reading the file.");
        };
        reader.readAsArrayBuffer(file); // Read the file as binary data
    }

    arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };

    removeEmptyStyleTagsAndReplaceSpan =(content)=> {
        const div = document.createElement('div');
        div.innerHTML = content;

        // Iterate through all elements with style attributes
        const elements = div.querySelectorAll('*[style]');
        elements.forEach((element) => {
            const style = element.getAttribute('style');
            
            // Retain only `text-align` properties (center, left, right)
            const retainedStyles = style
                .split(';')
                .map((rule) => rule.trim())
                .filter((rule) => {
                    const [property, value] = rule.split(':').map((item) => item.trim());
                    return (
                        property === 'text-align' &&
                        (value === 'center' || value === 'left' || value === 'right')
                    );
                })
                .join('; ');

            if (retainedStyles) {
                element.setAttribute('style', retainedStyles);
            } else {
                // Remove the style attribute if no valid styles remain
                element.removeAttribute('style');
            }
        });

        // Replace <span> tags with <div>
        const spans = div.querySelectorAll('span');
        spans.forEach((span) => {
            const newDiv = document.createElement('div');
            // Transfer all child nodes and attributes to the new <div>
            Array.from(span.attributes).forEach((attr) => {
                newDiv.setAttribute(attr.name, attr.value);
            });
            while (span.firstChild) {
                newDiv.appendChild(span.firstChild);
            }
            span.parentNode.replaceChild(newDiv, span);
        });

        if(div.innerHTML === "<br>"){
            return ""
        }else{
            return div.innerHTML; // Return sanitized HTML
        }
    }

    render() {
        let disable = false 
        if (this.props.isFrom === "dischargeSummary" && this.state.disableEdit) {
            disable = true
        } else {
            disable = false
        }       

        return (
            <Paper component={'div'} className={this.props.isFrom === 'dischargeSummary' ? 'discharge_Sum_inner_container':'rad_tst_tmp_content'} sx={{ height: this.props.overallHeight }} elevation={1}>
                <Box component={'div'} className={this.props.isFrom === 'dischargeSummary' ? 'discharge_Sum_header_container' : ''} borderBottom={'1px solid lightgray'} pl={'0.5vw'} height={'3.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={this.props.isFrom === "dischargeSummary" ? 'space-betweeen' : 'flex-start'} onMouseLeave={()=>{this.setState({isOutFocus: true})}} onMouseOver={()=>{this.setState({isOutFocus: false})}}>
                    <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",width:"100%"}}>
                        <Typography fontWeight={600}>{this.props.Title}</Typography>
                        <div className="toolbar" style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }} onMouseLeave={()=>{this.setState({isOutFocus: true})}} onMouseOver={()=>{this.setState({isOutFocus: false})}}>
                            <ButtonGroup variant="text" aria-label="Basic button group" sx={{ visibility: this.props?.EditorDisable ? 'hidden' : "visible" }}>
                                <Tooltip title={"Bold"} placement={'top'} arrow><IconButton disabled={disable || this.props?.EditorDisable} onClick={() => this.handleStyleChange('bold')}><FormatBoldIcon color={this.state.bold ? "primary" : "black"} /></IconButton></Tooltip>
                                <Tooltip title={"Italic"} placement={'top'} arrow><IconButton disabled={disable || this.props?.EditorDisable} onClick={() => this.handleStyleChange('italic')} ><FormatItalicOutlinedIcon color={this.state.italic ? "primary" : "black"} /></IconButton></Tooltip>
                                <Tooltip title={"Underline"} placement={'top'} arrow><IconButton disabled={disable || this.props?.EditorDisable } onClick={() => this.handleStyleChange('underline')}><FormatUnderlinedOutlinedIcon color={this.state.underline ? "primary" : "black"} /></IconButton></Tooltip>
                                <Tooltip title={"Left Align"} placement={'top'} arrow><IconButton disabled={disable || this.props?.EditorDisable } onClick={() => this.handleStyleChange('justifyLeft')}><FormatAlignLeftOutlinedIcon color={this.state.justifyLeft ? "primary" : "black"} /></IconButton></Tooltip>
                                <Tooltip title={"Center Align"} placement={'top'} arrow><IconButton disabled={disable || this.props?.EditorDisable } onClick={() => this.handleStyleChange('justifyCenter')}><FormatAlignCenterOutlinedIcon color={this.state.justifyCenter ? "primary" : "black"} /></IconButton></Tooltip>
                                <Tooltip title={"Right Align"} placement={'top'} arrow><IconButton disabled={disable || this.props?.EditorDisable } onClick={() => this.handleStyleChange('justifyRight')}><FormatAlignRightOutlinedIcon color={this.state.justifyRight ? "primary" : "black"} /></IconButton></Tooltip>
                                {/* <Box sx={{ borderLeft: 1, display: 'flex', borderColor: 'grey.500'}}>
                                    <Tooltip title={"Bullet List"} placement={"top"} arrow><IconButton disabled={disable || this.props?.EditorDisable} onClick={() => this.handleStyleChange('insertUnorderedList')}><FormatListBulletedIcon color={this.state.bulletList ? "primary" : "black"} /></IconButton></Tooltip>
                                    <Tooltip title={"Numbered List"} placement={"top"} arrow><IconButton disabled={disable || this.props?.EditorDisable} onClick={() => this.handleStyleChange('insertOrderedList')}><FormatListNumberedIcon color={this.state.orderedList ? "primary" : "black"} /></IconButton></Tooltip>
                                </Box> */}
                            </ButtonGroup>
                        </div>
                    </div>
                    <Tooltip title={"Mic"} placement={"top"} arrow><IconButton disabled={disable || this.props?.EditorDisable} onClick={this.handleMicClick}><MicIcon sx={{ color: grey[1000], cursor: "pointer", visibility: this.props?.EditorDisable ? 'hidden' : "visible" }} /></IconButton></Tooltip>
                </Box>
                <Box component={'div'} borderBottom={'1px solid lightgray'} height={this.props.height}>
                    <Box
                        onMouseUp={this.getTextStylesAtCursor}
                        onSelect={this.getTextStylesAtCursor}
                        component={'div'}
                        ref={this.editorRef}
                        contentEditable={this.props?.EditorDisable ? false : true}
                        className="editor"
                        sx={{ height: this.props.EditorHeight, overflow: "scroll", minHeight: this.props?.minHeight ? this.props.minHeight : 'auto',outline:"none" }}
                        onBlur={this.props.isFrom === "dischargeSummary" ? this.sendSavedData : null}
                        spellcheck="false"
                        onPaste={this.handlePaste}
                        >
                    </Box>
                </Box>
                {this.props.isFrom === "dischargeSummary" ? null :
                    <Box display={'flex'} justifyContent={this.props?.FromTestReport ? 'space-between' : 'flex-end'} alignItems={'center'} sx={{margin:"0.3vh"}}>
                         {this.props?.FromTestReport ?
                            <Box sx={{ marginLeft: '0.5vw', display: 'flex', gap: '0.5vw' }}>
                                <input
                                    type="file"
                                    accept="application/pdf"
                                    ref={this.uploadPDF}
                                    id="pdfInput"
                                    style={{ display: 'none' }}
                                    onChange={this.onUploadPDF}
                                />
                                {this.state.status === "Dispatched" ? null : <Button size="small" variant="contained" disabled={this.state.pdfUploadList?.length > 0 || this.state.status === "Completed"} onClick={() => this.uploadPDF.current.click()}>
                                    Upload Pdf
                                </Button>}
                                {this.state.pdfUploadList?.length > 0 ?
                                    <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        {(this.state.status === "Completed" || this.state.isdispatch || this.props?.EditorDisable) ? null : <IconButton onClick={() => { this.setState({ pdfUploadList: [] }) }}><img src={ImagePaths.CancelIcon.default} height={'15vw'} width={'15vw'} /></IconButton>}
                                        <Typography color={'#04B7B1'} display={'flex'} flexDirection={'row'} alignItems={'center'}><img src={ImagePaths.DocumentGreen.default} height={'12.5vw'} width={'12.5vw'} alt="document" color={'#04B7B1'} />
                                            {this.state.pdfUploadList[0]?.document_name ?
                                                this.state.pdfUploadList[0]?.document_name?.length > 23 ?
                                                    <Tooltip title={this.state.pdfUploadList[0]?.document_name} placement='top'><div>{this.state.pdfUploadList[0]?.document_name.slice(0, 20) + '...'}</div></Tooltip>
                                                    : this.state.pdfUploadList[0]?.document_name
                                                : '-'}
                                        </Typography>
                                    </Box>
                                    : null}
                            </Box>
                        : null}
                        <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} height={'8vh'} visibility={this.state.status === "Dispatched" ? 'hidden' : "visible"}>
                            <Button disabled={this.state.status === "Completed" ? true : false} variant='outlined' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                                onClick={() => { this.editorRef.current.innerHTML = ""; this.setState({pdfUploadList: []}) }}
                            >Clear</Button>
                            {
                                this.props?.FromTestReport ?
                                    <Box>
                                        <Button disabled={this.state.status === "Completed" ? true : false} variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                                            onClick={() => { this.props.handleSaveResult(this.removeEmptyStyleTagsAndReplaceSpan(this.editorRef.current.innerHTML), true, false, this.state.pdfUploadList); }}
                                        >Save</Button>
                                        <Button disabled={this.state.status === "Completed" ? true : ((!this?.editorRef?.current?.innerHTML || this?.editorRef?.current?.innerHTML === "<br>") && this.state.pdfUploadList?.length === 0)} variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                                            onClick={() => { this.props.handlePostResult(this.removeEmptyStyleTagsAndReplaceSpan(this.editorRef.current.innerHTML), false, true, this.state.pdfUploadList); }}
                                        >Complete</Button>
                                        <Button disabled={this.state.status === "Completed" ? false : true} variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                                            onClick={() => { this.props.handleDispatchResult(this.removeEmptyStyleTagsAndReplaceSpan(this.editorRef.current.innerHTML),); }}
                                        >Dispatch</Button>
                                    </Box>
                                    :
                                    <Button variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                                        onClick={() => { this.props.handlePost(this.removeEmptyStyleTagsAndReplaceSpan(this.editorRef.current.innerHTML)); }}
                                    >Complete</Button>
                            }

                        </Box>
                    </Box>
                }
                {this.state.listenPopUp ?
                    <VoiceToText
                        voiceTextConverter={this.voiceToTextgetter.bind(this)}
                        listenPopUp={this.listenPopUp.bind(this)}
                        micTextEditDisabled={this.props.micTextEditDisabled}
                    /> : null
                }
            </Paper>
        );
    }
}

export class PovEditPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            povEditTxt: this.props.povEditText,
            povData: this.props.povData,
            povList: [],
            isBtnClked: false,
        }
    }

    componentDidMount = () => {
        const pov_list = JSON.parse(localGetItem("PurposeOfVisit") || '[]');
        const arr = [...new Set(pov_list.map(item => item.reason))];

        if (arr.length) {
            this.setState({ povList: arr });
        }
    }

    closePopup = () => {
        this.props.AlertPopupClose();
    };

    btnDisable = (flag) => {
        this.setState({
            isBtnClked: flag
        });
    };

    postPovChange = () => {
        let { povData, povEditTxt } = this.state
        let clinicId = localGetItem('SelectedDoctorClinicId');
        if (CommonValidation.removeSpace(povEditTxt) !== "") {
            let datas = {
                "purpose_of_visit": CommonValidation.removeSpace(povEditTxt),
                "appointment_id": povData.appointment_id
            }
            if (clinicId) {
                datas["clinic_id"] = clinicId;
            }

            try {
                this.btnDisable(true);
                RestAPIService.create(datas, Serviceurls.FO_HOME_OP_POV_UPDATE)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.closePopup();
                            this.props.getTableData();
                            this.btnDisable(false);
                            this.props.successMsg(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.btnDisable(false);
                            this.props.errorMsg(error.response.data.message);
                        }
                    });
            } catch (e) {
                this.btnDisable(false);
                this.props.errorMsg(e.message);
            }
        }
    }

    render() {
        let testID = this.props.testID ? this.props.testID : "test";
        let { povList, povEditTxt } = this.state;
        return (
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="eMed_DialogBox_Title" style={{ padding: '0.5dvw' }}>
                    <div>{"Change Purpose Of Visit"}</div>
                    <div className="eMed_DialogBox_Close">
                        <img emed_tid={`povPop_${testID}_close`} className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={this.closePopup} />
                    </div>
                </div>
                <DialogContent sx={{ width: "30dvw" }}>
                    <Autocomplete
                        inputProps={{ maxLength: 250 }}
                        options={povList}
                        getOptionLabel={(options) => options}
                        onChange={(e, value) => {
                            this.setState({
                                povEditTxt: value
                            })
                        }}
                        clearIcon={false}
                        size='small'
                        sx={{ width: '26dvw' }}
                        value={povEditTxt}
                        renderInput={(params) => <TextField
                            sx={{ width: "26dvw" }}
                            autoComplete="off"
                            onChange={(event) => {
                                this.setState({
                                    povEditTxt: event.target.value
                                })
                            }}
                            {...params}
                            placeholder="Purpose Of Visit *"
                        />}
                    />
                </DialogContent>
                <DialogActions id="eMed_DialogBox_Btn">
                    <Button emed_tid={`povPop_${testID}_cancel`} size="small"
                        variant="outlined" id="eMed_Dia_Btn"
                        onClick={this.closePopup}>
                        Cancel
                    </Button>
                    <Button emed_tid={`povPop_${testID}_change`}
                        disabled={this.state.isBtnClked} size="small"
                        variant="contained" id="eMed_Dia_Btn" onClick={() => {
                            this.postPovChange();
                        }}>
                        Change
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
export class NewCommonPatientDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    render() {
        const StyledBadge = styled(Badge)(({ theme }) => ({
            '& .MuiBadge-badge': {
              backgroundColor: 'red',
              color: 'red',
              boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
              '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
              },
            },
            '@keyframes ripple': {
              '0%': {
                transform: 'scale(.8)',
                opacity: 1,
              },
            //   '100%': {
            //     transform: 'scale(2.4)',
            //     opacity: 0,
            //   },
            },
          }));
        const { data } = this.props
        let fullName = this.props.fromPurchase ? `${data.first_name} ${data.last_name}` : data?.login_fname + " " + data?.login_lname;
        let admission_date = data?.admission_date ? formatDate(data?.admission_date) : data?.approx_check_in_date ? formatDate(data?.approx_check_in_date) : data?.appointment_date ? formatDate(data?.appointment_date) : "-";
        let surgery_date = data?.surgery_date ? formatDate(data?.surgery_date) : "-";
        let admissionDetails = `${admission_date} | ${data?.admission_time ? data?.admission_time : "-"}`
        let SurgeryDetails = `${surgery_date} | ${data?.surgery_time ? this.props.isFromOTList === true ? timeOnlyConvert(data?.surgery_time) : data?.surgery_time : "-"}`
        let PatientAccNumber = data?.patient_account_numbers ? data?.patient_account_numbers : data?.patient_account_number ? data?.patient_account_number : data?.uhid ? data?.uhid : data?.patient_acc_no ? data?.patient_acc_no : data?.patient_uhid ? data?.patient_uhid : data?.patient__patient_account_number ? data?.patient__patient_account_number :  "-";
        let photo_url = data?.patient_photo_url || data?.photo_url;
        let patient_name = data?.patient_name ? data?.patient_name : data?.first_name ? data?.first_name : data?.patient_names ? data?.patient_names : data?.name ? data?.name : "-";
        let mobile_number = data?.patient_mobile_number || data?.patient__mobile_number || data?.mobile_number || data?.patients_mobile_number || data?.patient_mobile_no || data?.patients_mobile;
        let patient_age = data?.patient_age?.toString()?.includes("Y") ? data?.patient_age : data?.age?.toString()?.includes("Y") ? data?.age : data?.patient_age_str;
        let patient_gender = data?.patient_gender || data?.gender || data?.patient__gender;
        let whatGender = ((data?.patient_gender === 'Male' || data?.patient_gender === 'm' || data?.gender === "m" || data?.gender === "M" || data?.gender === 'Male' || data?.patient__gender === "m") ? "M" : (data?.patient_gender === 'Female' || data?.patient_gender === 'f' || data?.gender === "f" || data?.gender === "Female" || data?.gender === "F" || data?.patient__gender === "f") ? "F" : (data?.patient_gender === "Trans" || data?.patient_gender === 't' || data?.gender === "t" || data?.gender === "T" || data?.patient__gender === "t") ? "T" : "-");
        let branchName = data?.branch_name ? data?.branch_name : null
        return (
            <div className="eMed_Com_Patient_Details">
                {this.props.disablePhoto ? null :
                    <div>
                        {this.props.fromUserAccess ?
                            <div className="eMed_Com_Patient_Avatar">
                                {data?.comments ? 
                                <Tooltip title={data?.comments} placement="top" arrow>
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    variant="dot"
                                >
                                    <img className="eMed_Com_Patient_AvaIcon" style={{ borderColor: (data?.patient_type === "new_patient" || data?.patient__patient_type === 1) ? "#F1584A" : (data?.patient_type === "old_patient") || (data?.patient_type === "establish_patient") || (data?.patient__patient_type === 3) ? "#800080" : '#29B6F6' }}
                                        src={data?.user_image ? data?.user_image : ImagePaths.Male.default} alt={'Avatar'} />
                                </StyledBadge></Tooltip> :
                                <img className="eMed_Com_Patient_AvaIcon" style={{ borderColor: (data?.patient_type === "new_patient" || data?.patient__patient_type === 1) ? "#F1584A" : (data?.patient_type === "old_patient") || (data?.patient_type === "establish_patient") || (data?.patient__patient_type === 3) ? "#800080" : '#29B6F6' }}
                                src={data?.user_image ? data?.user_image : ImagePaths.Male.default} alt={'Avatar'} />}
                            </div> :
                            <div className="eMed_Com_Patient_Avatar">
                                {data?.comments ? 
                                <Tooltip title={data?.comments} placement="top" arrow>
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    variant="dot"
                                >
                                <img className="eMed_Com_Patient_AvaIcon" style={{ borderColor: (data?.patient_type === "new_patient" || data?.patient__patient_type === 1) ? "#F1584A" : (data?.patient_type === "old_patient") || (data?.patient_type === "establish_patient") || (data?.patient__patient_type === 3) ? "#800080" : '#29B6F6' }}
                                    src={photo_url === null || photo_url === "" || photo_url === undefined ? data?.patient_gender === "Male" || data?.patient_gender === "m" || data?.gender === "m" || data?.gender === "Male" ? ImagePaths.Male.default : ImagePaths.Female.default : photo_url} alt={'Avatar'} />
                            </StyledBadge></Tooltip> : 
                            <img className="eMed_Com_Patient_AvaIcon" style={{ borderColor: (data?.patient_type === "new_patient" || data?.patient__patient_type === 1) ? "#F1584A" : (data?.patient_type === "old_patient") || (data?.patient_type === "establish_patient") || (data?.patient__patient_type === 3) ? "#800080" : '#29B6F6' }}
                            src={photo_url === null || photo_url === "" || photo_url === undefined ? data?.patient_gender === "Male" || data?.patient_gender === "m" || data?.gender === "m" || data?.gender === "Male" ? ImagePaths.Male.default : ImagePaths.Female.default : photo_url} alt={'Avatar'} />}
                            </div>}
                    </div>
                }
                <div>
                    <Box component={'div'} className="eMed_Pateint_Detail_LongData">
                        {this.props.fromUserAccess ?
                            <Box component={'div'}>
                                <Typography component={'div'}>
                                    {(fullName?.length) > 20 ?
                                        <Tooltip title={fullName} placement={'top'}>
                                            <div><Typography id='eMed_Com_Patient_Name' style={{ fontSize: this.props.packageDetail ? "1.25vw" : "0.8vw" }}>{((fullName) ? ((fullName.slice(0, 20) + '...')) : '-')}</Typography>
                                            </div></Tooltip> : <Typography id='eMed_Com_Patient_Name' style={{ fontSize: this.props.packageDetail ? "1.25vw" : "0.8vw" }}>{(fullName ? fullName : '-')}</Typography>}
                                </Typography>
                                <Typography id='eMed_Com_Patient_UhidText'>{(data?.phone_number ? data?.phone_number : "-")}</Typography>
                            </Box> :
                            <Box component={'div'}>
                                <Typography component={'div'}>
                                    {(patient_name?.length) > 20 ?
                                        <Tooltip title={patient_name} placement={'top'} arrow>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <Typography id='eMed_Com_Patient_Name' style={{ fontSize: this.props.packageDetail ? "1.25vw" : "0.8vw" }}>{(patient_name ? ((patient_name?.slice(0, 20) + '...')) : '-') + ' ' + (patient_age ? patient_age : '-') + '/' + (patient_gender ? whatGender : '-')}</Typography>
                                                {this.props.icon ? <div style={{ marginLeft: "0.5vw" }}>
                                                    {whatGender === "M" ? <MaleIcon sx={{ fontSize: "1vw", color: '#616161' }} /> : whatGender === "F" ? <FemaleIcon sx={{ fontSize: "1vw", color: '#616161' }} /> : null}
                                                </div> : null}
                                            </div>
                                        </Tooltip> :
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <Typography id='eMed_Com_Patient_Name' style={{ fontSize: this.props.packageDetail ? "1.25vw" : "0.8vw" }}>{(patient_name ? patient_name : '-') + ' ' + (patient_age ? patient_age : '-') + '/' + (patient_gender ? whatGender : '-')}</Typography>
                                            {this.props.icon ? <div style={{ marginLeft: "0.5vw" }}>
                                                {whatGender === "M" ? <MaleIcon sx={{ fontSize: "1vw", color: '#616161' }} /> : whatGender === "F" ? <FemaleIcon sx={{ fontSize: "1vw", color: '#616161' }} /> : null}
                                            </div> : null}
                                        </div>
                                    }
                                </Typography>
                                <Typography id='eMed_Com_Patient_UhidText'>{(PatientAccNumber) + ' | ' + (mobile_number ? mobile_number : '-')}</Typography>
                                {this.props.isCommonUhid ? <Typography id='eMed_Com_Patient_UhidText'>Branch Name: {branchName?.length > 22 ? <Tooltip placement="top" title={branchName} arrow><span id='eMed_Com_Patient_UhidText'>{branchName?.slice(0, 19) + "..."}</span></Tooltip> : branchName ? branchName : "-"}</Typography>: null}
                            </Box>
                        }

                        {this.props.showDetailed === true ?
                            <Box component={'div'}>
                                {this.props?.fromDoctor ?
                                    <Typography id='eMed_Com_Patient_Name'>
                                        {data?.op_number?.length > 15 ?
                                            <Tooltip title={data?.op_number} placement={'top'} arrow>
                                                <Typography id='eMed_Com_Patient_Name'>{data?.op_number ? data?.op_number?.length > 9 ? ('...' + data?.op_number.slice(10)) : data?.op_number : '-'}</Typography>
                                            </Tooltip> : <Typography id='eMed_Com_Patient_Name'>{data?.op_number ? data?.op_number : '-'}</Typography>}
                                    </Typography>
                                    :
                                    <Typography id='eMed_Com_Patient_Name'>
                                        {(data?.ip_number?.length > 15) || (data?.op_number?.length > 15) ?
                                            <Tooltip title={data?.ip_number ? data?.ip_number : data?.op_number} placement={'top'} arrow>
                                                <Typography id='eMed_Com_Patient_Name'>{data?.ip_number ? (data?.ip_number?.length > 9 ? ('...' + data?.ip_number.slice(14)) : data?.ip_number) : data?.op_number ? data?.op_number?.length > 9 ? ('...' + data?.op_number.slice(10)) : data?.op_number : '-'}</Typography>
                                            </Tooltip> : <Typography id='eMed_Com_Patient_Name'>{data?.ip_number ? data?.ip_number : data?.op_number ? data?.op_number : '-'}</Typography>}
                                    </Typography>
                                }
                                <Typography id='eMed_Com_Patient_UhidText'>{admission_date ? `| ${admission_date}` : '-'}</Typography>
                            </Box> : null}
                    </Box>
                    {
                        this.props.showTag === true ?
                            <Typography component={'div'}>
                                {data?.patient_tag_name?.length > 15 ?
                                    <Tooltip title={data?.patient_tag_name} placement={'top'} arrow>
                                        <Typography id='eMed_Com_Patient_UhidText'>{'Patient Tag: ' + (data?.patient_tag_name ? data?.patient_tag_name.slice(0, 15) + '...' : '-')}</Typography>
                                    </Tooltip> : <Typography id='eMed_Com_Patient_UhidText' style={{fontSize:'0.8vw' , fontWeight:"bold" , color : data?.patient_tag_color ? data?.patient_tag_color : "#000000"}}>{'Tag: ' + (data?.patient_tag_name ? data?.patient_tag_name : '-')}</Typography>}
                            </Typography> : null
                    }
                    {
                        this.props.showAdmission === true ?
                            <Typography component={'div'}>
                                {admissionDetails?.length > 15 ?
                                    <Tooltip title={admissionDetails} placement={'top'} arrow>
                                        <Typography id='eMed_Com_Patient_UhidText'>{this.props.showAproxDate ? `Approx.Admission:` : `Admission Date&Time: `}<span id='eMed_Com_Patient_admissionTxt'>{admissionDetails ? admissionDetails.slice(0, 13) + '...' : '-'}</span></Typography>
                                    </Tooltip> : <Typography id='eMed_Com_Patient_UhidText'>{this.props.showAproxDate ? `Approx.Admission:` : `Admission Date&Time: `}<span id='eMed_Com_Patient_admissionTxt'>{admissionDetails ? admissionDetails : '-'}</span></Typography>}
                            </Typography> : null
                    }
                    {
                        this.props.isFromOTList === true ?
                            <Typography component={'div'}>
                                {SurgeryDetails?.length > 15 ?
                                    <Tooltip title={SurgeryDetails} placement={'top'} arrow>
                                        <Typography id='eMed_Com_Patient_UhidText'>{`Surgery Date&Time: `}<span id='eMed_Com_Patient_admissionTxt'>{SurgeryDetails ? SurgeryDetails.slice(0, 13) + '...' : '-'}</span></Typography>
                                    </Tooltip> : <Typography id='eMed_Com_Patient_UhidText'>{`Surgery Date&Time: `}<span id='eMed_Com_Patient_admissionTxt'>{SurgeryDetails ? SurgeryDetails : '-'}</span></Typography>}
                            </Typography> : null
                    }
                </div>
            </div>
        )
    }
}