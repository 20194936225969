import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Box, Modal, Button, Tooltip, Typography } from '@mui/material'
import CommonGridHeader, { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Colors } from '../../../../Styles/Colors'
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions'
import { DataGrid } from '@mui/x-data-grid'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { Bucket_Name } from '../../../../Utility/Constants'

let AWS = require('aws-sdk');
let s3 = new AWS.S3({
  region: "ap-south-1"
});

export default class DocCollabTestReport extends Component {

  constructor(props) {
    super(props)
    this.state = {
      clinicId: '',
      patientId: '',
      appointmentId: '',
      testReportData: [],
      page: 0,
      pageSize: 10,
      imageuploadList: [],
      imageViewerPopup: false,
      viewData: [],
      viewImage: null,
      loading: false,
      selectedImage: null,
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id
    }, () => {
      this.getPatientDetailData()
      this.getTestReportData()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getTestReportData = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_TEST_REPORT + `?patient_id=${this.state.patientId}&clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              testReportData: response?.data?.data,
              loading: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  async getS3Key(doc_key) {
    try {
        const response = await RestAPIService.getAllTwo(Serviceurls.AWS_URL_GET + "?doc_key=" + encodeURIComponent(doc_key));

        if (response.data.status === "success") {
            const Data = response.data;
            return {
                data_url: Data.data
            };
        }
    } catch (e) {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: "Error fetching S3 URL"
        });
    }
  }

  async renderImage(imageData, pdfData) {
    let tempArr = [];
    if (imageData.length > 0) {
      for (let i = 0; i < imageData.length; i++) {
        let image = imageData[i].result_img_url
        if (image !== null) {
          let doc_key = image;
          let getData = await this.getS3Key(doc_key)
          getData["type"] = "image";
          tempArr.push(getData)
        }
      }
    }
    if (pdfData.length > 0) {
      for (let i = 0; i < pdfData.length; i++) {
        let pdf = pdfData[i].result_img_url
        if (pdf !== null) {
          let pdf_key = pdf;
          let getPdf = await this.getS3Key(pdf_key)
          getPdf["type"] = "pdf"
          tempArr.push(getPdf)
        }
      }
    }
    this.setState({
      viewData: tempArr,
      viewImage: tempArr[0]
    })
  }

  renderImageViewerPopup = () => {
    try {
      return (
        <Box component={'div'}>
          {
            this.state.viewData?.length > 0 ?

              < Modal
                open={this.state.imageViewerPopup}
              >
                <Box className='eMed_Doc_Img_Pop_Up'>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'1vw'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Test Report</Typography>
                    <Button
                      onClick={() => this.setState({
                        imageViewerPopup: false
                      })}
                    >
                      <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} mr={'1vw'} />
                    </Button>
                  </Box>
                  <Box height={'30vw'}>
                    {this.state.viewImage.type === "pdf" ?
                      <Box>
                        <img style={{ width: '12vw', height: '12vw' }} src={ImagePaths.DocumentPreview.default} alt="slide" />
                        <Button variant="contained" onClick={() => { window.open(this.state.viewImage.data_url) }}>Download Document</Button>
                      </Box>
                      :
                      <Box component={'div'} height={'30vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <img src={this.state.viewImage.data_url ? this.state.viewImage.data_url : ""} alt="slide" style={{ objectFit: 'contain', height: '15vw', width: '30vw' }} />
                      </Box>}
                  </Box>
                  {/* <Box className="eMed_testrp_div" direction="row" justifyContent="space-between" alignItems="center">
                    <IconButton size="small" className="eMed_testrp_arrow" onClick={() => { this.prevImage(this.state.currentIndex) }}><KeyboardDoubleArrowLeftIcon className="eMed_testrp_arrowSize" fontSize="large" color="primary" /></IconButton>
                    <IconButton size="small" className="eMed_testrp_arrow" onClick={() => { this.nextImage(this.state.currentIndex) }}><KeyboardDoubleArrowRightIcon className="eMed_testrp_arrowSize" fontSize="large" color="primary" /></IconButton>
                  </Box> */}
                </Box>
              </Modal> : null
          }
        </Box >
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getTestReportPrint = (resultSummaryId,labId) => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + `?result_summary_id=${resultSummaryId}&laboratory_id=${labId}`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    this.state.testReportData?.forEach((element,index) => element.sno = index + 1)
    const columns = [
      {
        field: 'report_date', headerName: 'Reported Date', flex: 0.25,
        renderCell: (params) => (<Box>{params?.row?.report_date ? formatDate(params?.row?.report_date) : '-'}</Box>)
      },
      {
        field: 'report_type', headerName: 'Report Type', flex: 0.25,
        renderCell: (params) => (<Box>{params?.row?.report_type ? params?.row?.report_type?.length > 15 ?
          <Tooltip title={params?.row?.report_type} placement='top'><div>{params?.row?.report_type?.slice(0, 15) + '...'}</div></Tooltip> :
          params?.row?.report_type : '-'}</Box>)
      },
      {
        field: 'test_name', headerName: 'Test Details', flex: 0.25,
        renderCell: (params) => (<Box>{params?.row?.test_name ? params?.row?.test_name?.length > 15 ?
          <Tooltip title={params?.row?.test_name} placement='top'><div>{params?.row?.test_name?.slice(0, 15) + '...'}</div></Tooltip> :
          params?.row?.test_name : '-'}</Box>)
      },
      {
        field: 'action', headerName: 'Action', flex: 0.25,
        renderCell: (params) => (
          <Box>
              {(params?.row?.status === 'Result Inprogress') || (params?.row?.status === "Specimen Collected") ? params?.row?.status :
                ((params?.row?.status === 'Result Delivered') || (params?.row?.status === 'Result Completed')) ?
                  <Button sx={{ textTransform: 'capitalize' }} className='Common_Btn_Min_Width'
                    onClick={() => { this.getTestReportPrint(params?.row?.id,params?.row?.laboratory_id) }}
                  >View</Button>
                  :
                  <Button sx={{ textTransform: 'capitalize' }} className='Common_Btn_Min_Width'
                    onClick={() => {
                      if (params?.row?.file_name?.length > 0) {
                        let imageFile = params?.row?.file_name?.length > 0 ? params?.row?.file_name : [];
                        let pdfFile = params?.row?.pdf_file?.length > 0 ? params?.row?.pdf_file : [];
                        this.setState({
                          imageViewerPopup: true,
                          viewOpen: true,
                          viewData: [],
                          viewImage: null
                        }, () => this.renderImage(imageFile, pdfFile))
                      } else {
                        this.errorMessage('No image to be view')
                      }
                    }}
                  >View</Button>
              }
            </Box>), disableExport: true
      },
    ]
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true}/>
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'63vh'} overflow={'scroll'}>
          <DataGrid
            rows={this.state.testReportData}
            columns={columns}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            sx={{ backgroundColor: Colors.white }}
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay: () => (
                <Box className='eMed_conf_nodata'>
                  {'No Records To Be Shown'}
                </Box>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
            loading={this.state.loading}
          />
        </Box>
        {this.renderImageViewerPopup()}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
