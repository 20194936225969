import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';


class VendorBill extends Component {

    constructor(props) {
        super(props)
        this.state = {
            vendorBillData: [],
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterOpen: false,
            pur_Amt: 0,
            bill_Amt: 0,
            gst_Amt: 0,
            isLoader: false,
            dateIndex: 1,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            searchkey: '',
            search_PO_num: '',
            "days":30,
            tcsAmt:false,
            total_tcs_amount:0,
            enableMultiPharmaFilter: JSON.parse(localGetItem("loggedInUserInfo"))?.enable_multi_pharmacy_filter ,
            pharma_list_names : "",
            pharmacyId :null,
            pharmaList : [],
        }
    }

    componentDidMount() {
        let tcsAmt = JSON.parse(localGetItem('loggedInUserInfo'))
        this.setState({tcsAmt:tcsAmt?.tcs_purchase_applicable})
        this.getVendorBillData()
        this.getPharmaList()

    }

    getVendorBillData = () => {
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(Serviceurls.PHARMA_VENDOR_BILL + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&vendor_name=${this.state.searchkey}&po_number=${this.state.search_PO_num}&pharmacy_id=${this.state.pharmacyId ? this.state.pharmacyId : ""}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        let ReturnAPiData = response.data.data?.result ? response.data.data?.result : []
                        let ReturnFinalData = []
                        ReturnAPiData.forEach((list) => {
                            let GSTAmount = (list?.cgst_amount ? list?.cgst_amount : 0) + (list?.sgst_amount ? list?.sgst_amount : 0)
                            list.total_gst_amount = GSTAmount
                            ReturnFinalData.push(list)
                        })
                        this.setState({
                            vendorBillData: ReturnFinalData,
                            pur_Amt: response.data.data?.total_purchase_amount,
                            bill_Amt: response.data.data?.total_bill_amount,
                            gst_Amt: response.data.data?.total_gst_amount,
                            rowCount: response.data.data?.total_count,
                            total_tcs_amount: response.data.data?.total_tcs_amount
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
        }
    }

    getVendorBillExcel = () => {
        try {
            RestAPIService.excelGet(Serviceurls.PHARMA_VENDOR_BILL + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&vendor_name=${this.state.searchkey}&po_number=${this.state.search_PO_num}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&pharmacy_id=${this.state.pharmacyId ? this.state.pharmacyId : ""}&export=excel`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                })
        } catch (error) {
            this.errorMessage(error.message);
        }
    }

    getVendorBillPrint = () => {
        try {
            this.setState({disableBtn: true})
          RestAPIService.getAll(Serviceurls.PHARMA_VENDOR_BILL + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&vendor_name=${this.state.searchkey}&po_number=${this.state.search_PO_num}&pharmacy_id=${this.state.pharmacyId ? this.state.pharmacyId : ""}&export=pdf`).
            then((response) => {
              const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
              this.setState({disableBtn: false})
            }).catch((error) => {
              if(error?.response?.data?.message){
                this.errorMessage(error?.response?.data?.message)
              }else{
                this.errorMessage(error.message)
              }
              this.setState({disableBtn: false})
            })
        } catch (error) {
          this.errorMessage(error.message)
          this.setState({disableBtn: false})
        }
      }
    getPharmaList = () => {
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(Serviceurls.PHA_BRANCH_TOLOC)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        let pharmaList = response?.data?.data ? response?.data?.data : []
                        this.setState({
                            pharmaList: pharmaList
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error?.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false);
            this.errorMessage(error?.message)
        }
    }


    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    filterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props;
        let states = this.state;
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button emed_tid='purord_close' onClick={() => { this.setState({ filterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle' height={'35vh'}>
                    <Box sx={{ width: "40%", margin: "1vw", marginLeft: "14vw", }}>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.filterDate.bind(this)}
                            HideAllMenu={true}
                            days={this.state.days}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            label={t("VendorName")}
                            sx={{ width: "20vw" }}
                            size="small"
                            autoComplete="off"
                            inputProps={{ emed_tid: 'VendorName' }}
                            value={states.searchkey ? states.searchkey : ""}
                            onChange={(e) => {
                                this.setState({
                                    searchkey: e.target.value.trimStart()
                                })
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            label={t("PO Number")}
                            sx={{ width: "20vw" }}
                            size="small"
                            autoComplete="off"
                            inputProps={{ emed_tid: 'PoNUmber' }}
                            value={states.search_PO_num ? states.search_PO_num : ""}
                            onChange={(e) => {
                                this.setState({
                                    search_PO_num: e.target.value.trimStart()
                                })
                            }}
                        />
                    </Box>
                    {this.state.enableMultiPharmaFilter ? <Box>
                        <Autocomplete
                            size='small'
                            sx={{ width: "20vw", margin: "1vw 1vw" }}
                            options={this.state.pharmaList}
                            getOptionLabel={(option) => (typeof (option) === "string" ? option : option?.pharmacy_name)}
                            value={this.state.pharma_list_names}
                            onChange={(event, newValue) => {
                                this.state.pharma_list_names = newValue?.pharmacy_name
                                this.state.pharmacyId = newValue?.pharmacy_id
                                this.setState({ pharma_list_names: this.state.pharma_list_names, pharmacyId: this.state.pharmacyId })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Combined With')}
                                    InputProps={{
                                        ...params.InputProps,
                                        emed_tid: "combined_with"
                                    }}
                                />
                            )}
                        />

                    </Box> : null}

                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        emed_tid='fil_clear'
                        onClick={() => {
                            this.setState({
                                searchkey: "",
                                search_PO_num: "",
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                pharma_list_names : "",
                                pharmacyId :"",
                            }, () => this.getVendorBillData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        emed_tid='fil_search'
                        onClick={() => {
                            this.getVendorBillData()
                            this.setState({
                                filterOpen: false,
                            })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    closeFilter = () => {
        this.setState({ filterOpen: false })
    }

    render() {
        const { t } = this.props
        this.state.vendorBillData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            { field: 'sno', headerName: t('SNo'), sortable: false, flex: 0.05 },
            {
                field: 'vendor_name', headerName: "Vendor Name", flex: 0.15, type: 'string',
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.vendor_name?.length > 15 ? <Tooltip placement='top' title={params?.row?.vendor_name} arrow><div>{params?.row?.vendor_name?.slice(0, 15) + '...'}</div></Tooltip> :
                        params?.row?.vendor_name ? params?.row?.vendor_name : '-'}
                </Box>)
            },
            {
                field: 'vendor_gst_no', headerName: "GSTIN No", flex: 0.1, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.vendor_gst_no?.length > 15 ? <Tooltip placement='top' title={params?.row?.vendor_gst_no} arrow><div>{params?.row?.vendor_gst_no?.slice(0, 15) + '...'}</div></Tooltip> :
                        params?.row?.vendor_gst_no ? params?.row?.vendor_gst_no : '-'}
                </Box>)
            },
            {
                field: 'purchase_order_number', headerName: "PO Number", flex: 0.1, type: 'number',
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.purchase_order_number?.length > 12 ? <Tooltip placement='top' title={params?.row?.purchase_order_number} arrow><div>{params?.row?.purchase_order_number?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.purchase_order_number ? params?.row?.purchase_order_number : '-'}
                </Box>)
            },
            {
                field: 'bill_no', headerName: `${"Bill No"}`, flex: 0.1, type: "number",
                renderCell: (params) => (<Box>
                    {params?.row?.bill_no?.length > 10 ? <Tooltip placement='top' title={params?.row?.bill_no} arrow><div>{'...' + params?.row?.bill_no?.slice(-10)}</div></Tooltip> :
                        params?.row?.bill_no ? params?.row?.bill_no : '-'}
                </Box>)
            },
            {
                field: 'bill_date', headerName: `${"Bill Date"}`, flex: 0.1, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box>{params.row?.bill_date ? formatDate(params.row?.bill_date) : '-'}</Box>)
            },
            {
                field: 'purchase_amount', headerName: `${"Purchase Amount"} (${CurrencySymbol})`, flex: 0.1, type: "number",
                renderCell: (params) => (<Box>{params.row?.purchase_amount ? AmountFormat(params.row?.purchase_amount)?.replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
            },
            {
                field: 'total_gst_amount', headerName: `${"GST Amount"} (${CurrencySymbol})`, flex: 0.1, type: "number",
                renderCell: (params) => (<Box>{(params.row?.total_gst_amount) ? AmountFormat(params.row?.total_gst_amount)?.replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
            },
            {
                field: 'tcs_amount', headerName: `${"TCS"} (${CurrencySymbol})`, flex: 0.1, type: "number",
                renderCell: (params) => (<Box>{(params.row?.tcs_amount) ? AmountFormat(params.row?.tcs_amount)?.replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
            },
            {
                field: 'total_amount', headerName: `${"Bill Amount"} (${CurrencySymbol})`, flex: 0.1, type: "number",
                renderCell: (params) => (<Box>{(params.row?.total_amount) ? AmountFormat(params.row?.total_amount)?.replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
            },
        ]
    // Commented due to this flow stopped and incase use for furture use
        // const totalgstamount = columns.findIndex(col => col.field === 'total_gst_amount');
        // if(this.state.tcsAmt){
        //     columns.splice(totalgstamount + 1, 0, {
        //         field: 'tcs_amount', headerName: `${"TCS"} (${CurrencySymbol})`, flex: 0.1, type: "number",
        //         renderCell: (params) => (<Box>{params.row?.tcs_amount ? params.row?.tcs_amount : '0'}</Box>)
        //     });
        // }
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {AmountsCard("Purchase Amount", this.state.pur_Amt, false, "white", false, null, 'rpt_consoli_pur_total')}
                        {AmountsCard("GST Amount", this.state.gst_Amt, false, "white", false, null, 'rpt_consoli_pro_total')}
                        {AmountsCard("Bill Amount", this.state.bill_Amt, false, "white", false, null, 'rpt_consoli_sal_total')}
                        {AmountsCard("TCS", this.state.total_tcs_amount, false, "white", false, null, 'rpt_consoli_sal_tcs')}

                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid="rpt_consoli_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_consoli_flt" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getVendorBillPrint()}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        {/* <Button emed_tid="rpt_purOver_export" className='eMed_Pharma_rts_btn' onClick={() => this.getVendorBillExcel()}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.vendorBillData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        initialState={{
                            columns:{
                                columnVisibilityModel:{
                                    tcs_amount:false
                                }
                            }
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(VendorBill)